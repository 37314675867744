import { adalApiFetch, apiUrl, adalApiFetchDelete } from "../adalConfig";

export const getGroupListByCompanyAndSubscriptionId = (
  subscriptionId: any,
  companyId: any
) => {
  const url =
    `${apiUrl}/GetGroups?subscriptionId=` +
    subscriptionId +
    `&companyid=` +
    companyId;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const getGroupMemberListByGroupEmail = (groupEmail: any) => {
  const url = `${apiUrl}/GetGroupMembers?GroupEmail=` + groupEmail;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const getGroupNotMemberListByGroupEmail = (groupEmail: any) => {
  const url = `${apiUrl}/GetUsersnotAmember?GroupEmail=` + groupEmail;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const addGroupMemberListByGroupEmail = (list: any, groupEmail: any) => {
  const url = `${apiUrl}/AddUsertoGroupEmailasMember?GroupEmail=` + groupEmail;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(list),
  });
};
export const removeMemberGroup = (groupEmail: any, memberEmail: any) => {
  const url = `${apiUrl}/RemoveMemberGroup?GroupEmail=${groupEmail}&MemberEmail=${memberEmail}`;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

// export const removeMemberGroup = (groupEmail: any, memberEmail: any) => {
//   const url =
//     `${apiUrl}/RemoveMemberGroup?GroupEmail=` +
//     groupEmail +
//     "&MemberEmail=" +
//     memberEmail;
//   return adalApiFetchDelete(url, {
//     method: "DELETE",
//     headers: {
//       accept: "application/json;",
//       "Content-Type": "application/json",
//     },
//   });
// };
