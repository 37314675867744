import * as React from "react";
import Select, { components } from "react-select";
//import * as commonMethod from "../../commonMethods";
import ConfirmationDeleteComponent from "../sharedComponents/confirmationDeleteComponent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { getLoggedInUSerName } from "../../adalConfig";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
//import * as subscriptionService from "../../services/subscriptionComponent.service";
//import { CSVLink, CSVDownload } from "react-csv";
//import { Link } from "react-router-dom";
//import csvfile_image from "../../image/file.png";

const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};


export class SubscriptionAddUpdateComponent extends React.Component<any, any> {
  csvPartnerBilling: React.RefObject<any>;
  constructor(props: Readonly<{}>) {
    super(props);
    //csvLinkx =React.useRef<any | null>(null);
    this.csvPartnerBilling = React.createRef();
    this.state = {
      Id: this.props.calledFrom === "edit" ? this.props.editData.Id : 0,
      CompanyId:
        this.props.calledFrom === "edit"
          ? this.props.editData.CompanyId
          : this.props.selectedCompanyId !== ""
            ? this.props.selectedCompanyId
            : "",
      companyVersion: this.props.companyVersion,
      PaymentType:
        this.props.calledFrom === "edit"
          ? this.props.editData.PaymentType
          : -10,
      Credit:
        this.props.calledFrom === "edit" && this.props.editData.Credit
          ? this.props.editData.Credit.toFixed(4)
          : (0).toFixed(4),
      UsedCredit:
        this.props.calledFrom === "edit" && this.props.editData.UsedCredit
          ? this.props.editData.UsedCredit.toFixed(4) || (0).toFixed(4)
          : (0).toFixed(4),
      Name: this.props.calledFrom === "edit" ? this.props.editData.Name : "",
      // #3461  //Maksud
      TenantId: this.props.calledFrom === "edit" ? this.props.tenantId : "",
      ContactLookupEnabled: this.props.calledFrom === "edit" ? this.props.editData.ContactLookupEnabled : false,

      BillingInformation:
        this.props.calledFrom === "edit"
          ? this.props.editData.BillingInformation
          : "",
      UserLicenseLimitation:
        this.props.calledFrom === "edit"
          ? this.props.editData.UserLicenseLimitation
          : 1,
      Disabled:
        this.props.calledFrom === "edit" ? this.props.editData.Disabled : false,
      EnableTranslation:
        this.props.calledFrom === "edit"
          ? this.props.editData.EnableTranslation
          : false,
      EnableLists:
        this.props.calledFrom === "edit"
          ? this.props.editData.EnableLists
          : false,
      IncludeGroups:
        this.props.calledFrom === "edit"
          ? this.props.editData.IncludeGroups
          : false,
      ServicePlanId:
        this.props.calledFrom === "edit"
          ? this.props.editData.ServicePlanId
          : 1,
      MemberLimit:
        this.props.calledFrom === "edit" ? this.props.editData.MembersLimit : 0,
      monthlyFreeCredit:
        this.props.editData.MonthlyFreeCredit &&
          this.props.editData.MonthlyFreeCredit === -1
          ? "Unlimited"
          : this.props.editData.MonthlyFreeCredit
            ? this.props.editData.MonthlyFreeCredit.toFixed(4)
            : (0).toFixed(4),
      freeCreditUsed:
        this.props.calledFrom === "edit" && this.props.editData.UsedFreeCredit
          ? this.props.editData.UsedFreeCredit.toFixed(4)
          : (0).toFixed(4),
      freeCreditRemaining: this.props.editData.FreeCreditBalance
        ? this.props.editData.FreeCreditBalance.toFixed(4)
        : (0).toFixed(4),
      emailEnabled: this.props.editData
        ? this.props.editData.EmailEnabled || false
        : false,
      contactEmail: this.props.editData
        ? this.props.editData.ContactEmail || ""
        : "",
      ErrorName: "",
      ErrorCompanyID: "",
      SelectedCompanyName: "",
      ErrorUserLicenseLimitation: "",
      ErrorServiePlanId: "",
      servicePlanList: [],
      SelectedServiceName: "Select...",
      includeGroupLocked: false,
      activeTab: "Subscription-Details",
      Provider:
        this.props.calledFrom === "edit"
          ? this.props.editData.ServiceProviderID
          : 0,
      freeCreditRenewalDate:
        !new Date(this.props.editData.FreeCreditRenewalDate).getFullYear() ||
          new Date(this.props.editData.FreeCreditRenewalDate).getFullYear() <=
          1970
          ? ""
          : format(
            new Date(parseISO(this.props.editData.FreeCreditRenewalDate)),
            "yyyy-MM-dd"
          ),
      isDate: false,
      lowPurchasedCreditThreshold:
        this.props?.editData?.LowCreditThreshold || 0,
      lowFreeCreditThreshold: this.props?.editData?.LowFreeCreditThreshold || 0,
      lowCreditMessage:
        this.props?.editData?.CreditAlert === false ? false : true,
      lowCreditEmail:
        this.props?.editData?.EmailCreditAlert === false ? false : true,
      BotMediaDaysToExpire:
        this.props?.editData?.BotMediaDaysToExpire || 365,
    };
  }

  componentDidMount() {

    if (this.state.TenantId !== null && this.state.TenantId !== undefined && this.state.TenantId !== "undefined") {
      this.setState({
        TenantId: this.mask(this.state.TenantId, '-', '*')
      });
    }
    if (Object.keys(this.props.editData).length !== 0) {
      
      var selectedServiceName = "Select..."
      if(this.props.calledFrom === "edit")
      {
       selectedServiceName =
        this.state.servicePlanList !== undefined
          ? this.state.servicePlanList.length !== 0
            ? this.props.editData.ServicePlanId !== 0
              ? this.state.servicePlanList
                .filter(
                  (x: any) => x.Id === this.props.editData.ServicePlanId
                )
                .map((x: any) => x.ServicePlan)[0]
              : "Select"
            : ""
          : "";
      }
     
      var selectedPArentCompanyName =
        this.props.companyListProps !== undefined
          ? this.props.companyListProps.length !== 0
            ? this.props.editData.CompanyId !== 0
              ? this.props.companyListProps
                .filter((x: any) => x.Id === this.props.editData.CompanyId)
                .map((x: any) => x.Name)[0]
              : "Select"
            : ""
          : "";
      this.setState({
        SelectedServiceName: selectedServiceName,
        SelectedCompanyName: selectedPArentCompanyName,
      });
    }

    //console.log(this.props.editData);
    //console.log(this.state.freeCreditRemaining);
    //console.log(parseISO(this.props.editData.FreeCreditRenewalDate));
  }

  mask = (fullString: string | string, delimeter: string, mask: string) => {
    return fullString.split(delimeter).map(t => {
      return String(t).slice(0, 0).padStart(String(t).length, mask);
    }).join(delimeter);
  }

  addUpdateSubscriptionData = (e: any) => {
    if (this.IsValid() === true) {
      let userData = getLoggedInUSerName();
      if (
        this.state.emailEnabled !== this.props.editData.EmailEnabled ||
        this.state.contactEmail !== this.props.editData.ContactEmail
      ) {
        let emailObj = {
          enabled: this.state.emailEnabled,
          contactEmail: this.state.contactEmail,
          subscriptionId: this.state.Id,
        };

        this.props.updateSubscriptionEmailNotifications(emailObj);
        //console.log(emailObj);
      }
      const lowCreditObj = {
        lowCreditThreshold: this.state.lowPurchasedCreditThreshold || 0,
        lowFreeCreditThreshold: this.state.lowFreeCreditThreshold || 0,
        creditAlert: this.state.lowCreditMessage,
        emailCreditAlert: this.state.lowCreditEmail,
      };
      if (this.props.calledFrom === "add") {
        //var id = parseInt(this.props.totalSubscriberListCount) + 1;

        let data = {
          CompanyId: this.state.CompanyId,
          PaymentType: this.state.PaymentType,
          Credit:
            this.state.Credit === "" || this.state.Credit === 0
              ? 1
              : parseFloat(this.state.Credit).toFixed(4),
          UsedCredit: this.state.UsedCredit,
          // Users: {},
          Name: this.state.Name,
          // #3461  //Maksud
          ContactLookupEnabled: this.state.ContactLookupEnabled,

          BillingInformation: this.state.BillingInformation,
          UserLicenseLimitation: this.state.UserLicenseLimitation,
          Disabled: this.state.Disabled,
          EnableTranslation: this.state.EnableTranslation,
          EnableLists: this.state.EnableLists,
          ServicePlanId: this.state.ServicePlanId,
          IncludeGroups: this.state.IncludeGroups,
          MembersLimit: this.state.MemberLimit,
          ServiceProviderID: this.state.Provider,
          CreatedBy: userData.userName, //this field needs to be powered by an API in relation to the Modifier
          ModifiedBy: userData.userName,
          CreatedDateTime: new Date(),
          ModifiedDateTime: new Date(),
          BlockingSchema: null,
          BotMediaDaysToExpire: this.state.BotMediaDaysToExpire,
          FreeTextQty: 0,
          TextPrice: 0,
          MonthlyFreeCredit:
            this.state.monthlyFreeCredit === "Unlimited"
              ? -1
              : parseFloat(this.state.monthlyFreeCredit).toFixed(4),
          FreeCreditbalance:
            this.state.freeCreditRemaining === "Unlimited"
              ? -1
              : parseFloat(this.state.freeCreditRemaining).toFixed(4),
          UsedFreeCredit: this.state.freeCreditUsed,
          StripeSubscriptionID: 0,
          FreeCreditRenewalDate: new Date(),
          ...lowCreditObj,
        };
        this.props.addUpdateSubscriptionProp("add", data);

      } else {
        let dataBody = {
          id: this.state.Id,
          companyId: this.state.CompanyId,
          paymentType: this.state.PaymentType,
          credit:
            this.state.Credit === "" || this.state.Credit === 0
              ? 1
              : parseFloat(this.state.Credit).toFixed(4),
          usedCredit: this.state.UsedCredit,
          users: {},
          name: this.state.Name,

          // #3461  //Maksud
          contactLookupEnabled: this.state.ContactLookupEnabled,

          billingInformation: this.state.BillingInformation,
          userLicenseLimitation: this.state.UserLicenseLimitation,
          disabled: this.state.Disabled,
          EnableTranslation: this.state.EnableTranslation,
          EnableLists: this.state.EnableLists,
          ServicePlanId: this.state.ServicePlanId,
          IncludeGroups: this.state.IncludeGroups,
          MembersLimit: this.state.MemberLimit,
          ServiceProviderID: this.state.Provider,
          CreatedBy: userData.userName, //this field needs to be powered by an API in relation to the Modifier
          ModifiedBy: userData.userName,
          CreatedDateTime: new Date(),
          ModifiedDateTime: new Date(),
          BlockingSchema: "",
          BotMediaDaysToExpire: this.state.BotMediaDaysToExpire,
          FreeTextQty: 0,
          TextPrice: 0,
          MonthlyFreeCredit:
            this.state.monthlyFreeCredit === "Unlimited"
              ? -1
              : parseFloat(this.state.monthlyFreeCredit).toFixed(4),
          FreeCreditbalance:
            this.state.freeCreditRemaining === "Unlimited"
              ? -1
              : parseFloat(this.state.freeCreditRemaining),
          UsedFreeCredit: this.state.freeCreditUsed,
          StripeSubscriptionID: 0,
          FreeCreditRenewalDate: new Date(this.state.freeCreditRenewalDate),
          ContactEmail: this.state.contactEmail,
          EmailEnabled: this.state.emailEnabled,
          ...lowCreditObj,
        };
        this.props.addUpdateSubscriptionProp("edit", dataBody);

        // #3461  //Maksud

        this.props.updateSubscriptionContactLookupProp(this.state.Id,
          (this.props.tenantId !== null && this.props.tenantId !== undefined && this.props.tenantId !== "undefined")
            ? this.mask(this.props.tenantId, '-', '*') !== this.state.TenantId ? this.state.TenantId
              : this.props.tenantId
            : this.props.tenantId
          , this.state.ContactLookupEnabled);

      }

    }
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.servicePlan !== prevState.servicePlanList) {
      return {
        servicePlanList: nextProps.servicePlan,
      };
    } else return null;
  }
  IsValid() {
    var isValid = true;
    if (!this.state.Name) {
      this.setState({ ErrorName: "This Field is Required" });
      isValid = false;
    }
    if (this.state.CompanyId.toLocaleString().length === 0) {
      isValid = false;
      this.setState({
        ErrorCompanyID: "This Field is Required",
      });
    }
    if (this.state.ErrorServiePlanId !== "") {
      isValid = false;
    }
    return isValid;
  }

  //by Niyi
  getProvider = () => {
    const iterator = this.props.providersList.values();
    let value = "";
    for (const providr of iterator) {
      if (providr.Id === this.state.Provider) {
        value = providr.ProviderName;
      }
    }
    return value;
  };
  enforceFloat = (value: any) => {
    let valid = /^\-?\d+\.\d*$|^\-?[\d]*$/;
    let number = /\-\d+\.\d*|\-[\d]*|[\d]+\.[\d]*|[\d]+/;
    if (!valid.test(value)) {
      let n = value.match(number);
      value = n ? n[0] : "";
    }
  };
  forceNumeric = (value: any) => {
    if (!value.match(/^-?[0-9]*[.][0-9]+$/)) {
      return 0;
    }
  };

  alterDateState = () => {
    this.setState({ isDate: !this.state.isDate });
  };

  renderHtml() {
    return (
      <div className="nav-tabs-top">
        <div className="tab-content border-0">
          <div className="tab-pane fade show active" id="user-edit-account">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="formDiv">
                    <div className="row text-left">
                      <div className="col-md-4">
                        <div
                          className={
                            this.state.ErrorName !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Name<small className="text-muted">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                Name: e.target.value,
                                ErrorName: "",
                              });
                            }}
                            value={this.state.Name}
                          />
                          <small className="form-text ">
                            {this.state.ErrorName}{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Monthly Free Credit
                          </label>
                          <input
                            type="text"
                            disabled={false}
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                monthlyFreeCredit: (
                                  e.target as HTMLInputElement
                                ).value.replace(/[^-{0,1}\d+\.{0,1}\d*$]/, ""),
                              })
                            }
                            value={this.state.monthlyFreeCredit}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group pTop30">
                          <label className="custom-control custom-checkbox m-0 checkbox_right checkbox_right_xl">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.lowCreditMessage}
                              onChange={(e) => {
                                this.setState({
                                  lowCreditMessage: e.target.checked,
                                });
                              }}
                            />
                            <span className="custom-control-label">
                              Low Credit Message
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4">
                        <div
                          className={
                            this.state.ErrorServiePlanId !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Subscription Plan
                          </label>
                          <Select
                            components={{ DropdownIndicator }}
                            placeholder="Select.."
                            className={
                              this.state.ErrorServiePlanId !== ""
                                ? "subscriptionPlan validationClass"
                                : "subscriptionPlan"
                            }
                            value={[
                              {
                                label: (this.state.SelectedServiceName ==="" || this.state.SelectedServiceName  ===undefined) ? this.state.servicePlanList !== undefined
                                  ? this.state.servicePlanList.length !== 0
                                    ? this.props.editData.ServicePlanId !== 0
                                      ? this.state.servicePlanList
                                        .filter(
                                          (x: any) => x.Id === this.props.editData.ServicePlanId
                                        )
                                        .map((x: any) => x.ServicePlan)[0]
                                      : "Select"
                                    : ""
                                  : ""
                                  :this.state.SelectedServiceName,
                                value: this.state.ServicePlanId,
                              },
                            ]}
                            onChange={(opt: any) => {
                              if (opt.value === 2 || opt.value === 3 || opt.value === 1) {
                                this.setState({
                                  IncludeGroups: false,
                                  includeGroupLocked: true,
                                });
                              } else if (opt.value === 6 || opt.value === 4 || opt.value === 7) {
                                this.setState({
                                  IncludeGroups: true,
                                  includeGroupLocked: true,
                                });
                              } else {
                                this.setState({
                                  includeGroupLocked: false,
                                });
                              }
                              this.setState({
                                ServicePlanId: opt.value,
                                SelectedServiceName: opt.label,
                                ErrorServiePlanId: "",
                              });
                            }}
                            options={
                              this.props.servicePlan !== undefined
                                ? this.props.servicePlan.map((opt: any) => ({
                                  label: opt.ServicePlan,
                                  value: opt.ServicePlanId,
                                }))
                                : []
                            }
                          />

                          <small className="form-text ">
                            {this.state.ErrorServiePlanId}{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Free Credit Renewal Date
                          </label>
                          <input
                            type="Date"
                            disabled={false}
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                freeCreditRenewalDate: e.target.value,
                              })
                            }
                            value={this.state.freeCreditRenewalDate}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group pTop30">
                          <label className="custom-control custom-checkbox m-0 checkbox_right checkbox_right_xl">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              checked={this.state.lowCreditEmail}
                              onChange={(e) => {
                                this.setState({
                                  lowCreditEmail: e.target.checked,
                                });
                              }}
                            />
                            <span className="custom-control-label">
                              Low Credit Email
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Payment Type</label>

                          <Select
                            isSearchable={false}
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            value={[
                              {
                                label:
                                  this.state.PaymentType === -10
                                    ? "Pre Paid"
                                    : "Post Paid",
                                value: this.state.PaymentType,
                              },
                            ]}
                            onChange={(opt: any) => {
                              this.setState({
                                PaymentType: parseInt(opt.value),
                              });
                            }}
                            options={[
                              { label: "Pre Paid", value: -10 },
                              { label: "Post Paid", value: 10 },
                            ]}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Free Credit Used</label>
                          <input
                            type="number"
                            disabled={true}
                            className="form-control non-editable"
                            onChange={(e) => {
                              this.setState({
                                freeCreditUsed: parseInt(e.target.value),
                              });
                            }}
                            value={this.state.freeCreditUsed}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lowFreeCreditThreshold"
                          >
                            Low Free Credit Threshold
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="lowFreeCreditThreshold"
                            value={this.state.lowFreeCreditThreshold}
                            onChange={(e) => {
                              this.setState({
                                lowFreeCreditThreshold: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4 col-md-offset-4">
                        <div className="form-group ">

                          <label className="form-label ">{this.state.companyVersion === 2 ? "Inbox Licenses" : "User Licenses"}</label>
                          <input
                            type="number"
                            className={this.state.companyVersion === 2 ? "form-control number-arrow-none" : "form-control"}
                            min="1"
                            onChange={(e) => {

                              if (this.state.companyVersion === 2) {
                                if (
                                  parseInt(e.target.value) === null ||
                                  parseInt(e.target.value) < 0
                                ) {
                                  return false;
                                }
                              }
                              this.setState({
                                UserLicenseLimitation: e.target.value,
                              });
                            }}
                            value={this.state.UserLicenseLimitation}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Free Credit Remaining
                          </label>
                          <input
                            type="text"
                            placeholder="Unlimited"
                            disabled={false}
                            className="form-control"
                            onChange={(e) => {
                              const parsedValue = e.target.value.replace(
                                /[^-{0,1}\d+\.{0,1}\d*$]/,
                                ""
                              );
                              this.setState({
                                freeCreditRemaining: parsedValue,
                              });
                            }}
                            value={this.state.freeCreditRemaining}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="lowPurchasedCreditThreshold"
                          >
                            Low Credit Threshold
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="lowPurchasedCreditThreshold"
                            value={this.state.lowPurchasedCreditThreshold}
                            onChange={(e) => {
                              this.setState({
                                lowPurchasedCreditThreshold: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            {this.state.companyVersion === 2 ? "User Licenses" : "Group Member Licenses"}

                          </label>

                          {
                            this.state.companyVersion === 2 ?
                              (
                                <input
                                  type="number"
                                  className={this.state.companyVersion === 2 ? "form-control number-arrow-none" : "form-control"}
                                  min="0"
                                  onChange={(e) => {
                                    if ( parseInt(e.target.value) === null ||parseInt(e.target.value) < 0 ) {
                                      return false;
                                    }
                                    this.setState({
                                      MemberLimit: parseInt(e.target.value),
                                    });
                                  }}
                                  value={this.state.MemberLimit}
                                />
                              )
                              :
                              (
                                <input
                                  type="number"
                                  className="form-control"
                                  min="0"
                                  step="10"
                                  onChange={(e) => {
                                    if (parseInt(e.target.value) === null ||parseInt(e.target.value) % 10 !== 0 ) {
                                      return false;
                                    }

                                    this.setState({
                                      MemberLimit: parseInt(e.target.value),
                                    });
                                  }}
                                  value={this.state.MemberLimit}
                                />
                              )
                          }

                        </div>
                      </div>

                      <div className="col-md-4 col-md-offset-4">
                        <div className={"form-group"}>
                          <label className="form-label">
                            Purchased Credit Remaining
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({
                                ErrorCredit: "",
                                Credit: (
                                  e.target as HTMLInputElement
                                ).value.replace(/[^-{0,1}\d+\.{0,1}\d*$]/, ""),
                              });
                            }}
                            value={this.state.Credit}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="BotMediaDaysToExpire"
                          >
                            Bot Media Days To Expire
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="BotMediaDaysToExpire"
                            value={this.state.BotMediaDaysToExpire}
                            onChange={(e) => {
                              this.setState({
                                BotMediaDaysToExpire: e.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">Provider</label>

                          <Select
                            isSearchable={false}
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            value={[
                              {
                                label: this.getProvider(),
                                value: this.state.PaymentType,
                              },
                            ]}
                            onChange={(opt: any) => {
                              this.setState({ Provider: parseInt(opt.value) });
                            }}
                            options={this.props.providersList.map((a: any) => {
                              return {
                                label: a.ProviderName,
                                value: a.Id,
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Total Purchased Credit Used
                          </label>
                          <input
                            type="number"
                            disabled={true}
                            className="form-control non-editable"
                            onChange={(e) => {
                              this.setState({
                                UsedCredit: e.target.value,
                              });
                            }}
                            value={parseFloat(this.state.UsedCredit).toFixed(4)}
                          />
                        </div>
                      </div>

                      {this.props.calledFrom === "edit" ? (

                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-label">
                              Tenant ID (for Microsoft Contact Lookup)
                            </label>
                            <input
                              type="text"
                              className="form-control non-editable"
                              onChange={(e) => {
                                this.setState({
                                  TenantId: e.target.value,
                                  ContactLookupEnabled: e.target.value === "" ? false : true,
                                });
                              }}
                              value={this.state.TenantId}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="w-100"></div>
                      {this.props.selectedCompanyId !== "" ? null : (
                        <div className="col-md-4 col-md-offset-4">
                          <div
                            className={
                              this.state.ErrorCompanyID !== ""
                                ? "form-group validationClass"
                                : "form-group"
                            }
                          >
                            <label className="form-label">
                              Company
                              <small className="text-muted">
                                *{this.props.CompanyIdProp}{" "}
                              </small>
                            </label>
                            {this.props.calledFrom === "edit" ? (
                              <Select
                                className={
                                  this.state.ErrorCompanyID !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                isDisabled={true}
                                value={[
                                  {
                                    label: this.state.SelectedCompanyName,
                                    value: this.state.CompanyId,
                                  },
                                ]}
                                components={{ DropdownIndicator }}
                                onChange={(opt: any) =>
                                  this.setState({
                                    CompanyId: opt.value,
                                    ErrorCompanyID: "",
                                  })
                                }
                                options={this.props.companyListProps.map(
                                  (opt: any) => ({
                                    label: opt.Name,
                                    value: opt.Id,
                                  })
                                )}
                              />
                            ) : (
                              <Select
                                components={{ DropdownIndicator }}
                                className={
                                  this.state.ErrorCompanyID !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                onChange={(opt: any) =>
                                  this.setState({
                                    CompanyId: opt.value,
                                    ErrorCompanyID: "",
                                  })
                                }
                                options={this.props.companyListProps.map(
                                  (opt: any) => ({
                                    label: opt.Name,
                                    value: opt.Id,
                                  })
                                )}
                              />
                            )}
                            <small className="form-text ">
                              {this.state.ErrorCompanyID}{" "}
                            </small>
                          </div>
                        </div>
                      )}

                      {this.props.calledFrom === "edit" ? (
                        <div className="col-md-4">
                          <div className="form-group">
                            <label className="form-label">ID</label>
                            <input
                              type="number"
                              className="form-control non-editable"
                              disabled={true}
                              onChange={(e) => {
                                this.setState({ Id: parseInt(e.target.value) });
                              }}
                              value={this.state.Id}
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="w-100"></div>
                      <div className="col-md-4">
                        <div className="form-group enableList">
                          <label className="custom-control custom-checkbox m-0 checkbox_right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  emailEnabled: !this.state.emailEnabled,
                                });
                              }}
                              checked={this.state.emailEnabled}
                            />
                            <span className="custom-control-label">
                              Email Text
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4 col-md-offset-4">
                        <div className="form-group enableList">
                          <label className="custom-control custom-checkbox m-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  EnableLists: !this.state.EnableLists,
                                });
                              }}
                              checked={this.state.EnableLists}
                            />
                            <span className="custom-control-label">
                              Contact Groups
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label">
                            Alternative Notification Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => {
                              this.setState({ contactEmail: e.target.value });
                            }}
                            value={this.state.contactEmail}
                            placeholder="Email address"
                          //disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="custom-control custom-checkbox m-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              disabled={this.state.includeGroupLocked}
                              onChange={() => {
                                this.setState({
                                  IncludeGroups: !this.state.IncludeGroups,
                                });
                              }}
                              checked={!!this.state.IncludeGroups}
                            />
                            <span className="custom-control-label">
                              Include Groups
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="w-100"></div>
                      <div className="col-md-4 col-md-offset-4">
                        <div className="form-group">
                          <label className="custom-control custom-checkbox m-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  EnableTranslation:
                                    !this.state.EnableTranslation,
                                });
                              }}
                              defaultChecked={this.state.EnableTranslation}
                              disabled={
                                this.props.loggedInUserRole === -10
                                  ? false
                                  : true
                              }
                            />
                            <span className="custom-control-label">
                              Enable Translation
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="custom-control custom-checkbox m-0">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  Disabled: !this.state.Disabled,
                                });
                              }}
                              defaultChecked={this.state.Disabled}
                            />
                            <span className="custom-control-label">
                              Disabled
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="btnDiv d-flex justify-content-between align-items-center">
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => this.addUpdateSubscriptionData(e)}
                      >
                        Save changes
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default cancelBtn"
                        onClick={() => this.props.changeState()}
                      >
                        Cancel
                      </button>
                    </div>
                    <div>
                      {this.props.calledFrom === "edit" ? (
                        <button
                          type="button"
                          className="btn btn-primary delete_btn"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <span className="font-icon">&#107;</span>Delete
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.props.companyId !== undefined ? (
          <div
            className={
              this.props.selectedCompanyId === ""
                ? "container-fluid"
                : "container-fluid subscription_box"
            }
          >
            <h4 className="font-weight-bold text-left addUser_head">
              {this.props.calledFrom === "edit"
                ? "Edit Subscription"
                : "Add Subscription"}
            </h4>
            {this.renderHtml()}
          </div>
        ) : (
          <div
            className={
              this.props.selectedCompanyId === ""
                ? "container-fluid"
                : "container-fluid subscription_box"
            }
          >
            <h2 className="companyName  text-left">
              <span
                className="iconNavigation cursor_pointer"
                onClick={() => this.props.changeState()}
              >
                <i
                  className="fa fa-chevron-left cursor_pointer"
                  aria-hidden="true"
                ></i>
              </span>
              Subscriptions /{" "}
              {this.props.calledFrom === "edit" ? (
                <b>{this.state.Name}</b>
              ) : (
                <b>New Subscription</b>
              )}
            </h2>
            <Tabs
              className="tabGroupMember"
              defaultActiveKey={this.state.activeTab}
              onSelect={(currentTabName: any) => {
                this.setState({
                  activeTab: currentTabName,
                  companyName: "",
                });
              }}
              id="uncontrolled-tab-example"
            >
              <Tab
                eventKey="Subscription-Details"
                title={
                  <span>
                    <span className="font-icon">&#67;</span>Subscription Details
                  </span>
                }
              >
                {this.renderHtml()}
              </Tab>
            </Tabs>
          </div>
        )}
        <ConfirmationDeleteComponent
          deleteSucces={() => this.props.deleteSubscriptionProps(this.state.Id)}
        />
      </React.Fragment>
    );
  }
}
