import * as React from 'react';
import { DataTableComponent } from '../sharedComponents/dataTableComponent';

export class CompanyListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props)
    this.state = {
      searchBox:'',
      filterCompanyList:[],
      activeId:""
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.searchTextBox !== prevState.searchBox ||
      nextProps.filterCompanyList !==prevState.filterCompanyList ||
      nextProps.activeCompanyId !== prevState.activeId) {
      return {
        searchBox:nextProps.searchTextBox ,
        filterCompanyList: nextProps.filterCompanyList,
        activeId:nextProps.activeCompanyId
      };
    }
    return null
   }

public render() {
    return (                      
      <React.Fragment>
            <div className="positionRelative paddTop46">
                  <div className="card-datatable table-responsive">
                     <DataTableComponent
                          columnName={['Company Name','Country' ,'Primary Contact','Email',  'Number','Registered']}
                          rowsList={this.state.filterCompanyList}
                          filterUserByCompanyId ={(companyId : any,name:any)=>{this.props.fiterUserBycomId(companyId,name)}}
                          filterSubsciptionByCompanyId={(companyId : any,name : any)=>{this.props.fiterSubscriptionBycomId(companyId,name)}}
                          calledFrom={'Companies'}
                          openEditComponent={(object: {}) => {
                            this.props.saveEditDtail(object)
                            this.props.openAddUpdateWindow(object)
                          }}
                          showHideListProp={(value:any)=>{
                            this.props.showHideListComp(value)
                          }} 
                          searchBoxText={this.state.searchBox}
                          setSearchBoxText = {(value:any)=>this.props.setSearchBox(value)}
                          actualData={this.props.companyList}
                          filteredByTxtBoxSearch ={(list:any)=>{this.props.filterationByTxt(list)}}
                          rowActiveIdCompany_Props ={(id:any)=>{this.props.rowActiveIdCompanyProps(id)}}
                          activeCompanyId={this.state.activeId}
                          
                        />
                   </div>
                </div>
       </React.Fragment>
    );
  }
}
