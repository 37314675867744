
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import * as stateInterface from '../reducers/stateInterface';
import {
  characterReducer
} from '../reducers/reducer';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import history  from '../../history';
export interface IAppState {
  characterState: stateInterface.ICharacterState;
}


export default function configureStore(): Store<any, any> {
//  const store = createStore(rootReducer, undefined, applyMiddleware(thunk));
const store = createStore(
  combineReducers({
    characterReducer,
      routing: routerReducer,
   }),
applyMiddleware(thunk, routerMiddleware(history)),
);
return store;
}
