import { debug } from "console";
import * as React from "react";
import * as commonMethods from "../../commonMethods";
import { DataTableComponent } from "../sharedComponents/dataTableComponent";
import { InboxesAddComponent } from "./inboxesAddComponent";
export class InboxesListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      pendingList: true,
      inboxesListData: [],
      actualInboxesList: [],
      InboxesMemberList: [],
      selectedEmail: this.props.selecteInboxesName,
      pendingMemberList: false,
      showAddInboxesComponent: false,
      subscriptionListData: [],
      hideInboxesData: true,
      editObject: {},
      calledFrom: "add",
      selectedEmailForNonInboxesMember: "",
      uniqueNumber: "",
      defaultCountryCode: "",
      disable: false,
      uniqueNumberCost: "",
      registerationDate: "",
      email: "",
      name: "",
      roleId: "",
      subscriptionId: "",
      subscriptionName: "",
      unAssignedNumbers: [],
      searchTextBox: "",
      activeId: "",
      emailEnabled: false,
      inboxesId: this.props.InboxesId,
      inboxesTypeId: "",
      servicePhonenumber: this.props.ServicePhonenumber,
      contactLookupEmail: this.props.ContactLookupEmail,
      whiteLabelBrand: this.props.WhiteLabelBrand,
      contactLookupEnabled: this.props.ContactLookupEnabled,
    };
  }
  componentDidMount() {
    if (this.props.showAddInboxes === true) {
      this.props.showInboxesListTab(true);
    } else {
      this.props.showInboxesListTab(false);
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingInboxesList !== prevState.pendingList ||
      nextProps.inboxesList !== prevState.inboxesListData ||
      nextProps.actualDataProp !== prevState.actualInboxesList ||
      nextProps.inboxesMemberList !== prevState.InboxesMemberList ||
      nextProps.pendingInboxesMemberListByEmail !==
        prevState.pendingMemberList ||
      nextProps.subscriptionList !== prevState.subscriptionListData ||
      nextProps.hideInboxesList !== prevState.hideInboxesData ||
      nextProps.selecteInboxesName !== prevState.selectedEmail ||
      nextProps.editData !== prevState.editObject ||
      nextProps.uniqueNumberInboxesAdd !== prevState.uniqueNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      nextProps.DisableInboxes !== prevState.disable ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.Name !== prevState.name ||
      nextProps.RoleId !== prevState.roleId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.unAssignedNumbersprops !== prevState.unAssignedNumbers ||
      nextProps.searchText !== prevState.searchTextBox ||
      nextProps.activeIdProp !== prevState.activeId ||
      nextProps.emailEnabledForInboxes !== prevState.emailEnabled ||
      nextProps.InboxesId != prevState.inboxesId ||
      nextProps.pendingInboxesList !== prevState.pendingInboxesMembers ||
      nextProps.ServicePhonenumber != prevState.servicePhonenumber ||
      nextProps.ContactLookupEmail != prevState.contactLookupEmail ||
      nextProps.WhiteLabelBrand != prevState.whiteLabelBrand ||
      nextProps.ContactLookupEnabled != prevState.contactLookupEnabled
    ) {
      return {
        searchTextBox: nextProps.searchText,
        roleId: nextProps.RoleId,
        email: nextProps.Email,
        name: nextProps.Name,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        disable: nextProps.DisableInboxes,
        uniqueNumber: nextProps.uniqueNumberInboxesAdd,
        editObject: nextProps.editData,
        selectedEmail: nextProps.selecteInboxesName,
        hideInboxesData: nextProps.hideInboxesList,
        InboxesMemberList: nextProps.inboxesMemberList,
        pendingMemberList: nextProps.pendingInboxesMemberListByEmail,
        actualInboxesList: nextProps.actualDataProp,
        pendingList: nextProps.pendingInboxesList,
        inboxesListData: nextProps.inboxesList,
        subscriptionListData: nextProps.subscriptionList,
        defaultCountryCode: nextProps.DefaultCountryCode,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        registerationDate: nextProps.RegisterationDate,
        unAssignedNumbers: nextProps.unAssignedNumbersprops,
        activeId: nextProps.activeIdProp,
        emailEnabled: nextProps.emailEnabledForInboxes,
        inboxesId: nextProps.InboxesId,
        pendingInboxesMembers: nextProps.pendingInboxesList,

        servicePhonenumber: nextProps.ServicePhonenumber,
        contactLookupEmail: nextProps.ContactLookupEmail,
        whiteLabelBrand: nextProps.WhiteLabelBrand,
        contactLookupEnabled: nextProps.ContactLookupEnabled,
      };
    } else return null;
  }
  renderInboxesMemberData() {
  
    return this.state.InboxesMemberList.map((inboxesMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: inboxesMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                inboxesMeber.UserName !== ""
                  ? inboxesMeber.UserName
                  : inboxesMeber.UserEmail
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{inboxesMeber.UserName}</h5>
            <p>{inboxesMeber.UserEmail}</p>
          </div>
        </div>
      );
    });
  }
  setValueInObject = (object: any) => {
    var data = this.state.subscriptionListData
      .filter((opt: any) => opt.subscription.Id === object.SubscriptionId)
      .map((ele: any) => ele.subscription.Name)[0];

    this.props.setEmail(object.ContactLookupEmail);

    this.props.setInboxesId(object.Id);
    this.props.setName(object.Name);
    this.props.setSubscriptionId(object.SubscriptionId);

    this.props.setDefaultCountryCode(object.DefaultCountryCode);
    this.props.setSubscriptionName(data);
    this.props.setDisable(object.Disabled);
    this.props.setInboxesContactLookupEnabledForUser(
      object.ContactLookupEnabled
    );
    this.props.setInboxesServicePhonenumber(object.ServicePhoneNumber);
    this.props.setInboxesContactLookupEmail(object.ContactLookupEmail);
    this.props.setInboxesWhitelabelBrand(object.WhiteLabelBrand);

    this.props.setInboxesTypeId(object.Type ?? 0);
  };
  emptyValueInObject = () => {
    this.props.setInboxesId("");
    this.props.setEmail("");
    this.props.setName("");
    this.props.setSubscriptionId("");
    this.props.setInboxesTypeId("");
    this.props.setRegisterationDate("");
    this.props.setDefaultCountryCode(1);
    this.props.setSubscriptionName("");
    this.props.setInboxesContactLookupEnabledForUser(false);
    this.props.setInboxesServicePhonenumber("");
    this.props.setInboxesContactLookupEmail("");
    this.props.setInboxesWhitelabelBrand("");
  };
  public render() {
    return (
      <React.Fragment>
        {this.state.pendingList === true ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            {this.state.hideInboxesData !== true ? (
              <div
                className={
                  this.props.companyId === undefined ||
                  this.props.companyId === ""
                    ? this.state.inboxesListData !== undefined
                      ? this.state.inboxesListData.length === 0
                        ? "inboxesInboxesMemberDiv inboxesName increaseWidth  inboxesTableForMAinPAge"
                        : "inboxesInboxesMemberDiv inboxesName   inboxesTableForMAinPAge"
                      : "inboxesInboxesMemberDiv inboxesName inboxesMemberSearch inboxesTableForMAinPAge"
                    : this.state.inboxesListData !== undefined
                    ? this.state.inboxesListData.length === 0
                      ? "inboxesInboxesMemberDiv inboxesName increaseWidth tabTableHeight"
                      : "inboxesInboxesMemberDiv inboxesName  tabTableHeight"
                    : "inboxesInboxesMemberDiv inboxesName tabTableHeight"
                }
              >
                <div className="positionRelative paddTop56">
                  <div
                    className={
                      this.state.inboxesListData !== undefined
                        ? this.state.inboxesListData.length === 0
                          ? "card-datatable table-responsive"
                          : "showInboxesMemberTable card-datatable table-responsive"
                        : "card-datatable table-responsive"
                    }
                  >
                    <DataTableComponent
                      filteredByTxtBoxSearch={(newList: any, type: any) => {
                        this.props.filterationByTxt(newList, "InboxesList");
                      }}
                      actualData={this.state.actualInboxesList}
                      listCalled={"InboxesList"}
                      columnName={[
                        "Name",
                        "Type",
                        "Number",
                        "Country Code",
                        "Disabled",
                      ]}
                      rowsList={this.state.inboxesListData}
                      calledFrom={"InboxesList"}
                      showInboxesMemeber={(object: any) => {
                        this.setState({
                          selectedEmailForNonInboxesMember: object.Id,
                        });
                        this.setValueInObject(object);
                        this.props.setEditObject(object);
                        this.props.selectedEmailName(object.Name);
                        this.props.selectedInboxesEmailName(object.Name);
                        this.props.pendingInboxesMemberMethod(true);
                        this.props.getInboxesMembersByInboxesId(object.Id);
                      }}
                      searchFunctionality={(value: any, type: any) => {
                        this.props.searchFunction(value);
                      }}
                      showHideListProp={() => {
                        this.props.hideInboxesTabFromCompanyDetail(true);
                        this.emptyValueInObject();
                        this.props.showInboxesListTab(true);
                        this.props.getSubscriptionList();
                        this.props.getUnAssignedNumbers(null, null, "");
                        if (this.props.companyId !== undefined) {
                          this.props.addInboxesCalledFromCompanyTab(true);
                        } else {
                          this.props.addInboxesCalledFromCompanyTab(false);
                        }
                      }}
                      arrowIconClick={(inboxesid: any) => {
                        this.props.showInboxesNonMemberTabComp(
                          inboxesid,
                          "arrowClick"
                        );
                      }}
                      searchBoxText={this.state.searchTextBox}
                      setSearchBoxText={(value: any) =>
                        this.props.setSearchBoxValue(value)
                      }
                      rowActiveIdCompany_Props={(id: any) => {
                        this.props.rowActiveIdCompanyProps(id);
                      }}
                      activeCompanyId={this.state.activeId}
                      companyId={this.props.companyId}
                      loggedInUserRole={this.props.loggedInUserRole}
                    />
                  </div>
                </div>
                {this.props.pendingInboxesMemberList === true ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : this.state.InboxesMemberList !== undefined ? (
                  this.state.inboxesListData.length === 0 ? null : (
                    <div className="teamBox radiusRight">
                      <div className="media align-items-center saleTeam">
                        <div className="media_left d-flex justify-content-center">
                          <span className="align-self-center">
                            {commonMethods.generateFirstLastNameIntials(
                              this.state.selectedEmail
                            )}
                          </span>
                        </div>
                        <div className="media-body">
                          <h5>{this.state.selectedEmail}</h5>
                          <p>{this.state.InboxesMemberList.length} members</p>
                        </div>
                        <div className="media_right">
                          <span
                            className="align-self-center cursor_pointer"
                            onClick={() => {
                              this.props.showInboxesNonMemberTabComp(
                                // this.state.selectedEmailForNonInboxesMember,
                                this.state.inboxesId,
                                ""
                              );
                            }}
                          >
                            <span className="font-icon">&#122;</span>
                          </span>
                        </div>
                      </div>
                      {this.state.InboxesMemberList.length === 0 ? (
                        <div className="media-body">
                          <div className="noUser_found">
                            No inboxes Members found
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            this.props.companyId !== undefined
                              ? "memberBox inboxesMemberBox"
                              : "memberBox"
                          }
                        >
                          {this.renderInboxesMemberData()}
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            ) : (
              <InboxesAddComponent
                roleOfLoggedInUser={this.props.loggedInUserRole}
                uniquePhoneNumber={this.state.uniqueNumber}
                SubscriptionId={this.state.subscriptionId}
                SubscriptionName={this.state.subscriptionName}
                defaultCountryCodeForAddEditUserPRops={
                  this.state.defaultCountryCode
                }
                DisableInboxes={this.state.disable}
                UniqueNumberCost={this.state.uniqueNumberCost}
                RegisterationDate={this.state.registerationDate}
                Email={this.state.email}
                InboxesId={this.state.inboxesId}
                Name={this.state.name}
                InboxesTypeId={this.state.inboxesTypeId}
                ServicePhonenumber={this.state.servicePhonenumber}
                ContactLookupEmail={this.state.contactLookupEmail}
                WhiteLabelBrand={this.state.whiteLabelBrand}
                ContactLookupEnabled={this.state.contactLookupEnabled}
                setInboxesContactLookupEnabledForUser={(value: any) =>
                  this.props.setInboxesContactLookupEnabledForUser(value)
                }
                setInboxesServicePhonenumber={(value: any) =>
                  this.props.setInboxesServicePhonenumber(value)
                }
                setInboxesContactLookupEmail={(value: any) =>
                  this.props.setInboxesContactLookupEmail(value)
                }
                setInboxesWhitelabelBrand={(value: any) =>
                  this.props.setInboxesWhitelabelBrand(value)
                }
                emailEnabledProps={this.state.emailEnabled}
                disableForAddEdit={(value: any) => {
                  this.props.setDisable(value);
                }}
                setDefaultCountryCode={(value: any) => {
                  this.props.setDefaultCountryCode(value);
                }}
                setEmail={(value: any) => {
                  this.props.setEmail(value);
                }}
                setInboxesId={(value: any) => {
                  this.props.setInboxesId(value);
                }}
                setName={(value: any) => {
                  this.props.setName(value);
                }}
                setInboxesTypeId={(value: any) => {
                  this.props.setInboxesTypeId(value);
                }}
                setSubscriptionId={(value: any) => {
                  this.props.setSubscriptionId(value);
                }}
                setSubscriptionName={(value: any) => {
                  this.props.setSubscriptionName(value);
                }}
                companyId={this.props.companyId}
                subscriptionList={this.state.subscriptionListData}
                changeState={() => {
                  this.props.hideInboxesTabFromCompanyDetail(false);
                  this.props.showInboxesListTab(false);
                  this.props.addInboxesCalledFromCompanyTab(false);
                }}
                calledFrom={"add"}
                editData={this.state.editObject}
                addUpdateInboxesProp={(type: any, data: any) =>
                  this.props.addUpdateInboxesProp(type, data, null)
                }
                releaseNumberFromApi={(value: any) => {
                  this.props.removeNumberFromTwillio(value);
                }}
                unAssignPhoneNumberProps={this.state.unAssignedNumbers}
                getUnAssignedNumbers_inboxes={(
                  smsServieType: any,
                  subscriptionId: any
                ) => {
                  this.props.getUnAssignedNumbers(
                    smsServieType,
                    subscriptionId
                  );
                }}
                updateUserEmailNotifications={(obj: any) =>
                  this.props.updateUserEmailNotifications(obj)
                }
                deleteInboxesConfirm={(inboxesId: any) => {
                  this.props.deleteInboxes(inboxesId);
                }}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
