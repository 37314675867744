import * as React from 'react';
import { DataTableComponent }from '../sharedComponents/dataTableComponent';

export class NumberListComponent extends React.Component<any, any> {
    constructor(props: any , state : any) {
        super(props)
        this.state = {
          numberList:[],
          searchByFilter:"",
          SearchBox:"",
          activeId:""

        }
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.numberistProps !== prevState.numberList ||
        nextProps.selectedSearchFilter !== prevState.searchByFilter ||
        nextProps.searchBoxText !== prevState.SearchBox ||
        nextProps.activeRowId !== prevState.activeId) {
            return { 
              numberList: nextProps.numberistProps ,
              SearchBox:nextProps.searchBoxText,
              searchByFilter:nextProps.selectedSearchFilter,
              activeId:nextProps.activeRowId

            };
    }
    else return null;
  }
    public render() {
      
        return (
            <React.Fragment>
             <div className="positionRelative paddTop46">
                      <div className="card-datatable table-responsive">
                        <DataTableComponent
                          listCalled = {"Numbers"}
                          columnName={this.props.ColumnName}
                          rowsList={this.props.numberistProps}
                          calledFrom={'Numbers'}
                          openEditComponent={(object:any)=>{
                            this.props.openEditWindow(object)
                          }}
                          filteredByTxtBoxSearch ={(list:any)=>{this.props.filterationByTxt(list)}}
                          actualData={this.props.actualPhoneList}
                          searchBy={this.state.searchByFilter}
                          showHideListProp={(value:any)=>{
                            this.props.showHideListNumber(value)
                          }}
                          searchFunctionality={(value:any, type:any)=>{
                         this.props.searchFunction(value)
                          }}
                          searchBoxText={this.state.SearchBox}
                          setSearchBoxText={(value:any)=>this.props.searchBoxValue(value)}
                          rowActiveIdCompany_Props ={(id:any)=>{this.props.rowActiveIdCompanyProps(id)}}
                          activeCompanyId={this.state.activeId}
                          providersList= {this.props.providersList}
                          providersAccountCall = { (val: string | number) => this.props.providersAccountCall(val)}
                        />

                      </div>
                    </div>
            </React.Fragment>
        );
    }
};

