import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import NotificationComponent from "../sharedComponents/displayNotifications";
import { InboxesListComponent } from "./inboxesListComponent";
import { InboxesNonMemberComponent } from "./inboxesNonMemberComponent";
import * as action from "../../redux/actions/actions";
import history from "../../history";
import { AnyPtrRecord, AnySrvRecord } from "dns";
class InboxesParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      pendingInboxesList: true,
      inboxesListByCompId: [],
      errorOccured: "",
      pendingInboxesMemberListState: false,
      inboxesMemberListEmail: [],
      inboxesNonMemberList: [],
      showInboxesNonMember: false,
      selectedInboxesEmail: "",
      iconNameToBeDisplayed: "both",
      //
      nonInboxesMemberList: [],
      subscriptionList: [],
      showInboxesList: false,
      companyName: "",
      companyId: "",
      editInboxes: this.props.editInboxesObj,
      actualInboxesMemberList: [],
      actualInboxesNonMemberList: [],
      hideContent: true,
      selectwedGropMail: "",
      pendingNonInboxesMembers: false,
      pendingInboxesMembers: false,
      uniquNumber: "",
      defaultCountryCode: "",
      // storeText: true,
      disabled: false,
      // creditLimit: 0,
      uniqueNumberCost: "",
      // usedCredits: "",
      registerationDate: "",
      email: "",
      name: "",
      inboxesTypeId: "",
      subscriptionId: "",
      subscriptionName: "",
      currentActiveInboxesTabName: "",
      unAssignedNumbers: [],
      searchTextBox: "",
      rowActiveId: "",
      emailEnabled: false,
      inboxesId: "",
      deleteConfirmMessageByInboxesId: "",
      // loggedInUserDetails: null,
      myCompanyId: this.props.myCompanyId,
      inboxesServicePhonenumber: null,
      inboxesContactLookupEmail: null,
      inboxesWhiteLabelBrand: null,
      inboxesContactLookupEnabled: null,
      myId: this.props.myId,
    };
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingInboxesListBySubIdAndCompIdAction !==
      prevState.pendingInboxesList ||
      nextProps.actualInboxesList !== prevState.inboxesListByCompId ||
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.pendingInboxesMemberList !==
      prevState.pendingInboxesMemberListState ||
      nextProps.filteredInboxesMemberList !== prevState.inboxesMemberListEmail ||
      nextProps.filteredInboxesNonMemberList !== prevState.inboxesNonMemberList ||
      nextProps.iconName !== prevState.iconNameToBeDisplayed ||
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.showInboxesList !== prevState.showInboxesList ||
      nextProps.selectedInboxesName !== prevState.selectedInboxesEmail ||
      nextProps.hideNonInboxesMember !== prevState.showInboxesNonMember ||
      nextProps.editInboxesObj !== prevState.editInboxes ||
      nextProps.inboxesMemberListByEmail !== prevState.actualInboxesMemberList ||
      nextProps.actualInboxesNotMemberList !==
      prevState.actualInboxesNonMemberList ||
      nextProps.hideInboxesEmailHeadingCompo !== prevState.hideContent ||
      nextProps.selectedInboxesEmailValue !== prevState.selectwedGropMail ||
      nextProps.pendingInboxesNonMemberValue !==
      prevState.pendingNonInboxesMembers ||
      nextProps.pendingInboxesMemberValue !== prevState.pendingInboxesMembers ||
      nextProps.uniquNumberForInboxesAdd !== prevState.uniquNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      //nextProps.storeTextProps !== prevState.storeText ||
      nextProps.disableProps !== prevState.disabled ||
      //  nextProps.CreditLimit !== prevState.creditLimit ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      // nextProps.UsedCredit !== prevState.usedCredits ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.InboxesTypeId !== prevState.inboxesTypeId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.CurrentActiveInboxesTabName !==
      prevState.currentActiveInboxesTabName ||
      nextProps.unAssignPhoneNumberList !== prevState.unAssignedNumbers ||
      nextProps.searchTextBox_Text !== prevState.searchTextBox ||
      nextProps.activeId !== prevState.rowActiveId ||
      nextProps.emailEnabledForUser !== prevState.emailEnabled ||
      nextProps.InboxesId !== prevState.inboxesId ||
      nextProps.DeleteInboxesConfirmMessage !== prevState.deleteConfirmMessageByInboxesId ||
      //nextProps.LoggedInUserData !== prevState.loggedInUserDetails ||
      nextProps.ContactLookupEnabled !== prevState.inboxesContactLookupEnabled ||
      nextProps.ServicePhonenumber !== prevState.inboxesServicePhonenumber ||
      nextProps.ContactLookupEmail !== prevState.inboxesContactLookupEmail ||
      nextProps.WhiteLabelBrand !== prevState.inboxesWhiteLabelBrand ||
      nextProps.myCompanyId !== prevState.myCompanyId ||
      nextProps.myId !== prevState.myId


    ) {
      return {
        email: nextProps.Email,
        name: nextProps.Name,
        inboxesTypeId: nextProps.InboxesTypeId,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        registerationDate: nextProps.RegisterationDate,
        //usedCredits: nextProps.UsedCredit,
        selectwedGropMail: nextProps.selectedInboxesEmailValue,
        hideContent: nextProps.hideInboxesEmailHeadingCompo,
        actualInboxesNonMemberList: nextProps.actualInboxesNotMemberList,
        editInboxes: nextProps.editInboxesObj,
        showInboxesNonMember: nextProps.hideNonInboxesMember,
        showInboxesList: nextProps.showInboxesList,
        subscriptionList: nextProps.subscriptionList,
        inboxesNonMemberList: nextProps.filteredInboxesNonMemberList,
        iconNameToBeDisplayed: nextProps.iconName,
        pendingInboxesMemberListState: nextProps.pendingInboxesMemberList,
        errorOccured: nextProps.errorOccured,
        pendingInboxesList: nextProps.pendingInboxesListBySubIdAndCompIdAction,
        inboxesListByCompId: nextProps.actualInboxesList,
        inboxesMemberListEmail: nextProps.filteredInboxesMemberList,
        selectedInboxesEmail: nextProps.selectedInboxesName,
        actualInboxesMemberList: nextProps.inboxesMemberListByEmail,
        pendingNonInboxesMembers: nextProps.pendingInboxesNonMemberValue,
        pendingInboxesMembers: nextProps.pendingInboxesMemberValue,
        uniquNumber: nextProps.uniquNumberForInboxesAdd,
        defaultCountryCode: nextProps.DefaultCountryCode,
        //storeText: nextProps.storeTextProps,
        disabled: nextProps.disableProps,
        // creditLimit: nextProps.CreditLimit,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        currentActiveInboxesTabName: nextProps.CurrentActiveInboxesTabName,
        unAssignedNumbers: nextProps.unAssignPhoneNumberList,
        searchTextBox: nextProps.searchTextBox_Text,
        rowActiveId: nextProps.activeId,
        emailEnabled: nextProps.emailEnabledForUser,
        inboxesId: nextProps.InboxesId,
        deleteConfirmMessageByInboxesId: nextProps.DeleteInboxesConfirmMessage,
        // loggedInUserDetails: nextProps.LoggedInUserData,
        inboxesContactLookupEnabled: nextProps.ContactLookupEnabled,
        inboxesServicePhonenumber: nextProps.ServicePhonenumber,
        inboxesContactLookupEmail: nextProps.ContactLookupEmail,
        inboxesWhiteLabelBrand: nextProps.WhiteLabelBrand,
        myCompanyId: nextProps.myCompanyId,
        myId: nextProps.myId,
      };
    } else return null;
  }
  componentDidMount() {
    var calledFrom =
      this.props.location !== null && this.props.location !== undefined
        ? this.props.location.state !== null &&
          this.props.location.state !== undefined
          ? this.props.location.state.callingFrom !== null &&
            this.props.location.state.callingFrom !== undefined
            ? this.props.location.state.callingFrom
            : "Inboxes"
          : "Inboxes"
        : "Inboxes";
    if (calledFrom !== "Inboxes" && calledFrom !== "") {
      history.replace("/Inboxes", { callingFrom: "" });
      this.props.hideInboxesListProps(true);
    } else {
      this.callingComponent();
      //this.props.setSearchBoxValue("")
    }
  }
  callingComponent() {

    ///Nesting of multiple tabs according to calling from component tab
    var companyId = "";
    var CompanyName = "";
    //var showInboxesNonMember: any = null;
    if (
      this.props.companyId !== undefined &&
      this.props.showInboxesNonMember === undefined
    ) {
      companyId = this.props.companyId;
      CompanyName = this.props.selectedCompanyName;
      this.setState({
        companyId: companyId,
        companyName: CompanyName,
      });
      if (this.props.showInboxesEditTab === true) {
        this.props.hideInboxesListProps(true);
      } else {
        this.props.dispatchNonInboxesMemberTab(false);
        setTimeout(() => {
          this.props.filterInboxes(this.state.SubscriptionId, this.props.companyId, "", this.props.loggedinUserRole, this.props.companyId);
        }, 100);

      }
    }
    ///Only display non inboxes member component
    if (this.props.showInboxesNonMember !== undefined) {
      companyId = this.props.companyId;
      CompanyName = this.props.selectedCompanyName;
      this.setState({
        companyId: companyId,
        companyName: CompanyName,
      });
      this.props.dispatchNonInboxesMemberTab(true);
    }
    ///Direct calling
    if (
      this.props.companyId === undefined &&
      this.props.showInboxesNonMember === undefined
    ) {
      this.props.setSearchBoxValue("");
      this.props.rowActiveIdInboxes("");
      this.props.dispatchNonInboxesMemberTab(false);
      this.props.dispatchPendingInboxesList(true);

      const comId = this.props.loggedinUserRole === -10 ? null : this.state.myCompanyId;
      setTimeout(() => {
        this.props.filterInboxes(this.state.SubscriptionId, comId, "", this.props.loggedinUserRole, this.props.myCompanyId);
      }, 100);
    }
  }
  filterBySelectedStatus = (filterByStatus: any) => {
    let filteredList: any;
    if (filterByStatus === "disabled") {
      filteredList = this.props.inboxesListByCompAndSubIdAction.filter(
        (x: any) => x.Disabled === true
      );
      this.props.dispatchGetInboxesListFilter(filteredList);
    } else if (filterByStatus === "enabled") {
      filteredList = this.props.inboxesListByCompAndSubIdAction.filter(
        (x: any) => x.Disabled === false
      );
      this.props.dispatchGetInboxesListFilter(filteredList);
    } else {
      this.props.dispatchGetInboxesListFilter(
        this.props.inboxesListByCompAndSubIdAction
      );
    }
  };
  assignMembersToInboxes = (
    filteredMemberList: any,
    inboxesMemberist: any,
    notInboxesMember: any,
    actualInboxesNonMemberList: any
  ) => {


    filteredMemberList.forEach((element: any, index: any) => {
      if (element.checked) {
        element.checked = false;
        inboxesMemberist.push(element);
        var indexIninboxesMemberList = actualInboxesNonMemberList.map((item: any) => {
          return item.UserEmail;// item.Email;
        })
          .indexOf(element.UserEmail);
        //.indexOf(element.Email);
        if (indexIninboxesMemberList !== -1) {
          actualInboxesNonMemberList.splice(indexIninboxesMemberList, 1);
        }
      }
      this.props.showIcons("both");
    });
    this.props.dispatchGetInboxesNonMemberListFilter(actualInboxesNonMemberList);
    // this.props.dispatchgetNotInboxesMemberListAction(actualInboxesNonMemberList)
    this.setState({
      inboxesMemberListByEmail: inboxesMemberist,
    });
  };
  unAssignMembersFromInboxes = (
    filteredMemberList: any,
    inboxesMemberist: any,
    notInboxesMember: any,
    actualInboxesMemberList: any
  ) => {
    filteredMemberList.forEach((element: any, index: any) => {

      if (element.checked) {
        element.checked = false;
        notInboxesMember.push(element);
        var indexIninboxesMemberList = actualInboxesMemberList
          .map((item: any) => {
            return item.UserEmail;// item.Email;
          })
          //.indexOf(element.Email);
          .indexOf(element.UserEmail);
        if (indexIninboxesMemberList !== -1) {
          actualInboxesMemberList.splice(indexIninboxesMemberList, 1);
        }
      }
    });

    this.props.dispatchGetInboxesMemberListFilter(actualInboxesMemberList);
    // this.props.dispatchgetInboxesMemberListAction(actualInboxesMemberList)
    this.props.showIcons("both");
    this.setState({
      inboxesNotMemberListDataTab: notInboxesMember,
    });
  };
  breadCrumbNavigation = () => {
    ///Inside company
    if (this.props.companyId === undefined) {
      this.props.hideInboxesListProps(false);
    } else {
      this.props.hideInboxesListProps(true);
    }
    this.props.dispatchNonInboxesMemberTab(false);
    this.props.hideCompanyTabDispatch(true);
    this.props.hideInboxesNameHeading(true);
  };
  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          {this.props.companyId !== undefined ? null : (
            <NotificationComponent />
          )}
          <div
            className={
              this.props.companyId !== undefined &&
                this.state.showInboxesNonMember === true
                ? ""
                : "container-fluid subscription_box"
            }
          >
            {/* Non MemberComponent Header */}
            {this.state.hideContent === false ? (
              <h2 className="companyName  text-left ">
                <span
                  className="iconNavigation cursor_pointer"
                  onClick={() => this.breadCrumbNavigation()}
                >
                  <i
                    className="fa fa-chevron-left cursor_pointer"
                    aria-hidden="true"
                  ></i>
                </span>
                {this.state.companyName !== ""
                  ? this.state.companyName
                  : "Inboxes"}{" "}
                / <b>{this.state.selectedInboxesEmail}</b>
              </h2>
            ) : null}
            {this.state.errorOccured !== undefined &&
              this.state.errorOccured !== "" &&
              this.state.errorOccured.split(":")[1] === "Inboxes" ? (
              this.state.errorOccured.split(":")[0]
            ) : this.state.showInboxesNonMember === false ? (
              <InboxesListComponent
                loggedInUserRole={this.props.loggedinUserRole}
                hideInboxesTabFromCompanyDetail={(value: any) =>
                  this.props.hideInboxesTabFromCompanyDetail(value)
                }
                showAddInboxes={this.props.showInboxesEditTab}
                companyId={this.props.companyId}
                pendingInboxesList={this.state.pendingInboxesList}
                hideInboxesList={this.state.showInboxesList}
                inboxesList={this.state.inboxesListByCompId}
                actualDataProp={this.props.inboxesListByCompAndSubIdAction}
                filterationByTxt={(newList: any, type: any) => {
                  this.props.dispatchGetInboxesListFilter(newList);
                }}
                searchFunction={(value: any) => {
                  this.filterBySelectedStatus(value);
                }}
                //Inboxes Member functionality
                pendingInboxesMemberMethod={(value: any) => {
                  this.props.pendingInboxesMembeList(value);
                }}
                // getInboxesMembersByInboxesEmail={(inboxesId: any) => {\
                getInboxesMembersByInboxesId={(inboxesId: any) => {

                  var companyId
                  if (this.state.companyId !== undefined && this.state.companyId !== "") {
                    companyId = this.state.companyId;
                  }
                  else {
                    companyId = this.state.myCompanyId;
                  }
                  //  var companyId =this.state.companyId !==undefined ? this.state.companyId !==""? this.state.companyId: this.state.myCompanyId: this.state.myCompanyId; 
                  if (companyId !== undefined && companyId != null) {
                    this.props.getInboxesMembersByInboxesId(inboxesId, companyId, 1);
                  }

                }}
                pendingInboxesMemberListByEmail={
                  this.state.pendingInboxesMemberListState
                }
                inboxesMemberList={this.state.inboxesMemberListEmail}
                showInboxesNonMemberTabComp={(value: any, type: any) => {
                  if (type === "arrowClick") {
                    this.props.setCurrentActiveTabName("inboxesDetail");
                  } else {
                    this.props.setCurrentActiveTabName("inboxesMember");
                  }
                  //#3804

                  var companyId
                  if (this.state.companyId !== undefined && this.state.companyId !== "") {
                    companyId = this.state.companyId;
                  }
                  else {
                    companyId = this.state.myCompanyId;
                  }

                  this.props.getSubscriptionList(companyId === "" ? null : companyId);

                  this.props.showIcons("both");
                  this.props.hideInboxesNameHeading(false);

                  this.props.getInboxesNotMemberList(value, companyId !== "" ? companyId : this.state.myCompanyId);

                  if (this.props.companyId !== "") {
                    this.props.hideCompanyTabDispatch(false);
                    this.props.dispatchActiveTabName("inboxesList");
                  }
                  this.props.dispatchNonInboxesMemberTab(true);
                }}
                getInboxesNotMemberListInboxesMemeberComp={
                  //this.state.nonInboxesMemberList
                  this.state.inboxesNonMemberList
                }
                ///Add Inboxes
                subscriptionList={this.state.subscriptionList}
                getSubscriptionList={() => {
                  //#3804
                  debugger;
                  var companyId = (this.state.companyId === ""  || this.state.companyId === undefined
                  || this.state.companyId === null) ?  this.state.myCompanyId : this.state.companyId;
                  
                  this.props.getSubscriptionList(companyId === "" ? null : companyId);
                }}
                addUpdateInboxesProp={(type: any, data: any) => {

                  var companyId = this.state.companyId === "" ? (this.state.myCompanyId === undefined ? null : this.state.myCompanyId) : this.state.companyId;
                  this.props.addUpdateInboxesProp(
                    type,
                    data,
                    companyId
                  );
                }}
                showInboxesListTab={(value: any) => {
                  this.props.hideInboxesListProps(value);
                }}
                selecteInboxesName={this.state.selectedInboxesEmail}
                selectedEmailName={(value: any) => {
                  this.props.selectedInboxesNameProps(value);
                }}
                selectedInboxesEmailName={(value: any) => {
                  this.props.selectedInboxesEmailPropsAction(value);
                }}
                editData={this.state.editInboxes}
                setEditObject={(obj: any) => {
                    var companyId = this.state.companyId === "" ? (this.state.myCompanyId === undefined ? null : this.state.myCompanyId) : this.state.companyId;
                  this.props.getUnAssignPhoneNumber(null, null,companyId,"Edit",obj);
                  this.props.inboxesObject(obj);
                }}
                SubscriptionId={this.state.subscriptionId}
                SubscriptionName={this.state.subscriptionName}
                InboxesTypeId={this.state.inboxesTypeId}
                Email={this.state.email}
                Name={this.state.name}
                uniqueNumberInboxesAdd={this.state.uniquNumber}
                DefaultCountryCode={this.state.defaultCountryCode}
                //StoreText={this.state.storeText}
                DisableInboxes={this.state.disabled}
                // UsedCredits={this.state.usedCredits}
                //  CreditLimit={this.state.creditLimit}
                UniqueNumberCost={this.state.uniqueNumberCost}
                RegisterationDate={this.state.registerationDate}

                ServicePhonenumber={this.state.inboxesServicePhonenumber}
                ContactLookupEmail={this.state.inboxesContactLookupEmail}
                WhiteLabelBrand={this.state.inboxesWhiteLabelBrand}
                ContactLookupEnabled={this.state.inboxesContactLookupEnabled}

                setDisable={(value: any) => {
                  this.props.setDisable(value);
                }}

                setDefaultCountryCode={(value: any) => {
                  this.props.setDefaultCountryCode(value);
                }}
                setEmail={(value: any) => {
                  this.props.setEmail(value);
                }}

                setInboxesId={(value: any) => {
                  this.props.setInboxesId(value);
                }}
                InboxesId={this.state.inboxesId}
                setName={(value: any) => {
                  this.props.setName(value);
                }}
                setInboxesTypeId={(value: any) => {
                  this.props.setInboxesTypeId(value);
                }}
                setSubscriptionId={(value: any) => {
                  this.props.setSubscriptionId(value);
                }}
                setSubscriptionName={(value: any) => {
                  this.props.setSubscritpionName(value);
                }}
                setRegisterationDate={(value: any) =>
                  this.props.setRegisterationDate(value)
                }
                setInboxesContactLookupEnabledForUser={(value: any) =>
                  this.props.setInboxesContactLookupEnabledForUser(value)
                }
                setInboxesServicePhonenumber={(value: any) =>
                  this.props.setInboxesServicePhonenumber(value)
                }
                setInboxesContactLookupEmail={(value: any) =>
                  this.props.setInboxesContactLookupEmail(value)
                }
                setInboxesWhitelabelBrand={(value: any) =>
                  this.props.setInboxesWhitelabelBrand(value)
                }

                emailEnabledForInboxes={this.state.emailEnabled}
                removeNumberFromTwillio={(value: any) => {
                  var data = [
                    {
                      PhoneNumber: value,
                    },
                  ];
                  this.props.releaseNumberFromDatatabse(data);
                }}
                unAssignedNumbersprops={this.state.unAssignedNumbers}
                addInboxesCalledFromCompanyTab={(value: any) => {
                  this.props.emptyUnAssignedNumbers([]);
                  this.props.inboxesAddCalledFromCompanyTab(value);
                }}
                setCurrentActiveTab={(value: any) =>
                  this.props.setCurrentActiveTabName(value)
                }
                searchText={this.state.searchTextBox}
                getUnAssignedNumbers={(
                  smsServieType: any,
                  subscriptionId: any
                ) => {
                  this.props.getUnAssignPhoneNumber(
                    smsServieType,
                    subscriptionId,
                    this.props.companyId,
                    "",
                    ""
                  );
                }}
                setSearchBoxValue={(value: any) =>
                  this.props.setSearchBoxValue(value)
                }
                activeIdProp={this.state.rowActiveId}
                rowActiveIdCompanyProps={(id: any) =>
                  this.props.rowActiveIdInboxes(id)
                }
                updateUserEmailNotifications={(obj: any) => {
                  this.props.updateUserEmailNotifications(obj);
                }}
                deleteInboxes={(inboxesId: any, confirm: any) => {
                  this.props.deleteInboxes(inboxesId, confirm, this.props.companyId)
                }}

              />
            )
              :
              this.state.showInboxesNonMember === true ? (
                <InboxesNonMemberComponent
                  ActiveTab={this.state.currentActiveInboxesTabName}
                  companyId={
                    this.props.editCompanyDetail !== undefined
                      ? this.props.editCompanyDetail.Id
                      : ""
                  }
                  pendingInboxesMemberListByEmail={
                    this.state.pendingInboxesMemberListState
                  }
                  inboxesMemberList={this.state.inboxesMemberListEmail}
                  getInboxesNotMemberListInboxesMemeberComp={
                    this.state.inboxesNonMemberList
                  }
                  InboxesEmail={this.state.selectedInboxesEmail}
                  chnageIconName={(name: any) => {
                    this.props.showIcons(name);
                  }}
                  selectedInboxesEmailName={this.state.selectwedGropMail}
                  actualInboxesMemberData={this.state.actualInboxesMemberList}
                  actualInboxesNonMemberData={this.state.actualInboxesNonMemberList}
                  iconToBeDisplayedInboxes={this.state.iconNameToBeDisplayed}
                  rightIconClick={(
                    filteredMemberList: any,
                    inboxesMemberist: any,
                    notInboxesMember: any,
                    getInboxesMemberListActual: any
                  ) =>
                    this.unAssignMembersFromInboxes(
                      filteredMemberList,
                      inboxesMemberist,
                      notInboxesMember,
                      getInboxesMemberListActual
                    )
                  }
                  leftIconClick={(
                    filteredMemberList: any,
                    inboxesMemberist: any,
                    notInboxesMember: any,
                    inboxesNonMemberList: any
                  ) =>
                    this.assignMembersToInboxes(
                      filteredMemberList,
                      inboxesMemberist,
                      notInboxesMember,
                      inboxesNonMemberList
                    )
                  }
                  addUserInInboxes={(list: any, inboxesId: any) => {
                    let companyId = null
                    if (this.state.companyId) { companyId = this.state.companyId; }
                    else companyId = this.state.myCompanyId;
                    this.props.addUserInInboxes(list, inboxesId, companyId);
                  }}

                  unAssignUserFromInboxes={(list: any, inboxesId: any) => {
                    let companyId = null
                    if (this.state.companyId) { companyId = this.state.companyId; }
                    else companyId = this.state.myCompanyId;

                    this.props.unAssignUserFromInboxes(list, inboxesId, companyId);

                  }}

                  changeState={() => {
                    this.callingComponent();
                    this.props.hideInboxesListProps(false);
                    this.props.dispatchNonInboxesMemberTab(false);
                    this.props.hideCompanyTabDispatch(true);
                    this.props.hideInboxesNameHeading(true);
                  }}

                  subscriptionList={this.state.subscriptionList}

                  addUpdateInboxesProp={(type: any, data: any) => {

                    var companyId = this.state.companyId === "" ? (this.state.myCompanyId === undefined ? null : this.state.myCompanyId) : this.state.companyId;
                    this.props.addUpdateInboxesProp(
                      type,
                      data,
                      companyId
                    );
                  }}


                  updateUserEmailNotifications={(obj: any) => {
                    this.props.updateUserEmailNotifications(obj);
                  }}
                  editData={this.state.editInboxes}

                  deleteInboxesByInboxesId={(inboxesId: any, confirm: boolean) => {
                    this.props.deleteInboxesByInboxesId(inboxesId, this.state.myId, this.state.companyId, confirm);
                  }}
                  inboxesMemberListFilter={(list: any) => {
                    this.props.dispatchGetInboxesMemberListFilter(list);
                  }}
                  inboxesNonMemberListFilter={(list: any) => {
                    this.props.dispatchGetInboxesNonMemberListFilter(list);
                  }}
                  pendingNonInboxesList={this.state.pendingNonInboxesMembers}
                  pendingInboxesList={this.state.pendingInboxesMembers}
                  SubscriptionId={this.state.subscriptionId}
                  SubscriptionName={this.state.subscriptionName}
                  InboxesTypeId={this.state.inboxesTypeId}
                  Email={this.state.email}
                  InboxesId={this.state.inboxesId}
                  Name={this.state.name}
                  uniqueNumberInboxesAdd={this.state.uniquNumber}
                  DefaultCountryCode={this.state.defaultCountryCode}

                  DisableInboxes={this.state.disabled}

                  UniqueNumberCost={this.state.uniqueNumberCost}
                  RegisterationDate={this.state.registerationDate}

                  ServicePhonenumber={this.state.inboxesServicePhonenumber}
                  ContactLookupEmail={this.state.inboxesContactLookupEmail}
                  WhiteLabelBrand={this.state.inboxesWhiteLabelBrand}
                  ContactLookupEnabled={this.state.inboxesContactLookupEnabled}

                  setInboxesContactLookupEnabledForUser={(value: any) =>
                    this.props.setInboxesContactLookupEnabledForUser(value)
                  }
                  setInboxesServicePhonenumber={(value: any) =>
                    this.props.setInboxesServicePhonenumber(value)
                  }
                  setInboxesContactLookupEmail={(value: any) =>
                    this.props.setInboxesContactLookupEmail(value)
                  }
                  setInboxesWhitelabelBrand={(value: any) =>
                    this.props.setInboxesWhitelabelBrand(value)
                  }

                  setDisable={(value: any) => {
                    this.props.setDisable(value);
                  }}
                  setDefaultCountryCode={(value: any) => {
                    this.props.setDefaultCountryCode(value);
                  }}
                  setEmail={(value: any) => {
                    this.props.setEmail(value);
                  }}

                  setInboxesId={(value: any) => {
                    this.props.setInboxesId(value);
                  }}

                  setName={(value: any) => {
                    this.props.setName(value);
                  }}

                  emailEnabledForInboxes={this.state.emailEnabled}
                  setInboxesTypeId={(value: any) => {
                    this.props.setInboxesTypeId(value);
                  }}
                  setSubscriptionId={(value: any) => {
                    this.props.setSubscriptionId(value);
                  }}
                  setSubscriptionName={(value: any) => {
                    this.props.setSubscritpionName(value);
                  }}
                  setRegisterationDate={(value: any) =>
                    this.props.setRegisterationDate(value)
                  }

                  setAtiveTabName={(name: any) => {
                    this.props.setCurrentActiveTabName(name);
                  }}
                  releaseNumber={(value: any) => {
                    var data = [
                      {
                        PhoneNumber: value,
                      },
                    ];
                    this.props.releaseNumberFromDatatabse(data);
                  }}
                  loggedInUserRole={this.props.loggedinUserRole}
                  unAssignPhoneNumberProps={this.state.unAssignedNumbers}
                  getUnAssignedNumbers={(
                    smsServieType: any,
                    subscriptionId: any
                  ) => {

                    this.props.getUnAssignPhoneNumber(
                      smsServieType,
                      subscriptionId,
                      this.props.companyId,
                      "",
                      "",
                    );
                  }}
                  deleteInboxes={(inboxesId: any, confirm: any) => {
                    this.props.deleteInboxes(inboxesId, confirm, this.state.companyId)
                  }}
                />
              )

                : null

            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {

  return {
    showInboxesList: props.characterReducer.listShowComponentInboxesReducer,
    errorOccured: props.characterReducer.errorListMessage,
    actualInboxesList: props.characterReducer.inboxesListFilterReducer,
    pendingInboxesListBySubIdAndCompIdAction:
      props.characterReducer.pendingInboxesListBySubIdAndCompIdReducer,
    inboxesListByCompAndSubIdAction:
      props.characterReducer.inboxesListByCompAndSubIdReducer,
    pendingInboxesMemberList:
      props.characterReducer.pendingInboxesMemberListByEmailReducer,
    filteredInboxesMemberList:
      props.characterReducer.filteredInboxesMemberListReducer,
    iconName: props.characterReducer.iconNameReducer,
    filteredInboxesNonMemberList:
      props.characterReducer.filteredNonInboxesMemberListReducer,
    inboxesMemberListByEmail: props.characterReducer.inboxesMemberListReducer,
    subscriptionList: props.characterReducer.subscriptionList,
    selectedInboxesName: props.characterReducer.selectedInboxesNameReducer,
    hideNonInboxesMember: props.characterReducer.hideNonInboxesMember,
    editInboxesObj: props.characterReducer.inboxesEditObjectReducer,
    actualInboxesNotMemberList: props.characterReducer.filteredNonInboxesMemberListReducer,// props.characterReducer.inboxesNotMemberListReducer,

    hideInboxesEmailHeadingCompo: props.characterReducer.hideInboxesEmailHeading,
    selectedInboxesEmailValue: props.characterReducer.selectedInboxesEmailReducer,
    pendingInboxesNonMemberValue:
      props.characterReducer.pendingnotInboxesNotMemberListByInboxesIdReducer,
    pendingInboxesMemberValue:
      props.characterReducer.pendingInboxesMemberListByEmailReducer,
    uniquNumberForInboxesAdd: props.characterReducer.showUniqueNumberReducer,
    DefaultCountryCode: props.characterReducer.defaultCountryCode,
    // storeTextProps: props.characterReducer.storeTextForAddEditUser,
    disableProps: props.characterReducer.disabledForAddEditUser,
    // CreditLimit: props.characterReducer.creditLimitForAddEditUser,
    UniqueNumberCost: props.characterReducer.uniqueNumbetCostForAddEditUser,
    UsedCredit: props.characterReducer.usedCredit,
    RegisterationDate: props.characterReducer.registerationDate,
    Email: props.characterReducer.emailForAddEditUser,
    Name: props.characterReducer.nameForAddEditUser,
    InboxesTypeId: props.characterReducer.inboxesTypeId,

    SubscriptionId: props.characterReducer.subscriptionID,
    SubscriptionName: props.characterReducer.subscriptionNameForAddEditUser,
    CurrentActiveInboxesTabName: props.characterReducer.currentActiveInboxesTabName,
    editCompanyDetail: props.characterReducer.savedCompanyDetailReducer,
    loggedinUserRole: props.characterReducer.loggedinUserAccessLevel,
    unAssignPhoneNumberList: props.characterReducer.unAssignPhoneNumberReducer,
    inboxesAddCalledReducer:
      props.characterReducer.inboxesAddCalledFromCompanyTabReducer,
    searchTextBox_Text: props.characterReducer.searchTextBoxValue_InboxesReducer,
    activeId: props.characterReducer.rowActiveId_InboxesReducer,
    emailEnabledForUser: props.characterReducer.emailEnabledReducer,
    InboxesId: props.characterReducer.idForAddEditInboxes,
    DeleteInboxesConfirmMessage: props.characterReducer.deleteConfirmMessageReducer,
    // LoggedInUserData: props.characterReducer.loggedInUserDetailReducer,

    ServicePhonenumber: props.characterReducer.inboxesServicePhonenumberReducer,
    ContactLookupEmail: props.characterReducer.inboxesContactLookupEmailReducer,
    WhiteLabelBrand: props.characterReducer.inboxesWhitelabelBrandReducer,
    ContactLookupEnabled: props.characterReducer.ContactLookupEnabledForAddEditUser
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    rowActiveIdInboxes: (id: any) => {
      dispatch(action.rowActiveId_Inboxes(id));
    },
    hideInboxesNameHeading: (value: any) => {
      dispatch<any>(action.hideInboxesEmailHeading(value));
    },
    dispatchGetInboxesMemberListFilter: (list: any) => {
      dispatch<any>(action.getInboxesMemberListFilter(list));
    },
    setEmaillEnabledProps: (value: any) => {
      dispatch<any>(action.EmailEnabledForUser(value));
    },
    dispatchGetInboxesNonMemberListFilter: (list: any) => {
      dispatch<any>(action.getInboxesMemberNotListFilter(list));
    },
    dispatchNonInboxesMemberTab: (value: any) => {
      dispatch<any>(action.hideInboxesNonMemberTab(value));
    },
    inboxesObject: (obj: any) => {
      dispatch<any>(action.inboxesEditObject(obj));
    },
    dispatchPendingInboxesList: (value: any) => {
      dispatch<any>(action.pendingInboxesListBySubIdAndCompID(value));
    },
    filterInboxes: (subscriptionId: any, companyId: any, type: any, myId: any, mycomId: any) => {
      dispatch<any>(action.getInboxesListByCompanyAndSubscriptionId(subscriptionId, companyId, type, myId, mycomId));
    },
    selectedInboxesEmailPropsAction: (email: any) => {
      dispatch<any>(action.selectedInboxesEmail(email));
    },
    selectedInboxesNameProps: (value: any) => {
      dispatch<any>(action.displaySelectedInboxesName(value));
    },
    hideInboxesListProps: (value: any) => {
      dispatch<any>(action.showHideListInboxes(value));
    },
    dispatchGetInboxesListFilter: (value: any) => {
      dispatch<any>(action.getInboxesListFilter(value));
    },
    // getInboxesMembersByInboxesEmailAddress: (inboxesId: any,companyId:any) => {
    //   dispatch<any>(action.getInboxesMemberListByInboxesId(inboxesId,companyId));
    // },
    getInboxesMembersByInboxesId: (inboxesId: any, companyId: any, assignFlag: any) => {
      dispatch<any>(action.getInboxesMemberListByInboxesId(inboxesId, companyId, assignFlag));
    },
    pendingInboxesMembeList: (value: any) => {
      dispatch<any>(action.pendingInboxesMemberListByEmail(value));
    },
    showIcons: (value: any) => {
      dispatch<any>(action.showIcnOnInboxesUserTab(value));
    },
    getInboxesNotMemberList: (inboxesId: any, companyId: any) => {
      dispatch<any>(action.getInboxesNotMemberListByInboxesId(inboxesId, companyId));
    },
    hideCompanyTabDispatch: (value: any) => {
      dispatch<any>(action.hideCompanyTab(value));
    },
    dispatchgetInboxesMemberListAction: (value: any) => {
      dispatch<any>(action.getInboxesMemberListAction(value));
    },
    dispatchgetNotInboxesMemberListAction: (value: any) => {
      dispatch<any>(action.getNotInboxesMemberListAction(value));
    },
    addUserInInboxes: (list: any, inboxesId: any, companyId: any) => {
      dispatch(action.addUsernInboxesByEmail(list, inboxesId, companyId));
    },
    getSubscriptionList: (compId: any) => {
      dispatch(action.getSubscriptionList(compId, ""));
    },
    addUpdateUserProp: (type: any, data: any, compId: any) => {
      dispatch(action.addUpdateUsers(type, data, compId, "addInboxes"));
    },
    addUpdateInboxesProp: (type: any, data: any, compId: any) => {
      dispatch(action.addUpdateInboxes(type, data, compId));
    },

    updateUserEmailNotifications: (emailObj: any) => {
      dispatch(action.updateUserEmailNotifications(emailObj));
    },
    dispatchActiveTabName: (value: any) => {
      dispatch(action.displayActiveTabName(value));
    },

    deleteInboxesByInboxesId: (inboxesId: string, userId: any, companyId: any, confirm: boolean) => {
      //deleteInboxAllDataByInboxesId
      dispatch(action.deleteInboxAllDataByInboxesId(inboxesId, userId, companyId, confirm));
    },
    unAssignUserFromInboxes: (list: any, inboxesId: any, companyId: any) => {
      dispatch(action.addUsernInboxesByEmail(list, inboxesId, companyId));
    },

    setDisable: (value: any) => {
      dispatch(action.disabledForUser(value));
    },

    setDefaultCountryCode: (value: any) => {
      dispatch(action.defaultCountryCodeForUser(value));
    },
    setEmail: (value: any) => {
      dispatch(action.emailForUser(value));
    },
    setInboxesId: (value: any) => {
      dispatch(action.idForAddEditInboxes(value));
    },
    setName: (value: any) => {
      dispatch(action.nameForUser(value));
    },
    setInboxesTypeId: (value: any) => {
      dispatch(action.inboxesTypeId(value));
    },
    setInboxesContactLookupEnabledForUser: (value: any) => {
      dispatch(action.ContactLookupEnabledForUser(value));
    },
    setInboxesServicePhonenumber: (value: any) => {
      dispatch(action.inboxesServicePhonenumber(value));
    },
    setInboxesContactLookupEmail: (value: any) => {
      dispatch(action.inboxesContactLookupEmail(value));
    },
    setInboxesWhitelabelBrand: (value: any) => {
      dispatch(action.inboxesWhitelabelBrand(value));
    },

    setSubscriptionId: (value: any) => {
      dispatch(action.subscriptionIdForUser(value));
    },
    setSubscritpionName: (value: any) => {
      dispatch(action.subscriptionNameForUser(value));
    },
    setRegisterationDate: (value: any) => {
      dispatch(action.registerationDateForUser(value));
    },
    uniqueNumberProps: (value: any) => {
      dispatch(action.showNumberInUniqueNumber(value));
    },
    setCurrentActiveTabName: (value: any, companyId: any) => {
      dispatch(action.currentActiveInboxesTabName(value));
    },
    hideInboxesTabFromCompanyDetail: (value: any) => {
      dispatch(action.hideInboxesTabFromCompanyDetail(value));
    },
    releaseNumberFromDatatabse: (value: any, callingFrom: any) => {
      dispatch(action.getNumberInfoFromTwillio(value, "All", callingFrom));
    },
    getUnAssignPhoneNumber: (
      smsServiceType: any,
      subscriptionId: any,
      companyId: any,
      callFrom:any,
      editObject:any
    ) => {
      dispatch<any>(
        //action.unAssignedPhoneNumbers(smsServiceType, subscriptionId, companyId)
        action.unAssignedPhoneNumbersForInbox(smsServiceType, subscriptionId, companyId,callFrom,editObject)
      );
    },
    inboxesAddCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.inboxesAddCalledFromCompanyTab(value));
    },
    setSearchBoxValue: (value: any) => {
      dispatch<any>(action.searchBox_Value_In_Inboxes(value));
    },
    emptyUnAssignedNumbers: (data: any) => {
      dispatch<any>(action.unassignPhoneNumberList(data));
    },
    removeNumberFromYakchat: (
      data: any,
      searchBy: any,
      callingFrom: string
    ) => {
      dispatch<any>(
        action.deleteNumberByNumberModel(data, searchBy, callingFrom)
      );
    },
    deleteInboxes: (inboxesId: any, confirm: any, companyId: any) => {
      dispatch<any>(action.deleteInboxesConfirm(inboxesId, confirm, companyId)
      );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InboxesParentComponent);
