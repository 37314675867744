import { adalApiFetch, apiUrl, adalApiFetchDelete,apiUrlV2 } from "../adalConfig";

export const userList = (subscriptionId: any, companyid: any) => {
  const url =
    `${apiUrl}/getUsers?subscriptionId=` +
    subscriptionId +
    `&companyid=` +
    companyid;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const loggedInUserDetail = () => {
  const url = `${apiUrl}/GetUserDetails`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const addUpdateUser = (type: string, data: any) => {
  if (type === "add") {
    const url = `${apiUrl}/addUser`;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } else {
    const url = `${apiUrl}/modifyUser?userEmail=` + data.email;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};


export const removeUser = (parameter: string) => {
  const url = `${apiUrl}/removeUser?userEmail=${parameter}`;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const removeAllUserData = (parameter: string,confirm:boolean) => {
  //DeleteMessagingHistory
  //const url = `${apiUrl}/DeleteAllUserData?userEmail=${parameter}`+`&confirmDelete=${confirm}`;
  const url = `${apiUrl}/DeleteAllUserData?userEmail=${parameter}`+`&confirmDelete=${confirm}`;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const getAccessLevel = () => {
  const url = `${apiUrl}/getAccessLevel`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const UpdateUserEmailNotifications = (data: any) => {
  const url = `${apiUrl}/UpdateUserEmailNotifications`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(data),
  });
};
export const GetCompanyVersion = (userEmail : any) => {
  const url = `${apiUrlV2}/settings/companyVersion?userEmail=` +userEmail;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

/* V2 user add/delete List */

export const userListV2 = (companyid: any) => {
  const url =
    `${apiUrlV2}/users/query?companyId=` +
    companyid;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};


export const addUpdateUserV2 = (type: string, data: any,userId:any) => {
  
  if (type === "add") {
    const url = `${apiUrlV2}/users`;
    return adalApiFetch(url, {
      method: "PUT",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } else {
    const urlu = `${apiUrlV2}/users/details/${userId}`;    
    return adalApiFetch(urlu, {
      method: "PATCH",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }


};

export const removeAllUserDataV2 = (userId:any,fullDelte:boolean) => {   
  //const urlu = `${apiUrlV2}/users/${userId}?`+`fullDelete=${fullDelte}`;    
  const urlu = `${apiUrlV2}/users/${userId}`;    
  return adalApiFetch(urlu, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });

};

export const GetRemoveUserData = (userId:any) => {   
  const urlu = `${apiUrlV2}/users/${userId}/data`;    
  return adalApiFetch(urlu, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

