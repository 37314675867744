import { AuthenticationContext, adalFetch, AdalConfig } from "react-adal";
import configData from "./appsettings.json";
const endpoints: { [index: string]: any } = {};
endpoints[window.location.origin + ":8000/"] =
  "https://chatabox365.onmicrosoft.com/ChataAdminAPI";
//CommitForReact_29_07_2020
export const adalConfig: AdalConfig = {
  tenant: "common",
  clientId: configData.client_id,
  redirectUri: window.location.origin + "/Users",
  endpoints: endpoints,
  cacheLocation: "localStorage",
  //postLogoutRedirectUri: window.location.origin + "/Users",
  postLogoutRedirectUri: window.location.origin,
};
export const apiUrl = configData.APiUrl; //dev
export const apiUrlV2 = configData.APiUrlV2;
export const smsUrl =configData.smsUrl; //dev
export const webexUrl =configData.webExAPiUrl; //dev
// export const apiUrl = "https://services.yakchat.com:8000/AdminApi"; //production
// export const smsUrl = "https://services.yakchat.com:8200/message"; //production



export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => {
  return authContext.getCachedToken(adalConfig.clientId);
};
export const adalApiFetch = (url: string, options: any) => {
  return adalFetch(
    authContext,
    "https://chatabox365.onmicrosoft.com/ChataAdminAPI",
    fetch,
    url,
    options
  ).then((response) => response.text());
};
export const adalApiFetchDelete = (url: string, options: any) => {
  return adalFetch(
    authContext,
    "https://chatabox365.onmicrosoft.com/ChataAdminAPI",
    fetch,
    url,
    options
  );
};
export const loginMethod = () => {
  //debugger
  return authContext.login();
};
export const getLoggedInUSerName = () => {
  return authContext.getCachedUser();
};

export const authSid = "ACe5f2d866400660434e3d44d11b2ad94e";
export const authToken = "6691634d940e56ce7b1618699f79acff";
// export const authSid = 'ACb2c84dbadb957b9fb1abed96fb550851'
// export const authToken = '7d12e3fec52dc693077a4e2e716cfe0a'
