import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { NumberListComponent } from "./numbersListComponent";
import NotificationComponent from "../sharedComponents/displayNotifications";
import * as action from "../../redux/actions/actions";
import { NumbersAddUpdateComponent } from "./numbersAddUpdateComponent";
import history from "../../history";

class NumbersParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      selectedSearch: "All",
      phoneNumberList: this.props.filterNumberList,
      filterPhoneNumberList: [],
      calledFromData: "",
      pendingResultFromTwillio: false,
      twillioResponse: [],
      mobileTwillioesponse: [],
      savingTwillioNumberToDatabase: false,
      showLocaNumber: false,
      screenName: "",
      //companyListState: [],
      companyListItemState:[],
      searchBox: "",
      phoneNumberPurchased_State: "",
      showWarningMessage: false,
      buttonText: "Save Changes",
      selectedCompanyId: "",
      activeRowId: "",
    };
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.filterNumberList !== prevState.phoneNumberList ||
      nextProps.pendingResultFromTwillioApi !==
      prevState.pendingResultFromTwillio ||
      nextProps.filterBy !== prevState.selectedSearch ||
      nextProps.twillioResponseFromApi !== prevState.twillioResponse ||
      nextProps.mobileTwillioResponseFromApi !==
      prevState.mobileTwillioesponse ||
      nextProps.savingToDataBase !== prevState.savingTwillioNumberToDatabase ||
      nextProps.showLocalResponse !== prevState.showLocaNumber ||
      //nextProps.companyListProps !== prevState.companyListState ||
      nextProps.companyListItemProps !== prevState.companyListItemState ||
      
      nextProps.searchTextBox_Text !== prevState.searchBox ||
      nextProps.phoneNumberAction !== prevState.showWarningMessage ||
      nextProps.phoneNumberPurchased !== prevState.phoneNumberPurchased_State ||
      nextProps.buttonTextAction !== prevState.buttonText ||
      nextProps.rowActiveIdNumbers !== prevState.activeRowId
    ) {
      return {
        activeRowId: nextProps.rowActiveIdNumbers,
        phoneNumberPurchased_State: nextProps.phoneNumberPurchased,
        showLocaNumber: nextProps.showLocalResponse,
        phoneNumberList: nextProps.filterNumberList,
        selectedSearch: nextProps.filterBy,
        twillioResponse: nextProps.twillioResponseFromApi,
        mobileTwillioesponse: nextProps.mobileTwillioResponseFromApi,
        pendingResultFromTwillio: nextProps.pendingResultFromTwillioApi,
        savingTwillioNumberToDatabase: nextProps.savingToDataBase,
       // companyListState: nextProps.companyListProps,
        companyListItemState: nextProps.companyListItemProps,
        searchBox: nextProps.searchTextBox_Text,
        showWarningMessage: nextProps.phoneNumberAction,
        buttonText: nextProps.buttonTextAction,
      };
    } else return null;
  }
  componentDidMount() {
    //#3804 Maksud
    //this.props.getCompanyList();
    var calledFrom =
      this.props.location !== null && this.props.location !== undefined
        ? this.props.location.state !== null &&
          this.props.location.state !== undefined
          ? this.props.location.state.callingFrom !== null &&
            this.props.location.state.callingFrom !== undefined
            ? this.props.location.state.callingFrom
            : "Numbers"
          : "Numbers"
        : "Numbers";
    var compaanyId =
      this.props.location !== null && this.props.location !== undefined
        ? this.props.location.state !== null &&
          this.props.location.state !== undefined
          ? this.props.location.state.companyId !== null &&
            this.props.location.state.companyId !== undefined
            ? this.props.location.state.companyId
            : ""
          : ""
        : "";
    if (calledFrom !== "Numbers" && calledFrom !== "") {
      //var calling = calledFrom as string;
      history.replace("/Numbers", { callingFrom: "" });
      this.setState({
        screenName: calledFrom,
        calledFromData: "add",
        selectedCompanyId: compaanyId,
      });
      this.props.showHideListProp(false);
    } else {
      this.props.rowActiveId_Numbers("");
      this.props.setSearchBoxValue("");
      this.props.filterNumbersValue("All");
      this.props.showHideListProp(true);
      this.props.pendingNumberListAction(true);
      //#3804      
      this.props.getPhoneNumberListProps(null, "All");   
      
    }
    this.props.showWarningMessage("");
    this.props.purchasePhoneNumber("");
    this.props.buttonTextDisplay("Save Changes");
    this.props.fetchAllServiceProviders();

    this.props.providersList &&
      this.setState({ providersList: this.props.providersList });

  }
  searchByType(type: any) {
    let filteredList: any[] = [];
    this.props.providersList.forEach((provider: any) => {
      if (provider.ProviderName === type) {
        this.props.filterNumbersValue(type);

        filteredList = this.props.phoneNumberList.filter(
          (phoneNumber: any) => phoneNumber.Type === provider.Id
        );
      }
    });
    this.props.getPhoneNumberListPropsFilter(filteredList);
    //debugger
    // let filteredList: any;
    // if (type === "Twilio") {
    //   this.props.filterNumbersValue("Twilio");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "Twilio"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "MessageMedia") {
    //   this.props.filterNumbersValue("MessageMedia");

    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "MessageMedia"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "SMPP") {
    //   this.props.filterNumbersValue("SMPP");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "SMPP"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "Bandwidth") {
    //   this.props.filterNumbersValue("Bandwidth");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "Bandwidth"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "VOIP") {
    //   this.props.filterNumbersValue("VOIP");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "VOIP"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "Inteliquent") {
    //   this.props.filterNumbersValue("Inteliquent");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "Inteliquent"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else if (type === "Sinch") {
    //   this.props.filterNumbersValue("Sinch");
    //   filteredList = this.props.phoneNumberList.filter(
    //     (x: any) => x.Type === "Sinch"
    //   );
    //   this.props.getPhoneNumberListPropsFilter(filteredList);
    // } else {
    //   this.props.getPhoneNumberListPropsFilter(this.props.phoneNumberList);
    // }
  }

  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          <NotificationComponent />
          {this.props.showHideNumberList === true ? (
            <div className="container-fluid">
              {this.props.pendingNumberList === false ? (
                <NumberListComponent
                  numberistProps={this.state.phoneNumberList}
                  ColumnName={[
                    "Number",
                    "Provider",
                    "Provider Account",
                    "Company Name",
                    "OnlyReceiveMFA",
                    "Trial",
                  ]}
                  filterationByTxt={(newList: any) => {
                    this.props.getPhoneNumberListPropsFilter(newList);
                  }}
                  openEditWindow={(object: any) => {
                    this.setState({
                      calledFromData: "edit",
                      editObject: object,
                    });
                    this.setState({
                      screenName: "Edit Numbers",
                    });
                    this.props.purchasePhoneNumber(object.PhoneNumber);
                    this.props.showWarningMessage("");
                    this.props.showHideListProp(false);

                    //#3804 Maksud
                    //this.props.getServicePlansList();
                    this.props.getCompanyList();
                    //this.props.fetchAllServiceProviders();
                  }}
                  actualPhoneList={this.props.phoneNumberList}
                  selectedSearchFilter={this.state.selectedSearch}
                  showHideListNumber={(value: any) => {
                    this.setState({
                      editObject: {},
                      screenName: "Numbers",
                    });
                    this.props.showHideListProp(false);
                    this.props.buttonTextDisplay("Save Changes");
                    this.props.purchasePhoneNumber("");
                    this.props.showWarningMessage("");
                    this.setState({ calledFromData: "add" });

                    //#3804 Maksud
                    //this.props.getServicePlansList();
                    this.props.getCompanyList();
                    //this.props.fetchAllServiceProviders();
                  }}
                  searchFunction={(value: any) => {
                    this.props.filterNumbersValue(value);
                    this.searchByType(value);
                  }}
                  searchBoxText={this.state.searchBox}
                  searchBoxValue={(value: any) =>
                    this.props.setSearchBoxValue(value)
                  }
                  rowActiveIdCompanyProps={(id: any) =>
                    this.props.rowActiveId_Numbers(id)
                  }
                  activeRowId={this.state.activeRowId}
                  providersList={this.props.providersList}
                  providersAccountCall={(val: string | number) =>
                    this.props.fetchAllServiceProvidersAccount(val)
                  }
                  providersAccount={this.props.providersAccount}
                />
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
            </div>
          ) :         
          (
            <NumbersAddUpdateComponent
              screenName={this.state.screenName}
              showLocalNumber={this.state.showLocaNumber}
              calledFrom={this.state.calledFromData}
              editData={this.state.editObject}
              addUpdateNumberProp={(calledFrom: any, data: any) => {
                this.props.addUpdateNumber(
                  calledFrom,
                  data,
                  this.state.selectedSearch,
                  this.state.screenName
                );
              }}
              changeState={() => {
                this.props.showHideListProp(true);
              }}
              deletePhoneNumber={(object: any) => {
                this.props.removeRecord(object, this.state.selectedSearch);
              }}
              searchNumberFromTwillio={(
                countryCode: any,
                type: any,
                areaCode: any,
                firstRequest: any
              ) => {
                this.props.getPhoneNumbersFromTwillio(
                  countryCode,
                  type,
                  areaCode,
                  firstRequest
                );
              }}
              pendingResultFromTwillioProps={
                this.state.pendingResultFromTwillio
              }
              twillioData={this.state.twillioResponse}
              mobileTwillioData={this.state.mobileTwillioesponse}
              purchaseNumberFromTwilio={(
                phoneNumber: any,
                smsUrl: any,
                twilBin: any,
                callingFrom: any,
                countryCode: any
              ) => {
                this.props.provisionMumberFromTwillio(
                  phoneNumber,
                  smsUrl,
                  twilBin,
                  callingFrom,
                  countryCode
                );
              }}
              twillioList={(list: any) => {
                this.props.dispatchLocalListTwillio(list);
              }}
              savingTwillioNumber={this.state.savingTwillioNumberToDatabase}
              loggedInUserRole={this.props.loggedinUserAccessLevel}
              userEditTabHeading={(value: any) => {
                this.props.userEditTabHeading(value);
              }}
              getCompanyList={() => {
                this.props.getCompanyList();
              }}
              //CompanyList={this.props.companyListProps}
              CompanyList={this.props.companyListItemProps}
              
              showWarningMessagePRops={this.state.showWarningMessage}
              deleteFromDatabase={(object: any) => {
                this.props.removeNumberFromDatabase(
                  object,
                  this.state.selectedSearch
                );
              }}
              phoneNumberProps={this.state.phoneNumberPurchased_State}
              buttonTextAction={this.state.buttonText}
              phoneNumberValue={(value: any) =>
                this.props.purchasePhoneNumber(value)
              }
              selectedCompanyId={this.state.selectedCompanyId}
              providersList={this.props.providersList}
              providersAccountCall={(val: string | number) =>
                this.props.fetchAllServiceProvidersAccount(val)
              }
              providersAccount={this.props.providersAccount}
              addUpdatePhoneNumbers={(numberObj: any) =>
                this.props.fetchAddUpdatePhoneNumbers(numberObj)
              }
              numberistProps={this.state.phoneNumberList}
            />
          )
          
          
          }
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    showHideNumberList: props.characterReducer.showHideNumberListReduc,
    pendingNumberList: props.characterReducer.pendingNumbeListReduc,
    phoneNumberList: props.characterReducer.phoneNumberListReducer,
    filterNumberList: props.characterReducer.filterNumberListRedux,
    filterBy: props.characterReducer.filtersNumberAction,
    pendingResultFromTwillioApi:
      props.characterReducer.pendingResultFromTwillioReducer,
    twillioResponseFromApi: props.characterReducer.twillioResponseReducer,
    mobileTwillioResponseFromApi:
      props.characterReducer.mobileTwillioResponseReducer,
    savingToDataBase: props.characterReducer.saveTwillioNumberToDatabaseReducer,
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    showLocalResponse: props.characterReducer.showLocalResponseReducer,
   // companyListProps: props.characterReducer.companyList,
    companyListItemProps: props.characterReducer.companisListItem,
    searchTextBox_Text: props.characterReducer.searchTextBoxReducer,
    phoneNumberAction: props.characterReducer.phoneNumberReducer,
    phoneNumberPurchased: props.characterReducer.phoneNumberPurchasedReducer,
    buttonTextAction: props.characterReducer.buttonTextAction,
    rowActiveIdNumbers: props.characterReducer.rowActiveId_NumbersReducer,
    providersList: props.characterReducer.allServiceProvidersList,
    providersAccount: props.characterReducer.allServiceProvidersAccount,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    rowActiveId_Numbers: (id: any) => {
      dispatch(action.rowActiveId_Numbers(id));
    },
    showHideListProp: (value: any) => {
      dispatch<any>(action.showHideNumberList(value));
    },
    filterNumbersValue: (value: any) => {
      dispatch<any>(action.filterNumberBy(value));
    },
    getPhoneNumberListProps: (smsServiceType: any, type: any) => {     
      dispatch<any>(action.getPhoneNumerList(smsServiceType, type));      
    },
    removeRecord: (oject: any, filterBy: any) => {
      dispatch<any>(
        action.deleteNumberConfirmation(oject, filterBy, "Numbers")
      );
    },
    removeNumberFromDatabase: (oject: any, filterBy: any) => {
      dispatch<any>(
        action.deleteNumberByNumberModel(oject, filterBy, "Numbers")
      );
    },
    setSearchBoxValue: (value: any) => {
      dispatch<any>(action.searchBox_Value_InTables(value));
    },
    addUpdateNumber: (
      calledFrom: any,
      data: any,
      searchBy: any,
      screenName: any
    ) => {
      if (calledFrom === "edit") {
        dispatch<any>(
          action.updateNumberAction(data, searchBy, screenName, "")
        );
      } else {
        dispatch<any>(
          action.addUpdateNumberAction(data, searchBy, screenName, "")
        );
      }
    },
    pendingNumberListAction: (value: any) => {
      dispatch<any>(action.pendingNumberList(value));
    },
    getPhoneNumberListPropsFilter: (list: any) => {
      dispatch<any>(action.filterPhoneNumberList(list));
    },
    getPhoneNumbersFromTwillio: (
      countryCode: any,
      type: any,
      areaCode: any,
      firstRequest: any
    ) => {
      if (firstRequest === true) {
        dispatch<any>(action.twillioLogin(countryCode, type, areaCode));
      } else {
        dispatch<any>(action.fetchNumbers(countryCode, type, areaCode));
      }
    },
    provisionMumberFromTwillio: (
      phoneNumber: any,
      smsURl: any,
      twilBin: any,
      callingFrom: any,
      countryCode: any
    ) => {
      dispatch<any>(
        action.buyNumberFromTwillio(
          phoneNumber,
          smsURl,
          twilBin,
          callingFrom,
          countryCode
        )
      );
    },
    dispatchLocalListTwillio: (list: any) => {
      dispatch<any>(action.localNumberListTwillio(list));
    },
    userEditTabHeading: (value: any) => {
      dispatch(action.userEditFromNumberBreadCrumb(value));
    },
    getCompanyList: () => {
      //dispatch(action.getCompanyList("", [], ""));
      dispatch(action.getCompanisListItem("", [], ""));
    },
    buttonTextDisplay: (value: any) => {
      dispatch(action.changeButtonText(value));
    },
    purchasePhoneNumber: (phoneNumber: any) => {
      dispatch(action.purchasedPhoneNumber(phoneNumber));
    },
    showWarningMessage: (phoneNumber: any) => {
      dispatch(action.showWarningMessage(phoneNumber));
    },
    fetchAllServiceProviders: () => {
      dispatch(action.fetchAllServiceProviders());
    },
    fetchAllServiceProvidersAccount: (val: number | string) => {
      dispatch(action.fetchAllServiceProvidersAccount(val));
    },
    fetchAddUpdatePhoneNumbers: (numberObj: any) => {
      dispatch(action.fetchAddUpdatePhoneNumbers(numberObj));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NumbersParentComponent);
