import * as React from "react";
import * as commonMethod from "../../commonMethods";
import ConfirmationInboxesDeleteComponent from "../sharedComponents/ConfirmationInboxesDeleteComponent";

import Select, { components } from "react-select";
//import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import history from "../../history";
import { getLoggedInUSerName } from "../../adalConfig";
import { CSVLink } from "react-csv";
import * as companyService from "../../services/companyComponent.service";
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export class InboxesAddComponent extends React.Component<any, any> {
  csvPartnerMessage: React.RefObject<any>;
  constructor(props: any, state: any) {
    super(props);

    this.csvPartnerMessage = React.createRef();
    this.state = {
      messageCsv: [],
      Email:
        this.props.Email !== undefined
          ? this.props.Email !== null
            ? this.props.Email
            : this.props.Email
          : "",
      InboxesId: this.props.InboxesId !== undefined ? this.props.InboxesId : "",
      RegistrationDate:
        this.props.RegisterationDate !== undefined
          ? this.props.RegisterationDate
          : "",
      UniqueNumberCost:
        this.props.UniqueNumberCost !== undefined
          ? this.props.UniqueNumberCost
          : "",
      DefaultCountryCode:
        this.props.defaultCountryCodeForAddEditUserPRops !== undefined
          ? this.props.defaultCountryCodeForAddEditUserPRops
          : "",
      SubscriptionId:
        this.props.SubscriptionId !== undefined
          ? this.props.SubscriptionId
          : "",
      InboxesType:
        this.props.InboxesTypeId !== ""
          ? this.props.InboxesTypeId.toUpperCase() === "PERSONAL"
            ? 0
            : 1
          : 1,
      disable:
        this.props.calledFrom !== "edit"
          ? false
          : this.props.editData?.Disabled !== undefined
          ? this.props.editData.Disabled
          : false,

      Name: this.props.Name !== undefined ? this.props.Name : "",
      ErrorEmail: "",
      ErrorPhoneNumber: "",
      ErrorSubscription: "",
      InvalidEmail: "",
      subscriptionList: [],
      ServicePlanId: "",
      SelectedSubscName:
        this.props.SubscriptionName !== undefined
          ? this.props.SubscriptionName
          : "",
      ErrorName: "",
      activeTab: "Inbox Detail",
      unAssignedNumbers: [],    
      emailEnabled:
        this.props.emailEnabledProps !== undefined
          ? this.props.emailEnabledProps
          : false,
      emailText: false,
      AlternateEmail:
        this.props.editData !== undefined
          ? this.props.editData.ContactEmail || ""
          : "",
      EmailEnabled: this.props.editData?.EmailEnabled
        ? this.props.editData?.EmailEnabled
        : false,
      // ContactEmail: !this.props.editData.ContactEmail
      //   ? ""
      //   : this.props.editData.ContactEmail,

      whiteLabel:
        this.props.editData && this.props.editData.WhiteLabelBrand
          ? this.props.editData.WhiteLabelBrand
          : "",
      deleteMessage: "",
      servicePhonenumber:
        this.props.ServicePhonenumber !== null
          ? this.props.ServicePhonenumber
          : "",
      contactLookupEmail:
        this.props.ContactLookupEmail !== null
          ? this.props.ContactLookupEmail
          : "",
      whiteLabelBrand:
        this.props.WhiteLabelBrand !== null ? this.props.WhiteLabelBrand : "",
      contactLookupEnabled:
        this.props.ContactLookupEnabled === null
          ? false
          : this.props.ContactLookupEnabled,
      AErrorEmail:"",
      AInvalidEmail:"",
    };
  }
  componentDidMount() {
    if (this.props.calledFrom === "edit") {
      var data =
        this.props.subscriptionList !== undefined
          ? this.props.subscriptionList
              .filter(
                (opt: any) => opt.subscription.Id === this.state.SubscriptionId
              )
              .map((ele: any) => ele.subscription.Name)[0]
          : "";
      this.setState({
        SelectedSubscName: data,
      });
    }

  }
  async PartnerMessage() {
    let datareturned=await companyService.GeneratePartnerMessageSnapshotForInbox_v2(this.state.InboxesId);
  
    datareturned = datareturned.replace(/["']/g, "");
    if (datareturned == "") {
      alert(
        "Sorry, there are no messages for this inboxes in the last seven days."
      );
      return;
    }
    this.setState({ messageCsv: datareturned });
    this.csvPartnerMessage.current.link.click();
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.defaultCountryCodeForAddEditUserPRops !==
        prevState.DefaultCountryCode ||
      nextProps.uniquePhoneNumber !== prevState.UniqueNumber ||
      nextProps.unAssignPhoneNumberProps !== prevState.unAssignedNumbers ||
      nextProps.deleteConfirmMessage !== prevState.deleteMessage ||
      nextProps.ServicePhonenumber !== prevState.servicePhonenumber ||
      nextProps.ContactLookupEmail !== prevState.contactLookupEmail ||
      nextProps.WhiteLabelBrand !== prevState.whiteLabelBrand 
      //||nextProps.ContactLookupEnabled !== prevState.contactLookupEnabled
    ) {
      //console.log(nextProps.subscriptionList)
      return {
        // CreditLimit: nextProps.creditLimitForAddEditUserProp,
        UniqueNumber: nextProps.uniquePhoneNumber,
        DefaultCountryCode: nextProps.defaultCountryCodeForAddEditUserPRops,
        unAssignedNumbers: nextProps.unAssignPhoneNumberProps,
        subscriptionList:
          nextProps.subscriptionList !== undefined &&
          nextProps.subscriptionList.length !== 0
            ? nextProps.subscriptionList.sort((a: any, b: any) =>
                a.subscription.Name > b.subscription.Name
                  ? 1
                  : a.subscription.Name < b.subscription.Name
                  ? -1
                  : 0
              )
            : nextProps.subscriptionList,

        deleteMessage: nextProps.deleteConfirmMessage,
        servicePhonenumber: nextProps.ServicePhonenumber,
        contactLookupEmail: nextProps.ContactLookupEmail,
        whiteLabelBrand: nextProps.WhiteLabelBrand,
        //contactLookupEnabled: nextProps.ContactLookupEnabled,
      };
    } else return null;
  }

  IsValid() {
    var isValid = true;

    if (this.state.Name.length === 0) {
      this.setState({ ErrorName: "This Field is Required" });
      isValid = false;
    }
    if (
      this.state.SubscriptionId.length === 0 ||
      this.state.ErrorSubscription !== ""
    ) {
      isValid = false;
      if (this.state.SubscriptionId.length === 0) {
        this.setState({
          ErrorSubscription: "This Field is Required",
        });
      }
    }
    if (this.state.ErrorPhoneNumber !== "") {
      isValid = false;
    }

    if (this.state.contactLookupEnabled === true && this.state.contactLookupEmail === null) {
      isValid = false;
    }

    return isValid;
  }

  addUpdateData = () => {
    if (this.IsValid() === true) {
      if (
        this.state.emailEnabled !== this.props.emailEnabledProps ||
        this.state.AlternateEmail !==
          ((this.props.editData && this.props.editData.ContactEmail) || "")
      ) {
        const emailData = {
          enabled: this.state.emailEnabled,
          contactEmail: this.state.AlternateEmail,
          userEmail: this.state.Email,
        };
        this.props.updateUserEmailNotifications(emailData);
      }
      if (this.props.calledFrom === "edit") {
        var userIds = [];
        if (this.props.editData?.AssignedUsers.length > 0) {
          userIds = this.props.editData?.AssignedUsers.map(function (v: any) {
            return v.UserId;
          });
        }
        var dataBody = {
          id: this.state.InboxesId,
          subscriptionId: parseInt(this.state.SubscriptionId),
          name: this.state.Name,
          servicePhoneNumber: this.state.servicePhonenumber,
          type: this.state.InboxesType,
          disabled: this.state.disable,
          defaultCountryCode:
            this.state.DefaultCountryCode === ""
              ? 1
              : parseInt(this.state.DefaultCountryCode),
          contactLookupEnabled: this.state.contactLookupEnabled,
          contactLookupEmail: this.state.contactLookupEmail,
          userIds:userIds,
        };
        this.props.addUpdateInboxesProp("edit", dataBody);
      } else {

        

        var data = {
          subscriptionId: parseInt(this.state.SubscriptionId),
          name: this.state.Name,
          servicePhoneNumber: this.state.servicePhonenumber,
          type: this.state.InboxesType,
          disabled: this.state.disable,
          contactLookupEnabled: this.state.contactLookupEnabled,
          defaultCountryCode:
            this.state.DefaultCountryCode === ""
              ? 1
              : parseInt(this.state.DefaultCountryCode),
          contactLookupEmail: this.state.contactLookupEmail,          
        };
        this.props.addUpdateInboxesProp("add", data);
      }
    }
  };
  subscriptionChange = (opt: any) => {
    this.props.getUnAssignedNumbers_inboxes(null, opt.value);
    this.setState({ SubscriptionId: opt.value, SelectedSubscName: opt.label });
    var selectedSubscrition = this.state.subscriptionList.filter((x: any) => {
      return x.subscription.Id === opt.value;
    })[0];
    this.props.setSubscriptionId(opt.value);
    this.props.setSubscriptionName(opt.label);
    if (
      (selectedSubscrition.subscription.ServicePlanId === 1 &&
        selectedSubscrition.subscription.IncludeInboxes === true) ||
      selectedSubscrition.subscription.ServicePlanId === 4 ||
      selectedSubscrition.subscription.ServicePlanId === 5 ||
      selectedSubscrition.subscription.ServicePlanId === 6 ||
      selectedSubscrition.subscription.ServicePlanId === 7
    ) {
      this.setState({
        ErrorSubscription: "",
        servicePlanId: opt.ServicePlanId,
      });
    } else {
      // this.setState({
      //   ErrorSubscription: "Requires Inboxes Subscription",
      // });
      this.setState({ servicePlanId: opt.ServicePlanId });
    }
  };
  buyButtonMethod = () => {
    if (this.props.calledFrom === "edit") {
      history.push("Numbers", {
        callingFrom: "Company:editInboxes",
        companyId: this.props.companyId,
      });
    } else {
      history.push("Numbers", {
        callingFrom: "Company:addInboxes",
        companyId: this.props.companyId,
      });
    }
  };

  releaseNumberFromYakchat = () => {
    const userData = getLoggedInUSerName();
    const data = {
      PhoneNumber: this.props.uniquePhoneNumber,
      Type: 0,
      CompanyId: this.props.companyId,
      ServiceProviderAccountId: 0,
      Trial: false,
      OnlyReceiveMFA: false,
      CreatedBy: userData.userName,
      ModifiedBy: userData.userName,
      CreatedDateTime: new Date(),
      ModifiedDateTime: new Date(),
    };
    this.props.removeNumberFromYakchat(data, "", this.props.calledFrom);
  };
  showBuyButton = () => {
    return this.state.servicePhonenumber !== "" ? (
      <button
        type="button"
        className="btn btn-primary"
        onClick={(e) => {
          this.props.releaseNumberFromApi(this.state.servicePhonenumber);
          this.releaseNumberFromYakchat();
        }}
      >
        Release Number
      </button>
    ) : (
      <button
        type="button"
        className="btn btn-primary"
        disabled={this.state.ErrorSubscription !== "" ? true : false}
        onClick={(e) => this.buyButtonMethod()}
      >
        Buy Number
      </button>
    );
  };
  renderButtonHtml = () => {
    return (
      <div className="col-12">
        <div className="btnDiv d-flex justify-content-between align-items-center">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.addUpdateData}
            >
              Save changes
            </button>
            {/* {this.props.roleOfLoggedInUser === -10 &&
              this.props.companyId !== undefined
              ? this.showBuyButton()
              : null} */}
            <button
              type="button"
              className="btn btn-default cancelBtn"
              onClick={() => this.props.changeState()}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => this.PartnerMessage()}
              className="btn downloadBtn"
            >
              <i className="ion ion-md-download"></i>
              &nbsp; Message Log
            </button>
            <CSVLink
              data={this.state.messageCsv}
              filename="Partner_Message.csv"
              className="hidden"
              ref={this.csvPartnerMessage}
              target="_blank"
            />
          </div>
          <div>
            {this.props.calledFrom === "edit" ? (
              <button
                type="button"
                className="btn btn-primary delete_btn"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <span
                  className="font-icon"
                  onClick={() => {
                    var id = this.state.InboxesId;
                    this.props.deleteInboxesConfirm(id, 1);
                  }}
                >
                  &#107;
                </span>
                Delete
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  renderHtml() {
    return this.state.subscriptionList === undefined ? (
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    ) : (
      <div className="nav-tabs-top">
        <div className="tab-content border-0">
          <div className="tab-pane fade show active" id="user-edit-account">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="formDiv">
                    <div className="row text-left">
                      {this.props.calledFrom === "edit" ? (
                        <div className="col-md-6">
                          <div
                            className={
                              this.state.InboxesId !== ""
                                ? "form-group validationClass"
                                : "form-group"
                            }
                          >
                            <label className="form-label">Inboxes Id</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={true}
                              value={
                                this.state.InboxesId === null
                                  ? ""
                                  : this.state.InboxesId
                              }
                              name="validation-email"
                              placeholder="Inboxes Id"
                              required
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorName !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Name<small className="text-muted">* </small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="validation-email"
                            placeholder="Name"
                            onChange={(e) => {
                              this.props.setName(e.target.value);
                              this.setState({
                                Name: e.target.value,
                                ErrorName: "",
                              });
                            }}
                            value={this.state.Name}
                          />
                          <small className="form-text">
                            {this.state.ErrorName}{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorSubscription !== ""
                              ? "form-group validationClass zIndex"
                              : "form-group zIndex"
                          }
                        >
                          <label className="form-label">
                            Subscription<small className="text-muted">* </small>
                          </label>
                          <Select
                            classNamePrefix={
                              this.state.ErrorSubscription !== ""
                                ? " validationClass"
                                : ""
                            }
                            components={{
                              DropdownIndicator,
                            }}
                            onChange={(opt: any) =>
                              this.subscriptionChange(opt)
                            }
                            value={[
                              {
                                label:
                                  this.state.SelectedSubscName === undefined
                                    ? this.props.subscriptionList
                                        .filter(
                                          (opt: any) =>
                                            opt.subscription.Id ===
                                            this.state.SubscriptionId
                                        )
                                        .map(
                                          (ele: any) => ele.subscription.Name
                                        )[0]
                                    : this.state.SelectedSubscName,
                                value: this.state.SubscriptionId,
                              },
                            ]}
                            options={
                              this.state.subscriptionList !== undefined
                                ? this.state.subscriptionList.map(
                                    (opt: any) => ({
                                      label: opt.subscription.Name,
                                      value: opt.subscription.Id,
                                    })
                                  )
                                : null
                            }
                          />
                          <small className="form-text ">
                            {this.state.ErrorSubscription}{" "}
                          </small>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Inbox Type</label>
                          <select
                            value={this.state.InboxesType}
                            onChange={(e) => {
                              this.props.setInboxesTypeId(
                                parseInt(e.target.value)
                              );
                              this.setState({
                                InboxesType: parseInt(e.target.value),
                              });
                            }}
                            className="custom-select"
                          >
                            <option value={0}>Personal</option>
                            <option value={1}> Shared</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorPhoneNumber !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">Number</label>
                          <div className="textPhoneNumber">
                            {this.state.servicePhonenumber}
                          </div>
                          <Select
                            className={
                              this.state.ErrorPhoneNumber !== ""
                                ? " validationClass"
                                : ""
                            }
                            isSearchable={true}
                            isClearable={true}
                            components={{
                              DropdownIndicator,
                              SingleValue: () => {
                                return null;
                              },
                            }}
                            value={[
                              {
                                label: this.state.servicePhonenumber,
                                value: this.state.servicePhonenumber,
                              },
                            ]}
                            onChange={(opt: any) => {
                              if (opt !== null) {
                                this.props.setInboxesServicePhonenumber(
                                  opt.label
                                );
                                this.setState({
                                  ErrorPhoneNumber: "",
                                  servicePhonenumber: opt.label,
                                });
                              } else {
                                this.props.setInboxesServicePhonenumber("");
                                this.setState({ ErrorPhoneNumber: "" });
                              }
                            }}
                            options={
                              this.state.unAssignedNumbers !== undefined
                                ? this.state.unAssignedNumbers.map(
                                    (opt: any) => ({
                                      label: opt.PhoneNumber,
                                      value: opt.PhoneNumber,
                                    })
                                  )
                                : []
                            }
                          />
                          <small className="form-text ">
                            {this.state.ErrorPhoneNumber}{" "}
                          </small>
                        </div>
                      </div>

                      
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Country Code</label>
                          <Select
                            isSearchable={false}
                            isClearable={false}
                            components={{ DropdownIndicator }}
                            value={[
                              {
                                label:
                                  this.state.DefaultCountryCode === 1
                                    ? "1"
                                    : this.state.DefaultCountryCode === 44
                                    ? "44"
                                    : "61",
                                value: this.state.DefaultCountryCode,
                              },
                            ]}
                            onChange={(opt: any) => {
                              this.props.setDefaultCountryCode(
                                parseInt(opt.value)
                              );
                              this.setState({
                                DefaultCountryCode: parseInt(opt.value),
                              });
                            }}
                            options={[
                              { label: "1", value: 1 },
                              { label: "44", value: 44 },
                              { label: "61", value: 61 },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div  className={
                            this.state.AErrorEmail !== "" ||
                              this.state.AInvalidEmail !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }>
                          <label className="form-label">
                            Alternative Notification Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            onChange={(e) => {

                              var valid = commonMethod.validEmail(
                                e.target.value
                              );
                              if (!valid) {
                                this.setState({
                                  AInvalidEmail:
                                    "Please Enter Valid Email Address",
                                });
                              } else {
                                this.setState({ AInvalidEmail: "" });
                              } 

                              this.setState({ 
                                AlternateEmail: e.target.value,
                                AErrorEmail: "",
                               });
                            }}
                            value={this.state.AlternateEmail}
                            placeholder="Alternative Email address"
                          />
                          <small className="form-text">
                            {this.state.AErrorEmail}{" "}
                          </small>
                          <small className="form-text">
                            {this.state.AInvalidEmail}{" "}
                          </small>                      
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label className="custom-control custom-checkbox m-0 checkbox_right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  contactLookupEnabled: !this.state.contactLookupEnabled,
                                });
                              }}
                              checked={this.state.contactLookupEnabled}
                            />
                            <span className="custom-control-label">
                              Contact Lookup Enabled
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div  className={
                            this.state.ErrorEmail !== "" ||
                              this.state.InvalidEmail !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }>
                          <label className="form-label">
                            Outlook Contact Lookup Email
                            <small className="text-muted"> </small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="validation-email"
                            disabled={
                              !(this.state.contactLookupEnabled === null
                                ? false
                                : this.state.contactLookupEnabled)
                            }
                            placeholder=" Outlook Contact Lookup Email"
                            onChange={(e) => {

                              var valid = commonMethod.validEmail(
                                e.target.value
                              );
                              if (!valid) {
                                this.setState({
                                  InvalidEmail:
                                    "Please Enter Valid Email Address",
                                });
                              } else {
                                this.setState({ InvalidEmail: "" });
                              }                             
                              this.setState({
                                contactLookupEmail: e.target.value,
                                ErrorEmail: "",
                              });
                              this.props.setInboxesContactLookupEmail(
                                e.target.value
                              );
                            }}
                            value={
                              this.state.contactLookupEmail === null
                                ? ""
                                : this.state.contactLookupEmail
                            }
                          />
                        
                          <small className="form-text">
                            {this.state.ErrorEmail}{" "}
                          </small>
                          <small className="form-text">
                            {this.state.InvalidEmail}{" "}
                          </small>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label className="custom-control custom-checkbox m-0 checkbox_right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.setState({
                                  EmailEnabled: !this.state.EmailEnabled,
                                });
                              }}
                              checked={this.state.EmailEnabled}
                            />
                            <span className="custom-control-label">
                              Email Text
                            </span>
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group mt-3">
                          <label className="custom-control custom-checkbox m-0 checkbox_right">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              onChange={() => {
                                this.props.disableForAddEdit(
                                  !this.state.disable
                                );
                                this.setState({
                                  disable: !this.state.disable,
                                });
                              }}
                              checked={this.state.disable}
                            />
                            <span className="custom-control-label">
                              Disabled
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.renderButtonHtml()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        <div>
          {this.props.calledFrom === "edit" ||
          (this.props.companyId !== undefined &&
            this.props.companyId !== "") ? (
            <div>
              <h4 className="font-weight-bold text-left addUser_head">
                {this.props.calledFrom === "edit" ? "" : "Add Inboxes"}
              </h4>
              {this.renderHtml()}
            </div>
          ) : (
            <div>
              <h2 className="companyName  text-left">
                <span
                  className="iconNavigation cursor_pointer"
                  onClick={() => this.props.changeState()}
                >
                  <i
                    className="fa fa-chevron-left cursor_pointer"
                    aria-hidden="true"
                  ></i>
                </span>
                Inboxes / <b>New Inboxes</b>
              </h2>
              <Tabs
                className="tabInboxesMember"
                defaultActiveKey={this.state.activeTab}
                onSelect={(currentTabName: any) => {
                  this.setState({
                    activeTab: currentTabName,
                    companyName: "",
                  });
                }}
                id="uncontrolled-tab-example"
              >
                <Tab
                  eventKey="Inbox Detail"
                  title={
                    <span>
                      <span className="font-icon">&#67;</span>Inbox Detail
                    </span>
                  }
                >
                  {this.renderHtml()}
                </Tab>
              </Tabs>
            </div>
          )}
          <ConfirmationInboxesDeleteComponent
            deleteSucces={(confirm: boolean) => {
              this.props.deleteInboxes(this.state.InboxesId, confirm);
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
