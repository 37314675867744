import * as React from "react";
import * as commonMethod from "../../commonMethods";
import ConfirmationDeleteComponent from "../sharedComponents/confirmationDeleteComponent";
import Select, { components } from "react-select";
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export class CompanyAddUpdateComponent extends React.Component<any, any> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      id: this.props?.editData?.Id || "",
      name: this.props?.editData?.Name || "",
      registrationDate: this.props?.editData?.RegistrationDate || "",
      subscriptionCost: this.props?.editData?.SubscriptionCost || "",
      subscriptions: {},
      currency: this.props?.editData?.Currency || "",
      primaryContactName: this.props?.editData?.PrimaryContactName || "",
      primaryContactEmail: this.props?.editData?.PrimaryContactEmail || "",
      primaryContactPhone: this.props?.editData?.PrimaryContactPhone || "",
      billingAddresscountry:
        this.props?.editData?.BillingAddress?.Country || "",
      billingAddressstate: this.props?.editData?.BillingAddress?.State || "",
      billingAddresscity: this.props?.editData?.BillingAddress?.City || "",
      billingAddresszipCode:
        this.props?.editData?.BillingAddress?.ZipCode || "",
      billingAddressline1: this.props?.editData?.BillingAddress?.Line1 || "",
      billingAddressline2: this.props?.editData?.BillingAddress?.Line2 || "",
      billingAddressline3: this.props?.editData?.BillingAddress?.Line3 || "",
      InvalidEmail: "",
      ErrorName: "",
      ErrorSubscriptionCos: "",
      ErrorCurrency: "",
      ErrorCountry: "",
      ErrorCity: "",
      ErrorZipCode: "",
      ErrorLine1: "",
      isParent: this.props?.editData?.IsParent || false,
      SelectParentCompanyId: this.props?.editData?.ParentId || 0,
      SelectParentCompanyName: this.props?.editData?.ParentId === 0 || "",
      companyListState: [],
      myCompanyID: "",
      whiteLabel:
        this.props.calledFrom === "edit"
          ? this.props?.editData?.WhiteLabelBrand === undefined ||
            this.props?.editData?.WhiteLabelBrand === null
            ? this.props.loggedInUserRole === 5
              ? this.props.myWhiteLabelBrand
              : this.props?.editData?.WhiteLabelBrand
            : this.props?.editData?.WhiteLabelBrand
          : this.props.loggedInUserRole === 5
          ? this.props.myWhiteLabelBrand
          : "yakchat",

      defaultLowFreeCreditThreshold:
        this.props?.editData?.DefaultLowFreeCreditThreshold || "10",
      defaultLowCreditThreshold:
        this.props?.editData?.DefaultLowCreditThreshold || "5",
      sMTPHost: this.props?.editData?.SMTPHost || "",
      sMTPUsername: this.props?.editData?.SMTPUsername || "",
      sMTPPassword: this.props.editData?.SMTPPassword || "",
      defaultCreditAlert: this.props?.editData?.DefaultCreditAlert,
      defaultEmailCreditAlert: this.props?.editData?.DefaultEmailCreditAlert,
      lowCreditMessage: this.props?.editData?.LowCreditMessage,
      lowCreditSubject: this.props?.editData?.LowCreditSubject,
      primaryColor: this.props?.editData?.PrimaryColor,
      companyVersion:
        this.props.calledFrom === "edit"
          ? this.props?.editData?.Version
          : this.props.loggedInUserRole === 5
          ? this.props.myCompanyVersion
          : 1,

      // companyVersion:
      //   this.props.calledFrom !== "edit"
      //     ? this.props.loggedInUserRole === -10 ||
      //       this.props.loggedInUserRole === 5
      //       ? 1
      //       : 2
      //     : this.props?.editData?.Version !== 2
      //     ? 1
      //     : this.props?.editData?.Version,
    };
  }
  IsValid() {
    var isValid = true;
    if (!this.state.name) {
      this.setState({ ErrorName: "This Field is Required" });
      isValid = false;
    }
    if (!this.state.currency) {
      isValid = false;
      this.setState({
        ErrorCurrency: "This Field is Required",
      });
      //    return isValid
    }
    if (this.state.currency.length !== 3) {
      isValid = false;
      this.setState({
        ErrorCurrency: "This field needs to be exactly 3 characters in length.",
      });
    }

    if (!this.state.billingAddresscountry) {
      isValid = false;
      this.setState({
        ErrorCountry: "This Field is Required",
      });
      // return isValid
    }

    if (!this.state.billingAddresscity) {
      isValid = false;
      this.setState({
        ErrorCity: "This Field is Required",
      });
      //  return isValid
    }
    if (!this.state.billingAddresszipCode) {
      isValid = false;
      this.setState({
        ErrorZipCode: "This Field is Required",
      });
      //   return isValid
    }
    if (!this.state.billingAddressline1) {
      isValid = false;
      this.setState({
        ErrorLine1: "This Field is Required",
      });
    }

    return isValid;
  }
  addUpdateCompanyData = (e: any) => {
    if (
      this.state.primaryContactEmail.length !== 0 &&
      commonMethod.validEmail(this.state.primaryContactEmail) !== true
    ) {
      return false;
    }
    if (
      this.state.ErrorCurrency !== "" ||
      this.state.ErrorSubscriptionCos !== ""
    ) {
      return false;
    }
    const lowCreditObj = {
      defaultLowFreeCreditThreshold: this.state.defaultLowFreeCreditThreshold,
      defaultLowCreditThreshold: this.state.defaultLowCreditThreshold,
      sMTPHost: this.state.sMTPHost,
      sMTPUsername: this.state.sMTPUsername,
      sMTPPassword: this.state.sMTPPassword,
      defaultCreditAlert: this.state.defaultCreditAlert,
      defaultEmailCreditAlert: this.state.defaultEmailCreditAlert,
      lowCreditMessage: this.state.lowCreditMessage,
      lowCreditSubject: this.state.lowCreditSubject,
      primaryColor: this.state.primaryColor,
    };
    if (this.IsValid() === true) {
      if (this.props.calledFrom === "add") {
        //var parentId = parseInt(this.state.SelectParentCompanyId);
        var dateObj = new Date();
        var regData = dateObj.toISOString();
        var data = {
          name: this.state.name,
          registrationDate: regData,
          subscriptions: [],
          subscriptionCost: 0,
          currency: this.state.currency,
          primaryContactPhone: this.state.primaryContactPhone,
          primaryContactEmail: this.state.primaryContactEmail,
          primaryContactName: this.state.primaryContactName,
          billingAddress: {
            country: this.state.billingAddresscountry,
            state: this.state.billingAddressstate,
            zipCode: this.state.billingAddresszipCode,
            city: this.state.billingAddresscity,
            line1: this.state.billingAddressline1,
            line2: this.state.billingAddressline2,
            line3: this.state.billingAddressline3,
          },
          isParent: this.state.isParent,
          whiteLabelBrand: this.state.whiteLabel,
          parentId:
            this.state.isParent === true
              ? 0
              : this.props.loggedInUserRole === 5
              ? this.state.myCompanyID
              : this.state.SelectParentCompanyId,
          // for new company version will 2
          version: this.state.companyVersion,

          ...(this.state.isParent && { ...lowCreditObj }),
        };

        debugger;
        this.props.addUpdateCompanyProp("add", data);
        //console.log(data);
      } else {
        debugger;
        var dataBody = {
          id: this.state.id,
          name: this.state.name,
          registrationDate: this.state.registrationDate,
          subscriptions: [],
          subscriptionCost: this.state.subscriptionCost,
          currency: this.state.currency,
          primaryContactPhone: this.state.primaryContactPhone,
          primaryContactEmail: this.state.primaryContactEmail,
          primaryContactName: this.state.primaryContactName,
          billingAddress: {
            country: this.state.billingAddresscountry,
            state: this.state.billingAddressstate,
            zipCode: this.state.billingAddresszipCode,
            city: this.state.billingAddresscity,
            line1: this.state.billingAddressline1,
            line2: this.state.billingAddressline2,
            line3: this.state.billingAddressline3,
          },
          isParent: this.state.isParent,
          whiteLabelBrand: this.state.whiteLabel,
          parentId:
            this.state.isParent === true ? 0 : this.state.SelectParentCompanyId,

          ...(this.state.isParent && { ...lowCreditObj }),
        };
        this.props.addUpdateCompanyProp("edit", dataBody);
      }
    }
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.parentCompanyList !== prevState.companyListState ||
      nextProps.myCompanyId !== prevState.myCompanyID
    ) {
      return {
        companyListState: nextProps.parentCompanyList,
        myCompanyID: nextProps.myCompanyId,
      };
    } else return null;
  }
  componentDidMount() {
    this.props.getParentCompanyList();
    if (this.props.calledFrom === "edit") {
      var selectedPArentCompanyName =
        this.state.companyListState !== undefined
          ? this.state.companyListState.length !== 0
            ? this.props.editData.ParentId !== 0
              ? this.state.companyListState
                  .filter((x: any) => x.Id === this.props.editData.ParentId)
                  .map((x: any) => x.Name)[0]
              : ""
            : ""
          : "";
      this.setState({
        SelectParentCompanyName: selectedPArentCompanyName,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="nav-tabs-top">
            <div className="tab-content border-0">
              <div className="tab-pane fade show active" id="user-edit-account">
                <div className="card-body pb-2">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="formDiv">
                        <div className="row">
                          <div className="col-md-3">
                            <div
                              className={
                                this.state.ErrorName !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Company Name
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"Company Name"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    name: e.target.value,
                                    ErrorName: "",
                                  });
                                }}
                                defaultValue={this.state.name}
                              />
                              <small className="form-text ">
                                {this.state.ErrorName}{" "}
                              </small>
                            </div>
                            {this.props.calledFrom === "edit" ? (
                              <div className="form-group">
                                <label className="form-label">ID</label>
                                <input
                                  type="text"
                                  className="form-control non-editable"
                                  onChange={(e) => {
                                    this.setState({ id: e.target.value });
                                  }}
                                  value={this.state.id}
                                  disabled={true}
                                />
                              </div>
                            ) : null}
                            <div
                              className={
                                this.state.ErrorCurrency !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Currency<small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"Currency"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({ currency: e.target.value });
                                  if (e.target.value.length !== 3) {
                                    this.setState({
                                      ErrorCurrency:
                                        "This field needs to be exactly 3 characters in length.",
                                    });
                                  } else {
                                    this.setState({
                                      ErrorCurrency: "",
                                    });
                                  }
                                }}
                                value={this.state.currency}
                              />
                              <small className="form-text ">
                                {this.state.ErrorCurrency}{" "}
                              </small>
                            </div>
                            <div className="form-group">
                              <label className="form-label">
                                Primary Contact
                              </label>
                              <input
                                type="text"
                                placeholder={"Primary Contact"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    primaryContactName: e.target.value,
                                  });
                                }}
                                value={this.state.primaryContactName}
                              />
                            </div>
                            <div
                              className={
                                this.state.InvalidEmail !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">Email</label>
                              <input
                                placeholder={"Email"}
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                  var valid = commonMethod.validEmail(
                                    e.target.value
                                  );
                                  if (!valid) {
                                    this.setState({
                                      InvalidEmail:
                                        "Please Enter Valid Email Address",
                                    });
                                  } else {
                                    this.setState({ InvalidEmail: "" });
                                  }
                                  this.setState({
                                    primaryContactEmail: e.target.value,
                                  });
                                }}
                                value={this.state.primaryContactEmail}
                              />
                              <small className="form-text">
                                {this.state.InvalidEmail}{" "}
                              </small>
                            </div>
                            <div className="form-group">
                              <label className="form-label">Number</label>
                              <input
                                type="text"
                                placeholder={"Number"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    primaryContactPhone: e.target.value,
                                  });
                                }}
                                value={this.state.primaryContactPhone}
                              />
                            </div>

                            {this.state.isParent === false &&
                            this.props.loggedInUserRole !== 5 ? (
                              <div className={"form-group"}>
                                <label className="form-label">
                                  Parent Company
                                </label>
                                <Select
                                  components={{ DropdownIndicator }}
                                  isClearable={true}
                                  value={[
                                    {
                                      label: this.state.SelectParentCompanyName,
                                      value: this.state.SelectParentCompanyId,
                                    },
                                  ]}
                                  onChange={(opt: any) => {
                                    if (opt === null) {
                                      this.setState({
                                        SelectParentCompanyId: 0,
                                        SelectParentCompanyName: "",
                                      });
                                    } else {
                                      this.setState({
                                        SelectParentCompanyId: opt.value,
                                        SelectParentCompanyName: opt.label,
                                      });
                                    }
                                  }}
                                  options={
                                    this.state.companyListState !== undefined
                                      ? this.state.companyListState.map(
                                          (opt: any) => ({
                                            label: opt.Name,
                                            value: opt.Id,
                                          })
                                        )
                                      : []
                                  }
                                />
                              </div>
                            ) : null}

                            {/* Display if logged in user id superadmin */}
                            {this.props.loggedInUserRole === -10 ? (
                              <div>
                                <div className="form-group">
                                  <label className="custom-control custom-checkbox m-0 checkbox_right">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      onChange={(e) => {
                                        this.setState({
                                          isParent: e.target.checked,
                                        });
                                      }}
                                      checked={this.state.isParent}
                                    />
                                    <span className="custom-control-label">
                                      Is Parent
                                    </span>
                                  </label>
                                </div>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorLine1 !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Company Address
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"Line1"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    ErrorLine1: "",
                                    billingAddressline1: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddressline1}
                              />
                              <small className="form-text">
                                {this.state.ErrorLine1}{" "}
                              </small>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder={"Line2"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    billingAddressline2: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddressline2}
                              />
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder={"Line3"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    billingAddressline3: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddressline3}
                              />
                            </div>

                            <div
                              className={
                                this.state.ErrorCity !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                City
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"City"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    ErrorCity: "",
                                    billingAddresscity: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddresscity}
                              />
                              <small className="form-text">
                                {this.state.ErrorCity}{" "}
                              </small>
                            </div>
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder={"State"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    billingAddressstate: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddressstate}
                              />
                            </div>

                            <div
                              className={
                                this.state.ErrorZipCode !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Zip Code
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"ZipCode"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    ErrorZipCode: "",
                                    billingAddresszipCode: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddresszipCode}
                              />
                              <small className="form-text">
                                {this.state.ErrorZipCode}{" "}
                              </small>
                            </div>
                            <div
                              className={
                                this.state.ErrorCountry !== ""
                                  ? "form-group validationClass countryMargin"
                                  : "form-group countryMargin"
                              }
                            >
                              <label className="form-label">
                                Country
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"Country"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    ErrorCountry: "",
                                    billingAddresscountry: e.target.value,
                                  });
                                }}
                                value={this.state.billingAddresscountry}
                              />
                              <small className="form-text">
                                {this.state.ErrorCountry}{" "}
                              </small>
                            </div>

                            {/* Display version field if logged in user id superadmin */}
                            {(this.props.loggedInUserRole === -10 ||
                              this.props.loggedInUserRole === 5) &&
                            this.props.myCompanyVersion === 2 ? (
                              <div className="form-group">
                                <label className="form-label">
                                  Company Version
                                </label>
                                <select
                                  value={this.state.companyVersion}
                                  disabled={
                                    this.props.calledFrom === "edit"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) => {
                                    this.setState({
                                      companyVersion: parseInt(e.target.value),
                                    });
                                  }}
                                >
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                </select>
                              </div>
                            ) : null}

                            {this.props.loggedInUserRole === -10 ||
                            this.props.loggedInUserRole === 5 ? (
                              <div className="form-group">
                                <label className="form-label">
                                  White Label
                                </label>
                                <input
                                  type="text"
                                  placeholder={"White Label"}
                                  disabled={
                                    this.props.loggedInUserRole === 5
                                      ? true
                                      : false
                                  }
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      whiteLabel: e.target.value
                                        .trim()
                                        .toLocaleLowerCase(),
                                    });
                                  }}
                                  value={this.state.whiteLabel}
                                />
                              </div>
                            ) : null}

                            <div className="row">
                              <div className="col-md-6">
                                {this.state.isParent ? (
                                  <>
                                    <div className="form-group pTop30">
                                      <label className="custom-control custom-checkbox m-0 checkbox_right checkbox_right_xl">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          checked={
                                            this.state.defaultCreditAlert
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              defaultCreditAlert:
                                                e.target.checked,
                                            });
                                          }}
                                        />
                                        <span className="custom-control-label">
                                          Default Credit Alert
                                        </span>
                                      </label>
                                    </div>
                                    <div className="form-group pTop30">
                                      <label className="custom-control custom-checkbox m-0 checkbox_right checkbox_right_xl">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          checked={
                                            this.state.defaultEmailCreditAlert
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              defaultEmailCreditAlert:
                                                e.target.checked,
                                            });
                                          }}
                                        />
                                        <span className="custom-control-label">
                                          Default Email Credit Alert
                                        </span>
                                      </label>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-md-6">
                                {this.state.defaultCreditAlert &&
                                this.state.isParent ? (
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="defaultLowCreditThreshold"
                                    >
                                      Default Low Credit Threshold
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="defaultLowCreditThreshold"
                                      value={
                                        this.state.defaultLowCreditThreshold
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          defaultLowCreditThreshold:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                ) : null}
                                {this.state.defaultCreditAlert &&
                                this.state.isParent ? (
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="defaultLowFreeCreditThreshold"
                                    >
                                      Default Low Free Credit Threshold
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      id="defaultLowFreeCreditThreshold"
                                      value={
                                        this.state.defaultLowFreeCreditThreshold
                                      }
                                      onChange={(e) => {
                                        this.setState({
                                          defaultLowFreeCreditThreshold:
                                            e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            {this.state.isParent ? (
                              <>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="lowCreditMessage"
                                  >
                                    Low Credit Alert Message
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="lowCreditMessage"
                                    disabled={!this.state.isParent}
                                    value={this.state.lowCreditMessage}
                                    onChange={(e) => {
                                      this.setState({
                                        lowCreditMessage: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="lowCreditSubject"
                                  >
                                    Low Credit Alert Subject
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="lowCreditSubject"
                                    disabled={!this.state.isParent}
                                    value={this.state.lowCreditSubject}
                                    onChange={(e) => {
                                      this.setState({
                                        lowCreditSubject: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="defaultLowFreeCreditThreshold"
                                  >
                                    Brand Primary Color
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="primaryColor"
                                    disabled={!this.state.isParent}
                                    value={this.state.primaryColor}
                                    onChange={(e) => {
                                      this.setState({
                                        primaryColor: e.target.value,
                                      });
                                    }}
                                  />
                                </div>

                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="sMTPHost"
                                  >
                                    SMTP Host
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="sMTPHost"
                                    disabled={!this.state.isParent}
                                    value={this.state.sMTPHost}
                                    onChange={(e) => {
                                      this.setState({
                                        sMTPHost: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="sMTPUsername"
                                  >
                                    SMTP Username
                                  </label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    id="sMTPUsername"
                                    disabled={!this.state.isParent}
                                    value={this.state.sMTPUsername}
                                    onChange={(e) => {
                                      this.setState({
                                        sMTPUsername: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="form-group">
                                  <label
                                    className="form-label"
                                    htmlFor="sMTPPassword"
                                  >
                                    SMTP Password
                                  </label>
                                  <input
                                    className="form-control"
                                    type="password"
                                    id="sMTPPassword"
                                    disabled={!this.state.isParent}
                                    value={this.state.sMTPPassword}
                                    onChange={(e) => {
                                      this.setState({
                                        sMTPPassword: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btnDiv d-flex justify-content-between align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary saveBtn"
                            onClick={(e) => this.addUpdateCompanyData(e)}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-default cancelBtn"
                            onClick={this.props.changeState}
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          {this.props.calledFrom === "edit" ? (
                            <button
                              type="button"
                              className="btn btn-primary delete_btn"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <span className="font-icon">&#107;</span>Delete
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationDeleteComponent
            deleteSucces={() => {
              debugger;
              this.props.deleteCompany(this.state.id);
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
