import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { authContext } from '../../adalConfig';
class ErrorPageComponent extends React.Component<any, any> {
    constructor(props: any , state : any) {
        super(props)
        this.state = {
        }
  }
  componentDidMount(){
  }

    public render() {
      
        return (
            <React.Fragment>
       
<div className="error_page d-flex align-items-center justify-content-center">
	<div className="error_box">
	  <div className="error_logo d-flex align-items-center justify-content-center">
		<img src={require('../../image/logo_icon.svg')} alt="" />
		<span className="d-flex align-items-center justify-content-center"><i className="fas fa-exclamation-triangle"></i></span>
	  </div> 

	  <div className="text-center error_text">
      {
        this.props.loggedInUserDisable === true ?
        <div>
        <h3>You are not able to access the application</h3>
        <p>Please contact <a href="!#">info@yakchat.com</a> for assistance</p>
        </div>
      :
      <div>
		<h1>Unable to Access YakChat</h1>
		<p>Error Code: XXXXX</p>
		<p>Please contact <a href="!#">info@yakchat.com</a> for assistance</p>
    <p className="or_mor">or</p>
    <button  onClick={() => {authContext.logOut()}} className="different_opt">Sign in with different user</button>
    
    </div>
    }
	  </div>
	</div>
</div>

            </React.Fragment>
        );
    }
};

// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    loggedInUserDisable: props.characterReducer.loggedInUserDisableReducer
    
  }
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    
       
  }



};
export default connect(mapStateToProps,
  mapDispatchToProps)(ErrorPageComponent);