import { adalApiFetch, apiUrl, apiUrlV2, adalApiFetchDelete } from "../adalConfig";


export const getInboxesList = (subscriptionId: any, companyId: any) => {

  let url = "";
  subscriptionId = subscriptionId ?? "";
  companyId = companyId ?? "";
  //url = `${apiUrlV2}/inboxes?subscriptionId=` + subscriptionId+`&companyId=`+companyId; 
  if (companyId == "" && subscriptionId != "") { url = `${apiUrlV2}/inboxes?subscriptionId=` + subscriptionId; }
  else if (subscriptionId == "" && companyId != "") { url = `${apiUrlV2}/inboxes?companyId=` + companyId; }
  else if (subscriptionId == "" && companyId == "") { url = `${apiUrlV2}/inboxes`; }
  else url = `${apiUrlV2}/inboxes?subscriptionId=` + subscriptionId + `&companyId=` + companyId;

  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
//?subscriptionId=1&companyId=1

export const UpdateInboxes = (data: any) => {
  const url =
    `${apiUrlV2}/Inboxes/UpdateInbox`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const AssignNotAssignUser = (data: any) => {
  const url = `${apiUrlV2}/assigninboxuser`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const AddUpdateInboxes = (calledFrom: any, data: any) => {
  if (calledFrom === "add") {
    const url = `${apiUrlV2}/inboxes`;
    return adalApiFetch(url, {
      method: "PUT",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  else {
    const url = `${apiUrlV2}/inboxes/` + data.id;
    return adalApiFetch(url, {
      method: "PATCH",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};


export const DeleteInboxalldata = (inboxId: any, confirmDelete: any) => {
  const url = `${apiUrlV2}/inboxalldata/` + inboxId + '/' + confirmDelete;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const AddInboxes = (data: any) => {
  const url =
    `${apiUrlV2}/inboxes`;
  return adalApiFetch(url, {
    method: "PUT",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const getInboxesDeleteconfirm = (inboxesId: any, deleteflag: any) => {

  const url = `${apiUrlV2}/deleteconfirm/` + inboxesId + `/` + deleteflag;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

/* V2 API integration */


export const GetInboxesListV2 = (subscriptionId: any, companyId: any) => {

  let url = "";
  subscriptionId = subscriptionId ?? "";
  companyId = companyId ?? "";

  if (companyId == "" && subscriptionId != "") { url = `${apiUrlV2}/inboxes/query?subscriptionId=` + subscriptionId; }
  else if (subscriptionId == "" && companyId != "") { url = `${apiUrlV2}/inboxes/query?companyId=` + companyId; }
  else if (subscriptionId == "" && companyId == "") { url = `${apiUrlV2}/inboxes/query`; }
  else url = `${apiUrlV2}/inboxes/query?subscriptionId=` + subscriptionId + `&companyId=` + companyId;

  return adalApiFetch(url, {
    accept: "application/json;",
  });
};


export const AddUpdateInboxesV2 = (calledFrom: any, data: any) => {

  debugger;

  if (calledFrom === "add") {
    const url = `${apiUrlV2}/inboxes`;
    return adalApiFetch(url, {
      method: "PUT",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
  else {
    const url = `${apiUrlV2}/inboxes/` + data.id;
    return adalApiFetch(url, {
      method: "PATCH",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

export const GetRemoveInboxesDataV2 = (inboxId: any) => {

  const urlu = `${apiUrlV2}/inboxes/${inboxId}/data`;
  return adalApiFetch(urlu, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const RemoveInboxesAllDataV2 = (inboxId: any, confirmDelete: boolean) => {

  const urlu = `${apiUrlV2}/inboxalldata/${inboxId}/${confirmDelete}`;
  return adalApiFetch(urlu, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

/* Used to delete an inbox and all related data including conversations (rooms), messages, 
contacts, settings and bot configurations plus unassigns any users that are assigned the inbox.
The GET /data/bulk method is used to determine the related data that will be deleted prior to 
deleting the data using this method. The inbox will be deleted or an error will be returned.*/

export const RemoveInboxesV2 = (inboxId: any, confirmDelete: boolean) => {

  const urlu = `${apiUrlV2}/inboxes/${inboxId}?confirmEmail=${confirmDelete}`;
  return adalApiFetch(urlu, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const AssignNotAssignUserV2 = (data: any) => {
  const url = `${apiUrlV2}/assigninboxuser`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const GetInboxesMemberListByInboxesIdV2 = (inboxesId: any, companyId: any, assignFlag: any) => {

  assignFlag = assignFlag == 0 ? false : true;
  const url = `${apiUrlV2}/inboxes/${inboxesId}/users?isAssigned=${assignFlag}`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const AssignOrUnassignUserfromInboxes = (inboxId: any, data: any) => {
  const url = `${apiUrlV2}/inboxes/${inboxId}/users`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

