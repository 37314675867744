import { adalApiFetch, apiUrl, apiUrlV2, authSid, authToken } from "../adalConfig";

export const getPhoneNumbersApi = (smsServiceType: any) => {
  const url = `${apiUrl}/getPhones?smsServiceType=` + smsServiceType;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const addUpdateNumberService = (data: any) => {
  const url = `${apiUrl}/AddUpdatePhoneNumbers`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};
export const updateNumberService = (data: any) => {
  const url =
    `${apiUrl}/UpdateServicePhoneNumberAssignment?phonenNumber=` +
    encodeURIComponent(data[0].PhoneNumber) +
    `&companyId=` +
    data[0].CompanyId;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};
export const removeNumbers = (parameter: any) => {
  const url = `${apiUrl}/RemovePhoneNumbers`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parameter),
  });
};
export const twillioSign = () => {
  var headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(authSid + ":" + authToken));
  return fetch("https://api.twilio.com/2010-04-01/Accounts", {
    headers: headers,
  }).then((response) => response.text());
};
export const getPhoneNumberFromTwillio = (
  countryCode: any,
  type: any,
  extraParam: any
) => {
  var headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(authSid + ":" + authToken));
  if (extraParam !== "") {
    return fetch(
      "https://api.twilio.com/2010-04-01/Accounts/" +
        authSid +
        "/AvailablePhoneNumbers/" +
        countryCode +
        "/" +
        type +
        ".json?" +
        extraParam,
      {
        headers: headers,
      }
    ).then((response) => response.text());
  } else {
    return fetch(
      "https://api.twilio.com/2010-04-01/Accounts/" +
        authSid +
        "/AvailablePhoneNumbers/" +
        countryCode +
        "/" +
        type +
        ".json",
      {
        headers: headers,
      }
    ).then((response) => response.text());
  }
};
//purchase number
export const provisionPhoneNumberFromTwillio = (
  phoneNumber: any,
  smsUrl: any,
  twiliBin: any
) => {
  const data = new URLSearchParams();
  /////test
  // if(twiliBin === "This number if for texting only"){
  //    twiliBin = "https://handler.twilio.com/twiml/EH18867451272e58aa4c4d4bb8132bdfb3"
  // }
  ////live
  if (twiliBin === "This number if for texting only") {
    twiliBin =
      "https://handler.twilio.com/twiml/EH1ec62cc07ef6aa194f737babf37b09ff";
  }

  data.append("PhoneNumber", phoneNumber);
  data.append("SmsUrl", smsUrl);
  data.append("VoiceUrl", twiliBin);
  var headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(authSid + ":" + authToken));
  return fetch(
    "https://api.twilio.com/2010-04-01/Accounts/" +
      authSid +
      "/IncomingPhoneNumbers.json",
    {
      method: "POST",
      body: data,
      headers: headers,
    }
  ).then((response) => response.text());
};
export const getNumberInfoFromTwillio = (parameter: any) => {
  var headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(authSid + ":" + authToken));
  return fetch(
    "https://api.twilio.com/2010-04-01/Accounts/" +
      authSid +
      "/IncomingPhoneNumbers.json?PhoneNumber=" +
      parameter,
    {
      method: "GET",
      headers: headers,
    }
  ).then((response) => response.text());
};
export const releaseNumberFromTwillio = (parameter: any) => {
  var headers = new Headers();
  headers.append("Authorization", "Basic " + btoa(authSid + ":" + authToken));
  return fetch(
    "https://api.twilio.com/2010-04-01/Accounts/" +
      authSid +
      "/IncomingPhoneNumbers/" +
      parameter +
      ".json",
    {
      method: "DELETE",
      headers: headers,
    }
  ).then((response) => response.text());
};
export const getUnAssignPhoneNumber = (
  smsServiceType: any,
  subscriptionId: any
) => {
  const url =
    `${apiUrl}/GetNotAssignedPhoneNumbers?smsServiceType=` +
    smsServiceType +
    `&SubscriptionId=` +
    subscriptionId;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const getUnAssignPhoneNumberForInbox = (  
  companyId: any
) => {
  const url =
    `${apiUrlV2}/senderids/unassigned?companyId=`+ companyId;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

//service providers account call by Niyi
export const allServiceProvidersCall = () => {
  const url = `${apiUrl}/getAllServiceProviders`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const serviceProviderAccountsByProviderCall = (
  providerId: number | string
) => {
  const url = `${apiUrl}/getServiceProviderAccountsByProvider?serviceProviderId=${providerId}`;
  return adalApiFetch(url, {
    method: "GET",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const addUpdatePhoneNumbersCall = (numberObj: any) => {
  const url = `${apiUrl}/AddUpdatePhoneNumbers`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(numberObj),
  });
};

//service providers account call by Niyi
