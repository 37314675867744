
import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// Create the containers interface

class NotificationComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props)

  }
  public render() {
    return (
      <React.Fragment>

        {
          this.props.savedSuccessfull === true ?
         
            <div className="alert alert-dark-success alert-dismissible fade show alertMessages">
                    Saved Successfully
                  </div>


            :
            null
        }
        {
          this.props.deletedMessage === true ?
            <div className="alert alert-dark-success alert-dismissible fade show alertMessages">
            Deleted Successfully
          </div>

            :
            null
        }
        {
          this.props.errorOccured !== "" ?
            <div className="alert alert-dark-danger alert-dismissible fade show alertMessages">
              {String(this.props.errorOccured)}
            </div>


            :
            null
        }
          {
          this.props.errorOccured !== "" ?
            <div className="alert alert-dark-danger alert-dismissible fade show alertMessages">
             {String(this.props.errorOccured)}
            </div>


            :
            null
        }
{
          this.props.groupAddWarning === true ?
         
            <div className="alert alert-dark-warning alert-dismissible fade show alertMessages">
                 Warning: One or more Groups have no members assigned
                  </div>


            :
            this.props.savingTwillioNumber === false?
            <div className="alert alert-dark-success alert-dismissible fade show alertMessages">
                   Saved Number 
                  </div>
                  :
            null
        }
          {
          this.props.disabledLoggedInUserReducerWarning !== "" && this.props.disabledLoggedInUserReducerWarning !== undefined ?
            <div className="alert alert-dark-danger alert-dismissible fade show alertMessages">
              This User is Disabled
            </div>
          :
            null
        }
      </React.Fragment>
    );
  }
};


const mapStateToProps = (props: any) => {
  return {
    errorOccured: props.characterReducer.errorOccured,
    savedSuccessfull: props.characterReducer.dataSaved,
    deletedMessage: props.characterReducer.deletedMessgae,
    savingTwillioNumber:props.characterReducer.saveTwillioNumberToDatabaseReducer,
    groupAddWarning:props.characterReducer.groupAddWarningReducer,
    disabledLoggedInUserReducerWarning:props.characterReducer.disabledLoggedInUserReducer

  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {

  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(NotificationComponent);