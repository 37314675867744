import { adalApiFetch, apiUrl, adalApiFetchDelete } from "../adalConfig";

export const getSubscriptionList = (compId: any) => {
  const url = `${apiUrl}/getSubscriptions?companyId=` + compId;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};
export const getServicePlanList = () => {
  const url = `${apiUrl}/getServicePlans`;
  return adalApiFetch(url, {
    accept: "application/json;",
  });
};

export const addUpdateService = (type: string, data: any) => {
  if (type === "add") {
    const url = `${apiUrl}/addSubscription`;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  } else {
    const url = `${apiUrl}/modifySubscription?subscriptionId=` + data.id;
    return adalApiFetch(url, {
      method: "POST",
      headers: {
        accept: "application/json;",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};
// #3461  //Maksud
export const updateContactLookupService = (subscriptionId: number, tenantId: string,enabled: boolean) => {

  const url = `${apiUrl}/UpdateSubscriptionContactLookup?subscriptionId=` + subscriptionId+`&tenantId=`+tenantId+`&enabled=`+enabled;
  return adalApiFetchDelete(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const removeSubscription = (parameter: number) => {
  const url = `${apiUrl}/removeSubscription?subscriptionId=` + parameter;
  return adalApiFetchDelete(url, {
    method: "DELETE",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
  });
};

export const UpdateSubscriptionEmailNotifications = (data: any) => {
  const url = `${apiUrl}/UpdateSubscriptionEmailNotifications`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(data),
  });
};

export const ModifyFreeCreditRenewalDate = (
  date: Date,
  subscriptionId: number
) => {
  const url = `${apiUrl}/ModifyFreeCreditRenewalDate?subscriptionId=${subscriptionId}`;
  return adalApiFetch(url, {
    method: "POST",
    headers: {
      accept: "application/json;",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(date),
  });
};
