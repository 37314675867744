import * as React from 'react';
import './App.css';
import { ThunkDispatch } from 'redux-thunk';
import * as action from './redux/actions/actions';
import Layout from './Layout';
import { connect } from 'react-redux';
import { getLoggedInUSerName } from './adalConfig';
import history from './history';
class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props)
    this.state = {
      loggedinUserAccessLevel: '',
      roleNameState: "",
      disbaledUser: false,
      myCompanyVersion:"",
      editLoggedUser:"",
    }

  }
  componentDidMount() {
    var userData = getLoggedInUSerName();
    if (userData !== null) {
      this.props.getloggedInUserDetail(userData.userName);

      this.props.getloggedInUserCompanyVersion(userData.userName);
      // TASK 3804 // Maksud
      //this.props.getAccessLevel(userData.userName);
      
      //  this.setState({ myComapanyId: this.state.editLoggedUser.Subscription.Company.Id });
     
    }

  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (nextProps.loggedinUserAccessLevel !== prevState.loggedinUserAccessLevel ||
      nextProps.loggedInUserDisable !== prevState.disbaledUser ||  
      nextProps.loggedInUserCompanyVersion!== prevState.myCompanyVersion||
      nextProps.editData !== prevState.editLoggedUser
    ) {
      return {
        loggedinUserAccessLevel: nextProps.loggedinUserAccessLevel,
        disbaledUser: nextProps.loggedInUserDisable,       
        myCompanyVersion:nextProps.loggedInUserCompanyVersion,
        editLoggedUser:nextProps.editData
        
      };
    }
    else return null;
  }
  public render() {
    return (

      <div className="App">{
        (this.state.loggedinUserAccessLevel !== undefined || this.state.loggedinUserAccessLevel !== '') ?

          <Layout history={history}
            roleName={this.state.loggedinUserAccessLevel}
            disabledUserLoggedIn={this.state.disbaledUser}
            getLoggedInAccessLevel={() => {
              this.props.getAccessLevel()
            }}
            myCompanyVersion={this.state.myCompanyVersion}
            //myCompanyId={this.state.editLoggedUser?.Subscription?.Company?.Id}
            myCompanyId=
            {
              (this.state.editLoggedUser?.Subscription === undefined ||this.state.editLoggedUser?.Subscription===null) ?
                this.state.editLoggedUser?.CompanyId
              :this.state.editLoggedUser?.Subscription?.Company?.Id
            }
            myId={this.state.editLoggedUser?.Id}
            myWhiteLabelBrand=
            {
              (this.state.editLoggedUser?.Subscription === undefined ||this.state.editLoggedUser?.Subscription===null) ?
                this.state.editLoggedUser?.WhiteLabelBrand
              :this.state.editLoggedUser?.Subscription?.Company?.WhiteLabelBrand
            }
          />
          :
          null
      }

      </div>

    );
  }
};
const mapStateToProps = (props: any) => {
  return {
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    showHideListCompany: props.characterReducer.showHideListCompanyReduc,
    editData: props.characterReducer.loggedInUserDetailReducer,
    loggedInUserDisable: props.characterReducer.loggedInUserDisableReducer,
    loggedInUserCompanyVersion : props.characterReducer.loggedInUserCompanyVersionNoReducer,  
   

  }
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {
    getAccessLevel: (email: any) => {
      dispatch<any>(action.getAccessLeveApi(email))
    },
    getloggedInUserDetail: (email: boolean) => {
      dispatch(action.loggedInUserEmail(email))
    },
    fetchAllServiceProviders: () => {
      dispatch(action.fetchAllServiceProviders())
    },    
    getloggedInUserCompanyVersion: (email: boolean) => {
      dispatch(action.GetLoggedInUserCompanyVersion(email))
    }
  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(App);
