import * as React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import * as commonMethods from "../../commonMethods";
import { InboxesAddComponent } from "./inboxesAddComponent";

export class InboxesNonMemberComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      activeTab: "inboxesMember",
      pendingInboxesMeberListState: true,
      InboxesMemberList: [],
      inboxesNotMember: [],
      pendingInboxesNoMemeber: true,
      arrowDisplays: "both",
      filtereList: {},
      disable: false,
      disableInboxesNotMemberLits: false,
      disableInboxesMemberLits: false,
      showHideSelectALL: this.props.showHideSelectAllIconInboxesMemeber,
      showHideSelectAllNotGpMember: this.props.showHideSelectAllNotInboxesMember,
      InboxesEmail: this.props.InboxesEmail,
      subscriptionList: [],
      editDetails: {},
      actualInboxesMember: [],
      actualNonInboxesMember: [],
      showMinusIcon_InboxesMemberList: false,
      showMinusIcon_InboxesNonMemberList: false,
      assignMembersToinboxes: false,
      selectedInboxesNameEmail: this.props.selectedInboxesEmailName,
      pendingNonInboxesMembers: false,
      pendingInboxesMembers: false,
      uniqueNumber: "",
      defaultCountryCode: "",
      //storeText: true,
      //disableInboxes: false,
      // creditLimit: "",
      uniqueNumberCost: "",
      // usedCredits: "",
      registerationDate: "",
      email: "",
      name: "",
      inboxesTypeId: "",
      subscriptionId: "",
      subscriptionName: "",
      setUniqueNumberList: [],
      searchInboxesMember_text: "",
      searchInboxesNonMember_text: "",
      emailEnabled: false,
      inboxesId: "",
      servicePhonenumber: this.props.ServicePhonenumber,
      contactLookupEmail: this.props.ContactLookupEmail,
      whiteLabelBrand: this.props.WhiteLabelBrand,
      contactLookupEnabled: this.props.ContactLookupEnabled,
      disableLeftIcon: false,
    };
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingInboxesMemberList !==
      prevState.pendingInboxesMeberListState ||
      nextProps.inboxesMemberList !== prevState.InboxesMemberList ||
      nextProps.getInboxesNotMemberListInboxesMemeberComp !==
      prevState.inboxesNotMember ||
      nextProps.pendingInboxesNotEmail !== prevState.pendingInboxesNoMemeber ||
      nextProps.iconToBeDisplayedInboxes !== prevState.arrowDisplays ||
      nextProps.showHideSelectAllIconInboxesMemeber !==
      prevState.showHideSelectALL ||
      nextProps.showHideSelectAllNotInboxesMember !==
      prevState.showHideSelectAllNotGpMember ||
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.editData !== prevState.editDetails ||
      nextProps.actualInboxesMemberData !== prevState.actualInboxesMember ||
      nextProps.actualInboxesNonMemberData !== prevState.actualNonInboxesMember ||
      nextProps.pendingNonInboxesList !== prevState.pendingNonInboxesMembers ||
      nextProps.pendingInboxesList !== prevState.pendingInboxesMembers ||
      nextProps.ActiveTab !== prevState.activeTab ||
      nextProps.uniqueNumberInboxesAdd !== prevState.uniqueNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      //nextProps.StoreText !== prevState.storeText ||
      nextProps.DisableInboxes !== prevState.disable ||
      //  nextProps.CreditLimit !== prevState.creditLimit ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      //  nextProps.UsedCredits !== prevState.usedCredits ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.Name !== prevState.name ||
      nextProps.InboxesTypeId !== prevState.inboxesTypeId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.unAssignPhoneNumberProps !== prevState.setUniqueNumberList ||
      nextProps.emailEnabledForInboxes !== prevState.emailEnabled ||
      nextProps.InboxesId != prevState.inboxesId ||

      nextProps.ServicePhonenumber != prevState.servicePhonenumber ||
      nextProps.ContactLookupEmail != prevState.contactLookupEmail ||
      nextProps.WhiteLabelBrand != prevState.whiteLabelBrand ||
      nextProps.ContactLookupEnabled != prevState.contactLookupEnabled

    ) {
      return {
        activeTab: nextProps.ActiveTab,
        actualNonInboxesMember: nextProps.actualInboxesNonMemberData,
        actualInboxesMember: nextProps.actualInboxesMemberData,
        editDetails: nextProps.editData,
        showHideSelectAllNotGpMember: nextProps.showHideSelectAllNotInboxesMember,
        showHideSelectALL: nextProps.showHideSelectAllIconInboxesMemeber,
        arrowDisplays: nextProps.iconToBeDisplayedInboxes,
        pendingInboxesMeberListState: nextProps.pendingInboxesMemberList,
        InboxesMemberList: nextProps.inboxesMemberList,
        inboxesNotMember: nextProps.getInboxesNotMemberListInboxesMemeberComp,
        pendingInboxesNoMemeber: nextProps.pendingInboxesNotEmail,
        subscriptionList: nextProps.subscriptionList,
        pendingNonInboxesMembers: nextProps.pendingNonInboxesList,
        pendingInboxesMembers: nextProps.pendingInboxesList,
        inboxesTypeId: nextProps.InboxesTypeId,
        email: nextProps.Email,
        name: nextProps.Name,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        disable: nextProps.DisableInboxes,
        uniqueNumber: nextProps.uniqueNumberInboxesAdd,
        defaultCountryCode: nextProps.DefaultCountryCode,
        //storeText: nextProps.StoreText,
        //creditLimit: nextProps.CreditLimit,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        // usedCredits: nextProps.UsedCredits,
        registerationDate: nextProps.RegisterationDate,
        setUniqueNumberList: nextProps.unAssignPhoneNumberProps,
        emailEnabled: nextProps.emailEnabledForInboxes,
        inboxesId: nextProps.InboxesId,

        servicePhonenumber: nextProps.ServicePhonenumber,
        contactLookupEmail: nextProps.ContactLookupEmail,
        whiteLabelBrand: nextProps.WhiteLabelBrand,
        contactLookupEnabled: nextProps.ContactLookupEnabled,
      };
    } else return null;
  }

  disableLefticon() {
    if (this.state.editDetails.Type.toUpperCase() === "PERSONAL") {

      var filtereList = this.state.inboxesNotMember.filter(
        (x: any) => x.checked === true
      );

      if (this.state.actualInboxesMember.length >= 1 || filtereList.length > 1) {
        this.setState({
          disableLeftIcon: true,
        });
      }
      else {
        this.setState({
          disableLeftIcon: false,
        });
      }
    }

  }
  selectCheckBox(e: any, inboxesMeber: any) {
    var data = e.target.checked;
    inboxesMeber.checked = data;
    var countOfFalse = this.state.InboxesMemberList.filter(
      (x: any) => x.checked === true
    ).length;
    if (countOfFalse === 0) {
      this.setState({
        disableInboxesNotMemberLits: false,
      });
      this.props.chnageIconName("both");
      this.setState({
        InboxesMemberList: this.state.InboxesMeberList,
        showMinusIcon_InboxesMemberList: false,
      });
    } else {
      this.setState({
        InboxesMemberList: this.state.InboxesMeberList,
        showMinusIcon_InboxesMemberList: true,
      });
      this.setState({
        disableInboxesNotMemberLits: true,
      });
      this.props.chnageIconName("right");
    }
  }
  selectCheckBoxNotGoupMember(e: any, gorupMember: any) {
    var data = e.target.checked;
    gorupMember.checked = data;
    var countOfFalse = this.state.inboxesNotMember.filter(
      (x: any) => x.checked === true
    ).length;
    if (countOfFalse === 0) {
      this.setState({
        disableInboxesMemberLits: false,
      });
      this.props.chnageIconName("both");

      this.setState({
        inboxesNotMember: this.state.inboxesNotMember,
        showMinusIcon_InboxesNonMemberList: false,
      });
    } else {
      this.setState({
        inboxesNotMember: this.state.inboxesNotMember,
        showMinusIcon_InboxesNonMemberList: true,
      });
      this.setState({
        disableInboxesMemberLits: true,
        arrowDisplays: "left",
      });
      this.props.chnageIconName("left");
     
      this.disableLefticon();
    }
  }
  renderInboxesMemberData() {
    return this.state.InboxesMemberList.map((inboxesMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div className="custom-control custom-checkbox circle_check">
            <input
              className="custom-control-input"
              type="checkbox"
              id={index}
              key={index}
              disabled={this.state.disableInboxesMemberLits}
              checked={inboxesMeber.checked}
              value={inboxesMeber.checked}
              onChange={(e: any) => {
                this.selectCheckBox(e, inboxesMeber);
              }}
            />
            <label className="custom-control-label" htmlFor={index}></label>
          </div>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: inboxesMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                inboxesMeber.UserName !== ""
                  ? inboxesMeber.UserName
                  : inboxesMeber.Email
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{inboxesMeber.UserName}</h5>
            <p>{inboxesMeber.Email}</p>
          </div>
        </div>
      );
    });
  }
  saveUserInInboxes() {
    debugger;
    //Assign memberto inboxess
   // if (this.state.assignMembersToinboxes === true) {
      var filterInboxes = this.state.InboxesMemberList.filter((x: any) => {
        return x.inboxMember === false;
      });

      if (filterInboxes.length !== 0) {

        const result = filterInboxes.map((ele: any) => {
          return {
            inboxId: this.state.inboxesId,
            userId: ele.UserId,
            assignFlag: 1
          }
        });

        this.props.addUserInInboxes(
          result,
          this.state.inboxesId
        );
      }
    //}
    //unASSIGN membes to inboxess
    //else {
      var filterNonInboxes = this.state.inboxesNotMember.filter((x: any) => {
        return x.inboxMember === true;
      });

      if (filterNonInboxes.length !== 0) {

        const removeUser = filterNonInboxes.map((ele: any) => {
          return {
            inboxId: this.state.inboxesId,
            userId: ele.UserId,
            assignFlag: 0
          }
        });

        this.props.unAssignUserFromInboxes(
          removeUser,
          this.state.inboxesId
        );
      }
    //}
  }
  searchInboxesMembers = (e: any) => {
    this.setState({
      searchInboxesMember_text: e.target.value,
    });
    var list = commonMethods.handleChange(e, this.state.actualInboxesMember, "");
    this.props.inboxesMemberListFilter(list);
  };
  searchInboxesNonMembers = (e: any) => {
    this.setState({
      searchInboxesNonMember_text: e.target.value,
    });
    var list = commonMethods.handleChange(
      e,
      this.state.actualNonInboxesMember,
      ""
    );
    this.props.inboxesNonMemberListFilter(list);
  };
  renderInboxesNotMemberData() {

    return this.state.inboxesNotMember.map((inboxesMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div className="custom-control custom-checkbox circle_check">
            <input
              className="custom-control-input"
              id={"inboxes" + index}
              type="checkbox"
              checked={inboxesMeber.checked}
              value={inboxesMeber.checked}
              onChange={(e: any) => {
                this.selectCheckBoxNotGoupMember(e, inboxesMeber);
              }}
              disabled={this.state.disableInboxesNotMemberLits}
            />
            <label
              className="custom-control-label"
              htmlFor={"inboxes" + index}
            ></label>
          </div>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: inboxesMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                inboxesMeber.UserName !== ""
                  ? inboxesMeber.UserName
                  : inboxesMeber.Email
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{inboxesMeber.UserName}</h5>
            <p>{inboxesMeber.Email}</p>
          </div>
        </div>
      );
    });
  }
  selectUnselectAllCheckboxes_InboxesMEmberList = (
    calledFrom: any,
    list: any
  ) => {
    var newList = [];
    if (calledFrom === "selectAll") {
      if (this.state.InboxesMemberList.length !== 0) {
        newList = this.state.InboxesMemberList.map((x: any) => {
          x.checked = true;
          return x;
        });
        this.props.inboxesMemberListFilter(newList);
        this.props.chnageIconName("right");
        this.setState({
          showMinusIcon_InboxesMemberList: true,
          disableInboxesNotMemberLits: true,
        });       

      }
    } else {
      newList = this.state.InboxesMemberList.map((x: any) => {
        x.checked = false;
        return x;
      });
      this.props.inboxesMemberListFilter(newList);
      this.props.chnageIconName("both");
      this.setState({
        showMinusIcon_InboxesMemberList: false,
        disableInboxesNotMemberLits: false,
      });
    }
  };
  selectUnselectAllCheckboxes = (calledFrom: any, list: any) => {
    var newList = [];
    if (calledFrom === "selectAll") {
      if (this.state.inboxesNotMember.length !== 0) {
        newList = this.state.inboxesNotMember.map((x: any) => {
          x.checked = true;
          return x;
        });
        this.props.inboxesNonMemberListFilter(newList);
        this.props.chnageIconName("left");
        this.setState({
          showMinusIcon_InboxesNonMemberList: true,
          disableInboxesMemberLits: true,
        });     

      }   

    } else {
      newList = this.state.inboxesNotMember.map((x: any) => {
        x.checked = false;
        return x;
      });
      this.props.inboxesNonMemberListFilter(newList);
      this.props.chnageIconName("both");
      this.setState({
        showMinusIcon_InboxesNonMemberList: false,
        disableInboxesMemberLits: false,
      });
    }
    this.disableLefticon();

  };
  arrowRight() {

    this.setState({
      disableInboxesMemberLits: false,
      disableInboxesNotMemberLits: false,
    });
    var filtereList = this.state.InboxesMemberList.filter(
      (x: any) => x.checked === true
    );

    this.props.rightIconClick(
      filtereList,
      this.state.InboxesMemberList,
      this.state.inboxesNotMember,
      this.state.actualInboxesMember
    );
    this.setState({
      showMinusIcon_InboxesMemberList: false,
      assignMembersToinboxes: false,
      searchInboxesMember_text: "",
    });
  }

  arrowLeft() {

    if (this.state.disableLeftIcon === true)
    {
      return;
    }
    
    this.setState({
      disableInboxesMemberLits: false,
      disableInboxesNotMemberLits: false,
    });
    var filtereList = this.state.inboxesNotMember.filter(
      (x: any) => x.checked === true
    );
    this.props.leftIconClick(
      filtereList,
      this.state.InboxesMemberList,
      this.state.inboxesNotMember,
      this.state.actualNonInboxesMember
    );
    this.setState({
      showMinusIcon_InboxesNonMemberList: false,
      assignMembersToinboxes: true,
      searchInboxesNonMember_text: "",
    });
  }

  public render() {

    return (
      <React.Fragment>
        <Tabs
          className="tabInboxesMember"
          defaultActiveKey={this.state.activeTab}
          onSelect={(currentTabName: any) => {
            this.setState({
              activeTab: currentTabName,
              companyName: "",
            });
            this.props.setAtiveTabName(currentTabName);
          }}
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="inboxesMember"
            title={
              <span>
                <span className="font-icon">&#112;</span> Inbox Members
              </span>
            }
          >
            {this.props.pendingInboxesMemberList === true ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div>
                {this.state.InboxesMemberList !== undefined ? (
                  <div className="member_sec">
                    <div className="inboxesInboxesMemberDiv inboxesMember ">
                      {this.state.arrowDisplays === "right" ? (
                        <span
                          onClick={() => this.arrowRight()}
                          className="alignCenter right_arrow d-flex align-items-center justify-content-center"
                        >
                          <i
                            className="fas fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : this.state.arrowDisplays === "left" ? (
                        <span
                          onClick={() =>  
                              this.arrowLeft()                         
                          }
                          className={this.state.disableLeftIcon === true ? "dleft-icon d-flex align-items-center justify-content-center" : "alignCenter d-flex align-items-center justify-content-center"}
                        >
                          <i
                            className="fas fa-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : (
                        <span className="alignCenter d-flex align-items-center justify-content-center">
                          <i
                            className="fa fa-exchange-alt"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                      <div className="teamBox bodrRight">
                        <div className="media align-items-center saleTeam">
                          <div className="media_left d-flex justify-content-center">
                            <span className="align-self-center">
                              {commonMethods.generateFirstLastNameIntials(
                                this.state.InboxesEmail
                              )}
                            </span>
                          </div>
                          <div className="media-body">
                            <h5>{this.state.InboxesEmail}</h5>
                            <p>{this.state.InboxesMemberList.length} members</p>
                          </div>
                        </div>
                        <div className="media align-items-center inboxesSearch">
                          <div className="custom-control custom-checkbox circle_check">
                            {this.state.InboxesMemberList.length !== 0 &&
                              this.state.showMinusIcon_InboxesMemberList ===
                              true ? (
                              <i
                                className="fa fa-minus-circle"
                                onClick={() => {
                                  this.selectUnselectAllCheckboxes_InboxesMEmberList(
                                    "deselectAll",
                                    this.state.InboxesMemberList
                                  );
                                }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <div>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="inboxesMember12"
                                  onClick={() => {
                                    this.selectUnselectAllCheckboxes_InboxesMEmberList(
                                      "selectAll",
                                      this.state.InboxesMemberList
                                    );
                                  }}
                                  disabled={this.state.disableInboxesMemberLits}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="inboxesMember12"
                                ></label>
                              </div>
                            )}
                          </div>
                          <div className="media-body">
                            <div className="search_btn">
                              <input
                                className="form-control searchUser"
                                value={this.state.searchInboxesMember_text}
                                onChange={(e: any) => {
                                  this.searchInboxesMembers(e);
                                }}
                                type="text"
                                placeholder="Search Users"
                              />
                              <button type="button">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {this.state.InboxesMemberList.length === 0 ? (
                          <div className="media-body">
                            <div className="noUser_found">
                              No inboxes Members found
                            </div>
                          </div>
                        ) : (
                          <div className="memberBox">
                            {this.state.pendingInboxesMembers === true ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              this.renderInboxesMemberData()
                            )}
                          </div>
                        )}
                      </div>
                      {this.state.inboxesNotMember !== undefined ? (
                        <div className="teamBox">
                          <div className="media align-items-center saleTeam">
                            <div className="media_left d-flex justify-content-center balck_bg">
                              <span className="align-self-center">
                                <i className="font-icon">G&#8203;</i>
                              </span>
                            </div>
                            <div className="media-body">
                              <h5> Users</h5>
                              <p>{this.state.inboxesNotMember.length} members</p>
                            </div>
                            <div className="media_right"></div>
                          </div>
                          <div className="media align-items-center inboxesSearch">
                            <div className="custom-control custom-checkbox circle_check">
                              {this.state.inboxesNotMember.length !== 0 &&
                                this.state.showMinusIcon_InboxesNonMemberList ===
                                true ? (
                                <i
                                  className="fa fa-minus-circle"
                                  onClick={() => {
                                    this.selectUnselectAllCheckboxes(
                                      "deselectAll",
                                      this.state.inboxesNotMember
                                    );
                                  }}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <div>
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="nonInboxesMembers"
                                    onClick={() => {
                                      this.selectUnselectAllCheckboxes(
                                        "selectAll",
                                        this.state.inboxesNotMember
                                      );
                                    }}
                                    disabled={
                                      this.state.disableInboxesNotMemberLits
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="nonInboxesMembers"
                                  ></label>
                                </div>
                              )}
                            </div>
                            <div className="media-body">
                              <div className="search_btn">
                                <input
                                  className="form-control searchUser"
                                  value={this.state.searchInboxesNonMember_text}
                                  onChange={(e: any) => {
                                    this.searchInboxesNonMembers(e);
                                  }}
                                  type="text"
                                  placeholder="Search Users"
                                />
                                <button type="button">
                                  <i className="fas fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {this.state.inboxesNotMember.length === 0 ? (
                            <div className="media-body">
                              <div className="noUser_found">
                                No inboxes Members found
                              </div>
                            </div>
                          ) : (
                            <div className="memberBox">
                              {this.state.pendingNonInboxesMembers === true ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                this.renderInboxesNotMemberData()
                              )}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="text-left mt-3 btnDiv">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.saveUserInInboxes();
                        }}
                      >
                        Save changes
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default cancelBtn"
                        onClick={() => this.props.changeState()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </Tab>
          <Tab
            eventKey="inboxesDetail"
            title={
              <span>
                <span className="font-icon">&#67;</span> Inbox Detail
              </span>
            }
          >
            <InboxesAddComponent
              uniquePhoneNumber={this.state.uniqueNumber}
              SubscriptionId={this.state.subscriptionId}
              SubscriptionName={this.state.subscriptionName}
              defaultCountryCodeForAddEditUserPRops={
                this.state.defaultCountryCode
              }
              //StoreText={this.state.storeText}
              DisableInboxes={this.state.disable}
              //creditLimitForAddEditUserProp={this.state.creditLimit}
              UniqueNumberCost={this.state.uniqueNumberCost}
              // UsedCredits={this.state.usedCredits}
              RegisterationDate={this.state.registerationDate}
              Email={this.state.email}
              InboxesId={this.state.inboxesId}
              Name={this.state.name}
              InboxesTypeId={this.state.inboxesTypeId}
              ServicePhonenumber={this.state.servicePhonenumber}
              ContactLookupEmail={this.state.contactLookupEmail}
              WhiteLabelBrand={this.state.whiteLabelBrand}
              ContactLookupEnabled={this.state.contactLookupEnabled}

              setInboxesContactLookupEnabledForUser={(value: any) =>
                this.props.setInboxesContactLookupEnabledForUser(value)
              }
              setInboxesServicePhonenumber={(value: any) =>
                this.props.setInboxesServicePhonenumber(value)
              }
              setInboxesContactLookupEmail={(value: any) =>
                this.props.setInboxesContactLookupEmail(value)
              }
              setInboxesWhitelabelBrand={(value: any) =>
                this.props.setInboxesWhitelabelBrand(value)
              }

              emailEnabledProps={this.state.emailEnabled}
              // EmailEnabledForAddEdit={(value: any) => {
              //   this.props.setEmailEnabled(value);
              // }}
              // storeTextForAddEdit={(value: any) => {
              //   this.props.setStoreText(value);
              // }}
              disableForAddEdit={(value: any) => {
                this.props.setDisable(value);
              }}
              // setCreditLimit={(value: any) => {
              //   this.props.setCreditLimit(value);
              // }}
              // setUniqueNumberCost={(value: any) => {
              //   this.props.setUniqueNumberCost(value);
              // }}
              setDefaultCountryCode={(value: any) => {
                this.props.setDefaultCountryCode(value);
              }}
              setEmail={(value: any) => {
                this.props.setEmail(value);
              }}
              setName={(value: any) => {
                this.props.setName(value);
              }}
              setInboxesTypeId={(value: any) => {
                this.props.setInboxesTypeId(value);
              }}
              setSubscriptionId={(value: any) => {
                this.props.setSubscriptionId(value);
              }}
              setSubscriptionName={(value: any) => {
                this.props.setSubscriptionName(value);
              }}
              companyId={this.props.companyId}
              subscriptionList={this.state.subscriptionList}
              changeState={() => this.props.changeState()}
              calledFrom={"edit"}
              editData={this.state.editDetails}
              addUpdateInboxesProp={(type: any, data: any) =>
                this.props.addUpdateInboxesProp(type, data)
              }
              updateUserEmailNotifications={(obj: any) =>
                this.props.updateUserEmailNotifications(obj)
              }
              deleteInboxes={(inboxesId: any, confirm: boolean) => {
                this.props.deleteInboxesByInboxesId(inboxesId, confirm);
              }}
              releaseNumberFromApi={(value: any) => {
                this.props.releaseNumber(value);
              }}
              roleOfLoggedInUser={this.props.loggedInUserRole}
              unAssignPhoneNumberProps={this.state.setUniqueNumberList}
              // setUniqueNumber={(value: any) => {
              //   this.props.setUniqueNumber(value);
              // }}
              getUnAssignedNumbers_inboxes={(
                smsServieType: any,
                subscriptionId: any
              ) => {
                this.props.getUnAssignedNumbers(smsServieType, subscriptionId);
              }}
              deleteInboxesConfirm={(inboxesId: any, deleteFlag: any) => {

                this.props.deleteInboxes(inboxesId, deleteFlag)
              }
              }
              deleteConfirmMessage={this.state.deleteMessage}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}
