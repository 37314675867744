import { Reducer } from "redux";
import { CharacterActionTypes } from "../actions/index";
import * as stateInterface from "./stateInterface";
const initialCharacterState: stateInterface.ICharacterState = {
  characters: [],
  userList: [],
  companyList: [],
  errorOccured: "",
  subscriptionList: [],
  dataSaved: false,
  deletedMessgae: false,
  pendingResultFromTwillioReducer: false,
  toggleClassProps: false,
  allServiceProvidersList: [],
  allServiceProvidersAccount: [],
  freeCreditRenewalDate: "",
};

export const characterReducer: Reducer<any> = (
  state = initialCharacterState,
  action
) => {
  switch (action.type) {
    case CharacterActionTypes.GET_ALL: {
      return {
        ...state,
        characters: action.characters,
      };
    }
    case CharacterActionTypes.PENDING_NUMBER_LIST: {
      return {
        ...state,
        pendingNumbeListReduc: action.pendingNumbeList,
      };
    }
    case CharacterActionTypes.PENDING_SUBSCRIPTION_LIST: {
      return {
        ...state,
        pendingSubscriptionList: action.pendingSubscriptionList,
      };
    }
    case CharacterActionTypes.PENDING_COMPANY_LIST: {
      return {
        ...state,
        pendingCompanyList: action.pendingCompanyList,
      };
    }
    case CharacterActionTypes.ICON_NAME_GROUP_NON_GROUP_MEMBERS: {
      return {
        ...state,
        iconNameReducer: action.iconName,
      };
    }
    case CharacterActionTypes.GET_PHONE_NUMBER_LIST: {
      return {
        ...state,
        phoneNumberListReducer: action.phoneNumberList,
      };
    }
    case CharacterActionTypes.SELECTED_COMPANY_NAME: {
      return {
        ...state,
        selectedCompanyName: action.selectedCompanyNameAction,
      };
    }
    case CharacterActionTypes.HIDE_SHOW_SELECT_ALL_NOT_GROUP_MEMBER: {
      return {
        ...state,
        showHideSelectAllIconNotGroupListReducer:
          action.showHideSelectAllIconNotGroupList,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID_COMPANY: {
      return {
        ...state,
        rowActiveIdCompanyReducer: action.rowActiveIdCompany,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID: {
      return {
        ...state,
        rowActiveIdReducer: action.rowActiveId,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID_NUMBERS: {
      return {
        ...state,
        rowActiveId_NumbersReducer: action.rowActiveId_Numbers,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID_GROUPS: {
      return {
        ...state,
        rowActiveId_GroupsReducer: action.rowActiveId_Groups,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID_SUBSCRIPTION: {
      return {
        ...state,
        rowActiveId_SbscriptionReducer: action.rowActiveId_Sbscription,
      };
    }
    case CharacterActionTypes.FILTERED_GROUP_MEMBER_LIST: {
      return {
        ...state,
        filteredGroupMemberListReducer: action.filteredGroupMemberList,
      };
    }
    case CharacterActionTypes.GROUP_LIST_FILTER: {
      return {
        ...state,
        groupListFilterReducer: action.groupListFilter,
      };
    }
    case CharacterActionTypes.FILTERED_NON_GROUP_MEMBER_LIST: {
      return {
        ...state,
        filteredNonGroupMemberListReducer: action.filteredNonGroupMemberList,
      };
    }
    case CharacterActionTypes.HIDE_SHOW_SELECT_ALL: {
      return {
        ...state,
        showHideSelectAllIconReducer: action.showHideSelectAllIcon,
      };
    }

    case CharacterActionTypes.HIDE_COMPANY_TAB: {
      return {
        ...state,
        hideCompanyTabReducer: action.hideCompanyTab,
      };
    }
    case CharacterActionTypes.GORUP_SIDE_NAV_CLICK: {
      return {
        ...state,
        showGroupNameReducer: action.showGroupTab,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST_GROUP: {
      return {
        ...state,
        listShowComponentGroupREduc: action.listShowComponentGroup,
      };
    }
    case CharacterActionTypes.SELECTED_GROUP_NAME: {
      return {
        ...state,
        selectedGroupNameReducer: action.selectedGroupNameAction,
      };
    }
    case CharacterActionTypes.SELECTED_GROUP_EMAIL: {
      return {
        ...state,
        selectedGroupEmailReducer: action.selectedGroupEmailAction,
      };
    }
    case CharacterActionTypes.SEARCH_TEXT_BOX_VALUE: {
      return {
        ...state,
        searchTextBoxReducer: action.searchTextBoxValue,
      };
    }
    case CharacterActionTypes.ACTIVE_TAB_NAME: {
      return {
        ...state,
        activeTabNameReducer: action.activeTabNameAction,
      };
    }
    case CharacterActionTypes.LOADER_HEADER: {
      return {
        ...state,
        loaderValueHeaderReducer: action.loaderValueHeader,
      };
    }

    case CharacterActionTypes.HIDE_GROUP_NON_MEMBER_TAB: {
      return {
        ...state,
        hideNonGroupMember: action.hideGroupNonMemberTab,
      };
    }
    case CharacterActionTypes.PENDING_USER_LIST: {
      return {
        ...state,
        pendingUserList: action.pendingUserList,
      };
    }

    case CharacterActionTypes.GROUP_EDIT_OBJECT: {
      return {
        ...state,
        groupEditObjectReducer: action.groupEditObject,
      };
    }
    case CharacterActionTypes.PENDING_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        pendingGroupMemberListByEmailReducer:
          action.pendingGroupMemberListByEmail,
      };
    }
    case CharacterActionTypes.PENDING_NOT_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        pendingnotGroupNotMemberListByEmailReducer:
          action.pendingnotGroupNotMemberListByEmail,
      };
    }
    case CharacterActionTypes.GROUP_NOT_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        groupNotMemberListReducer: action.groupNotMemberList,
      };
    }

    case CharacterActionTypes.HIDE_GROUP_EMAIL_HEADING: {
      return {
        ...state,
        hideGroupEmailHeading: action.hideGroupEmailHeading,
      };
    }
    case CharacterActionTypes.GROUP_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        groupMemberListReducer: action.groupMemberList,
      };
    }
    case CharacterActionTypes.GET_ALL_USERSLIST: {
      return {
        ...state,
        userList: action.usersList,
      };
    }
    case CharacterActionTypes.ERROR_SAVED: {
      return {
        ...state,
        errorOccured: action.errorOccured,
      };
    }
    case CharacterActionTypes.WARNING_PHONE_NUMBER: {
      return {
        ...state,
        phoneNumberReducer: action.phoneNumber,
      };
    }
    case CharacterActionTypes.PHONE_NUMBER_PURCHASED: {
      return {
        ...state,
        phoneNumberPurchasedReducer: action.phoneNumberPurchased,
      };
    }
    case CharacterActionTypes.CHANGE_BUTTON_TEXT: {
      return {
        ...state,
        buttonTextAction: action.buttonTextReducer,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST: {
      return {
        ...state,
        showHideList: action.listShowComponent,
      };
    }
    case CharacterActionTypes.FILTER_COMPANYLIST: {
      return {
        ...state,
        filterCompanyListReducer: action.filterCompanyList,
      };
    }
    case CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_SUBSCRIPTION: {
      return {
        ...state,
        searchTextBoxValue_SubscriptionReducer:
          action.searchTextBoxValue_Subscription,
      };
    }
    case CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_USER: {
      return {
        ...state,
        searchTextBoxValue_UserReducer: action.searchTextBoxValue_User,
      };
    }
    case CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_GROUP: {
      return {
        ...state,
        searchTextBoxValue_GroupReducer: action.searchTextBoxValue_Group,
      };
    }

    case CharacterActionTypes.FILTER_NUMBER_LIST: {
      return {
        ...state,
        filterNumberListRedux: action.filterNumberList,
      };
    }
    case CharacterActionTypes.SUBSCRIPTION_LIST_LENGTH: {
      return {
        ...state,
        subscriptionListLengthRedux: action.subscriptionListLength,
      };
    }
    case CharacterActionTypes.SUBSCRIPTION_FILTER_LIST: {
      return {
        ...state,
        filteringSubscription: action.filteringSubscriptionAction,
      };
    }
    case CharacterActionTypes.USER_FILTER_LIST: {
      return {
        ...state,
        filteringUser: action.filteringUserAction,
      };
    }
    case CharacterActionTypes.HIDE_GROUP_MEMBERS_GROUP_TAB: {
      return {
        ...state,
        hideGroupMembrFromGroupTabReducer:
          action.hideGroupMembrFromGroupTabActions,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST_COMPANY: {
      return {
        ...state,
        showHideListCompanyReduc: action.listShowComponentCompany,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST_NUMBERS: {
      return {
        ...state,
        showHideNumberListReduc: action.showHideNumberListAction,
      };
    }
    case CharacterActionTypes.GROUP_LIST_BY_SUBID_COMPID: {

      return {
        ...state,
        groupListByCompAndSubIdReducer: action.groupListByCompAndSubId,
      };
    }
    case CharacterActionTypes.PENDING_GROUP_LIST_BY_SUBID_COMPID: {
      return {
        ...state,
        pendingGroupListBySubIdAndCompIdReducer:
          action.pendingGroupListBySubIdAndCompId,
      };
    }
    case CharacterActionTypes.EMAIL_ENABLED_FOR_USER: {
      return {
        ...state,
        emailEnabledReducer: action.emailEnabled,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST_USER: {
      return {
        ...state,
        showHideUser: action.listShowComponentUser,
      };
    }
    case CharacterActionTypes.DISPLAY_ERROR_LIST_MESSAGE: {
      return {
        ...state,
        errorListMessage: action.errorListMessage,
      };
    }
    case CharacterActionTypes.GET_ACCESS_LEVEL_LOGGED_IN_USER: {
      return {
        ...state,
        loggedinUserAccessLevel: action.loggedinUserAccessLevel,
      };
    }
    case CharacterActionTypes.COMPANY_LIST: {
      return {
        ...state,
        companyList: action.companyList,
      };
    }
    case CharacterActionTypes.COMPANY_LISTITEM: {
      return {
        ...state,
        companisListItem: action.companisListItem,
      };
    }

    case CharacterActionTypes.SUBSCRIPTION_LIST: {
      return {
        ...state,
        subscriptionList: action.subscriptionList,
      };
    }
    case CharacterActionTypes.SUCCESS_SAVED: {
      return {
        ...state,
        dataSaved: action.dataSaved,
      };
    }
    case CharacterActionTypes.DELETED_SUCCESSFULLY_MESSAGE: {
      return {
        ...state,
        deletedMessgae: action.deletedMessgae,
      };
    }
    case CharacterActionTypes.FILTER_NUMBERS: {
      return {
        ...state,
        filtersNumberAction: action.filterNumbersBY,
      };
    }
    case CharacterActionTypes.SERVICE_PLAN_LIST: {
      return {
        ...state,
        servicePlanListReducer: action.servicePlanList,
      };
    }
    case CharacterActionTypes.LOGGED_IN_USER_DETAIL: {
      return {
        ...state,
        loggedInUserDetailReducer: action.userDetail,
      };
    }
    case CharacterActionTypes.PENDING_LOGGED_IN_RESULT: {
      return {
        ...state,
        pendingResultReducer: action.pendingResult,
      };
    }
    case CharacterActionTypes.PENDING_RESULT_FROM_TWILLIO: {
      return {
        ...state,
        pendingResultFromTwillioReducer: action.pendingResultFromTwillio,
      };
    }
    case CharacterActionTypes.MOBILE_NUMBER_LIST_TWILLIO: {
      return {
        ...state,
        mobileTwillioResponseReducer: action.mobileNumberListTwillio,
      };
    }
    case CharacterActionTypes.LOCAL_NUMBER_LIST_TWILLIO: {
      return {
        ...state,
        twillioResponseReducer: action.localNumberListTwillio,
      };
    }
    case CharacterActionTypes.SAVE_TWILLIO_NUMBER_TO_DATABASE: {
      return {
        ...state,
        saveTwillioNumberToDatabaseReducer: action.saveTwillioNumberToDatabase,
      };
    }
    case CharacterActionTypes.PARENT_COMPANY_LIST: {
      return {
        ...state,
        parentCompanyListReducer: action.parentCompanyList,
      };
    }
    case CharacterActionTypes.TOGGLE_CLASS_SIDE_NAV: {
      return {
        ...state,
        toggleClassSidNavReducer: action.toggleClassSidNav,
      };
    }
    case CharacterActionTypes.LOCAL_RESPONSE_SHOW: {
      return {
        ...state,
        showLocalResponseReducer: action.showLocalResponse,
      };
    }
    case CharacterActionTypes.SHOW_UNIQUE_NUMBER: {
      return {
        ...state,
        showUniqueNumberReducer: action.showUniqueNumber,
      };
    }
    case CharacterActionTypes.EMAIL_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        emailForAddEditUser: action.emailForAddEditUser,
      };
    }
    case CharacterActionTypes.ALIAS_EMAIL_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        aliasEmailForAddEditUser: action.aliasEmailForAddEditUser,
      };
    }
    case CharacterActionTypes.NAME_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        nameForAddEditUser: action.nameForAddEditUser,
      };
    }

    case CharacterActionTypes.FIRST_NAME_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        firstNameForAddEditUserReducer: action.firstNameForAddEditUserReducer,
      };
    }

    case CharacterActionTypes.LAST_NAME_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        lastNameForAddEditUserReducer: action.lastNameForAddEditUserReducer,
      };
    }


    case CharacterActionTypes.SUBSCRIPTION_ID_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        subscriptionID: action.subscriptionIdForAddEditUser,
      };
    }
    case CharacterActionTypes.SUBSCRIPTION_NAME_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        subscriptionNameForAddEditUser: action.subscriptionNameForAddEditUser,
      };
    }
    case CharacterActionTypes.UNIQUE_NUMBER_COST_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        uniqueNumbetCostForAddEditUser: action.uniqueNumbetCostForAddEditUser,
      };
    }
    case CharacterActionTypes.CREDIT_LIMIT_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        creditLimitForAddEditUser: action.creditLimitForAddEditUser,
      };
    }
    case CharacterActionTypes.ROLE_ID_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        roleIdForAddEditUser: action.roleIdForAddEditUser,
      };
    }
    case CharacterActionTypes.STORE_TEXT_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        storeTextForAddEditUser: action.storeTextForAddEditUser,
      };
    }
    case CharacterActionTypes.DISABLED_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        disabledForAddEditUser: action.disabledForAddEditUser,
      };
    }
    //Task #3461 
    //Maksud
    case CharacterActionTypes.CONTACT_LOOKUP_ENABLED_EDIT_USER: {
      return {
        ...state,
        ContactLookupEnabledForAddEditUser: action.ContactLookupEnabledForAddEditUser,
      };
    }

    case CharacterActionTypes.SUBSCRIPTION_TENANT_ID_MICROSOFT_CONTACT_LOOKUP: {
      return {
        ...state,
        SubscriptionTenantIdMicrosoftContactLookup: action.SubscriptionTenantIdMicrosoftContactLookup,
      };
    }


    case CharacterActionTypes.USER_DETAIL_OBJ: {
      return {
        ...state,
        objUserData: action.objectUserData,
      };
    }
    case CharacterActionTypes.DEFAULT_COUNTRY_CODE: {
      return {
        ...state,
        defaultCountryCode: action.defaultCountryCode,
      };
    }
    case CharacterActionTypes.REGISTERATION_DATE_FOR_ADD_EDIT: {
      return {
        ...state,
        registerationDate: action.registerationDate,
      };
    }
    case CharacterActionTypes.USED_CREDIT_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        usedCredit: action.usedCredit,
      };
    }
    case CharacterActionTypes.SAVED_SELECTED_COMPANY_DETAIL: {
      return {
        ...state,
        savedCompanyDetailReducer: action.savedCompanyDetail,
      };
    }
    case CharacterActionTypes.WHITE_LABEL_FOR_USER: {
      return {
        ...state,
        whiteLabelReducer: action.whiteLabelAction,
      };
    }
    case CharacterActionTypes.WARNING_AFTER_GROUP_ADD: {
      return {
        ...state,
        groupAddWarningReducer: action.groupAddWarning,
      };
    }

    case CharacterActionTypes.HIDE_USER_LIST_FROM_COMPANY_TAB: {
      return {
        ...state,
        hideUserTabCompanyDetailReducer: action.hideUserTabCompanyDetail,
      };
    }
    case CharacterActionTypes.CURRENT_ACTIVE_TAB_NAME: {
      return {
        ...state,
        currentActiveGroupTabName: action.activeGroupTabName,
      };
    }
    case CharacterActionTypes.HIDE_GROUP_TAB_FROM_COMPANY_DETAIL: {
      return {
        ...state,
        hideGroupTabCompanyDetailReducer: action.hideGroupTabCompanyDetail,
      };
    }
    case CharacterActionTypes.USER_EDIT_BREADCRUMB_NUMBER: {
      return {
        ...state,
        userEditTabReducer: action.userEditTabValue,
      };
    }
    case CharacterActionTypes.PENDING_LOGGED_IN_COMPANY_DETAIL: {
      return {
        ...state,
        pendingLoggedInCompanyDetailReducer:
          action.pendingLoggedInCompanyDetail,
      };
    }
    case CharacterActionTypes.MY_COMPANY_NAME: {
      return {
        ...state,
        myCompanyNameReducer: action.myCompanyName,
      };
    }
    case CharacterActionTypes.MY_COMPANY_EMAIL: {
      return {
        ...state,
        myCompanyEmailReducer: action.myCompanyEmail,
      };
    }
    case CharacterActionTypes.MY_COMPANY_PRIMARY_CONTACT_NAME: {
      return {
        ...state,
        myCompanyPrimaryContactNameReducer: action.myCompanyPrimaryContactName,
      };
    }
    case CharacterActionTypes.MY_COMPANY_PRIMARY_CONTACT_NUMBER: {
      return {
        ...state,
        myCompanyPrimaryContactNumberReducer:
          action.myCompanyPrimaryContactNumber,
      };
    }
    case CharacterActionTypes.MY_COMPANY_SUBSCRIPTION_COST: {
      return {
        ...state,
        myCompanySubscriptionCostReducer: action.myCompanySubscriptionCost,
      };
    }
    case CharacterActionTypes.MY_COMPANY_ID: {
      return {
        ...state,
        myCompanyIdReducer: action.myCompanyId,
      };
    }
    case CharacterActionTypes.MY_COMPANY_PARTNER_PARENT_ID: {
      return {
        ...state,
        myCompanyPartnerParentReducer: action.myCompanyPartnerParent,
      };
    }
    case CharacterActionTypes.MY_COMPANY_LINE3: {
      return {
        ...state,
        myCompanyLine3Reducer: action.myCompanyLine3,
      };
    }
    case CharacterActionTypes.MY_COMPANY_CITY: {
      return {
        ...state,
        myCompanyCityReducer: action.myCompanyCity,
      };
    }
    case CharacterActionTypes.MY_COMPANY_COUNTRY: {
      return {
        ...state,
        myCompanyCountryReducer: action.myCompanyCoutnry,
      };
    }
    case CharacterActionTypes.MY_COMPANY_ZIPCODE: {
      return {
        ...state,
        myCompanyZipCodeReducer: action.myCompanyZipCode,
      };
    }
    case CharacterActionTypes.MY_COMPANY_CURRENCY: {
      return {
        ...state,
        myCompanyCurrencyReducer: action.myCompanyCurrency,
      };
    }
    case CharacterActionTypes.PENDING_UNASSIGN_PHONE_NUMBERS: {
      return {
        ...state,
        pendingUnAssignPhoneNumbersReducer: action.pendingUnAssignPhoneNumbers,
      };
    }
    case CharacterActionTypes.LOGGED_IN_USER_DISABELD: {
      return {
        ...state,
        loggedInUserDisableReducer: action.loggedInUserDisable,
      };
    }

    case CharacterActionTypes.UNASSIGN_PHONE_NUMBERS: {
      return {
        ...state,
        unAssignPhoneNumberReducer: action.unAssignPhoneNumber,
      };
    }
    case CharacterActionTypes.USER_ADD_CALLED_FROM_PHONE_NUMBER: {
      return {
        ...state,
        userAddCalledFromCompanyTabReducer: action.userAddCalledFromCompanyTab,
      };
    }
    case CharacterActionTypes.SUBSCRIPTON_ADD_CALLED_FROM_PHONE_NUMBER: {
      return {
        ...state,
        subscriptionAddCalledFromCompanyTabReducer:
          action.subscriptionAddCalledFromCompanyTab,
      };
    }
    case CharacterActionTypes.GROUP_ADD_CALLED_FROM_PHONE_NUMBER: {
      return {
        ...state,
        groupAddCalledFromCompanyTabReducer:
          action.groupAddCalledFromCompanyTab,
      };
    }
    case CharacterActionTypes.GROUP_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        groupForAddEditReducer: action.groupForAddEditUser,
      };
    }
    case CharacterActionTypes.COST_CODE_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        costCodeForAddEditUserReducer: action.costCodeForAddEditUser,
      };
    }
    case CharacterActionTypes.MY_COMPANY_IS_PARENT: {
      return {
        ...state,
        myCompanyIsParentReducer: action.myCompanyisParent,
      };
    }
    case CharacterActionTypes.MY_COMPANY_PARENT_ID: {
      return {
        ...state,
        myCompanyParentIDReducer: action.myCompanyparentId,
      };
    }
    case CharacterActionTypes.MY_COMPANY_REGISTERATION_DATE: {
      return {
        ...state,
        myCompanyRegisterationDateReducer: action.myCompanyRegisterationDate,
      };
    }
    case CharacterActionTypes.WHITE_BRAND_LOGGED_IN_USER: {
      return {
        ...state,
        whiteLabelActionLoggedInUserReducer:
          action.whiteLabelActionLoggedInUser,
      };
    }
    case CharacterActionTypes.GET_ALL_SERVICE_PROVIDERS: {
      return {
        ...state,
        allServiceProvidersList: action.payload,
      };
    }
    case CharacterActionTypes.GET_ALL_SERVICE_PROVIDERS_ACCOUNT: {
      return {
        ...state,
        allServiceProvidersAccount: action.payload,
      };
    }
    case CharacterActionTypes.MODIFY_FREE_CREDIT_DATE: {
      return {
        ...state,
        freeCreditRenewalDate: action.payload,
      };
    }

    /* inboxes page Added by Maksud */
    case CharacterActionTypes.HIDE_INBOXES_MEMBERS_INBOXES_TAB: {
      return {
        ...state,
        hideInboxesMembrFromInboxesTabReducer:
          action.hideInboxesMembrFromInboxesTabActions,
      };
    }

    case CharacterActionTypes.INBOXES_LIST_BY_SUBID_COMPID: {
      debugger;
      return {
        ...state,
        inboxesListByCompAndSubIdReducer: action.inboxesListByCompAndSubId,
      };
    }

    case CharacterActionTypes.PENDING_INBOXES_LIST_BY_SUBID_COMPID: {
      return {
        ...state,
        pendingInboxesListBySubIdAndCompIdReducer:
          action.pendingInboxesListBySubIdAndCompId,
      };
    }

    case CharacterActionTypes.INBOXES_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        inboxesMemberListReducer: action.inboxesMemberList,
      };
    }

    case CharacterActionTypes.INBOXES_NOT_MEMBER_LIST_BY_EMAIL: {
      return {
        ...state,
        inboxesNotMemberListReducer: action.inboxesNotMemberList,
      };
    }

    case CharacterActionTypes.ICON_NAME_INBOXES_NON_INBOXES_MEMBERS: {
      return {
        ...state,
        iconNameReducer: action.iconName,
      };
    }
    case CharacterActionTypes.HIDE_SHOW_SELECT_ALL_NOT_INBOXES_MEMBER: {
      return {
        ...state,
        showHideSelectAllIconNotInboxesListReducer:
          action.showHideSelectAllIconNotInboxesList,
      };
    }
    case CharacterActionTypes.FILTERED_INBOXES_MEMBER_LIST: {
      return {
        ...state,
        filteredInboxesMemberListReducer: action.filteredInboxesMemberList,
      };
    }
    case CharacterActionTypes.FILTERED_NON_INBOXES_MEMBER_LIST: {
      return {
        ...state,
        filteredNonInboxesMemberListReducer: action.filteredNonInboxesMemberList,
      };
    }

    case CharacterActionTypes.INBOXES_LIST_FILTER: {
      return {
        ...state,
        inboxesListFilterReducer: action.inboxesListFilter,
      };
    }
    case CharacterActionTypes.SHOW_HIDE_LIST_INBOXES: {
      return {
        ...state,
        listShowComponentInboxesReducer: action.listShowComponentInboxes,
      };
    }

    case CharacterActionTypes.SELECTED_INBOXES_NAME: {
      return {
        ...state,
        selectedInboxesNameReducer: action.selectedInboxesNameAction,
      };
    }

    case CharacterActionTypes.HIDE_INBOXES_NON_MEMBER_TAB: {
      return {
        ...state,
        hideNonInboxesMember: action.hideInboxesNonMemberTab,
      };
    }
    case CharacterActionTypes.INBOXES_EDIT_OBJECT: {
      return {
        ...state,
        inboxesEditObjectReducer: action.inboxesEditObject,
      };
    }


    case CharacterActionTypes.HIDE_INBOXES_EMAIL_HEADING: {
      return {
        ...state,
        hideInboxesEmailHeading: action.hideInboxesEmailHeading,
      };
    }
    case CharacterActionTypes.SELECTED_INBOXES_EMAIL: {
      return {
        ...state,
        selectedInboxesEmailReducer: action.selectedInboxesEmailAction,
      };
    }

    case CharacterActionTypes.HIDE_INBOXES_TAB_FROM_COMPANY_DETAIL: {
      return {
        ...state,
        hideInboxesTabCompanyDetailReducer: action.hideInboxesTabCompanyDetail,
      };
    }

    case CharacterActionTypes.INBOXES_ADD_CALLED_FROM_PHONE_NUMBER: {
      return {
        ...state,
        inboxesAddCalledFromCompanyTabReducer:
          action.inboxesAddCalledFromCompanyTab,
      };
    }
    case CharacterActionTypes.INBOXES_FOR_ADD_EDIT_USER: {
      return {
        ...state,
        inboxesForAddEditReducer: action.inboxesForAddEditUser,
      };
    }

    case CharacterActionTypes.SEARCH_TEXT_BOX_VALUE_INBOXES: {
      return {
        ...state,
        searchTextBoxValue_InboxesReducer: action.searchTextBoxValue_Inboxes,
      };
    }
    case CharacterActionTypes.WARNING_AFTER_INBOXES_ADD: {
      return {
        ...state,
        inboxesAddWarningReducer: action.inboxesAddWarning,
      };
    }
    case CharacterActionTypes.ROW_ACTIVE_ID_INBOXES: {
      return {
        ...state,
        rowActiveId_InboxesReducer: action.rowActiveId_Inboxes,
      };
    }
    case CharacterActionTypes.CURRENT_ACTIVE_INBOXES_TAB_NAME: {
      return {
        ...state,
        currentActiveInboxesTabName: action.activeInboxesTabName,
      };
    }

    case CharacterActionTypes.ID_FOR_ADD_EDIT_INBOXES: {
      return {
        ...state,
        idForAddEditInboxes: action.idForAddEditInboxes,
      };
    }

    case CharacterActionTypes.PENDING_NOT_MEMBER_LIST_BY_INBOXESID: {
      return {
        ...state,
        pendingnotInboxesNotMemberListByInboxesIdReducer:
          action.pendingnotInboxesNotMemberListByInboxesId,
      };
    }

    case CharacterActionTypes.DELETED_CONFIRM_MESSAGE_INBOXES: {
      return {
        ...state,
        deleteConfirmMessageReducer: action.deleteConfirmMessage,
      };
    }
    case CharacterActionTypes.INBOXES_TYPE: {
      return {
        ...state,
        inboxesTypeId: action.inboxesType,
      };
    }

    case CharacterActionTypes.INBOXES_SERVICE_PHONENUMBER: {
      return {
        ...state,
        inboxesServicePhonenumberReducer: action.inboxesServicePhonenumber,
      };
    }
    case CharacterActionTypes.INBOXES_CONTACT_LOOKUP_EMAIL: {
      return {
        ...state,
        inboxesContactLookupEmailReducer: action.inboxesContactLookupEmail,
      };
    }
    case CharacterActionTypes.INBOXES_WHITE_LABEL_BRAND: {
      return {
        ...state,
        inboxesWhitelabelBrandReducer: action.inboxesWhitelabelBrand,
      };
    }

    case CharacterActionTypes.SELECTED_USER_COMPANY_VERSION_NO: {
      return {
        ...state,
        selectedUserCompanyVersionNoReducer: action.selectedUserCompanyVersionNo,
      };
    }
    case CharacterActionTypes.LOGGED_IN_USER_COMPANY_VERSION_NO: {
      return {
        ...state,
        loggedInUserCompanyVersionNoReducer: action.loggedInUserCompanyVersionNo,
      };
    }

    case CharacterActionTypes.REMOVE_USER_DATA_FOR_DELETE: {
      return {
        ...state,
        removeUserDataObjectReducer: action.removeUserDataObject,
      };
    }
/* WebEx */
    // case CharacterActionTypes.FILTER_COMPANYLIST: {
    //   return {
    //     ...state,
    //     filterCompanyListReducer: action.filterCompanyList,
    //   };
    // }

    case CharacterActionTypes.WEBEX_COMPANY_BY_ID: {
      return {
        ...state,
        webExCompanyByIdReducer: action.webExCompanyById,
      };
    }


    /*  */
    default:
      return state;
  }
};
