import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import NotificationComponent from "../sharedComponents/displayNotifications";
import { GroupListComponent } from "./groupComponentList";
import { GroupNonMemberComponent } from "./grouNonMemberComponent";
import * as action from "../../redux/actions/actions";
import history from "../../history";
class GroupsParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      pendingGroupList: true,
      groupListByCompId: [],
      errorOccured: "",
      pendingGroupMemberListState: false,
      groupMemberListEmail: [],
      groupNonMemberList: [],
      showGroupNonMember: false,
      selectedGroupEmail: "",
      iconNameToBeDisplayed: "both",
      nonGroupMemberList: [],
      subscriptionList: [],
      showGroupList: false,
      companyName: "",
      companyId: "",
      editGroup: this.props.editGroupObj,
      actualGroupMemberList: [],
      actualGroupNonMemberList: [],
      hideContent: true,
      selectwedGropMail: "",
      pendingNonGroupMembers: false,
      pendingGroupMembers: false,
      uniquNumber: "",
      defaultCountryCode: "",
      storeText: true,
      disabled: false,
      creditLimit: 0,
      uniqueNumberCost: "",
      usedCredits: "",
      registerationDate: "",
      email: "",
      name: "",
      roleId: "",
      subscriptionId: "",
      subscriptionName: "",
      currentActiveGroupTabName: "",
      unAssignedNumbers: [],
      searchTextBox: "",
      rowActiveId: "",
      emailEnabled: false,
    };
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingGroupListBySubIdAndCompIdAction !==
        prevState.pendingGroupList ||
      nextProps.actualGroupList !== prevState.groupListByCompId ||
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.pendingGroupMemberList !==
        prevState.pendingGroupMemberListState ||
      nextProps.filteredGroupMemberList !== prevState.groupMemberListEmail ||
      nextProps.filteredGroupNonMemberList !== prevState.groupNonMemberList ||
      nextProps.iconName !== prevState.iconNameToBeDisplayed ||
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.showGroupList !== prevState.showGroupList ||
      nextProps.selectedGroupName !== prevState.selectedGroupEmail ||
      nextProps.hideNonGroupMember !== prevState.showGroupNonMember ||
      nextProps.editGroupObj !== prevState.editGroup ||
      nextProps.groupMemberListByEmail !== prevState.actualGroupMemberList ||
      nextProps.actualGroupNotMemberList !==
        prevState.actualGroupNonMemberList ||
      nextProps.hideGroupEmailHeadingCompo !== prevState.hideContent ||
      nextProps.selectedGroupEmailValue !== prevState.selectwedGropMail ||
      nextProps.pendingGroupNonMemberValue !==
        prevState.pendingNonGroupMembers ||
      nextProps.pendingGroupMemberValue !== prevState.pendingGroupMembers ||
      nextProps.uniquNumberForGroupAdd !== prevState.uniquNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      nextProps.storeTextProps !== prevState.storeText ||
      nextProps.disableProps !== prevState.disabled ||
      nextProps.CreditLimit !== prevState.creditLimit ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      nextProps.UsedCredit !== prevState.usedCredits ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.RoleId !== prevState.roleId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.CurrentActiveGroupTabName !==
        prevState.currentActiveGroupTabName ||
      nextProps.unAssignPhoneNumberList !== prevState.unAssignedNumbers ||
      nextProps.searchTextBox_Text !== prevState.searchTextBox ||
      nextProps.activeId !== prevState.rowActiveId ||
      nextProps.emailEnabledForUser !== prevState.emailEnabled
    ) {
      return {
        email: nextProps.Email,
        name: nextProps.Name,
        roleId: nextProps.RoleId,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        registerationDate: nextProps.RegisterationDate,
        usedCredits: nextProps.UsedCredit,
        selectwedGropMail: nextProps.selectedGroupEmailValue,
        hideContent: nextProps.hideGroupEmailHeadingCompo,
        actualGroupNonMemberList: nextProps.actualGroupNotMemberList,
        editGroup: nextProps.editGroupObj,
        showGroupNonMember: nextProps.hideNonGroupMember,
        showGroupList: nextProps.showGroupList,
        subscriptionList: nextProps.subscriptionList,
        groupNonMemberList: nextProps.filteredGroupNonMemberList,
        iconNameToBeDisplayed: nextProps.iconName,
        pendingGroupMemberListState: nextProps.pendingGroupMemberList,
        errorOccured: nextProps.errorOccured,
        pendingGroupList: nextProps.pendingGroupListBySubIdAndCompIdAction,
        groupListByCompId: nextProps.actualGroupList,
        groupMemberListEmail: nextProps.filteredGroupMemberList,
        selectedGroupEmail: nextProps.selectedGroupName,
        actualGroupMemberList: nextProps.groupMemberListByEmail,
        pendingNonGroupMembers: nextProps.pendingGroupNonMemberValue,
        pendingGroupMembers: nextProps.pendingGroupMemberValue,
        uniquNumber: nextProps.uniquNumberForGroupAdd,
        defaultCountryCode: nextProps.DefaultCountryCode,
        storeText: nextProps.storeTextProps,
        disabled: nextProps.disableProps,
        creditLimit: nextProps.CreditLimit,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        currentActiveGroupTabName: nextProps.CurrentActiveGroupTabName,
        unAssignedNumbers: nextProps.unAssignPhoneNumberList,
        searchTextBox: nextProps.searchTextBox_Text,
        rowActiveId: nextProps.activeId,
        emailEnabled: nextProps.emailEnabledForUser,
      };
    } else return null;
  }
  componentDidMount() {
    var calledFrom =
      this.props.location !== null && this.props.location !== undefined
        ? this.props.location.state !== null &&
          this.props.location.state !== undefined
          ? this.props.location.state.callingFrom !== null &&
            this.props.location.state.callingFrom !== undefined
            ? this.props.location.state.callingFrom
            : "Groups"
          : "Groups"
        : "Groups";
    if (calledFrom !== "Groups" && calledFrom !== "") {
      history.replace("/Groups", { callingFrom: "" });
      this.props.hideGroupListProps(true);
    } else {
      this.callingComponent();
      //this.props.setSearchBoxValue("")
    }
  }
  callingComponent() {
    ///Nesting of multiple tabs according to calling from component tab
    var companyId = "";
    var CompanyName = "";
    //var showGroupNonMember: any = null;
    if (
      this.props.companyId !== undefined &&
      this.props.showGroupNonMember === undefined
    ) {
      companyId = this.props.companyId;
      CompanyName = this.props.selectedCompanyName;
      this.setState({
        companyId: companyId,
        companyName: CompanyName,
      });
      if (this.props.showGroupEditTab === true) {
        this.props.hideGroupListProps(true);
      } else {
        this.props.dispatchNonGroupMemberTab(false);
        this.props.filterGroupByCompId(
          this.props.companyId === undefined ? null : this.props.companyId
        );
        
        //#3804
        //this.props.getSubscriptionList(companyId === "" ? null : companyId);
      }
    }
    ///Only display non group member component
    if (this.props.showGroupNonMember !== undefined) {
      companyId = this.props.companyId;
      CompanyName = this.props.selectedCompanyName;
      this.setState({
        companyId: companyId,
        companyName: CompanyName,
      });
      //showGroupNonMember = this.props.showGroupNonMember;
      this.props.dispatchNonGroupMemberTab(true);
    }
    ///Direct calling
    if (
      this.props.companyId === undefined &&
      this.props.showGroupNonMember === undefined
    ) {
      this.props.setSearchBoxValue("");
      this.props.rowActiveIdGroups("");
      this.props.dispatchNonGroupMemberTab(false);
      this.props.dispatchPendingGroupList(true);
      this.props.filterGroupByCompId(
        this.props.companyId === undefined ? null : this.props.companyId
      );
      //#3804
      //this.props.getSubscriptionList(companyId === "" ? null : companyId);
    }
  }
  filterBySelectedStatus = (filterByStatus: any) => {
    let filteredList: any;
    if (filterByStatus === "disabled") {
      filteredList = this.props.groupListByCompAndSubIdAction.filter(
        (x: any) => x.Disabled === true
      );
      this.props.dispatchGetGroupListFilter(filteredList);
    } else if (filterByStatus === "enabled") {
      filteredList = this.props.groupListByCompAndSubIdAction.filter(
        (x: any) => x.Disabled === false
      );
      this.props.dispatchGetGroupListFilter(filteredList);
    } else {
      this.props.dispatchGetGroupListFilter(
        this.props.groupListByCompAndSubIdAction
      );
    }
  };
  assignMembersToGroups = (
    filteredMemberList: any,
    groupMemberist: any,
    notGroupMember: any,
    actualGroupNonMemberList: any
  ) => {
    filteredMemberList.forEach((element: any, index: any) => {
      if (element.checked) {
        element.checked = false;
        groupMemberist.push(element);
        var indexIngroupMemberList = actualGroupNonMemberList
          .map((item: any) => {
            return item.Email;
          })
          .indexOf(element.Email);

        if (indexIngroupMemberList !== -1) {
          actualGroupNonMemberList.splice(indexIngroupMemberList, 1);
        }
      }
      this.props.showIcons("both");
    });
    this.props.dispatchGetGroupNonMemberListFilter(actualGroupNonMemberList);
    // this.props.dispatchgetNotGroupMemberListAction(actualGroupNonMemberList)
    this.setState({
      groupMemberListByEmail: groupMemberist,
    });
  };
  unAssignMembersFromGroups = (
    filteredMemberList: any,
    groupMemberist: any,
    notGroupMember: any,
    actualGroupMemberList: any
  ) => {
    filteredMemberList.forEach((element: any, index: any) => {
      if (element.checked) {
        element.checked = false;
        notGroupMember.push(element);
        var indexIngroupMemberList = actualGroupMemberList
          .map((item: any) => {
            return item.Email;
          })
          .indexOf(element.Email);
        if (indexIngroupMemberList !== -1) {
          actualGroupMemberList.splice(indexIngroupMemberList, 1);
        }
      }
    });

    this.props.dispatchGetGroupMemberListFilter(actualGroupMemberList);
    // this.props.dispatchgetGroupMemberListAction(actualGroupMemberList)
    this.props.showIcons("both");
    this.setState({
      groupNotMemberListDataTab: notGroupMember,
    });
  };
  breadCrumbNavigation = () => {
    ///Inside company
    if (this.props.companyId === undefined) {
      this.props.hideGroupListProps(false);
    } else {
      this.props.hideGroupListProps(true);
    }
    this.props.dispatchNonGroupMemberTab(false);
    this.props.hideCompanyTabDispatch(true);
    this.props.hideGroupNameHeading(true);
  };
  public render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          {this.props.companyId !== undefined ? null : (
            <NotificationComponent />
          )}
          <div
            className={
              this.props.companyId !== undefined &&
              this.state.showGroupNonMember === true
                ? ""
                : "container-fluid subscription_box"
            }
          >
            {/* Non MemberComponent Header */}
            {this.state.hideContent === false ? (
              <h2 className="companyName  text-left ">
                <span
                  className="iconNavigation cursor_pointer"
                  onClick={() => this.breadCrumbNavigation()}
                >
                  <i
                    className="fa fa-chevron-left cursor_pointer"
                    aria-hidden="true"
                  ></i>
                </span>
                {this.state.companyName !== ""
                  ? this.state.companyName
                  : "Group"}{" "}
                / <b>{this.state.selectedGroupEmail}</b>
              </h2>
            ) : null}
            {this.state.errorOccured !== undefined &&
            this.state.errorOccured !== "" &&
            this.state.errorOccured.split(":")[1] === "Groups" ? (
              this.state.errorOccured.split(":")[0]
            ) : this.state.showGroupNonMember === false ? (
              <GroupListComponent
                loggedInUserRole={this.props.loggedinUserRole}
                hideGroupTabFromCompanyDetail={(value: any) =>
                  this.props.hideGroupTabFromCompanyDetail(value)
                }
                showAddGroup={this.props.showGroupEditTab}
                companyId={
                  this.props.editCompanyDetail !== undefined
                    ? this.props.editCompanyDetail.Id
                    : ""
                }
                pendingGropList={this.state.pendingGroupList}
                hideGroupList={this.state.showGroupList}
                groupList={this.state.groupListByCompId}
                actualDataProp={this.props.groupListByCompAndSubIdAction}
                filterationByTxt={(newList: any, type: any) => {
                  this.props.dispatchGetGroupListFilter(newList);
                }}
                searchFunction={(value: any) => {
                  this.filterBySelectedStatus(value);
                }}
                //Group Member functionality
                pendingGroupMemberMethod={(value: any) => {
                  this.props.pendingGroupMembeList(value);
                }}
                getGroupMembersByGroupEmail={(email: any) => {
                  this.props.getGroupMembersByGroupEmailAddress(email);
                }}
                pendingGroupMemberListByEmail={
                  this.state.pendingGroupMemberListState
                }
                groupMemberList={this.state.groupMemberListEmail}
                showGroupNonMemberTabComp={(value: any, type: any) => {                
                  if (type === "arrowClick") {                    
                    this.props.setCurrentActiveTabName("groupDetail");
                  } else {
                    this.props.setCurrentActiveTabName("groupMember");
                  }
                   //#3804
                  var companyId = this.state.companyId===""? null:this.state.companyId;
                  this.props.getSubscriptionList(companyId === "" ? null : companyId);

                  this.props.showIcons("both");
                  this.props.hideGroupNameHeading(false);
                  this.props.getGroupNotMemberList(
                    this.state.selectwedGropMail
                  );
                  if (this.props.companyId !== "") {
                    this.props.hideCompanyTabDispatch(false);
                    this.props.dispatchActiveTabName("groupList");
                  }
                  this.props.dispatchNonGroupMemberTab(true);
                }}
                getGroupNotMemberListGroupMemeberComp={
                  this.state.nonGroupMemberList
                }
                ///Add Group
                subscriptionList={this.state.subscriptionList}
                getSubscriptionList={() => {                  
                  //#3804
                  var companyId = this.state.companyId===""? null:this.state.companyId;
                  this.props.getSubscriptionList(companyId === "" ? null : companyId);
                }}
                addUpdateUserProp={(type: any, data: any, compId: any) => {
                  this.props.addUpdateUserProp(
                    type,
                    data,
                    this.state.companyId
                  );
                }}
                showGroupListTab={(value: any) => {
                  this.props.hideGroupListProps(value);
                }}
                selecteGroupName={this.state.selectedGroupEmail}
                selectedEmailName={(value: any) => {
                  this.props.selectedGroupNameProps(value);
                }}
                selectedGroupEmailName={(value: any) => {
                  this.props.selectedGroupEmailPropsAction(value);
                }}
                editData={this.state.editGroup}
                setEditObject={(obj: any) => {
                  //#8047
                  var companyId = (this.props.companyId ===undefined || this.props.companyId ==="" 
                                  || this.props.companyId ===null) ? "": this.props.companyId;
                  this.props.getUnAssignPhoneNumber(null, obj.SubscriptionId, companyId);
                  this.props.groupObject(obj);
                }}
                SubscriptionId={this.state.subscriptionId}
                SubscriptionName={this.state.subscriptionName}
                RoleId={this.state.roleId}
                Email={this.state.email}
                Name={this.state.name}
                uniqueNumberGroupAdd={this.state.uniquNumber}
                DefaultCountryCode={this.state.defaultCountryCode}
                StoreText={this.state.storeText}
                DisableGroup={this.state.disabled}
                UsedCredits={this.state.usedCredits}
                CreditLimit={this.state.creditLimit}
                UniqueNumberCost={this.state.uniqueNumberCost}
                RegisterationDate={this.state.registerationDate}
                setStoreText={(value: any) => {
                  this.props.setStorText(value);
                }}
                setDisable={(value: any) => {
                  this.props.setDisable(value);
                }}
                setCreditLimit={(value: any) => {
                  this.props.setCreditLimit(value);
                }}
                setUniqueNumberCost={(value: any) => {
                  this.props.setUniqueNumberCost(value);
                }}
                setDefaultCountryCode={(value: any) => {
                  this.props.setDefaultCountryCode(value);
                }}
                setEmail={(value: any) => {
                  this.props.setEmail(value);
                }}
                setName={(value: any) => {
                  this.props.setName(value);
                }}
                setRoleId={(value: any) => {
                  this.props.setRoleId(value);
                }}
                setSubscriptionId={(value: any) => {
                  this.props.setSubscriptionId(value);
                }}
                setSubscriptionName={(value: any) => {
                  this.props.setSubscritpionName(value);
                }}
                setRegisterationDate={(value: any) =>
                  this.props.setRegisterationDate(value)
                }
                setUsedCredits={(value: any) =>
                  this.props.setUsedCredits(value)
                }
                setUniqueNumber={(value: any) =>
                  this.props.uniqueNumberProps(value)
                }
                setEmailEnabled={(value: any) =>
                  this.props.setEmaillEnabledProps(value)
                }
                emailEnabledForGroup={this.state.emailEnabled}
                removeNumberFromTwillio={(value: any) => {
                  var data = [
                    {
                      PhoneNumber: value,
                    },
                  ];
                  this.props.releaseNumberFromDatatabse(data);
                }}
                unAssignedNumbersprops={this.state.unAssignedNumbers}
                addGroupCalledFromCompanyTab={(value: any) => {
                  this.props.emptyUnAssignedNumbers([]);
                  this.props.groupAddCalledFromCompanyTab(value);
                }}
                setCurrentActiveTab={(value: any) =>
                  this.props.setCurrentActiveTabName(value)
                }
                searchText={this.state.searchTextBox}
                getUnAssignedNumbers={(
                  smsServieType: any,
                  subscriptionId: any
                ) => {
                  this.props.getUnAssignPhoneNumber(
                    smsServieType,
                    subscriptionId,
                    this.props.companyId
                  );
                }}
                setSearchBoxValue={(value: any) =>
                  this.props.setSearchBoxValue(value)
                }
                activeIdProp={this.state.rowActiveId}
                rowActiveIdCompanyProps={(id: any) =>
                  this.props.rowActiveIdGroups(id)
                }
                updateUserEmailNotifications={(obj: any) => {
                  this.props.updateUserEmailNotifications(obj);
                }}
              />
            ) : this.state.showGroupNonMember === true ? (
              <GroupNonMemberComponent
                ActiveTab={this.state.currentActiveGroupTabName}
                companyId={
                  this.props.editCompanyDetail !== undefined
                    ? this.props.editCompanyDetail.Id
                    : ""
                }
                pendingGroupMemberListByEmail={
                  this.state.pendingGroupMemberListState
                }
                groupMemberList={this.state.groupMemberListEmail}
                getGroupNotMemberListGroupMemeberComp={
                  this.state.groupNonMemberList
                }
                GroupEmail={this.state.selectedGroupEmail}
                chnageIconName={(name: any) => {
                  this.props.showIcons(name);
                }}
                selectedGroupEmailName={this.state.selectwedGropMail}
                actualGroupMemberData={this.state.actualGroupMemberList}
                actualGroupNonMemberData={this.state.actualGroupNonMemberList}
                iconToBeDisplayedGroup={this.state.iconNameToBeDisplayed}
                rightIconClick={(
                  filteredMemberList: any,
                  groupMemberist: any,
                  notGroupMember: any,
                  getGroupMemberListActual: any
                ) =>
                  this.unAssignMembersFromGroups(
                    filteredMemberList,
                    groupMemberist,
                    notGroupMember,
                    getGroupMemberListActual
                  )
                }
                leftIconClick={(
                  filteredMemberList: any,
                  groupMemberist: any,
                  notGroupMember: any,
                  groupNonMemberList: any
                ) =>
                  this.assignMembersToGroups(
                    filteredMemberList,
                    groupMemberist,
                    notGroupMember,
                    groupNonMemberList
                  )
                }
                addUserInGroup={(list: any, email: any) => {
                  this.props.addUserInGroup(list, email);
                }}
                unAssignUserFromGroup={(list: any, email: any) => {
                  this.props.unAssignUserFromGroup(list, email);
                }}
                changeState={() => {
                  this.props.hideGroupListProps(false);
                  this.props.dispatchNonGroupMemberTab(false);
                  this.props.hideCompanyTabDispatch(true);
                  this.props.hideGroupNameHeading(true);
                }}
                //GroupDetails Tab
                subscriptionList={this.state.subscriptionList}
                addUpdateUserProp={(type: any, data: any, compId: any) => {
                  this.props.addUpdateUserProp(
                    type,
                    data,
                    this.state.companyId
                  );
                }}
                updateUserEmailNotifications={(obj: any) => {
                  this.props.updateUserEmailNotifications(obj);
                }}
                editData={this.state.editGroup}

                deleteGroupByEmail={(email: any, companyId: any,confirm:boolean) => {                
                   this.props.deleteGroupsByEmail(email, companyId,confirm);
                }}
                groupMemberListFilter={(list: any) => {
                  this.props.dispatchGetGroupMemberListFilter(list);
                }}
                groupNonMemberListFilter={(list: any) => {
                  this.props.dispatchGetGroupNonMemberListFilter(list);
                }}
                pendingNonGroupList={this.state.pendingNonGroupMembers}
                pendingGroupList={this.state.pendingGroupMembers}
                SubscriptionId={this.state.subscriptionId}
                SubscriptionName={this.state.subscriptionName}
                RoleId={this.state.roleId}
                Email={this.state.email}
                Name={this.state.name}
                uniqueNumberGroupAdd={this.state.uniquNumber}
                DefaultCountryCode={this.state.defaultCountryCode}
                StoreText={this.state.storeText}
                DisableGroup={this.state.disabled}
                UsedCredits={this.state.usedCredits}
                CreditLimit={this.state.creditLimit}
                UniqueNumberCost={this.state.uniqueNumberCost}
                RegisterationDate={this.state.registerationDate}
                setStoreText={(value: any) => {
                  this.props.setStorText(value);
                }}
                setDisable={(value: any) => {
                  this.props.setDisable(value);
                }}
                setCreditLimit={(value: any) => {
                  this.props.setCreditLimit(value);
                }}
                setUniqueNumberCost={(value: any) => {
                  this.props.setUniqueNumberCost(value);
                }}
                setDefaultCountryCode={(value: any) => {
                  this.props.setDefaultCountryCode(value);
                }}
                setEmail={(value: any) => {
                  this.props.setEmail(value);
                }}
                setName={(value: any) => {
                  this.props.setName(value);
                }}
                setEmailEnabled={(value: any) =>
                  this.props.setEmaillEnabledProps(value)
                }
                emailEnabledForGroup={this.state.emailEnabled}
                setRoleId={(value: any) => {
                  this.props.setRoleId(value);
                }}
                setSubscriptionId={(value: any) => {
                  this.props.setSubscriptionId(value);
                }}
                setSubscriptionName={(value: any) => {
                  this.props.setSubscritpionName(value);
                }}
                setRegisterationDate={(value: any) =>
                  this.props.setRegisterationDate(value)
                }
                setUsedCredits={(value: any) =>
                  this.props.setUsedCredits(value)
                }
                setUniqueNumber={(value: any) =>
                  this.props.uniqueNumberProps(value)
                }
                setAtiveTabName={(name: any) => {
                  this.props.setCurrentActiveTabName(name);
                }}
                releaseNumber={(value: any) => {
                  var data = [
                    {
                      PhoneNumber: value,
                    },
                  ];
                  this.props.releaseNumberFromDatatabse(data);
                }}
                loggedInUserRole={this.props.loggedinUserRole}
                unAssignPhoneNumberProps={this.state.unAssignedNumbers}
                getUnAssignedNumbers={(
                  smsServieType: any,
                  subscriptionId: any
                ) => {
                  this.props.getUnAssignPhoneNumber(
                    smsServieType,
                    subscriptionId,
                    this.props.companyId
                  );
                }}
              />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    showGroupList: props.characterReducer.listShowComponentGroupREduc,
    errorOccured: props.characterReducer.errorListMessage,
    actualGroupList: props.characterReducer.groupListFilterReducer,
    pendingGroupListBySubIdAndCompIdAction:
      props.characterReducer.pendingGroupListBySubIdAndCompIdReducer,
    groupListByCompAndSubIdAction:
      props.characterReducer.groupListByCompAndSubIdReducer,
    pendingGroupMemberList:
      props.characterReducer.pendingGroupMemberListByEmailReducer,
    filteredGroupMemberList:
      props.characterReducer.filteredGroupMemberListReducer,
    iconName: props.characterReducer.iconNameReducer,
    filteredGroupNonMemberList:
      props.characterReducer.filteredNonGroupMemberListReducer,
    groupMemberListByEmail: props.characterReducer.groupMemberListReducer,
    subscriptionList: props.characterReducer.subscriptionList,
    selectedGroupName: props.characterReducer.selectedGroupNameReducer,
    hideNonGroupMember: props.characterReducer.hideNonGroupMember,
    editGroupObj: props.characterReducer.groupEditObjectReducer,
    actualGroupNotMemberList: props.characterReducer.groupNotMemberListReducer,
    hideGroupEmailHeadingCompo: props.characterReducer.hideGroupEmailHeading,
    selectedGroupEmailValue: props.characterReducer.selectedGroupEmailReducer,
    pendingGroupNonMemberValue:
      props.characterReducer.pendingnotGroupNotMemberListByEmailReducer,
    pendingGroupMemberValue:
      props.characterReducer.pendingGroupMemberListByEmailReducer,
    uniquNumberForGroupAdd: props.characterReducer.showUniqueNumberReducer,
    DefaultCountryCode: props.characterReducer.defaultCountryCode,
    storeTextProps: props.characterReducer.storeTextForAddEditUser,
    disableProps: props.characterReducer.disabledForAddEditUser,
    CreditLimit: props.characterReducer.creditLimitForAddEditUser,
    UniqueNumberCost: props.characterReducer.uniqueNumbetCostForAddEditUser,
    UsedCredit: props.characterReducer.usedCredit,
    RegisterationDate: props.characterReducer.registerationDate,
    Email: props.characterReducer.emailForAddEditUser,
    Name: props.characterReducer.nameForAddEditUser,
    RoleId: props.characterReducer.roleIdForAddEditUser,
    SubscriptionId: props.characterReducer.subscriptionID,
    SubscriptionName: props.characterReducer.subscriptionNameForAddEditUser,
    CurrentActiveGroupTabName: props.characterReducer.currentActiveGroupTabName,
    editCompanyDetail: props.characterReducer.savedCompanyDetailReducer,
    loggedinUserRole: props.characterReducer.loggedinUserAccessLevel,
    unAssignPhoneNumberList: props.characterReducer.unAssignPhoneNumberReducer,
    groupAddCalledReducer:
      props.characterReducer.groupAddCalledFromCompanyTabReducer,
    searchTextBox_Text: props.characterReducer.searchTextBoxValue_GroupReducer,
    activeId: props.characterReducer.rowActiveId_GroupsReducer,
    emailEnabledForUser: props.characterReducer.emailEnabledReducer,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    rowActiveIdGroups: (id: any) => {
      dispatch(action.rowActiveId_Groups(id));
    },
    hideGroupNameHeading: (value: any) => {
      dispatch<any>(action.hideGroupEmailHeading(value));
    },
    dispatchGetGroupMemberListFilter: (list: any) => {
      dispatch<any>(action.getGroupMemberListFilter(list));
    },
    setEmaillEnabledProps: (value: any) => {
      dispatch<any>(action.EmailEnabledForUser(value));
    },
    dispatchGetGroupNonMemberListFilter: (list: any) => {
      dispatch<any>(action.getGroupMemberNotListFilter(list));
    },
    dispatchNonGroupMemberTab: (value: any) => {
      dispatch<any>(action.hideGroupNonMemberTab(value));
    },
    groupObject: (obj: any) => {
      dispatch<any>(action.groupEditObject(obj));
    },
    dispatchPendingGroupList: (value: any) => {
      dispatch<any>(action.pendingGroupListBySubIdAndCompID(value));
    },
    filterGroupByCompId: (compId: any) => {
      dispatch<any>(
        action.getGroupListByCompanyAndSubscriptionId(null, compId, "")
      );
    },
    selectedGroupEmailPropsAction: (email: any) => {
      dispatch<any>(action.selectedGroupEmail(email));
    },
    selectedGroupNameProps: (value: any) => {
      dispatch<any>(action.displaySelectedGroupName(value));
    },
    hideGroupListProps: (value: any) => {
      dispatch<any>(action.showHideListGroups(value));
    },
    dispatchGetGroupListFilter: (value: any) => {
      dispatch<any>(action.getGroupListFilter(value));
    },
    getGroupMembersByGroupEmailAddress: (email: any) => {
      dispatch<any>(action.getGroupMemberListByEmail(email));
    },
    pendingGroupMembeList: (value: any) => {
      dispatch<any>(action.pendingGroupMemberListByEmail(value));
    },
    showIcons: (value: any) => {
      dispatch<any>(action.showIcnOnGroupUserTab(value));
    },
    getGroupNotMemberList: (value: any) => {
      dispatch<any>(action.getGroupNotMemberListByEmail(value));
    },
    hideCompanyTabDispatch: (value: any) => {
      dispatch<any>(action.hideCompanyTab(value));
    },
    dispatchgetGroupMemberListAction: (value: any) => {
      dispatch<any>(action.getGroupMemberListAction(value));
    },
    dispatchgetNotGroupMemberListAction: (value: any) => {
      dispatch<any>(action.getNotGroupMemberListAction(value));
    },
    addUserInGroup: (list: any, email: any) => {
      dispatch(action.addUsernGroupByEmail(list, email));
    },
    getSubscriptionList: (compId: any) => {
      dispatch(action.getSubscriptionList(compId, ""));
    },
    addUpdateUserProp: (type: any, data: any, compId: any) => {
      dispatch(action.addUpdateUsers(type, data, compId, "addGroup"));
    },
    updateUserEmailNotifications: (emailObj: any) => {
      dispatch(action.updateUserEmailNotifications(emailObj));
    },
    dispatchActiveTabName: (value: any) => {
      dispatch(action.displayActiveTabName(value));
    },

     deleteGroupsByEmail: (email: string, compId: any,confirm:boolean) => {   
      dispatch(action.deleteAllUserDataByEmail(email, compId, "GroupDelete",confirm));
    },
    unAssignUserFromGroup: (list: any, email: any) => {
      dispatch(action.unassignMemberFromGroup(list, email));
    },
    setStorText: (value: any) => {
      dispatch(action.storeTextForUser(value));
    },
    setDisable: (value: any) => {
      dispatch(action.disabledForUser(value));
    },
    setCreditLimit: (value: any) => {
      dispatch(action.creditLimitForUser(value));
    },
    setUniqueNumberCost: (value: any) => {
      dispatch(action.uniqueNumberCostForUser(value));
    },
    setDefaultCountryCode: (value: any) => {
      dispatch(action.defaultCountryCodeForUser(value));
    },
    setEmail: (value: any) => {
      dispatch(action.emailForUser(value));
    },
    setName: (value: any) => {
      dispatch(action.nameForUser(value));
    },
    setRoleId: (value: any) => {
      dispatch(action.roleIdForUser(value));
    },
    setSubscriptionId: (value: any) => {
      dispatch(action.subscriptionIdForUser(value));
    },
    setSubscritpionName: (value: any) => {
      dispatch(action.subscriptionNameForUser(value));
    },
    setRegisterationDate: (value: any) => {
      dispatch(action.registerationDateForUser(value));
    },
    setUsedCredits: (value: any) => {
      dispatch(action.usedCreditForUser(value));
    },
    uniqueNumberProps: (value: any) => {
      dispatch(action.showNumberInUniqueNumber(value));
    },
    setCurrentActiveTabName: (value: any,companyId:any) => {      
      dispatch(action.currentActiveGroupTabName(value));
    },
    hideGroupTabFromCompanyDetail: (value: any) => {
      dispatch(action.hideGroupTabFromCompanyDetail(value));
    },
    releaseNumberFromDatatabse: (value: any, callingFrom: any) => {
      dispatch(action.getNumberInfoFromTwillio(value, "All", callingFrom));
    },
    getUnAssignPhoneNumber: (
      smsServiceType: any,
      subscriptionId: any,
      companyId: any
    ) => {    
      dispatch<any>(
        action.unAssignedPhoneNumbers(smsServiceType, subscriptionId, companyId)
      );
    },
    groupAddCalledFromCompanyTab: (value: any) => {
      dispatch<any>(action.groupsAddCalledFromCompanyTab(value));
    },
    setSearchBoxValue: (value: any) => {
      dispatch<any>(action.searchBox_Value_In_Groups(value));
    },
    emptyUnAssignedNumbers: (data: any) => {
      dispatch<any>(action.unassignPhoneNumberList(data));
    },
    removeNumberFromYakchat: (
      data: any,
      searchBy: any,
      callingFrom: string
    ) => {
      dispatch<any>(
        action.deleteNumberByNumberModel(data, searchBy, callingFrom)
      );
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupsParentComponent);
