import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { authContext } from "../../adalConfig";
// Create the containers interface
class IdleComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {};
  }

  componentDidMount() { this.props.history.push("/") }

  public render() {
    return (
      <React.Fragment>
        <div className="frontend">
          <div className="timeOutSec">
            <div className="center_part">
              <svg
                id="Logo_Error"
                data-name="Logo / Error"
                xmlns="http://www.w3.org/2000/svg"
                width="179"
                height="179"
                viewBox="0 0 179 179"
              >
                <g
                  id="Group_359"
                  data-name="Group 359"
                  transform="translate(-0.051 -0.051)"
                >
                  <g id="Group_329" data-name="Group 329">
                    <g id="Group_815" data-name="Group 815">
                      <g id="Group_327" data-name="Group 327">
                        <g id="Group_319" data-name="Group 319">
                          <circle
                            id="Ellipse_415"
                            data-name="Ellipse 415"
                            cx="89.5"
                            cy="89.5"
                            r="89.5"
                            transform="translate(0.051 0.051)"
                            fill="#fcbf00"
                          />
                        </g>
                        <g
                          id="Group_325"
                          data-name="Group 325"
                          transform="translate(22.31 53.912)"
                        >
                          <g id="Group_324" data-name="Group 324">
                            <path
                              id="Path_429"
                              data-name="Path 429"
                              d="M392.321,28.708a.334.334,0,0,0-.652-.116c-3,8.814-8.865,10.05-10.646,10.226a10.081,10.081,0,0,0-9.874-8.053H344.136a10.078,10.078,0,0,0-10.078,10.078L338.5,78.465c.639,5.553,4.512,10.078,10.078,10.078H368.2a10.078,10.078,0,0,0,10.078-10.078l2.15-27.412C390.355,48.71,392.048,34.516,392.321,28.708Z"
                              transform="translate(-320.057 -22.735)"
                              fill="#fff"
                            />
                            <path
                              id="Path_430"
                              data-name="Path 430"
                              d="M444.976,45.166c-9.631,3.42-17.416-4.343-19.757-7.042V35.516A16.318,16.318,0,0,0,408.9,19.2H356.3a16.249,16.249,0,0,0-10.293,3.657H335.333a14.473,14.473,0,0,0-13.634,9.8c-1.688-.193-6.861-1.493-9.86-10.121a.3.3,0,0,0-.583.123c.521,5.7,2.571,18.846,10.417,21.587l3.652,30.961c.666,7.737,6.62,14.169,14.447,14.169h2.639a16.306,16.306,0,0,0,13.89,7.754h52.6a16.319,16.319,0,0,0,16.319-16.319V50.389c10.764,4.656,17.639-1.049,20.438-4.254A.612.612,0,0,0,444.976,45.166Zm-73.352,2.35-2.15,27.412A10.078,10.078,0,0,1,359.4,85H339.77c-5.566,0-9.439-4.524-10.078-10.078L325.255,37.3a10.078,10.078,0,0,1,10.078-10.078h27.014a10.081,10.081,0,0,1,9.874,8.053c1.781-.176,7.644-1.412,10.646-10.226a.334.334,0,0,1,.652.116C383.244,30.978,381.552,45.172,371.624,47.515Z"
                              transform="translate(-311.254 -19.197)"
                            />
                            <g
                              id="Group_816"
                              data-name="Group 816"
                              transform="translate(43.655 26.338)"
                            >
                              <path
                                id="Path_485"
                                data-name="Path 485"
                                d="M0,6.533,6.533,0"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-width="4"
                              />
                              <path
                                id="Path_486"
                                data-name="Path 486"
                                d="M6.533,6.533,0,0"
                                fill="none"
                                stroke="#000"
                                stroke-linecap="round"
                                stroke-width="4"
                              />
                            </g>
                            <path
                              id="Path_487"
                              data-name="Path 487"
                              d="M0,6.533,6.533,0"
                              transform="translate(24.688 26.338)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-width="4"
                            />
                            <path
                              id="Path_488"
                              data-name="Path 488"
                              d="M6.533,6.533,0,0"
                              transform="translate(24.688 26.338)"
                              fill="none"
                              stroke="#000"
                              stroke-linecap="round"
                              stroke-width="4"
                            />
                          </g>
                        </g>
                      </g>
                      <g
                        id="Group_328"
                        data-name="Group 328"
                        transform="translate(127.819 10.293)"
                      >
                        <path
                          id="Path_263"
                          data-name="Path 263"
                          d="M19.539,0A19.539,19.539,0,1,1,0,19.539,19.539,19.539,0,0,1,19.539,0Z"
                          fill="#c73a3a"
                        />
                        <path
                          id="Path_431"
                          data-name="Path 431"
                          d="M27.276,26.817,17,9.107a2.225,2.225,0,0,0-3.848,0L2.871,26.817A2.214,2.214,0,0,0,4.8,30.138h20.61a2.224,2.224,0,0,0,1.924-1.107A2.154,2.154,0,0,0,27.276,26.817Zm-12.2-13.23a1.588,1.588,0,0,1,1.581,1.66l-.316,6.114a1.266,1.266,0,0,1-2.53,0l-.316-6.114A1.572,1.572,0,0,1,15.074,13.587Zm0,13.467a1.66,1.66,0,1,1,1.66-1.66A1.653,1.653,0,0,1,15.074,27.055Z"
                          transform="translate(4.298 -1.086)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <div className="timeOutBox">
                <div className="inner_box">
                  <h1>Your Session has Expired</h1>
                  <p className="subheading">Please sign in to your account.</p>
                  <div className="btnDiv">
                    <button
                      onClick={() => authContext.login()}
                      className="btn signInBtn"
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {};
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(IdleComponent);
