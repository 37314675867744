import * as React from "react";
import * as commonMethods from "../../commonMethods";
import { DataTableComponent } from "../sharedComponents/dataTableComponent";
import { GroupAddComponent } from "./groupAddComponent";
export class GroupListComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      pendingList: true,
      groupListData: [],
      actualGroupList: [],
      GroupMemberList: [],
      selectedEmail: this.props.selecteGroupName,
      pendingMemberList: false,
      showAddGroupComponent: false,
      subscriptionListData: [],
      hideGroupsData: true,
      editObject: {},
      calledFrom: "add",
      selectedEmailForNonGroupMember: "",
      uniqueNumber: "",
      defaultCountryCode: "",
      storeText: true,
      disable: false,
      creditLimit: "",
      uniqueNumberCost: "",
      usedCredits: "",
      registerationDate: "",
      email: "",
      name: "",
      roleId: "",
      subscriptionId: "",
      subscriptionName: "",
      unAssignedNumbers: [],
      searchTextBox: "",
      activeId: "",
      emailEnabled: false,
    };
  }
  componentDidMount() {
    if (this.props.showAddGroup === true) {
      this.props.showGroupListTab(true);
    } else {
      this.props.showGroupListTab(false);
    }

    //console.log(this.props.GroupMemberList);
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingGropList !== prevState.pendingList ||
      nextProps.groupList !== prevState.groupListData ||
      nextProps.actualDataProp !== prevState.actualGroupList ||
      nextProps.groupMemberList !== prevState.GroupMemberList ||
      nextProps.pendingGroupMemberListByEmail !== prevState.pendingMemberList ||
      nextProps.subscriptionList !== prevState.subscriptionListData ||
      nextProps.hideGroupList !== prevState.hideGroupsData ||
      nextProps.selecteGroupName !== prevState.selectedEmail ||
      nextProps.editData !== prevState.editObject ||
      nextProps.uniqueNumberGroupAdd !== prevState.uniqueNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      nextProps.StoreText !== prevState.storeText ||
      nextProps.DisableGroup !== prevState.disable ||
      nextProps.CreditLimit !== prevState.creditLimit ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      nextProps.UsedCredits !== prevState.usedCredits ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.Name !== prevState.name ||
      nextProps.RoleId !== prevState.roleId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.unAssignedNumbersprops !== prevState.unAssignedNumbers ||
      nextProps.searchText !== prevState.searchTextBox ||
      nextProps.activeIdProp !== prevState.activeId ||
      nextProps.emailEnabledForGroup !== prevState.emailEnabled
    ) {
      return {
        searchTextBox: nextProps.searchText,
        roleId: nextProps.RoleId,
        email: nextProps.Email,
        name: nextProps.Name,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        disable: nextProps.DisableGroup,
        uniqueNumber: nextProps.uniqueNumberGroupAdd,
        editObject: nextProps.editData,
        selectedEmail: nextProps.selecteGroupName,
        hideGroupsData: nextProps.hideGroupList,
        GroupMemberList: nextProps.groupMemberList,
        pendingMemberList: nextProps.pendingGroupMemberListByEmail,
        actualGroupList: nextProps.actualDataProp,
        pendingList: nextProps.pendingGropList,
        groupListData: nextProps.groupList,
        subscriptionListData: nextProps.subscriptionList,
        defaultCountryCode: nextProps.DefaultCountryCode,
        storeText: nextProps.StoreText,
        creditLimit: nextProps.CreditLimit,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        usedCredits: nextProps.UsedCredits,
        registerationDate: nextProps.RegisterationDate,
        unAssignedNumbers: nextProps.unAssignedNumbersprops,
        activeId: nextProps.activeIdProp,
        emailEnabled: nextProps.emailEnabledForGroup,
      };
    } else return null;
  }
  renderGroupMemberData() {
    return this.state.GroupMemberList.map((groupMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: groupMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                groupMeber.UserName !== ""
                  ? groupMeber.UserName
                  : groupMeber.Email
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{groupMeber.UserName}</h5>
            <p>{groupMeber.Email}</p>
          </div>
        </div>
      );
    });
  }
  //For Edt Goup
  setValueInObject = (object: any) => {
    var data = this.state.subscriptionListData
      .filter((opt: any) => opt.subscription.Id === object.SubscriptionId)
      .map((ele: any) => ele.subscription.Name)[0];
    this.props.setEmail(object.Email);
    this.props.setName(object.UserName);
    this.props.setSubscriptionId(object.SubscriptionId);
    this.props.setCreditLimit(object.CreditLimit);
    this.props.setUniqueNumberCost(object.UniqueNumberCost);
    this.props.setRoleId(object.Role);
    this.props.setStoreText(object.StoreText);
    this.props.setRegisterationDate(object.RegistrationDate);
    this.props.setDefaultCountryCode(object.DefaultCountryCode);
    this.props.setSubscriptionName(data);
    this.props.setUniqueNumber(object.UniqueNumber);
    this.props.setUsedCredits(object.UsedCredit);
    this.props.setDisable(object.Disabled);
    this.props.setEmailEnabled(object.EmailEnabled);
  };
  emptyValueInObject = () => {
    this.props.setEmail("");
    this.props.setName("");
    this.props.setSubscriptionId("");
    this.props.setCreditLimit("");
    this.props.setUniqueNumberCost("");
    this.props.setRoleId(20);
    this.props.setStoreText("");
    this.props.setRegisterationDate("");
    this.props.setDefaultCountryCode(1);
    this.props.setSubscriptionName("");
    this.props.setUniqueNumber("");
    this.props.setUsedCredits("");
    this.props.setEmailEnabled(false);
  };
  public render() {
    return (
      <React.Fragment>
        {this.state.pendingList === true ? (
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div>
            {this.state.hideGroupsData !== true ? (
              <div
                className={
                  this.props.companyId === undefined ||
                  this.props.companyId === ""
                    ? this.state.groupListData !== undefined
                      ? this.state.groupListData.length === 0
                        ? "groupGroupMemberDiv groupName increaseWidth  groupTableForMAinPAge"
                        : "groupGroupMemberDiv groupName   groupTableForMAinPAge"
                      : "groupGroupMemberDiv groupName groupMemberSearch groupTableForMAinPAge"
                    : this.state.groupListData !== undefined
                    ? this.state.groupListData.length === 0
                      ? "groupGroupMemberDiv groupName increaseWidth tabTableHeight"
                      : "groupGroupMemberDiv groupName  tabTableHeight"
                    : "groupGroupMemberDiv groupName tabTableHeight"
                }
              >
                <div className="positionRelative paddTop56">
                  <div
                    className={
                      this.state.groupListData !== undefined
                        ? this.state.groupListData.length === 0
                          ? "card-datatable table-responsive"
                          : "showGroupMemberTable card-datatable table-responsive"
                        : "card-datatable table-responsive"
                    }
                  >
                    <DataTableComponent
                      filteredByTxtBoxSearch={(newList: any, type: any) => {
                        this.props.filterationByTxt(newList, "GroupList");
                      }}
                      actualData={this.state.actualGroupList}
                      listCalled={"GroupList"}
                      columnName={[
                        "Name",
                        "Email",
                        "Number",
                        "Country Code",
                        "Store Text",
                        "Disabled",
                      ]}
                      rowsList={this.state.groupListData}
                      calledFrom={"GroupList"}
                      showGroupMemeber={(email: any, object: any) => {
                        this.setState({
                          selectedEmailForNonGroupMember: object.Email,
                        });
                        this.setValueInObject(object);
                        this.props.setEditObject(object);
                        this.props.selectedEmailName(email);
                        this.props.selectedGroupEmailName(object.Email);
                        this.props.pendingGroupMemberMethod(true);
                        this.props.getGroupMembersByGroupEmail(object.Email);
                      }}
                      searchFunctionality={(value: any, type: any) => {
                        this.props.searchFunction(value);
                      }}
                      showHideListProp={() => {
                        this.props.hideGroupTabFromCompanyDetail(true);
                        this.emptyValueInObject();
                        this.props.showGroupListTab(true);
                        this.props.getSubscriptionList();
                        this.props.getUnAssignedNumbers(null, null, "");
                        if (this.props.companyId !== undefined) {
                          this.props.addGroupCalledFromCompanyTab(true);
                        } else {
                          this.props.addGroupCalledFromCompanyTab(false);
                        }
                      }}
                      arrowIconClick={(email: any) => {
                        this.props.showGroupNonMemberTabComp(
                          email,
                          "arrowClick"
                        );
                      }}
                      searchBoxText={this.state.searchTextBox}
                      setSearchBoxText={(value: any) =>
                        this.props.setSearchBoxValue(value)
                      }
                      rowActiveIdCompany_Props={(id: any) => {
                        this.props.rowActiveIdCompanyProps(id);
                      }}
                      activeCompanyId={this.state.activeId}
                    />
                  </div>
                </div>
                {this.props.pendingGroupMemberList === true ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : this.state.GroupMemberList !== undefined ? (
                  this.state.groupListData.length === 0 ? null : (
                    <div className="teamBox radiusRight">
                      <div className="media align-items-center saleTeam">
                        <div className="media_left d-flex justify-content-center">
                          <span className="align-self-center">
                            {commonMethods.generateFirstLastNameIntials(
                              this.state.selectedEmail
                            )}
                          </span>
                        </div>
                        <div className="media-body">
                          <h5>{this.state.selectedEmail}</h5>
                          <p>{this.state.GroupMemberList.length} members</p>
                        </div>
                        <div className="media_right">
                          <span
                            className="align-self-center cursor_pointer"
                            onClick={() => {
                              this.props.showGroupNonMemberTabComp(
                                this.state.selectedEmailForNonGroupMember,
                                ""
                              );
                            }}
                          >
                            <span className="font-icon">&#122;</span>
                          </span>
                        </div>
                      </div>
                      {this.state.GroupMemberList.length === 0 ? (
                        <div className="media-body">
                          <div className="noUser_found">
                            No group Members found
                          </div>
                        </div>
                      ) : (
                        <div
                          className={
                            this.props.companyId !== undefined
                              ? "memberBox groupMemberBox"
                              : "memberBox"
                          }
                        >
                          {this.renderGroupMemberData()}
                        </div>
                      )}
                    </div>
                  )
                ) : (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            ) : (
              <GroupAddComponent
                roleOfLoggedInUser={this.props.loggedInUserRole}
                uniquePhoneNumber={this.state.uniqueNumber}
                SubscriptionId={this.state.subscriptionId}
                SubscriptionName={this.state.subscriptionName}
                defaultCountryCodeForAddEditUserPRops={
                  this.state.defaultCountryCode
                }
                StoreText={this.state.storeText}
                DisableGroup={this.state.disable}
                creditLimitForAddEditUserProp={this.state.creditLimit}
                UniqueNumberCost={this.state.uniqueNumberCost}
                UsedCredits={this.state.usedCredits}
                RegisterationDate={this.state.registerationDate}
                Email={this.state.email}
                Name={this.state.name}
                RoleId={this.state.roleId}
                storeTextForAddEdit={(value: any) => {
                  this.props.setStoreText(value);
                }}
                emailEnabledProps={this.state.emailEnabled}
                disableForAddEdit={(value: any) => {
                  this.props.setDisable(value);
                }}
                setCreditLimit={(value: any) => {
                  this.props.setCreditLimit(value);
                }}
                setUniqueNumberCost={(value: any) => {
                  this.props.setUniqueNumberCost(value);
                }}
                setDefaultCountryCode={(value: any) => {
                  this.props.setDefaultCountryCode(value);
                }}
                setEmail={(value: any) => {
                  this.props.setEmail(value);
                }}
                setName={(value: any) => {
                  this.props.setName(value);
                }}
                setRoleId={(value: any) => {
                  this.props.setRoleId(value);
                }}
                setSubscriptionId={(value: any) => {
                  this.props.setSubscriptionId(value);
                }}
                setSubscriptionName={(value: any) => {
                  this.props.setSubscriptionName(value);
                }}
                companyId={this.props.companyId}
                subscriptionList={this.state.subscriptionListData}
                changeState={() => {
                  this.props.hideGroupTabFromCompanyDetail(false);
                  this.props.showGroupListTab(false);
                  this.props.addGroupCalledFromCompanyTab(false);
                }}
                calledFrom={"add"}
                editData={this.state.editObject}
                addUpdateUserProp={(type: any, data: any) =>
                  this.props.addUpdateUserProp(type, data, null)
                }
                releaseNumberFromApi={(value: any) => {
                  this.props.removeNumberFromTwillio(value);
                }}
                unAssignPhoneNumberProps={this.state.unAssignedNumbers}
                setUniqueNumber={(value: any) => {
                  this.props.setUniqueNumber(value);
                }}
                getUnAssignedNumbers_groups={(
                  smsServieType: any,
                  subscriptionId: any
                ) => {
                  this.props.getUnAssignedNumbers(
                    smsServieType,
                    subscriptionId
                  );
                }}
                EmailEnabledForAddEdit={(value: any) => {
                  this.props.setEmailEnabled(value);
                }}
                updateUserEmailNotifications={(obj: any) =>
                  this.props.updateUserEmailNotifications(obj)
                }
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}
