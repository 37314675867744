import * as React from 'react';
export default class FooterComponent extends React.Component {
    // constructor(props: Readonly<{}>) {
    //     super(props)
      
    // }
   public render() {
      return (
            <React.Fragment>
              <nav className="layout-footer footer bg-footer-theme">
  <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
    <div className="pt-3">
      <span className="footer-text font-weight-bolder">YakChat Ltd </span> © 2020
    </div>
    <div>
      <a href="https://www.yakchat.com/yakchat-aboutus/" className="footer-link pt-3">About Us</a>
      <a href="https://www.yakchat.com/help-center/" className="footer-link pt-3 ml-4">Help</a>
      <a href="https://www.yakchat.com/yakchat-contact/" className="footer-link pt-3 ml-4">Contact</a>
      <a href="https://www.yakchat.com/terms-of-service/" className="footer-link pt-3 ml-4">Terms &amp; Conditions</a>
    </div>
  </div>
</nav>
            </React.Fragment>
        );
    }
};


