
import * as React from 'react';
import logo from "../../image/YakChatLogo_Horizantal.svg";
import callTowerLogo from "../../image/CallTower_192x192_Full_Color.png";
import { authContext, getLoggedInUSerName } from '../../adalConfig';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import * as action from '../../redux/actions/actions'
import history from '../../history';
import * as commonMethod from '../../commonMethods';
class HeaderComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props)
        this.state = {
            loggedInUserName: '',
            buttonClick: false,
            intialsLetter: '',
            WhiteLabelBrand: ''
        }

    }
    componentDidMount() {
        var userData = getLoggedInUSerName();
        if (userData !== null) {
            if (userData.profile !== null) {
                var fullName = userData.profile.name;
                var getIntials = commonMethod.generateFirstLastNameIntials(fullName);
                var userName = userData.userName;
                this.setState({
                    loggedInUserName: userName,
                    intialsLetter: getIntials
                })
            }
        }


    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.WhiteLabelBrandProps !== prevState.WhiteLabelBrand || 
            nextProps.loaderValueHeaderProps !== prevState.loaderValueHeader) {
            document.body.className = nextProps.WhiteLabelBrandProps === "calltower" ? 'callTower-theme' : 'light-theme';
            return {
                WhiteLabelBrand: nextProps.WhiteLabelBrandProps,
                toggleClassProps: nextProps.toggleClassProps,
                loaderValueHeader:nextProps.loaderValueHeaderProps
            }
        }
        return null;
    }

    public render() {
        return (
            <React.Fragment>
                <nav className="layout-navbar navbar navbar-expand-lg align-items-lg-center bg-navbar-theme" id="layout-navbar">
                  {
                      this.props.loaderValueHeaderProps === undefined ?
                      <div className="spinner-border headerSpinner" role="status">
                      <span className="sr-only">Loading...</span>
                  </div> 
                  :
null                  }
                    <button className={this.state.buttonClick === true ? "sideNav_toggler toggle" : "sideNav_toggler"} type="button"
                        onClick={() => {
                            this.props.toggleClass(!this.props.toggleClassProps)
                        }}
                    >
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                        <span className="toggler-icon"></span>
                    </button>
                    <a href="index.html" className="navbar-brand app-brand demo py-0 mr-4 yakChatLogo">
                        {
                            this.state.WhiteLabelBrand === "calltower" ?
                                <img src={callTowerLogo} alt="logo" height="50px" width="50px"/>
                                :
                                <img src={logo} alt="logo" />
                        }
                    </a>
                    <button className={"navbar-toggler"} type="button"
                        data-toggle="collapse" data-target="!#layout-navbar-collapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="navbar-collapse collapse" id="layout-navbar-collapse">
                        <hr className="d-lg-none w-100 my-2" />
                        <div className="navbar-nav align-items-lg-center ml-auto">
                            <div className="demo-navbar-notifications nav-item dropdown mr-lg-3">
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="bg-primary text-center text-white font-weight-bold p-3">
                                        4 New Notifications
                                     </div>
                                    <div className="list-group list-group-flush">
                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <div className="ui-icon ui-icon-sm ion ion-md-home bg-secondary border-0 text-white"></div>
                                            <div className="media-body line-height-condenced ml-3">
                                                <div className="text-body">Login from 192.168.1.1</div>
                                                <div className="text-light small mt-1">
                                                    Aliquam ex eros, imperdiet vulputate hendrerit et.
                        </div>
                                                <div className="text-light small mt-1">12h ago</div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <div className="ui-icon ui-icon-sm ion ion-md-person-add bg-info border-0 text-white"></div>
                                            <div className="media-body line-height-condenced ml-3">
                                                <div className="text-body">You have <strong>4</strong> new followers</div>
                                                <div className="text-light small mt-1">
                                                    Phasellus nunc nisl, posuere cursus pretium nec, dictum vehicula tellus.
                        </div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <div className="ui-icon ui-icon-sm ion ion-md-power bg-danger border-0 text-white"></div>
                                            <div className="media-body line-height-condenced ml-3">
                                                <div className="text-body">Server restarted</div>
                                                <div className="text-light small mt-1">
                                                    19h ago
                        </div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <div className="ui-icon ui-icon-sm ion ion-md-warning bg-warning border-0 text-body"></div>
                                            <div className="media-body line-height-condenced ml-3">
                                                <div className="text-body">99% server load</div>
                                                <div className="text-light small mt-1">
                                                    Etiam nec fringilla magna. Donec mi metus.
                        </div>
                                                <div className="text-light small mt-1">
                                                    20h ago
                        </div>
                                            </div>
                                        </a>
                                    </div>

                                    <a href="!#" className="d-block text-center text-light small p-2 my-1">Show all notifications</a>
                                </div>
                            </div>

                            <div className="demo-navbar-messages nav-item dropdown mr-lg-3">
                                <div className="dropdown-menu dropdown-menu-right">
                                    <div className="bg-primary text-center text-white font-weight-bold p-3">
                                        4 New Messages
                  </div>
                                    <div className="list-group list-group-flush">
                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <img src="assets/img/avatars/6-small.png" className="d-block ui-w-40 rounded-circle" alt="" />
                                            <div className="media-body ml-3">
                                                <div className="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                                                <div className="text-light small mt-1">
                                                    Mae Gibson &nbsp;·&nbsp; 58m ago
                        </div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <img src="assets/img/avatars/4-small.png" className="d-block ui-w-40 rounded-circle" alt="" />
                                            <div className="media-body ml-3">
                                                <div className="text-body line-height-condenced">Mea et legere fuisset, ius amet purto luptatum te.</div>
                                                <div className="text-light small mt-1">
                                                    Kenneth Frazier &nbsp;·&nbsp; 1h ago
                        </div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <img src="assets/img/avatars/5-small.png" className="d-block ui-w-40 rounded-circle" alt="" />
                                            <div className="media-body ml-3">
                                                <div className="text-body line-height-condenced">Sit meis deleniti eu, pri vidit meliore docendi ut.</div>
                                                <div className="text-light small mt-1">
                                                    Nelle Maxwell &nbsp;·&nbsp; 2h ago
                        </div>
                                            </div>
                                        </a>

                                        <a href="!#" className="list-group-item list-group-item-action media d-flex align-items-center">
                                            <img src="assets/img/avatars/11-small.png" className="d-block ui-w-40 rounded-circle" alt="" />
                                            <div className="media-body ml-3">
                                                <div className="text-body line-height-condenced">Lorem ipsum dolor sit amet, vis erat denique in, dicunt prodesset te vix.</div>
                                                <div className="text-light small mt-1">
                                                    Belle Ross &nbsp;·&nbsp; 5h ago
                        </div>
                                            </div>
                                        </a>
                                    </div>

                                    <a href="!#" className="d-block text-center text-light small p-2 my-1">Show all messages</a>
                                </div>
                            </div>
                            <div className="nav-item d-none d-lg-block text-big font-weight-light line-height-1 opacity-25 mr-3 ml-1"></div>

                            <div className="demo-navbar-user nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="!#" data-toggle="dropdown">
                                    <span className="d-inline-flex align-items-center align-middle">
                                        <span className="profileImg">
                                            {/* <img src="assets/img/avatars/1.png" alt="" className="d-block rounded-circle" /> */}
                                          <div className="media_left d-flex justify-content-center badge_name" style={{ background: "#000000" }}>
                                                <span className="align-self-center">{this.state.intialsLetter}</span>
                                            </div>
                                        </span>
                                        <span className="px-1 mr-lg-2 ml-2 ml-lg-0 loggedInUserName">{this.state.loggedInUserName}</span>
                                    </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <button onClick={() => {
                                        this.props.getloggedInUserDetail(this.state.loggedInUserName)
                                        history.push('/MyProfile')
                                    }} className="dropdown-item" ><span className="font-icon font-resize">&#71;</span>&nbsp;My Profile</button>
                                    <button onClick={() => {
                                        history.push('/MyCompany')
                                    }} className="dropdown-item" >
                                        <span className="font-icon font-resize">&#104;</span>&nbsp;My Company</button>
                                    <button onClick={() => {
                                        authContext.logOut()
                                    }} className="dropdown-item"
                                    ><i className="ion ion-ios-log-out text-danger"></i>&nbsp; Log Out</button>

                                </div>
                            </div>
                        </div>
                    </div>
               
                </nav>
                {/**/}
            </React.Fragment>
        );
    }
};
const mapStateToProps = (props: any) => {
    return {
        toggleClassProps: props.characterReducer.toggleClassSidNavReducer,
        WhiteLabelBrandProps: props.characterReducer.whiteLabelActionLoggedInUserReducer,
        loaderValueHeaderProps: props.characterReducer.loaderValueHeaderReducer
    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        showHideListUser: (value: boolean) => {
            dispatch(action.showHideListUser(value))
        },

        getloggedInUserDetail: (email: boolean) => {
            dispatch(action.getSubscriptionList(null, "Users"));
            dispatch(action.loggedInUserEmail(email))
        },
        toggleClass: (value: any) => {
            dispatch<any>(action.toggleClassSidNav(value))
        }
    }
}
export default connect(mapStateToProps,
    mapDispatchToProps)(HeaderComponent);