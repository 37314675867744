import * as React from "react";
import * as commonMethod from "../../commonMethods";
import arrow_top from "../../image/arrow_top.png";
import arrow_down from "../../image/arrow_btm.png";
import Select, { components } from "react-select";
import { CSVLink } from "react-csv";
//import {getLoggedInUSerName} from '../../adalConfig';
//import { myCompanyId } from "../../redux/actions/actions";
import * as companyService from "../../services/companyComponent.service";
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};
interface Providers {
  CreatedBy: string;
  CreatedDateTime: string;
  Id: number;
  IsActive: boolean;
  ModifiedBy: string;
  ModifiedDateTime: string;
  ProviderName: string;
}
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export class DataTableComponent extends React.Component<any, any> {
  csvPartnerBilling: React.RefObject<any>;
  constructor(props: Readonly<{}>) {
    super(props);
    this.csvPartnerBilling = React.createRef();
    this.state = {
      dataCsv: [],
      errorMessage: this.props.errorOccured,
      tableColumnName: this.props.columnName,
      dataList: this.props.rowsList !== undefined ? this.props.rowsList : [],
      providerId: "",
      calledFrom: this.props.calledFrom,
      actualData: this.props.actualData,
      searchType: this.props.searchBy,
      filterSearchNumbers: this.props.searchBy,
      showMinusIcon: false,
      activeId: "",
      status: this.props.calledFrom === "Numbers" ? "all" : "enabled",
      searchBoxValue: this.props.searchBoxText ? this.props.searchBoxText : "",
    };
  }
  async PartnerBiling() {
    //debugger;
    let userData = await companyService.getloggedInUserDetail();
    const LoggedInUesr = JSON.parse(userData)
    let companyId = LoggedInUesr.Subscription.CompanyId;
    if (LoggedInUesr.Subscription.Company.ParentId > 0) {
      companyId = LoggedInUesr.Subscription.Company.ParentId;
    }
    let datareturned =this.state.companyVersion === 2? await companyService.GeneratePartnerBillingSnapshot(companyId):await companyService.GeneratePartnerBillingSnapshot_v2(companyId);
    datareturned = datareturned.replace(/["']/g, "");
    if (datareturned == "") {
      alert("Sorry, There is no PartnerBilling available for this Company.");
      return;
    }
    this.setState({ dataCsv: datareturned });
    this.csvPartnerBilling.current.link.click();
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.rowsList !== prevState.dataList ||
      nextProps.actualData !== prevState.actualData ||
      nextProps.showHideSelectAllIcon !== prevState.showMinusIcon ||
      nextProps.searchBy !== prevState.filterSearchNumbers ||
      nextProps.searchBoxText !== prevState.searchBoxValue ||
      nextProps.activeCompanyId !== prevState.activeId
    ) {
      return {
        searchBoxValue: nextProps.searchBoxText,
        showMinusIcon: nextProps.showHideSelectAllIcon,
        actualData: nextProps.actualData,
        errorOccured: nextProps.errorOccured,
        dataList: nextProps.rowsList,
        activeId: nextProps.activeCompanyId,
        filterSearchNumbers: nextProps.searchBy,
        errorMessage:
          nextProps.rowsList !== undefined
            ? nextProps.rowsList.length === 0
              ? "No Records Found"
              : ""
            : "No Records Found",
      };
    } else return null;
  }

  componentDidMount() {
    if (this.props.calledFrom === "Users" && this.props.hideFilter === true) {
      if (this.state.searchBoxValue === "") {
        let list = this.props.rowsList
          ? this.props.rowsList.filter((x: any) => x.Disabled === false)
          : this.props.rowList;
        this.props.filteredByTxtBoxSearch(list);
      }
    } else if (
      this.props.calledFrom === "Subscription" &&
      this.props.hideFilter === true
    ) {
      if (this.state.searchBoxValue === "") {
        let list =
          this.props.rowsList !== undefined
            ? this.props.rowsList.filter(
              (x: any) => x.subscription.Disabled === false
            )
            : this.props.rowList;
        this.props.filteredByTxtBoxSearch(list);
      }
    }
  }
  acutalElemntValue = (name: any) => {

    switch (name) {
      case "Qty": {
        return "UserLicenseLimitation";
      }
      case "Translation": {
        return "EnableTranslation";
      }
      case "Id": {
        return "Id";
      }
      case "Used Credit": {
        return "UsedCredit";
      }
      case "Company Name": {
        return "Name";
      }
      case "Primary Contact": {
        return "PrimaryContactName";
      }
      case "Email": {
        return this.state.calledFrom === "Users" ||
          this.state.calledFrom === "GroupNotMemberList" ||
          this.state.calledFrom === "GroupMemberList" ||
          this.state.calledFrom === "GroupList"
          ? "Email"
          : "PrimaryContactEmail";
      }
      case "Number": {
        return this.state.calledFrom === "Users" ||
          this.state.calledFrom === "GroupList"
          ? "UniqueNumber"
          : this.state.calledFrom === "Numbers"
            ? "PhoneNumber"
            : "PrimaryContactPhone";
      }
      case "Registered": {
        return "RegistrationDate";
      }
      case "Date Created": {
        return "RegistrationDate";
      }
      case "Country": {
        return "BillingAddress";
      }
      case "Country Code": {
        return "DefaultCountryCode";
      }
      case "Provider": {
        return "Type";
      }
      case "Store Text": {
        return "StoreText";
      }
      case "Company": {
        return "CompanyId";
      }
      case "Name": {
        return this.state.calledFrom === "GroupList" ||
          this.state.calledFrom === "Users"
          ? "UserName"
          : "Name";
      }
      case "ID": {
        return this.state.calledFrom === "Users"
          ? "SubscriptionId"
          : this.state.calledFrom === "Subscription"
            ? "Id"
            : "ID";
      }
      default: {
        return name;
      }
    }
  };
  sorteDesc = (type: any, propertyName: any, dataType: any) => {

    var element = this.acutalElemntValue(propertyName);
    return this.state.dataList.sort((obj1: any, obj2: any) => {
      var obj1Element =
        dataType === "number"
          ? parseInt(obj1[element])
          : element === "BillingAddress"
            ? obj1[element].Country.toString()
            : obj1[element] !== undefined
              ? obj1[element].toString()
              : obj1[element];
      var obj2Element =
        dataType === "number"
          ? parseInt(obj2[element])
          : element === "BillingAddress"
            ? obj2[element].Country.toString()
            : obj2[element] !== undefined
              ? obj2[element].toString()
              : obj2[element];
      if (type === "down") {
        if (obj1Element < obj2Element) {
          return 1;
        }
        if (obj1Element > obj2Element) {
          return -1;
        }
        return 0;
      } else {
        if (obj1Element > obj2Element) {
          return 1;
        }
        if (obj1Element < obj2Element) {
          return -1;
        }
        return 0;
      }
    });
  };

  handleChangeMethod = (e: any) => {
    var listForFiltering = [];
    if (this.state.calledFrom === "Users") {
      this.props.setSearchBoxText(e.target.value);
      listForFiltering =
        this.state.searchType === "status_all"
          ? this.state.actualData
          : this.state.searchType === "enabled"
            ? this.state.actualData.filter((x: any) => x.Disabled === false)
            : this.state.actualData.filter((x: any) => x.Disabled === true);
    } else {
      this.props.setSearchBoxText(e.target.value);
      listForFiltering = this.state.actualData;
    }
    var newList = commonMethod.handleChange(
      e,
      listForFiltering,
      this.state.calledFrom
    );
    this.props.filteredByTxtBoxSearch(newList);
    //console.log(newList)
  };
  setActiveElement(id: any) {
    //   this.setState({ activeId: id })
    this.props.rowActiveIdCompany_Props(id);
  }

  AddButton() {
    const visibilityState = this.state.calledFrom === "Companies" ? "visible" : "hidden";
    //debugger;
    return (
      <><button
        className="addBtn yellowBtn"
        onClick={() => {
          this.props.showHideListProp(false);
        }}
        type="button"
      >
        <i className="ion ion-md-add"></i>
      </button>
        <button
          className="addDownload yellowBtn" style={{ visibility: visibilityState }}
          onClick={() => this.PartnerBiling()}
          type="button"
        >
          <i className="ion ion-md-download"></i>
        </button>
        <CSVLink data={this.state.dataCsv} filename="Partner_Billing_Snapshot.csv" className="hidden" ref={this.csvPartnerBilling} target="_blank" />

      </>
    );
  }
  subscriptionClick = (index: any, object: any) => {
    if (this.props.loggedInUserRole !== 0) {
      this.setActiveElement(index);
      setTimeout(() => {
        this.props.openEditComponent(object);
      }, 200);
    }
  };

  //by Niyi
  deriveProviderName = (data: any) => {
    const iterator = this.props.providersList.values();
    let value = "";
    for (const providr of iterator) {
      if (data === providr.Id || data === providr.ProviderName) {
        value = providr.ProviderName;
      }
    }
    return value;
  };
  makeFilterQuery = () => {
    if (this.props.providersList.length) {
      const newList = [...this.props.providersList, { ProviderName: "All" }];
      let requiredValue = newList.find((provider: Providers) => {
        return provider.ProviderName === this.state.filterSearchNumbers;
      });
      //console.log(requiredValue);
      return requiredValue.ProviderName;
    }
  };

  doRecycle = (obj: any) => {
    if (obj.$id !== undefined && obj.$id !== null) {
      return obj;
    }

    if (obj.$ref !== undefined && obj.$ref !== null) {
    }
  };
  public render() {

    const listItems = this.state.dataList ? (
      this.state.dataList.length !== 0 ? (
        this.state.calledFrom === "Users" ? (
          this.state.dataList.map((x: any, i: number) => (
            <tr
              role="row"
              onClick={() => {
                this.setActiveElement(i);
                setTimeout(() => {
                  this.props.openEditComponent(x);
                }, 200);
              }}
              className={
                i === this.state.activeId
                  ? "active odd cursor_pointer"
                  : "odd cursor_pointer"
              }
              key={i}
            >
              <td className="userList_RegistrationDate">
                <div
                  className="media_left d-flex justify-content-center badge_name"
                  style={{ background: x.backGroundColor }}
                >
                  <span className="align-self-center">
                    {commonMethod.generateFirstLastNameIntials(
                      x.UserName !== "" ? x.UserName : x.Email
                    )}
                  </span>
                </div>
              </td>
              {/* <td className="userList_SubId" style={{ width: "100px" }}>
                {x.SubscriptionId !== undefined
                  ? x.SubscriptionId
                  : "No Subscription id"}
              </td> */}
              <td className="userList_SubId" style={{ width: "100px" }}>
                {x.Id !== undefined
                  ? x.Id
                  : "No User Id"}
              </td>
              <td className="userList_Email">
                {x.UserName !== undefined ? x.UserName : ""}
              </td>
              <td className="userList_Email">
                {x.Email !== undefined ? x.Email : "No Email"}
              </td>
              <td className="userList_RegistrationDate">
                {x.Role === 10
                  ? "Subscription admin"
                  : x.Role === 20
                    ? "User"
                    : x.Role === 5
                      ? "Partner admin"
                      : "Admin"}
              </td>
              <td className="userList_RegistrationDate">
                {x.UniqueNumber !== undefined ? x.UniqueNumber : ""}
              </td>
              <td className="userList_RegistrationDate">
                {x.DefaultCountryCode !== undefined ? x.DefaultCountryCode : ""}
              </td>
              <td className="userList_RegistrationDate">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    <input
                      className="custom-control-input"
                      id={"user" + i}
                      type="checkbox"
                      checked={x.StoreText === true ? true : false}
                      disabled={true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"user" + i}
                    ></label>
                  </div>
                </div>
              </td>
              <td className="userList_UsedCredit">
                {x.UsedCredit !== undefined ? x.UsedCredit : "No Credits"}
              </td>
              <td className="userList_RegistrationDate">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    <input
                      className="custom-control-input"
                      id={"user" + i}
                      type="checkbox"
                      checked={x.Disabled === true ? true : false}
                      disabled={true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"user" + i}
                    ></label>
                  </div>
                  <span className="font-icon rightangle_arrow">&#122;</span>
                </div>
              </td>
              {/* {this.state.dataList && console.log(this.state.dataList)} */}
            </tr>
          ))
        ) : this.state.calledFrom === "Companies" ? (
          this.state.dataList.map((x: any, i: number) => (
            <tr
              role="row"
              onClick={() => {
                this.setActiveElement(i);
                setTimeout(() => {
                  this.props.openEditComponent(x);
                }, 200);
              }}
              className={
                i === this.state.activeId
                  ? "active odd cursor_pointer"
                  : "odd cursor_pointer"
              }
              key={i}
            >
              {
                x.Version ===2 ?  <td className="companyNameVersion" > {x.Name !== undefined ? x.Name : "No Name"}</td>
                     : <td> {x.Name !== undefined ? x.Name : "No Name"}</td>
              }
              <td>
                {x.BillingAddress !== undefined
                  ? x.BillingAddress.Country
                  : "No Country"}
              </td>
              <td>
                {x.PrimaryContactName !== undefined
                  ? x.PrimaryContactName
                  : "No ContactName"}
              </td>
              <td className="PrimaryContactEmail">
                {x.PrimaryContactEmail !== undefined
                  ? x.PrimaryContactEmail
                  : "NoContactEmail"}
              </td>
              <td className="companyEmail">
                {x.PrimaryContactPhone !== undefined
                  ? x.PrimaryContactPhone
                  : "No Conatc Number"}
              </td>
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    {x.RegistrationDate !== undefined
                      ? x.RegistrationDate.split("T")[0]
                      : "No Date"}
                  </div>
                  <span className="font-icon rightangle_arrow">&#122;</span>
                </div>
              </td>
            </tr>
          ))
        ) : this.state.calledFrom === "Numbers" ? (
          this.state.dataList.map((x: any, i: number) => (
            <tr
              role="row"
              onClick={() => {
                this.setActiveElement(i);
                setTimeout(() => {
                  this.props.openEditComponent(x);
                }, 200);
              }}
              className={
                i === this.state.activeId
                  ? "active odd cursor_pointer"
                  : "odd cursor_pointer"
              }
              key={i}
            >
              <td>
                {x.PhoneNumber !== undefined ? x.PhoneNumber : "No PhoneNumber"}
              </td>
              <td className="sorting_1">
              {x.ServiceName !== undefined
                  ? x.ServiceName
                  : "No Type"}{" "}
             </td>
              <td className="sorting_1">
                {x.ServiceProviderAccountId !== undefined
                  ? x.AccountName
                  : "No Assigned"}{" "}
              </td>
              <td className="sorting_1">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    {x.CompanyName !== "" ? x.CompanyName : ""}
                  </div>
                </div>
              </td>
              <td className="userList_RegistrationDate">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      checked={x.OnlyReceiveMFA ? true : false}
                      disabled={true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"number" + i}
                    ></label>
                  </div>
                </div>
              </td>
              <td className="userList_RegistrationDate">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    <input
                      className="custom-control-input"
                      id={"number" + i}
                      type="checkbox"
                      checked={x.Trial ? true : false}
                      disabled={true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"number" + i}
                    ></label>
                  </div>
                  <span className="font-icon rightangle_arrow">&#122;</span>
                </div>
              </td>
              {/* {console.log(this.state.dataList)} */}
              {/* {console.log(this.props.providersList)} */}
            </tr>
          ))
        ) : this.state.calledFrom === "GroupList" ? (
          this.state.dataList.map((x: any, i: number) => (
            <tr
              role="row"
              className={
                i === this.state.activeId
                  ? "active odd cursor_pointer"
                  : "odd cursor_pointer"
              }
              onClick={(e) => {
                this.setActiveElement(i);
                setTimeout(() => {
                  this.props.showGroupMemeber(
                    x.UserName !== "" ? x.UserName : x.Email,
                    x
                  );
                }, 200);
              }}
              key={i}
            >
              <td className="userNameTd">
                {x.UserName !== "" ? (
                  <div className="d-flex">
                    <div
                      className="media_left d-flex justify-content-center  group_list_icon"
                      style={{ background: x.backGroundColor }}
                    >
                      <span className="align-self-center">
                        {commonMethod.generateFirstLastNameIntials(x.UserName)}
                      </span>
                    </div>
                    <span className="align-self-center textNowrap">
                      {x.UserName}
                    </span>
                  </div>
                ) : (
                  <div
                    className="media_left d-flex justify-content-center group_list_icon"
                    style={{ background: x.backGroundColor }}
                  >
                    <span className="align-self-center ">
                      {commonMethod.generateFirstLastNameIntials(x.Email)}
                    </span>
                  </div>
                )}
              </td>
              <td className="userList_Email">
                {x.Email !== undefined ? x.Email : "No Email"}
              </td>
              <td className="userList_RegistrationDate">
                {x.UniqueNumber !== undefined ? x.UniqueNumber : ""}
              </td>
              <td className="userList_RegistrationDate">
                {x.DefaultCountryCode !== undefined ? x.DefaultCountryCode : ""}
              </td>
              <td className="userList_RegistrationDate">
                <div className="custom-control custom-checkbox circle_check custom_check">
                  <input
                    className="custom-control-input"
                    id={"group" + i}
                    type="checkbox"
                    checked={x.StoreText === true ? true : false}
                    disabled={true}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"group" + i}
                  ></label>
                </div>
              </td>
              <td className="userList_RegistrationDate">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    <input
                      className="custom-control-input"
                      id={"disableGroup" + i}
                      type="checkbox"
                      checked={x.Disabled}
                      disabled={true}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={"disableGroup" + i}
                    ></label>
                  </div>
                  <span
                    className="font-icon rightangle_arrow"
                    // onClick={(e) => {    
                    //    //#3804 Maksud                 
                    //   this.props.arrowIconClick(x.Email);                      
                    // }}
                    onClick={() => {
                      this.setActiveElement(i);
                      setTimeout(() => {
                        this.props.showGroupMemeber(
                          x.UserName !== "" ? x.UserName : x.Email,
                          x
                        );
                        this.props.arrowIconClick(x.Email);
                      }, 300);
                    }}
                  >
                    &#122;
                  </span>
                </div>
              </td>
            </tr>
          ))
        ) : this.state.calledFrom === "Subscription" ? (
          this.state.dataList.map((x: any, i: number) => (
            <tr
              role="row"
              onClick={() => {
                this.subscriptionClick(i, x);
              }}
              className={
                i === this.state.activeId
                  ? "active odd cursor_pointer"
                  : "odd cursor_pointer"
              }
              key={i}
            >
              <td>
                {x.subscription.Disabled === false ? (
                  <span className="font-icon icon_font33">D&#8203;</span>
                ) : (
                  <span className="font-icon gray_color icon_font33">D</span>
                )}{" "}
              </td>
              <td>
                {x.subscription.Id !== undefined ? x.subscription.Id : ""}
              </td>
              <td>
                {x.subscription.Name !== undefined
                  ? x.subscription.Name
                  : "No Name"}
              </td>
              <td>
                {x.subscription.CompanyId !== undefined
                  ? x.subscription.CompanyId
                  : "No Name"}
              </td>
              {this.props.loggedInUserRole === -10 ? (
                <td>
                  {x.subscription.UserLicenseLimitation !== undefined
                    ? x.subscription.UserLicenseLimitation
                    : "No UserLicenseLimitation"}
                </td>
              ) : null}
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="custom-control custom-checkbox circle_check custom_check">
                    {this.props.loggedInUserRole === -10
                      ? x.userAssigned !== undefined
                        ? x.userAssigned
                        : 0
                      : x.subscription.UserLicenseLimitation !== undefined
                        ? x.subscription.UserLicenseLimitation
                        : "No UserLicenseLimitation"}
                  </div>
                </div>
              </td>
              <td>
                {x.subscription.MonthlyFreeCredit !== -1
                  ? x.subscription.MonthlyFreeCredit.toFixed(4)
                  : "Unlimited"}
              </td>
              <td>
                {x.subscription.MonthlyFreeCredit !== -1
                  ? x.subscription.FreeCreditBalance.toFixed(4)
                  : "Not Applicable"}
              </td>

              <td>
                {x.subscription.Credit !== undefined
                  ? x.subscription.Credit.toFixed(4)
                  : "No Credit"}
              </td>
              {/* <span className="font-icon rightangle_arrow">&#122;</span> */}
              {/* {this.state.dataList && console.log(this.state.dataList)} */}
            </tr>
          ))
        ) :

          this.state.calledFrom === "InboxesList" ? (
            this.state.dataList.map((x: any, i: number) => (
              <tr
                role="row"
                className={
                  i === this.state.activeId
                    ? "active odd cursor_pointer"
                    : "odd cursor_pointer"
                }
                onClick={(e) => {
                  this.setActiveElement(i);
                  setTimeout(() => {                    
                    this.props.showInboxesMemeber(x );
                  }, 200);
                }}
                key={i}
              >
                <td className="userNameTd">
                  {x.Name !== "" ? (
                    <div className="d-flex">
                      <div
                        className="media_left d-flex justify-content-center  inboxes_list_icon"
                        style={{ background: x.backGroundColor }}
                      >
                        <span className="align-self-center">
                          {commonMethod.generateFirstLastNameIntials(x.Name)}
                        </span>
                      </div>
                      <span className="align-self-center textNowrap">
                        {x.Name}
                      </span>
                    </div>
                  ) : (
                    <div
                      className="media_left d-flex justify-content-center inboxes_list_icon"
                      style={{ background: x.backGroundColor }}
                    >
                      <span className="align-self-center ">

                      </span>
                    </div>
                  )}
                </td>
               <td className="userList_RegistrationDate">
              {/* {x.Type !== undefined ? x.Type ===0 ? "Personal" :"Shared" : "Shared"} */}
              {x.Type !== undefined ? x.Type ===null ? "" :x.Type : ""}
            </td> 
                <td className="userList_RegistrationDate">
                  {x.ServicePhoneNumber !== undefined ? x.ServicePhoneNumber : ""}
                </td>
                <td className="userList_RegistrationDate">
                  {x.DefaultCountryCode !== undefined ? x.DefaultCountryCode : ""}
                </td>

                <td className="userList_RegistrationDate">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="custom-control custom-checkbox circle_check custom_check">
                      <input
                        className="custom-control-input"
                        id={"disableInboxes" + i}
                        type="checkbox"
                        checked={x.Disabled}
                        disabled={true}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"disableInboxes" + i}
                      ></label>
                    </div>
                    <span
                      className="font-icon rightangle_arrow"
                      // onClick={(e) => {    
                      //    //#3804 Maksud                 
                      //   this.props.arrowIconClick(x.Email);                      
                      // }}
                      onClick={() => {
                        this.setActiveElement(i);
                        setTimeout(() => {
                          this.props.showInboxesMemeber(x);
                          this.props.arrowIconClick(x.Id);
                        }, 300);
                      }}
                    >
                      &#122;
                    </span>
                  </div>
                </td>
              </tr>
            ))
          )
            :

            (
              <tr role="row" className="odd">
                <td colSpan={this.state.tableColumnName.length}>
                  <div className="noUser_found">{this.state.errorMessage}</div>
                </td>
              </tr>
            )
      ) : (
        <tr role="row" className="odd">
          <td colSpan={this.state.tableColumnName.length}>
            <div className="noUser_found">No Data Found </div>
          </td>
        </tr>
      )
    ) : (
      <tr role="row" className="odd">
        <td colSpan={this.state.tableColumnName.length}>
          <div className="noUser_found">{this.state.errorMessage}</div>
        </td>
      </tr>
    );
    return (
      <React.Fragment>
        <div
          id="user-list_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="searchBar">
            <div className="row">
              <div className="col-md-4">
                {
                  <h6 className={"font-weight-bold text-left d-flex main_head"}>
                    {this.props.calledFrom === "GroupList"
                      ? "Group"
                      : this.props.calledFrom === "InboxesList" ? "Inboxes"
                        : this.props.calledFrom}
                    {/* For Subscription if company is parent donot add subscription for that company */}
                    {this.state.calledFrom === "Subscription"
                      ? this.props.loggedInUserRole === 0
                        ? null
                        : this.AddButton()
                      : this.state.calledFrom === "Users" 
                        ? (this.props.loggedInUserRole === -10 || this.props.loggedInUserRole === 5) && this.props.companyId ===undefined  ? null
                          :this.AddButton()
                       :this.AddButton()
                      }
                  </h6>
                }
              </div>

              <div className="col-md-8">
                <div id="user-list_filter" className="dataTables_filter">
                  {this.props.calledFrom === "Companies" ? null : (
                    <div className="select_srch header_drwn">
                      {this.props.calledFrom === "Subscription" &&
                        this.props.hideFilter === true ? (
                        this.props.loggedInUserRole !== 0 ? (
                          <div>
                            <Select
                              isSearchable={false}
                              className="Commondropdownclass"
                              isClearable={false}
                              components={{ DropdownIndicator }}
                              value={[
                                {
                                  label:
                                    this.state.plan === "5"
                                      ? "Hosted"
                                      : this.state.plan === "0"
                                        ? "No Plan"
                                        : this.state.plan === "1"
                                          ? "Trail"
                                          : this.state.plan === "2"
                                            ? "User"
                                            : this.state.plan === "6"
                                              ? "Group"
                                              : this.state.plan === "4"
                                                ? "User & Group"
                                                : "All",
                                  value: this.state.plan,
                                },
                              ]}
                              onChange={(opt: any) => {
                                this.setState({ plan: opt.value });
                                this.props.searchFunctionality(
                                  opt.value,
                                  "plan"
                                );
                              }}
                              options={[
                                { label: "All", value: "plan_all" },
                                { label: "No Plan", value: "0" },
                                { label: "Trail", value: "1" },
                                { label: "User", value: "2" },
                                { label: "Group", value: "6" },
                                { label: "User & Group", value: "4" },
                                { label: "Hosted", value: "5" },
                              ]}
                            />
                          </div>
                        ) : null
                      ) : null}
                    </div>
                  )}
                  <div className="select_srch">
                    {this.props.hideFilter === false ? null : this.props
                      .calledFrom === "Numbers" ? (
                      <Select
                        isSearchable={false}
                        className="Commondropdownclass"
                        isClearable={false}
                        components={{ DropdownIndicator }}
                        value={[
                          {
                            label: this.makeFilterQuery(),
                            value: this.state.filterSearchNumbers,
                          },
                        ]}
                        onChange={(opt: any) => {
                          this.props.searchFunctionality(opt.label);
                        }}
                        options={this.props.providersList.map((a: any) => {
                          return {
                            label: a.ProviderName,
                            value: a.Id,
                          };
                        })}
                      />
                    ) : this.props.calledFrom === "Companies" ? null : (
                      <Select
                        isSearchable={false}
                        isClearable={false}
                        className="Commondropdownclass"
                        components={{ DropdownIndicator }}
                        value={[
                          {
                            label:
                              this.state.status === "status_all"
                                ? "All"
                                : this.state.status === "enabled"
                                  ? "Enabled"
                                  : "Disabled",
                            value: this.state.status,
                          },
                        ]}
                        onChange={(opt: any) => {
                          this.setState({ status: opt.value });
                          this.props.searchFunctionality(opt.value, "status");
                        }}
                        options={[
                          { label: "All", value: "status_all" },
                          { label: "Enabled", value: "enabled" },
                          { label: "Disabled", value: "disabled" },
                        ]}
                      />
                    )}
                  </div>
                  <div className="search_btn">
                    <input
                      type="search"
                      placeholder={"Search"}
                      onChange={(e: any) => {
                        this.handleChangeMethod(e);
                      }}
                      value={this.state.searchBoxValue}
                      className="form-control form-control-sm searchUser"
                      aria-controls="user-list"
                    />
                    <button type="button">
                      <i className="fas fa-search"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <table
                id="user-listData"
                className={
                  "table table-striped table-bordered dataTable no-footer"
                }
                role="grid"
                aria-describedby="user-list_info"
              >
                <thead>
                  <tr role="row">
                    {this.state.tableColumnName.map(
                      (element: any, index: number) => {
                        return (
                          <th
                            className={
                              element !== "Show Members"
                                ? "sorting_asc "
                                : "actionClassTable"
                            }
                            tabIndex={0}
                            rowSpan={1}
                            colSpan={1}
                            aria-sort="ascending"
                            style={{ width: "100px" }}
                            key={index}
                          >
                            {element}
                            {element !== "Show Members" && element !== "" ? (
                              <span className="arrow_span">
                                <img
                                  src={arrow_top}
                                  alt="website logo"
                                  className="arrow_top"
                                  onClick={() => {
                                    var data = this.sorteDesc(
                                      "up",
                                      element,
                                      element === "Qty" ||
                                        element === "Used Credit" ||
                                        element === "Id" ||
                                        element === "PhoneNumber" ||
                                        element === "Company"
                                        ? "number"
                                        : "string"
                                    );
                                    this.setState({
                                      dataList: data,
                                    });
                                  }}
                                />
                                <img
                                  src={arrow_down}
                                  alt="website logo"
                                  className="arrow_btm"
                                  onClick={() => {
                                    var data = this.sorteDesc(
                                      "down",
                                      element,
                                      element === "Qty" ||
                                        element === "Used Credit" ||
                                        element === "Id" ||
                                        element === "PhoneNumber" ||
                                        element === "Company"
                                        ? "number"
                                        : "string"
                                    );
                                    this.setState({
                                      dataList: data,
                                    });
                                  }}
                                ></img>
                              </span>
                            ) : null}
                          </th>
                        );
                      }
                    )}
                  </tr>
                </thead>
                <tbody>{listItems}</tbody>
              </table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}


