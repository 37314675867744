import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import * as serviceWorker from './serviceWorker';
import { runWithAdal } from 'react-adal';
import { authContext } from './adalConfig';
import configureStore, { IAppState } from './redux/store/Store';
//import './index.css';
import App from './App';

interface IProps {
  store: Store<IAppState>;
}
 const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {
  
},DO_NOT_LOGIN);

const Root: React.SFC<IProps> = props => {
  return (
    <Provider store={props.store}>
      <App  />
    </Provider>
  );
};


const store = configureStore();
//store.dispatch(getUserList())

ReactDOM.render(<Root store={store} />, document.getElementById(
  'root'
) as HTMLElement);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();