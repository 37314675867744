export const handleChange = (e: any, list: [], type: string) => {
    debugger;
     let currentList = [];
    let newList = [];
    if (e.target.value !== "") {
        currentList = list;
        const searchValue = e.target.value.toLowerCase();
        if(type === "Subscription"){
            newList = currentList.filter((item: any) => {
                 return Object.keys(item.subscription).some(key =>
                    item.subscription[key] && item.subscription[key].toString().toLowerCase().includes(searchValue)
                 );
            });
        }
        else if (type === "Users"){
            newList = currentList.filter((item: any) => {

                return Object.keys(item).some(key => 
                    item[key] && !(key.toString() ==="ModifiedBy" || key.toString() ==="CreatedBy") 
                    && item[key].toString().toLowerCase().includes(searchValue)
                );                

            });
        }
        else{
            newList = currentList.filter((item: any) => {
                return Object.keys(item).some(key => 
                    item[key] && item[key].toString().toLowerCase().includes(searchValue)
                );
            });
        }
      


    } else {
        newList = list;
    }
    return newList;
}
export const validEmail = (email: string) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(email)) {
        return true;
    }
    else {
        return false
    }
}
export const validPhoneNumber = (phoneNumber: any) => {
    // ^\+[1-9]\d{1,14}$
    return /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/.test(phoneNumber)

}
export const validPhoneNumberForAustralia = (phoneNumber: any) => {
        // ^\+[1-9]\d{1,14}$
    return /(^[+]?\d{9}$)|(^\d{9}$)/.test(phoneNumber)

}

export const validCredit = (credits: any) => {
    return /^\d{1,3}(\.\d{1,3})?$/.test(credits)

}
export const randomColorGenerator = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
export const generateFirstLastNameIntials = (name: any) => {
    var firstName = "";
    var lastName = "";
    var inital = ""
    if (name !== undefined && name !== "") {
        if (name.indexOf(' ') !== -1) {
            var splitName = name.split(' ');
            firstName = splitName[0].toString();
            lastName = splitName[1].toString();
            var firstIntiial = firstName !== "" ? firstName.charAt(0) : "";
            var lastIntial = lastName !== "" ? lastName.charAt(0) : "";
            inital = firstIntiial + ""+lastIntial;
        }
        else {
            inital = name.charAt(0);
        }
    }
    else{
        inital = "";

    }
  
    return inital
}