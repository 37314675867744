import * as React from "react";
import * as commonMethod from "../../commonMethods";
import ConfirmationDeleteComponent from "../sharedComponents/ConfirmationUserGroupDeleteComponent";
import Select, { components } from "react-select";
//import PhoneInput from "react-phone-input-2";
import Tabs from "react-bootstrap/Tabs";
import history from "../../history";
import Tab from "react-bootstrap/Tab";
import "react-phone-input-2/lib/style.css";
import { getLoggedInUSerName } from "../../adalConfig";
//import { roleIdForUser } from "../../redux/actions/actions";
import { CSVLink } from "react-csv";
import * as companyService from "../../services/companyComponent.service";
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
export class UserEditComponent extends React.Component<any, any> {
  csvPartnerMessage: React.RefObject<any>;
  constructor(props: any, state: any) {
    super(props);
    this.csvPartnerMessage = React.createRef();
    this.state = {
      messageCsv: [],
      Email: this.props.emailForAddEditUserProp
        ? this.props.emailForAddEditUserProp
        : "",
      AliasEmail: this.props.aliasEmailForAddEditUserProp
        ? this.props.aliasEmailForAddEditUserProp
        : "",
      RegistrationDate: this.props.registerationDate
        ? this.props.registerationDate
        : "",
      UsedCredit: this.props.usedCreditForAddEditUserProps
        ? this.props.usedCreditForAddEditUserProps
        : "",
      DefaultCountryCode: this.props.defaultCountryCodeForAddEditUserPRops
        ? this.props.defaultCountryCodeForAddEditUserPRops
        : 1,
      SubscriptionId: this.props.subscriptionForAddEditUserProp
        ? this.props.subscriptionForAddEditUserProp
        : "",
      StoreText: this.props.storeTextForAddEditUserProp
        ? this.props.storeTextForAddEditUserProp
        : true,
      Role: this.props.roleIdForAddEditUserProp
        ? this.props.roleIdForAddEditUserProp
        : 20,
      UniqueNumber: this.props.uniquePhoneNumber
        ? this.props.uniquePhoneNumber
        : "",
      Disabled: this.props.disableForAddEditUserProp
        ? this.props.disableForAddEditUserProp
        : false,
      //Task #3461 //Maksud
      ContactLookupEnabled: this.props.ContactLookupEnabledForAddEditUserProp
        ? this.props.ContactLookupEnabledForAddEditUserProp
        : false,
      CreditLimit: this.props.creditLimitForAddEditUserProp
        ? this.props.creditLimitForAddEditUserProp
        : "",
      ErrorEmail: "",
      ErrorPhoneNumber: "",
      ErrorSubscription: "",
      InvalidEmail: "",
      InvalidAliesEmail: "",
      subscriptionList: [],
      ErrorCreditLimit: "",
      SelectedSubscName: this.props.subscriptionNameForAddEditUserProp
        ? this.props.subscriptionNameForAddEditUserProp
        : "",
      Name:
        this.props.nameForAddEditUserProp !== null
          ? this.props.nameForAddEditUserProp
          : "",

      FirstName:
        this.props.firstNameForAddEditUserProp !== null
          ? this.props.firstNameForAddEditUserProp
          : "",
      LastName:
        this.props.lastNameForAddEditUserProp !== null
          ? this.props.lastNameForAddEditUserProp
          : "",

      ErrorName: "",
      activeTab: "User-Details",
      pendingPhoneNumber: false,
      listUnAssignedNumbers: [],
      whiteLabel:
        this.props.editData !== undefined
          ? this.props.editData.WhiteLabelBrand || ""
          : "",
      EmailEnabled: this.props.editData.EmailEnabled
        ? this.props.editData.EmailEnabled
        : false,
      ContactEmail: !this.props.editData.ContactEmail
        ? ""
        : this.props.editData.ContactEmail,
      SelectedUserCompanyVersionId: this.props.selectedUserCompanyVersionId,
      companyId: this.props.companyId ?? "",
      companyName: "",
      companyVersion: this.props.companyVersion,
      LoggedInUserCompanyVersionNo: this.props.loggedInUserCompanyVersionNo,
      LoggedInUserCompanyId: this.props.loggedInUserCompanyId,
      RemoveUserDataObject: this.props.removeUserDataObject,
      UserId:
        this.props.calledFrom === "edit"
          ? this.props.editData !== undefined
            ? this.props.editData.Id || ""
            : ""
          : "",
      SelectedCompanyId:
        this.props.calledFrom === "edit"
          ? this.props.editData !== undefined
            ? this.props.editData?.CompanyId
            : 0
          : this.props.companyId === undefined || this.props.companyId === ""
          ? this.props.loggedInUserCompanyId
          : this.props.companyId,
      SelectedCompanyName: "",
    };
  }
  componentDidMount() {
    setTimeout(() => {
      var userCompanyVersionId =
        this.props.calledFrom === "edit"
          ? this.props.companyVersion === undefined ||
            this.props.companyVersion === null
            ? this.state.SelectedUserCompanyVersionId
            : this.props.companyVersion
          : undefined;

      if (userCompanyVersionId === 2) {
        this.props.getRemoveUserData(this.props.editData.Id);
      }
    }, 3000);

    setTimeout(() => {
      var selectedCompanyName =
        this.props.companyListProps !== undefined
          ? this.props.companyListProps.length !== 0
            ? this.props.editData.CompanyId !== 0
              ? this.props.companyListProps
                  .filter((x: any) => x.Id === this.state.SelectedCompanyId)
                  .map((x: any) => x.Name)[0]
              : "Select"
            : ""
          : "";

      this.setState({
        SelectedCompanyName: selectedCompanyName,
      });
    }, 2000);
  }

  componentDidUpdate = (prevProps: any, prevState: any) => {
    if (
      prevProps.editData.ContactEmail !== this.props.editData.ContactEmail ||
      prevProps.editData.EmailEnabled !== this.props.editData.EmailEnabled
    ) {
      this.setState({
        EmailEnabled: this.props.editData.EmailEnabled,
        ContactEmail: this.props.editData.ContactEmail,
      });
    }

    if (this.props.roleIdForAddEditUserProp !== this.state.Role) {
      this.setState({ Role: this.props.roleIdForAddEditUserProp });
    }
    //this.state.UniqueNumber && console.log(this.state.UniqueNumber, "here");
    //console.log(this.props.uniquePhoneNumber, "main number");
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.defaultCountryCodeForAddEditUserPRops !==
        prevState.DefaultCountryCode ||
      nextProps.uniquePhoneNumber !== prevState.UniqueNumber ||
      nextProps.pendingUnAssignPhoneNumberProps !==
        prevState.pendingPhoneNumber ||
      nextProps.listUnAssignPhoneNumberProps !==
        prevState.listUnAssignedNumbers ||
      nextProps.whiteLabelPrps !== prevState.whiteLabel ||
      nextProps.selectedUserCompanyVersionId !==
        prevState.SelectedUserCompanyVersionId ||
      nextProps.removeUserDataObject !== prevState.RemoveUserDataObject
    ) {
      return {
        // whiteLabel: nextProps.whiteLabelPrps,
        listUnAssignedNumbers: nextProps.listUnAssignPhoneNumberProps,
        pendingPhoneNumber: nextProps.pendingUnAssignPhoneNumberProps,
        UniqueNumber: nextProps.uniquePhoneNumber,
        DefaultCountryCode: nextProps.defaultCountryCodeForAddEditUserPRops,
        subscriptionList: nextProps.subscriptionList.sort((a: any, b: any) =>
          a.subscription.Name > b.subscription.Name
            ? 1
            : a.subscription.Name < b.subscription.Name
            ? -1
            : 0
        ),
        SelectedUserCompanyVersionId: nextProps.selectedUserCompanyVersionId,
        RemoveUserDataObject: nextProps.removeUserDataObject,
      };
    } else return null;
  }
  async PartnerMessage() {
    let datareturned=this.state.LoggedInUserCompanyVersionNo === 2?(await companyService.GeneratePartnerMessageSnapshotForUser_v2(this.state.Email)):(await companyService.GeneratePartnerMessageSnapshotForUser(this.state.Email));
  
    datareturned = datareturned.replace(/["']/g, "");
    if (datareturned == "") {
      alert(
        "Sorry, there are no messages for this user in the last seven days."
      );
      return;
    }
    this.setState({ messageCsv: datareturned });
    this.csvPartnerMessage.current.link.click();
  }
  IsValidV2() {
    var isValid = true;
    if (!this.state.Email) {
      this.setState({ ErrorEmail: "This Field is Required" });
      isValid = false;
    }
    if (
      this.state.ErrorPhoneNumber !== "" ||
      this.state.ErrorCreditLimit !== ""
    ) {
      isValid = false;
    }
    if (!this.state.FirstName) {
      isValid = false;
      this.setState({ ErrorName: "This Field is Required" });
    }

    if (!this.state.Name) {
      isValid = false;
      this.setState({ ErrorName: "This Field is Required" });
    }

    return isValid;
  }

  IsValid() {
    var isValid = true;
    if (!this.state.Email) {
      this.setState({ ErrorEmail: "This Field is Required" });
      isValid = false;
    }

    if (!this.state.SubscriptionId) {
      isValid = false;
      this.setState({
        ErrorSubscription: "This Field is Required",
      });
    }
    if (
      this.state.ErrorPhoneNumber !== "" ||
      this.state.ErrorCreditLimit !== ""
    ) {
      isValid = false;
    }
    if (!this.state.Name) {
      isValid = false;

      this.setState({ ErrorName: "This Field is Required" });
    }

    return isValid;
  }
  addUpdateV2Data = (versionId: any) => {
    var body = {};

    if (this.IsValidV2()) {
      if (this.props.calledFrom === "add") {
        body = {
          email: this.state.Email, // required
          aliasEmail: this.state.AliasEmail,
          UserName: this.state.Name, // required
          firstName: this.state.FirstName, // required
          lastName: this.state.LastName,
          department: "",
          source: "",
          // companyId: (this.state.companyId === undefined || this.state.companyId === "") ? this.state.LoggedInUserCompanyId
          //   : this.state.companyId,
          companyId: this.state.SelectedCompanyId,
          role: this.state.Role,
          disabled: this.state.Disabled,
          EmailEnabled: this.state.EmailEnabled,
          contactEmail: this.state.ContactEmail,
        };
        this.props.addUpdateUserProp("add", body, versionId);
      } else {
        
       
        body = {
         // email: this.state.Email, // required
          aliasEmail: this.state.AliasEmail,
          UserName: this.state.Name, // required
          firstName: this.state.FirstName, // required
          lastName: this.state.LastName,
          department: "",
          source: "",
          companyId: this.state.SelectedCompanyId, //this.props.editData.CompanyId,
          role: this.state.Role,
          disabled: this.state.Disabled,
          EmailEnabled: this.state.EmailEnabled,
          contactEmail: this.state.ContactEmail,
          RegistrationDate: this.state.RegistrationDate,
        };

        let uid = this.props.editData.Id;
        this.props.addUpdateUserProp("edit", body, versionId, uid);
      }

      if (
        this.state.EmailEnabled !== this.props.EmailEnabled ||
        this.state.ContactEmail !== this.props.editData.ContactEmail
      ) {
        const emailData = {
          enabled: this.state.EmailEnabled,
          contactEmail: this.state.ContactEmail,
          userEmail: this.state.Email,
        };
        this.props.updateUserEmailNotifications(emailData);
      }
    }
  };

  addUpdateData = (e: any) => {
    if (
      this.state.Email.length !== 0 &&
      commonMethod.validEmail(this.state.Email) === false
    ) {
      return false;
    }
    const versionId =
      this.props.companyVersion === undefined ||
      this.props.companyVersion === null
        ? this.state.SelectedUserCompanyVersionId === undefined ||
          this.state.SelectedUserCompanyVersionId === null
          ? this.props.loggedInUserCompanyVersionNo === undefined ||
            this.props.loggedInUserCompanyVersionNo === null
            ? 1
            : this.props.loggedInUserCompanyVersionNo
          : this.state.SelectedUserCompanyVersionId
        : this.props.companyVersion;

    if (versionId === 2) {
      this.addUpdateV2Data(versionId);
    } else {
      if (this.IsValid()) {
        var creditLimit =
          this.state.CreditLimit === undefined ||
          this.state.CreditLimit === "" ||
          this.state.CreditLimit === "null"
            ? null
            : this.state.CreditLimit;
        if (this.props.calledFrom === "add") {
          var dateObj = new Date();
          var regData = dateObj.toISOString();
          var data = {};
          if (
            this.props.loggedInUserRole === -10 ||
            this.props.loggedInUserRole === 5
          ) {
            data = {
              email: this.state.Email,
              subscriptionId: parseInt(this.state.SubscriptionId),
              storeText: this.state.StoreText,
              role: this.state.Role,
              uniqueNumber: this.state.UniqueNumber,
              defaultCountryCode:
                this.state.DefaultCountryCode === ""
                  ? 1
                  : parseInt(this.state.DefaultCountryCode),
              disabled: this.state.Disabled,
              contactLookupEnabled: this.state.ContactLookupEnabled,
              registrationDate: regData,
              creditLimit: creditLimit,
              costCode: this.state.CostalCode,
              UserName: this.state.Name,
              WhiteLabelBrand: this.state.whiteLabel,
              EmailEnabled: this.state.EmailEnabled,
              companyId: this.state.companyId,
            };
          } else {
            data = {
              email: this.state.Email,
              subscriptionId: parseInt(this.state.SubscriptionId),
              storeText: this.state.StoreText,
              role: this.state.Role,
              uniqueNumber: this.state.UniqueNumber,
              uniqueNumberCost: !this.state.UniqueNumberCost
                ? 0
                : parseFloat(this.state.UniqueNumberCost),
              defaultCountryCode: !this.state.DefaultCountryCode
                ? 1
                : parseInt(this.state.DefaultCountryCode),
              disabled: this.state.Disabled,
              contactLookupEnabled: this.state.ContactLookupEnabled,
              registrationDate: regData,
              creditLimit: creditLimit,
              costCode: this.state.CostalCode,
              UserName: this.state.Name,
              EmailEnabled: this.state.EmailEnabled,
            };
          }
          this.props.addUpdateUserProp("add", data, versionId);
          //console.log(data);
        } else {
          var dataBody = {};
          if (
            this.props.loggedInUserRole === -10 ||
            this.props.loggedInUserRole === 5
          ) {
            dataBody = {
              email: this.state.Email,
              subscriptionId: parseInt(this.state.SubscriptionId),
              storeText: this.state.StoreText,
              role: this.state.Role,
              usedCredit: this.state.UsedCredit,
              registrationDate: this.state.RegistrationDate,
              rooms: {},
              uniqueNumber: this.state.UniqueNumber,
              defaultCountryCode:
                this.state.DefaultCountryCode === 0 ||
                this.state.DefaultCountryCode === ""
                  ? 1
                  : parseInt(this.state.DefaultCountryCode),
              disabled: this.state.Disabled,
              contactLookupEnabled: this.state.ContactLookupEnabled,
              username: this.state.Name,
              creditLimit: creditLimit,
              whiteLabelBrand: this.state.whiteLabel,
              emailEnabled: this.state.EmailEnabled,
              companyId: null,
            };
          } else {
            dataBody = {
              email: this.state.Email,
              subscriptionId: parseInt(this.state.SubscriptionId),
              storeText: this.state.StoreText,
              role: this.state.Role,
              usedCredit: this.state.UsedCredit,
              registrationDate: this.state.RegistrationDate,
              rooms: {},
              uniqueNumber: this.state.UniqueNumber,
              defaultCountryCode:
                this.state.DefaultCountryCode === 0 ||
                this.state.DefaultCountryCode === ""
                  ? 1
                  : parseInt(this.state.DefaultCountryCode),
              disabled: this.state.Disabled,
              contactLookupEnabled: this.state.ContactLookupEnabled,
              username: this.state.Name,
              creditLimit: creditLimit,
              // "emailEnabled": this.state.EmailEnabled
            };
          }
          this.props.addUpdateUserProp("edit", dataBody, versionId);
        }

        if (
          this.state.EmailEnabled !== this.props.EmailEnabled ||
          this.state.ContactEmail !== this.props.editData.ContactEmail
        ) {
          const emailData = {
            enabled: this.state.EmailEnabled,
            contactEmail: this.state.ContactEmail,
            userEmail: this.state.Email,
          };
          this.props.updateUserEmailNotifications(emailData);
        }
      }
    }
  };
  creditLimitValidation = (e: any) => {
    var validCredit = commonMethod.validCredit(e.target.value);
    if (validCredit === true) {
      this.setState({
        CreditLimit: parseFloat(e.target.value),
        ErrorCreditLimit: "",
      });
    } else if (e.target.value === "null" || e.target.value === "") {
      this.setState({ CreditLimit: e.target.value, ErrorCreditLimit: "" });
    } else {
      this.setState({
        ErrorCreditLimit: "Credit Limit should be between 0-999",
        CreditLimit: e.target.value,
      });
    }
  };
  buyButtonPress = (e: any) => {
    if (this.props.calledFrom === "edit") {
      history.push("Numbers", {
        callingFrom: "Company:edit",
        companyId: this.props.companyId,
      });
    } else {
      history.push("Numbers", {
        callingFrom: "Company:add",
        companyId: this.props.companyId,
      });
    }
  };

  releaseNumberFromYakchat = () => {
    const userData = getLoggedInUSerName();
    const data = [
      {
        PhoneNumber: this.props.uniquePhoneNumber,
        Type: 0,
        CompanyId: this.props.companyId,
        ServiceProviderAccountId: 0,
        Trial: false,
        OnlyReceiveMFA: false,
        CreatedBy: userData.userName,
        ModifiedBy: userData.userName,
        CreatedDateTime: new Date(),
        ModifiedDateTime: new Date(),
      },
    ];
    this.props.removeNumberFromYakchat(data, "", this.props.calledFrom);
    //this.setState({ UniqueNumber: "" });
  };

  showBuyButton = () => {
    return this.state.UniqueNumber ? (
      <button
        type="button"
        className="btn btn-primary"
        onClick={(e) => {
          //this.releaseNumberFromYakchat();
          this.props.uniqueNumberProps("");
        }}
      >
        Release Number
      </button>
    ) : this.props.loggedInUserRole === -10 ||
      this.props.loggedInUserRole === 5 ? null : (
      <button
        type="button"
        className="btn btn-primary"
        onClick={(e) => this.buyButtonPress(e)}
      >
        Buy Number
      </button>
    );
  };
  renderButtonHtml = () => {
    return (
      <div className="col-12">
        <div className="btnDiv d-flex justify-content-between align-items-center">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => this.addUpdateData(e)}
            >
              Save changes
            </button>
            {/* show button to superadmin only  */}
            {this.props.loggedInUserRole === -10 &&
            this.props.companyId !== undefined
              ? this.showBuyButton()
              : null}

            <button
              type="button"
              className="btn btn-default cancelBtn"
              onClick={() => this.props.changeState()}
            >
              Cancel
            </button>

              <button
                type="button"
                onClick={() => this.PartnerMessage()}
                className="btn downloadBtn"
              >
                <i className="ion ion-md-download"></i>
                &nbsp; Message Log
              </button>
            <CSVLink
              data={this.state.messageCsv}
              filename="Partner_Message.csv"
              className="hidden"
              ref={this.csvPartnerMessage}
              target="_blank"
            />
          </div>
          <div>
            {this.props.calledFrom === "edit" ? (
              <button
                type="button"
                className="btn btn-primary delete_btn"
                data-toggle="modal"
                data-target="#exampleModal"
              >
                <span className="font-icon">&#107;</span>Delete
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  makeOptions = () => {
    let arr = null;
    if (this.props.loggedInUserRole === 0) {
      arr = [
        { label: "Admin", value: 0 },
        { label: "User", value: 20 },
      ];
    } else {
      arr = [
        { label: "Partner admin", value: 5 },
        { label: "Admin", value: 0 },
        { label: "User", value: 20 },
      ];
    }
    return arr;
  };

  renderHtml() {
    return (
      <div className="nav-tabs-top">
        <div className="tab-content border-0">
          <div className="tab-pane fade show active" id="user-edit-account">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="formDiv">
                    <div className="row text-left">
                      {
                        //(this.props.companyVersion === 2 || this.state.SelectedUserCompanyVersionId === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo === 2) ? (
                          <div className="col-md-12">
                            <div
                              className="col-md-6"
                              style={{ paddingLeft: 0 }}
                            >
                              <div className="form-group">
                                <label className="form-label">User Id</label>
                                <input
                                  type="text"
                                  className="form-control non-editable"
                                  value={this.state.UserId}
                                  disabled={true}
                                  name="validation-email"
                                />
                              </div>
                            </div>
                          </div>
                        ) : null
                      }

                      {
                        //(this.props.companyVersion === 2 || this.state.SelectedUserCompanyVersionId === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?

                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo === 2) ? (
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorName !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                First Name
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="validation-email"
                                placeholder="Name"
                                onChange={(e) => {
                                  this.setState({
                                    FirstName: e.target.value,
                                    ErrorName: "",
                                  });
                                  this.props.firstNameSetForAddEdit(
                                    e.target.value
                                  );
                                  let displayName =
                                    (e.target.value ?? "") +
                                    " " +
                                    (this.state.LastName === undefined
                                      ? ""
                                      : this.state.LastName.trim());
                                  this.setState({
                                    Name: displayName,
                                  });
                                  this.props.nameSetForAddEdit(displayName);
                                }}
                                value={this.state.FirstName}
                              />
                              <small className="form-text">
                                {this.state.ErrorName}{" "}
                              </small>
                            </div>
                          </div>
                        ) : null
                      }
                      {
                        //(this.props.companyVersion === 2 || this.state.SelectedUserCompanyVersionId === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo === 2) ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">Last Name</label>
                              <input
                                type="text"
                                placeholder="Last Name"
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    LastName: e.target.value,
                                  });
                                  this.props.lastNameSetForAddEdit(
                                    e.target.value
                                  );
                                  let displayName =
                                    (this.state.FirstName === undefined
                                      ? ""
                                      : this.state.FirstName.trim()) +
                                    " " +
                                    (e.target.value.trim() ?? "");
                                  this.setState({
                                    Name: displayName,
                                  });
                                  this.props.nameSetForAddEdit(displayName);
                                }}
                                value={this.state.LastName}
                              />
                            </div>
                          </div>
                        ) : null
                      }
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorName !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          {
                            //(this.props.companyVersion === 2 || this.state.SelectedUserCompanyVersionId === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                            this.props.companyVersion === 2 ||
                            this.state.SelectedUserCompanyVersionId === 2 ||
                            (!(
                              this.props.loggedInUserRole === -10 ||
                              this.props.loggedInUserRole === 5
                            ) &&
                              this.state.LoggedInUserCompanyVersionNo === 2) ? (
                              <>
                                <label className="form-label">
                                  Display Name
                                  <small className="text-muted">*</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="display-name"
                                  placeholder="Display Name"
                                  disabled={true}
                                  onChange={(e) => {
                                    this.setState({
                                      Name: e.target.value,
                                      ErrorName: "",
                                    });
                                    this.props.nameSetForAddEdit(
                                      e.target.value
                                    );
                                  }}
                                  value={this.state.Name}
                                />
                                <small className="form-text">
                                  {this.state.ErrorName}{" "}
                                </small>
                              </>
                            ) : (
                              <>
                                <label className="form-label">
                                  Name
                                  <small className="text-muted">*</small>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="validation-email"
                                  placeholder="Name"
                                  onChange={(e) => {
                                    this.setState({
                                      Name: e.target.value,
                                      ErrorName: "",
                                    });
                                    this.props.nameSetForAddEdit(
                                      e.target.value
                                    );
                                  }}
                                  value={this.state.Name}
                                />
                                <small className="form-text">
                                  {this.state.ErrorName}{" "}
                                </small>
                              </>
                            )
                          }
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorEmail !== "" ||
                            this.state.InvalidEmail !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Email<small className="text-muted">*</small>
                          </label>
                          <input
                            type="text"
                            className={
                              this.props.calledFrom === "edit"
                                ? "form-control non-editable"
                                : "form-control "
                            }
                            name="validation-email"
                            placeholder="Email"
                            disabled={
                              this.props.calledFrom === "edit" ? true : false
                            }
                            onChange={(e) => {
                              var valid = commonMethod.validEmail(
                                e.target.value
                              );
                              if (!valid) {
                                this.setState({
                                  InvalidEmail:
                                    "Please Enter Valid Email Address",
                                });
                              } else {
                                this.setState({ InvalidEmail: "" });
                              }
                              this.setState({
                                Email: e.target.value,
                                ErrorEmail: "",
                              });
                              this.props.emailSetForAddEdit(e.target.value);
                            }}
                            value={this.state.Email}
                          />
                          <small className="form-text">
                            {this.state.ErrorEmail}{" "}
                          </small>
                          <small className="form-text">
                            {this.state.InvalidEmail}{" "}
                          </small>
                        </div>
                      </div>

                      {this.props.calledFrom === "edit" ? (
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ? (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">Company</label>
                              <Select
                                className={
                                  this.state.ErrorCompanyID !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                isDisabled={
                                  !(
                                    this.props.loggedInUserRole === -10 ||
                                    this.props.loggedInUserRole === 5
                                  ) ||
                                  !(
                                    this.state.companyId === undefined ||
                                    this.state.companyId === null ||
                                    this.state.companyId === ""
                                  )
                                }
                                value={[
                                  {
                                    label: this.state.SelectedCompanyName,
                                    value: this.state.SelectedCompanyId,
                                  },
                                ]}
                                components={{ DropdownIndicator }}
                                onChange={(opt: any) =>
                                  this.setState({
                                    SelectedCompanyId: opt.value,
                                    SelectedCompanyName: opt.label,
                                    ErrorCompanyID: "",
                                  })
                                }
                                options={this.props.companyListProps.map(
                                  (opt: any) => ({
                                    label: opt.Name,
                                    value: opt.Id,
                                  })
                                )}
                              />
                            </div>
                          </div>
                        ) : null
                      ) : this.props.companyVersion === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo === 2) ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Company</label>
                            <Select
                              className={
                                this.state.ErrorCompanyID !== ""
                                  ? " validationClass"
                                  : ""
                              }
                              isDisabled={
                                !(
                                  this.props.loggedInUserRole === -10 ||
                                  this.props.loggedInUserRole === 5
                                ) ||
                                !(
                                  this.state.companyId === undefined ||
                                  this.state.companyId === null ||
                                  this.state.companyId === ""
                                )
                              }
                              value={[
                                {
                                  label: this.state.SelectedCompanyName,
                                  value: this.state.SelectedCompanyId,
                                },
                              ]}
                              components={{ DropdownIndicator }}
                              onChange={(opt: any) =>
                                this.setState({
                                  SelectedCompanyId: opt.value,
                                  SelectedCompanyName: opt.label,
                                  ErrorCompanyID: "",
                                })
                              }
                              options={this.props.companyListProps.map(
                                (opt: any) => ({
                                  label: opt.Name,
                                  value: opt.Id,
                                })
                              )}
                            />
                          </div>
                        </div>
                      ) : null}

                      {
                        //this.props.versionId === 2 && this.props.companyId === undefined
                        this.props.calledFrom === "edit" ? (
                          this.props.companyVersion === 2 ||
                          this.state.SelectedUserCompanyVersionId ===
                            2 ? null : (
                            <div className="col-md-6">
                              <div
                                className={
                                  this.state.ErrorSubscription !== ""
                                    ? "form-group validationClass zIndex"
                                    : "form-group zIndex"
                                }
                              >
                                <label className="form-label">
                                  Subscription
                                  <small className="text-muted">* </small>
                                </label>
                                <Select
                                  className={
                                    this.state.ErrorSubscription !== ""
                                      ? " validationClass"
                                      : ""
                                  }
                                  value={[
                                    {
                                      label: this.state.SelectedSubscName,
                                      value: this.state.SubscriptionId,
                                    },
                                  ]}
                                  components={{
                                    DropdownIndicator,
                                  }}
                                  onChange={(opt: any) => {
                                    this.props.getUnAssignedNumbers(
                                      null,
                                      opt.value
                                    );
                                    this.props.subscriptionIdSetForAddEdit(
                                      opt.value
                                    );
                                    this.props.subscriptionNameForAddEdit(
                                      opt.label
                                    );
                                    this.setState({
                                      SubscriptionId: opt.value,
                                      SelectedSubscName: opt.label,
                                      ErrorSubscription: "",
                                    });
                                  }}
                                  options={this.state.subscriptionList.map(
                                    (opt: any) => ({
                                      label: opt.subscription.Name,
                                      value: opt.subscription.Id,
                                    })
                                  )}
                                />
                                <small className="form-text ">
                                  {this.state.ErrorSubscription}{" "}
                                </small>
                              </div>
                            </div>
                          )
                        ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                        this.props.companyVersion === 2 ||
                          (!(
                            this.props.loggedInUserRole === -10 ||
                            this.props.loggedInUserRole === 5
                          ) &&
                            this.state.LoggedInUserCompanyVersionNo ===
                              2) ? null : (
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorSubscription !== ""
                                  ? "form-group validationClass zIndex"
                                  : "form-group zIndex"
                              }
                            >
                              <label className="form-label">
                                Subscription
                                <small className="text-muted">* </small>
                              </label>
                              <Select
                                className={
                                  this.state.ErrorSubscription !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                value={[
                                  {
                                    label: this.state.SelectedSubscName,
                                    value: this.state.SubscriptionId,
                                  },
                                ]}
                                components={{
                                  DropdownIndicator,
                                }}
                                onChange={(opt: any) => {
                                  this.props.getUnAssignedNumbers(
                                    null,
                                    opt.value
                                  );
                                  this.props.subscriptionIdSetForAddEdit(
                                    opt.value
                                  );
                                  this.props.subscriptionNameForAddEdit(
                                    opt.label
                                  );
                                  this.setState({
                                    SubscriptionId: opt.value,
                                    SelectedSubscName: opt.label,
                                    ErrorSubscription: "",
                                  });
                                }}
                                options={this.state.subscriptionList.map(
                                  (opt: any) => ({
                                    label: opt.subscription.Name,
                                    value: opt.subscription.Id,
                                  })
                                )}
                              />
                              <small className="form-text ">
                                {this.state.ErrorSubscription}{" "}
                              </small>
                            </div>
                          </div>
                        )
                      }

                      {this.props.calledFrom === "edit" ? (
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ? (
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorEmail !== "" ||
                                this.state.InvalidAliesEmail !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Alias Email
                                {/* <small className="text-muted">*</small> */}
                              </label>
                              <input
                                type="text"
                                className={
                                  this.props.calledFrom === "edit"
                                    ? "form-control non-editable"
                                    : "form-control "
                                }
                                name="validation-email"
                                placeholder="Alias Email"
                                onChange={(e) => {
                                  var valid = commonMethod.validEmail(
                                    e.target.value
                                  );
                                  if (!valid) {
                                    this.setState({
                                      InvalidAliesEmail:
                                        "Please Enter Valid Email Address",
                                    });
                                  } else {
                                    this.setState({ InvalidAliesEmail: "" });
                                  }
                                  this.setState({
                                    AliasEmail: e.target.value,
                                    ErrorEmail: "",
                                  });
                                  this.props.aliasEmailSetForAddEdit(
                                    e.target.value
                                  );
                                }}
                                value={this.state.AliasEmail}
                              />
                              <small className="form-text">
                                {this.state.ErrorEmail}{" "}
                              </small>
                              <small className="form-text">
                                {this.state.InvalidAliesEmail}{" "}
                              </small>
                            </div>
                          </div>
                        ) : null
                      ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                      this.props.companyVersion === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo === 2) ? (
                        <div className="col-md-6">
                          <div
                            className={
                              this.state.ErrorEmail !== "" ||
                              this.state.InvalidAliesEmail !== ""
                                ? "form-group validationClass"
                                : "form-group"
                            }
                          >
                            <label className="form-label">
                              Alias Email
                              {/* <small className="text-muted">*</small> */}
                            </label>
                            <input
                              type="text"
                              className={
                                this.props.calledFrom === "edit"
                                  ? "form-control non-editable"
                                  : "form-control "
                              }
                              name="validation-email"
                              placeholder="Alias Email"
                              onChange={(e) => {
                                var valid = commonMethod.validEmail(
                                  e.target.value
                                );
                                if (!valid) {
                                  this.setState({
                                    InvalidAliesEmail:
                                      "Please Enter Valid Email Address",
                                  });
                                } else {
                                  this.setState({ InvalidAliesEmail: "" });
                                }
                                this.setState({
                                  AliasEmail: e.target.value,
                                  ErrorEmail: "",
                                });
                                this.props.aliasEmailSetForAddEdit(
                                  e.target.value
                                );
                              }}
                              value={this.state.AliasEmail}
                            />
                            <small className="form-text">
                              {this.state.ErrorEmail}{" "}
                            </small>
                            <small className="form-text">
                              {this.state.InvalidAliesEmail}{" "}
                            </small>
                          </div>
                        </div>
                      ) : null}

                      {this.props.calledFrom === "edit" ? (
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ? null : (
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorPhoneNumber !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">Number</label>
                              <div className="textPhoneNumber">
                                {this.state.UniqueNumber}
                              </div>
                              <Select
                                className={
                                  this.state.ErrorPhoneNumber !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                isSearchable={true}
                                isClearable={true}
                                components={{
                                  DropdownIndicator,
                                  SingleValue: () => {
                                    return null;
                                  },
                                }}
                                value={[
                                  {
                                    label: this.state.UniqueNumber,
                                    value: this.state.UniqueNumber,
                                  },
                                ]}
                                onChange={(opt: any) => {
                                  if (opt === null) {
                                    this.props.uniqueNumberProps("");
                                    this.setState({ ErrorPhoneNumber: "" });
                                  } else {
                                    this.props.uniqueNumberProps(opt.label);
                                    this.setState({ ErrorPhoneNumber: "" });
                                  }
                                }}
                                options={
                                  this.state.listUnAssignedNumbers &&
                                  this.state.listUnAssignedNumbers.length
                                    ? this.state.listUnAssignedNumbers.map(
                                        (opt: any) => ({
                                          label: opt.PhoneNumber,
                                          value: opt.id,
                                        })
                                      )
                                    : []
                                }
                              />
                              <small className="form-text ">
                                {this.state.ErrorPhoneNumber}{" "}
                              </small>
                            </div>
                          </div>
                        )
                      ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?

                      this.props.companyVersion === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo ===
                            2) ? null : (
                        <div className="col-md-6">
                          <div
                            className={
                              this.state.ErrorPhoneNumber !== ""
                                ? "form-group validationClass"
                                : "form-group"
                            }
                          >
                            <label className="form-label">Number</label>
                            <div className="textPhoneNumber">
                              {this.state.UniqueNumber}
                            </div>
                            <Select
                              className={
                                this.state.ErrorPhoneNumber !== ""
                                  ? " validationClass"
                                  : ""
                              }
                              isSearchable={true}
                              isClearable={true}
                              components={{
                                DropdownIndicator,
                                SingleValue: () => {
                                  return null;
                                },
                              }}
                              value={[
                                {
                                  label: this.state.UniqueNumber,
                                  value: this.state.UniqueNumber,
                                },
                              ]}
                              onChange={(opt: any) => {
                                if (opt === null) {
                                  this.props.uniqueNumberProps("");
                                  this.setState({ ErrorPhoneNumber: "" });
                                } else {
                                  this.props.uniqueNumberProps(opt.label);
                                  this.setState({ ErrorPhoneNumber: "" });
                                }
                              }}
                              options={
                                this.state.listUnAssignedNumbers &&
                                this.state.listUnAssignedNumbers.length
                                  ? this.state.listUnAssignedNumbers.map(
                                      (opt: any) => ({
                                        label: opt.PhoneNumber,
                                        value: opt.id,
                                      })
                                    )
                                  : []
                              }
                            />
                            <small className="form-text ">
                              {this.state.ErrorPhoneNumber}{" "}
                            </small>
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="form-label">Role</label>
                          {this.props.loggedInUserRole === 5 ? (
                            this.state.Role === 5 ? (
                              <Select
                                className={
                                  this.state.ErrorPhoneNumber !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                isSearchable={false}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                value={[
                                  {
                                    label:
                                      this.state.Role === 10
                                        ? "Subscription Admin"
                                        : this.state.Role === 20
                                        ? "User"
                                        : this.state.Role === 5
                                        ? "Partner Admin"
                                        : "Admin",

                                    value: this.state.Role,
                                  },
                                ]}
                                onChange={(opt: any) => {
                                  this.props.roleForAddEdit(
                                    parseInt(opt.value)
                                  );
                                  this.setState({ Role: parseInt(opt.value) });
                                }}
                                options={[
                                  { label: "User", value: 20 },
                                  { label: "Partner admin", value: 5 },
                                  { label: "Admin", value: 0 },
                                ]}
                              />
                            ) : (
                              <Select
                                className={
                                  this.state.ErrorPhoneNumber !== ""
                                    ? " validationClass"
                                    : ""
                                }
                                isSearchable={false}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                value={[
                                  {
                                    label:
                                      this.state.Role === 10
                                        ? "Subscription Admin"
                                        : this.state.Role === 20
                                        ? "User"
                                        : this.state.Role === 5
                                        ? "Partner Admin"
                                        : "Admin",

                                    value: this.state.Role,
                                  },
                                ]}
                                onChange={(opt: any) => {
                                  this.props.roleForAddEdit(
                                    parseInt(opt.value)
                                  );
                                  this.setState({ Role: parseInt(opt.value) });
                                }}
                                options={[
                                  { label: "User", value: 20 },
                                  { label: "Partner admin", value: 5 },
                                  { label: "Admin", value: 0 },
                                ]}
                              />
                            )
                          ) : (
                            <Select
                              className={
                                this.state.ErrorPhoneNumber !== ""
                                  ? " validationClass"
                                  : ""
                              }
                              isSearchable={false}
                              isClearable={false}
                              components={{ DropdownIndicator }}
                              value={[
                                {
                                  label:
                                    this.state.Role === 10
                                      ? "Subscription Admin"
                                      : this.state.Role === 20
                                      ? "User"
                                      : this.state.Role === 5
                                      ? "Partner Admin"
                                      : "Admin",
                                  value: this.state.Role,
                                },
                              ]}
                              onChange={(opt: any) => {
                                this.props.roleForAddEdit(parseInt(opt.value));
                                this.setState({ Role: parseInt(opt.value) });
                              }}
                              options={this.makeOptions()}
                            />
                          )}
                        </div>
                      </div>

                      {this.props.calledFrom === "edit" ? (
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ? null : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">Country Code</label>
                              <Select
                                isSearchable={false}
                                isClearable={false}
                                components={{ DropdownIndicator }}
                                value={[
                                  {
                                    label:
                                      this.state.DefaultCountryCode === 1
                                        ? "1"
                                        : this.state.DefaultCountryCode === 44
                                        ? "44"
                                        : "61",
                                    value: this.state.DefaultCountryCode,
                                  },
                                ]}
                                onChange={(opt: any) => {
                                  this.props.defaultCountryCodeMethod(
                                    parseInt(opt.value)
                                  );
                                  this.setState({
                                    DefaultCountryCode: parseInt(opt.value),
                                  });
                                }}
                                options={[
                                  { label: "1", value: 1 },
                                  { label: "44", value: 44 },
                                  { label: "61", value: 61 },
                                ]}
                              />
                            </div>
                          </div>
                        )
                      ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                      this.props.companyVersion === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo ===
                            2) ? null : (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">Country Code</label>
                            <Select
                              isSearchable={false}
                              isClearable={false}
                              components={{ DropdownIndicator }}
                              value={[
                                {
                                  label:
                                    this.state.DefaultCountryCode === 1
                                      ? "1"
                                      : this.state.DefaultCountryCode === 44
                                      ? "44"
                                      : "61",
                                  value: this.state.DefaultCountryCode,
                                },
                              ]}
                              onChange={(opt: any) => {
                                this.props.defaultCountryCodeMethod(
                                  parseInt(opt.value)
                                );
                                this.setState({
                                  DefaultCountryCode: parseInt(opt.value),
                                });
                              }}
                              options={[
                                { label: "1", value: 1 },
                                { label: "44", value: 44 },
                                { label: "61", value: 61 },
                              ]}
                            />
                          </div>
                        </div>
                      )}
                      {
                        /*  1. If the value of version=2 and we go from user category page:
                            2. If the value of version=2 and we go from user tab from company page:
                          for both point 1 & 2: Subscription and Credit limit field will be hide.
                        */
                        //this.props.versionId === 2 && this.props.companyId === undefined

                        this.props.calledFrom === "edit" ? (
                          this.props.companyVersion === 2 ||
                          this.state.SelectedUserCompanyVersionId ===
                            2 ? null : (
                            <div className="col-md-6">
                              <div
                                className={
                                  this.state.ErrorCreditLimit !== ""
                                    ? "form-group validationClass"
                                    : "form-group"
                                }
                              >
                                <label className="form-label">
                                  Credit Limit
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    this.props.creditLimitForAddEdit(
                                      e.target.value
                                    );
                                    this.creditLimitValidation(e);
                                  }}
                                  value={
                                    this.state.CreditLimit === null
                                      ? ""
                                      : this.state.CreditLimit
                                  }
                                  name="validation-email"
                                  placeholder="CreditLimit"
                                  required
                                />
                                <small className="form-text ">
                                  {this.state.ErrorCreditLimit}{" "}
                                </small>
                              </div>
                            </div>
                          )
                        ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                        this.props.companyVersion === 2 ||
                          (!(
                            this.props.loggedInUserRole === -10 ||
                            this.props.loggedInUserRole === 5
                          ) &&
                            this.state.LoggedInUserCompanyVersionNo ===
                              2) ? null : (
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.ErrorCreditLimit !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">Credit Limit</label>
                              <input
                                type="text"
                                className="form-control"
                                onChange={(e) => {
                                  this.props.creditLimitForAddEdit(
                                    e.target.value
                                  );
                                  this.creditLimitValidation(e);
                                }}
                                value={
                                  this.state.CreditLimit === null
                                    ? ""
                                    : this.state.CreditLimit
                                }
                                name="validation-email"
                                placeholder="CreditLimit"
                                required
                              />
                              <small className="form-text ">
                                {this.state.ErrorCreditLimit}{" "}
                              </small>
                            </div>
                          </div>
                        )
                      }
                      {this.props.loggedInUserRole === -10 ||
                      this.props.loggedInUserRole === 5 ? (
                        this.props.calledFrom === "edit" ? (
                          this.props.companyVersion === 2 ||
                          this.state.SelectedUserCompanyVersionId ===
                            2 ? null : (
                            <div className="col-md-6">
                              <div className="form-group">
                                <label className="form-label">
                                  White Label
                                </label>
                                <input
                                  type="text"
                                  placeholder="White Label"
                                  className="form-control"
                                  onChange={(e) => {
                                    this.setState({
                                      whiteLabel: e.target.value
                                        .trim()
                                        .toLocaleLowerCase(),
                                    });
                                  }}
                                  value={this.state.whiteLabel}
                                />
                              </div>
                            </div>
                          )
                        ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                        this.props.companyVersion === 2 ||
                          (!(
                            this.props.loggedInUserRole === -10 ||
                            this.props.loggedInUserRole === 5
                          ) &&
                            this.state.LoggedInUserCompanyVersionNo ===
                              2) ? null : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">White Label</label>
                              <input
                                type="text"
                                placeholder="White Label"
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    whiteLabel: e.target.value
                                      .trim()
                                      .toLocaleLowerCase(),
                                  });
                                }}
                                value={this.state.whiteLabel}
                              />
                            </div>
                          </div>
                        )
                      ) : null}
                      {this.props.calledFrom === "edit" ? (
                        <div className="col-md-6">
                          <div className={"form-group"}>
                            <label className="form-label">Created Date</label>
                            <input
                              type="Date"
                              className="form-control"
                              placeholder="Created Date"
                              value={
                                this.state.RegistrationDate !== "" ||
                                this.state.RegistrationDate !== undefined
                                  ? this.state.RegistrationDate.split("T")[0]
                                  : this.state.RegistrationDate
                              }
                              required
                              disabled={
                                this.props.loggedInUserRole === -10
                                  ? false
                                  : true
                              }
                              onChange={(e) =>
                                this.setState({
                                  RegistrationDate: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                      <div className="w-100"></div>

                      {
                        // added by maksud
                        this.props.calledFrom === "edit" ? (
                          this.props.companyVersion === 2 ||
                          this.state.SelectedUserCompanyVersionId ===
                            2 ? null : this.state.subscriptionList
                              .filter(
                                (opt: any) =>
                                  opt.subscription.Id ===
                                  this.props.editData.SubscriptionId
                              )
                              .map(
                                (ele: any) => ele.subscription.ServicePlanId
                              )[0] !== 6 ? (
                            <div className="col-md-6">
                              <div className="form-group mt-3">
                                <label className="custom-control custom-checkbox m-0 checkbox_right">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    onChange={() => {
                                      this.setState({
                                        EmailEnabled: !this.state.EmailEnabled,
                                      });
                                    }}
                                    checked={this.state.EmailEnabled}
                                  />
                                  <span className="custom-control-label">
                                    Email Text
                                  </span>
                                </label>
                              </div>
                            </div>
                          ) : null
                        ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?

                        this.props.companyVersion === 2 ||
                          (!(
                            this.props.loggedInUserRole === -10 ||
                            this.props.loggedInUserRole === 5
                          ) &&
                            this.state.LoggedInUserCompanyVersionNo ===
                              2) ? null : this.state.subscriptionList
                            .filter(
                              (opt: any) =>
                                opt.subscription.Id ===
                                this.props.editData.SubscriptionId
                            )
                            .map(
                              (ele: any) => ele.subscription.ServicePlanId
                            )[0] !== 6 ? (
                          <div className="col-md-6">
                            <div className="form-group mt-3">
                              <label className="custom-control custom-checkbox m-0 checkbox_right">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={() => {
                                    this.setState({
                                      EmailEnabled: !this.state.EmailEnabled,
                                    });
                                  }}
                                  checked={this.state.EmailEnabled}
                                />
                                <span className="custom-control-label">
                                  Email Text
                                </span>
                              </label>
                            </div>
                          </div>
                        ) : null
                      }
                      {this.state.subscriptionList
                        .filter(
                          (opt: any) =>
                            opt.subscription.Id ===
                            this.props.editData.SubscriptionId
                        )
                        .map(
                          (ele: any) => ele.subscription.ContactLookupEnabled
                        )[0] === true ? (
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label className="custom-control custom-checkbox m-0 checkbox_right">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={() => {
                                  this.props.ContactLookupEnabledForAddEditUser(
                                    !this.state.ContactLookupEnabled
                                  );
                                  this.setState({
                                    ContactLookupEnabled:
                                      !this.state.ContactLookupEnabled,
                                  });
                                }}
                                checked={this.state.ContactLookupEnabled}
                              />
                              <span className="custom-control-label">
                                Microsoft Contact Lookup
                              </span>
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {this.props.calledFrom === "edit" ? (
                        <div className="col-md-6">
                          <div className="form-group mt-3">
                            <label className="custom-control custom-checkbox m-0 checkbox_right">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                onChange={() => {
                                  this.props.disableForAddEdit(
                                    !this.state.Disabled
                                  );
                                  this.setState({
                                    Disabled: !this.state.Disabled,
                                  });
                                }}
                                checked={this.state.Disabled}
                              />
                              <span className="custom-control-label">
                                Disabled
                              </span>
                            </label>
                          </div>
                        </div>
                      ) : null}

                      {this.props.calledFrom === "edit" ? (
                        this.props.companyVersion === 2 ||
                        this.state.SelectedUserCompanyVersionId === 2 ? null : (
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label">
                                Alternative Notification Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    ContactEmail: e.target.value,
                                  });
                                }}
                                value={this.state.ContactEmail}
                                placeholder="Alternative Email address"
                              />
                            </div>
                          </div>
                        )
                      ) : //(this.props.companyVersion === 2 || this.state.LoggedInUserCompanyVersionNo === 2) ?
                      this.props.companyVersion === 2 ||
                        (!(
                          this.props.loggedInUserRole === -10 ||
                          this.props.loggedInUserRole === 5
                        ) &&
                          this.state.LoggedInUserCompanyVersionNo ===
                            2) ? null : (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Alternative Notification Email
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              onChange={(e) => {
                                this.setState({ ContactEmail: e.target.value });
                              }}
                              value={this.state.ContactEmail}
                              placeholder="Alternative Email address"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {this.renderButtonHtml()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    return (
      <React.Fragment>
        {
          //Display content without tabs
          this.props.companyId !== undefined ? (
            <div className="container-fluid subscription_box">
              <h4 className="font-weight-bold text-left addUser_head">
                {this.props.calledFrom === "edit" ? "Edit User" : "Add User"}
              </h4>
              {this.renderHtml()}
            </div>
          ) : (
            //Display content inside tabs
            <div className="container-fluid subscription_box">
              <h2 className="companyName  text-left">
                <span
                  className="iconNavigation cursor_pointer"
                  onClick={() => this.props.changeState()}
                >
                  <i
                    className="fa fa-chevron-left cursor_pointer"
                    aria-hidden="true"
                  ></i>
                </span>
                Users /{" "}
                {this.props.calledFrom === "edit" ? (
                  <b>
                    {this.state.Name !== ""
                      ? this.state.Name
                      : this.state.Email}
                  </b>
                ) : (
                  <b>New User</b>
                )}
              </h2>
              <Tabs
                className="tabGroupMember"
                defaultActiveKey={this.state.activeTab}
                onSelect={(currentTabName: any) => {
                  this.setState({
                    activeTab: currentTabName,
                    companyName: "",
                  });
                }}
                id="uncontrolled-tab-example"
              >
                <Tab
                  eventKey="User-Details"
                  title={
                    <span>
                      <span className="font-icon">&#67;</span> User Details
                    </span>
                  }
                >
                  {this.renderHtml()}
                </Tab>
              </Tabs>
            </div>
          )
        }

        {this.props.calledFrom === "edit" ? (
          this.state.SelectedUserCompanyVersionId === 2 ? (
            <ConfirmationDeleteComponent
              deleteSucces={(confirm: boolean, versionId: any) => {
                this.props.deleteUserProps(
                  this.state.Email,
                  confirm,
                  versionId,
                  this.props.editData.Id
                );
              }}
              editUser={this.props.editData}
              removeUserDataObject={this.state.RemoveUserDataObject}
              companyVersionId={
                this.props.companyVersion === undefined ||
                this.props.companyVersion === null
                  ? this.state.SelectedUserCompanyVersionId === undefined ||
                    this.state.SelectedUserCompanyVersionId === null
                    ? this.props.loggedInUserCompanyVersionNo === undefined ||
                      this.props.loggedInUserCompanyVersionNo === null
                      ? 1
                      : this.props.loggedInUserCompanyVersionNo
                    : this.state.SelectedUserCompanyVersionId
                  : this.props.companyVersion
              }
            />
          ) : (
            <ConfirmationDeleteComponent
              deleteSucces={(confirm: boolean) => {
                const versionId =
                  this.props.companyVersion === undefined ||
                  this.props.companyVersion === null
                    ? this.state.SelectedUserCompanyVersionId === undefined ||
                      this.state.SelectedUserCompanyVersionId === null
                      ? this.props.loggedInUserCompanyVersionNo === undefined ||
                        this.props.loggedInUserCompanyVersionNo === null
                        ? 1
                        : this.props.loggedInUserCompanyVersionNo
                      : this.state.SelectedUserCompanyVersionId
                    : this.props.companyVersion;
                this.props.deleteUserProps(
                  this.state.Email,
                  confirm,
                  versionId,
                  this.props.editData.Id
                );
              }}
              editUser={this.props.editData}
            />
          )
        ) : null}
        {/* <ConfirmationDeleteComponent
          deleteSucces={(confirm: boolean) => {
            debugger;
            const versionId = (this.props.companyVersion === undefined || this.props.companyVersion === null) ?
              (this.state.SelectedUserCompanyVersionId === undefined || this.state.SelectedUserCompanyVersionId === null) ?
                (this.props.loggedInUserCompanyVersionNo === undefined || this.props.loggedInUserCompanyVersionNo === null) ?
                  1 : this.props.loggedInUserCompanyVersionNo
                : this.state.SelectedUserCompanyVersionId
              : this.props.companyVersion;         
            this.props.deleteUserProps(this.state.Email, confirm, versionId, this.props.editData.Id);
          }}
          editUser={this.props.editData}
          companyVersionId=
          {
            (this.props.companyVersion === undefined || this.props.companyVersion === null) ?
              (this.state.SelectedUserCompanyVersionId === undefined || this.state.SelectedUserCompanyVersionId === null) ?
                (this.props.loggedInUserCompanyVersionNo === undefined || this.props.loggedInUserCompanyVersionNo === null) ?
                  1 : this.props.loggedInUserCompanyVersionNo
                : this.state.SelectedUserCompanyVersionId
              : this.props.companyVersion
          }

        /> */}
      </React.Fragment>
    );
  }
}
