import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getLoggedInUSerName } from '../../adalConfig';
import * as commonMethod from "../../commonMethods";
class ConfirmationUserGroupDeleteComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props)
    this.state = {
      ConfirmaDeleteEmail: "",
      InvalidConfirmDeleteEmail: "",
      ErrorEmail: "",
      removeUserDataObject: this.props.removeUserDataObject,
      ConfirmDelete: false,
      DeleteAllData: true, //false,
      RadioButton: "dalldata", // "duseronly",


    }
  }
  deleteAllUserData() {
    if (this.state.ConfirmaDeleteEmail !== '') {
      this.props.deleteSucces(true);
    }
    else {

      this.setState({
        InvalidConfirmDeleteEmail:
          "Please enter valid user id ( email )",
      });
    }
  }

  deleteV2UserData() {
    if (this.state.ConfirmDelete === 'DELETE') {
      this.props.deleteSucces(this.state.DeleteAllData, 2);
    }
    else {
      this.setState({
        InvalidConfirmDeleteEmail:
          "Please enter valid text for delete",
      });
    }
    this.setState({
      ConfirmDelete: "",
    });
  }


  componentDidMount() {
    var userData = getLoggedInUSerName();
    if (userData !== null) {
      if (userData.profile !== null) {
        this.setState({
          loggedInUserName: userData.userName
        })
      }
    }
  }
  renderHtml() {
    return (
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-header-user-delete">
              <i className='fas fa-exclamation-triangle delete_alert_icon' ></i>

              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>The user’s text messages will be deleted when a User or Group is deleted. Once deleted, the text messages cannot be recovered.  </p>
              <p>Use the ‘disable’ checkbox to remove a user license without deleting the associated text messages. If you are sure you want to delete the User/Group and associated text messages then enter your User ID (your Email address for tracking) into the box below and click the Delete button.</p>
              {
                <div className='user-delete'>

                  <input
                    type="text"
                    className="form-control"
                    name="validation-email"
                    placeholder="User ID (Email)"
                    disabled={
                      this.props.calledFrom === "edit" ? true : false
                    }
                    onChange={(e) => {
                      var valid = commonMethod.validEmail(
                        e.target.value
                      );
                      if (!valid) {
                        this.setState({
                          InvalidConfirmDeleteEmail:
                            "Please Enter Valid Email Address",
                        });
                      }
                      else {
                        this.setState({ InvalidConfirmDeleteEmail: "" });
                      }
                      if (this.state.loggedInUserName === e.target.value) {
                        this.setState({
                          ConfirmaDeleteEmail: e.target.value,
                          ErrorEmail: "",
                        });
                      }
                      else {
                        this.setState({
                          InvalidConfirmDeleteEmail:
                            "Please enter valid user id ( email )",
                        });
                      }

                    }
                    }
                    value={this.state.ConfirmDeleteEmail}
                  />
                  <p className='validationClass'>
                    <small className="form-text">
                      {this.state.ErrorEmail}{" "}
                    </small>
                    <small className="form-text">
                      {this.state.InvalidConfirmDeleteEmail}{" "}
                    </small>
                  </p>
                </div>
              }
            </div>
            <div className="modal-footer model-footer-user-delete">
              <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary delete_btn_dialog_footer" data-dismiss={this.state.ConfirmaDeleteEmail !== '' ? "modal" : null} onClick={() => { this.deleteAllUserData() }}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderHtmlV2() {

    return (
      <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog v2-user-delete-model" role="document">
          <div className="modal-content modal-content-v2-delete">
            <div className="modal-header v2-user-delete-modal-header">
              <h5 className="modal-title">Are you sure you want to delete this user?</h5>
            </div>
            <div className="modal-body v2-user-delete-modal-content">
              <div className=" v2-user-delete-modal-deleteprompt">

                {/* {
                  this.props.removeUserDataObject?.CanDeleteUserOnly === true ?
                    (
                      <div className="v2-user-delete-modal-deleteprompt-form">
                        <div className="v2-user-delete-modal-deleteprompt-form-rt">
                          <div className="v2-user-delete-modal-deleteprompt-form-tb">
                            <input className="" value="duseronly" type="radio" name="duseronly" id="duseronly"
                              onChange={(e) => {
                                this.setState({
                                  DeleteAllData: false,
                                });

                                this.setState({
                                  RadioButton: e.target.value,
                                });

                              }}
                              checked={this.state.RadioButton === "duseronly"}
                            />
                            <label className="form-check-label v2-user-delete-modal-deleteprompt-form-tb-text" htmlFor="duseronly">
                              Delete just the user and unlink from the inbox
                            </label>
                          </div>
                        </div>
                        <div className="v2-user-delete-modal-deleteprompt-form-rt">
                          <div className="v2-user-delete-modal-deleteprompt-form-tb">
                            <input className="" type="radio" value="dalldata" name="duseronly" id="dalldata"

                              onChange={(e) => {

                                this.setState({
                                  DeleteAllData: true,
                                })
                                this.setState({
                                  RadioButton: e.target.value,
                                });
                              }}
                              checked={this.state.RadioButton === "dalldata"}
                            />
                            <label className="form-check-label v2-user-delete-modal-deleteprompt-form-tb-text" htmlFor="dalldata">
                              Delete the user and all related data
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null
                } */}
                <div className="udwm">
                  <div className="rectabgle"></div>
                  <div className="wrapper">
                    <div className="sc-kmXbIF leOGqA"><svg width="24" height="24" viewBox="0 0 40 40" fill="#EF5350" xmlns="http://www.w3.org/2000/svg"><path d="M35.2321 35H4.6726C3.25775 35 2.01074 34.257 1.33676 33.0126C0.663246 31.7684 0.722754 30.3184 1.49582 29.1337L16.7757 5.72643C17.479 4.64948 18.6665 4.00653 19.9524 4.00653C21.2383 4.00653 22.4258 4.64948 23.129 5.72643L38.4088 29.1337C39.1821 30.3182 39.2416 31.7684 38.5679 33.0127C37.8942 34.2572 36.6472 35 35.2321 35H35.2321ZM20.29 26.857C19.66 26.857 19.143 27.0497 18.7534 27.43C18.3642 27.8116 18.1667 28.2978 18.1667 28.8751C18.1667 29.4522 18.3642 29.9358 18.7534 30.3125C19.143 30.6889 19.66 30.8798 20.29 30.8798C20.9288 30.8798 21.4504 30.6889 21.8403 30.3125C22.2295 29.9356 22.4268 29.4519 22.4268 28.8751C22.4268 28.2893 22.2295 27.8011 21.8403 27.4242C21.4504 27.0479 20.9288 26.857 20.29 26.857ZM17.943 11.4634L18.3915 24.7723H21.873L22.3216 11.4634H17.943H17.943Z"></path></svg>

                      <div className="sc-kTxHUi VlTmj">If you proceed the following information will be permanently deleted. This action can not be undone.
                        {
                          this.props.removeUserDataObject !== undefined || this.props.removeUserDataObject !== null ?
                            this.state.DeleteAllData === true ?
                              (
                                <div>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.editUser?.Id} user</li>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.removeUserDataObject?.InboxUnassignedCount !== undefined ? this.props.removeUserDataObject?.InboxUnassignedCount : 0} Personal Inbox</li>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.removeUserDataObject?.RoomMessageCount !== undefined ? this.props.removeUserDataObject?.RoomMessageCount : 0} Message</li>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.removeUserDataObject?.ContactCount !== undefined ? this.props.removeUserDataObject?.ContactCount : 0}  Contact</li>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.removeUserDataObject?.ContactListCount !== undefined ? this.props.removeUserDataObject?.ContactListCount : 0} Contact Group</li>
                                  <li className="sc-hbqYmb lhcnPy">{this.props.removeUserDataObject?.roomCount !== undefined ? this.props.removeUserDataObject?.roomCount : 0} Conversation</li>
                                </div>)
                              :
                              (<div>
                                <li className="sc-hbqYmb lhcnPy">{this.props.editUser?.Id} user</li>
                              </div>)
                            : null

                        }
                      </div>

                    </div>
                  </div>
                </div>
                <p className="description">Alternatively, you can disable the user and retain this information. Please confirm you wish to continue by entering the text below.</p>
                <div className="sc-jDfIjF kOXpQp">Type “DELETE” to confirm
                  <input placeholder="Enter text" className="sc-bJYTlW gCVSpE"
                    onChange={(e) => {
                      this.setState({
                        ConfirmDelete: e.target.value,
                        ErrorName: "",
                      });

                    }}

                  />

                </div>
                <div className="button_area">
                  <button className="sc-GhhNo dveFXJ" data-dismiss="modal" font-size="body1" type="button">Cancel</button>
                  <button className="sc-GhhNo ryoSJ delete_button" disabled={this.state.ConfirmDelete === "DELETE" ? false : true} data-dismiss={this.state.ConfirmDelete !== '' ? "modal" : null} onClick={() => {
                    this.deleteV2UserData();
                  }} font-size="body1" type="button">Permanently delete</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    );
  }
  renderDeleteConfirmation() {
    return (
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Are you sure you want to Delete this number ?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default cancelBtn" data-dismiss="modal">Cancel</button>
            <button type="button" className="btn btn-primary " data-dismiss="modal" onClick={() => { this.props.deleteSucces() }}>Delete</button>
          </div>
        </div>
      </div>
    );
  }
  public render() {
    return (
      <React.Fragment>
        {
          this.props.companyVersionId === 2 ? this.renderHtmlV2() :
            (
              this.props.confimationMessage !== '' && this.props.confimationMessage !== undefined ?
                this.renderDeleteConfirmation()
                :
                this.renderHtml()
            )
        }

      </React.Fragment>
    );
  }
};


const mapStateToProps = (props: any) => {
  return {
    errorOccured: props.characterReducer.errorOccured,
    savedSuccessfull: props.characterReducer.dataSaved,
    deletedMessage: props.characterReducer.deletedMessgae

  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
  return {

  }
}
export default connect(mapStateToProps,
  mapDispatchToProps)(ConfirmationUserGroupDeleteComponent);