import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import {
  getSubscriptionList,
  getSubscriptListLength,
  searchBox_Value_In_Subscription,
  getServicePlans,
  subscriptionAddCalledFromCompanyTab,
  getCompanyList,
  getSubscriptionListAction,
  addUpdateSubscription,
  showHideList,
  pendingSubscriptionList,
  deleteSubscriptionBySubscriptionId,
  rowActiveId_Subscription,
  fetchAllServiceProviders,
  updateSubscriptionEmailNotifications,
  fetchModifyFreeCreditRenewalDate,
  // #3461  //Maksud
  updateSubscriptionContactLookup,
} from "../../redux/actions/actions";
import { SubscriptionListComponent } from "./subscriptionListComponent";
import { SubscriptionAddUpdateComponent } from "./subscriptionAddUpdateComponent";
import NotificationComponent from "../sharedComponents/displayNotifications";
// Create the containers interface


class SubscriptionParentComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      subscriptionListState: this.props.filteringSubscriptionProps,
      showEdit: false,
      calledFrom: "",
      editDetail: {},
      tenantId:"",
      companyId: "",
      selectedSearch: "",
      errorOccured: "",
      sevicePlanList: [],
      searchBox: "",
      rowActiveId: "",
      companyVersion: this.props.companyVersion,
      myCompanyId: this.props.myCompanyId,
    };
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }
  shouldComponentRender() {
    const { pending } = this.props;
    if (pending === true) {
      return true;
    }
    // more tests
    return false;
  }


  
  componentDidMount() {

//debugger;
    //var item = this.mask("4a072639-e635-44a1-920d-b1fc3828da64","-","*");

    //alert(item);

    ///Using PArent  Company and Company id as prop
    var companyId = "";
    if (this.props.companyId !== null && this.props.companyId !== undefined) {
      companyId = this.props.companyId;
      this.setState({ companyId: companyId });
      this.setState({ companyVersion: this.props.companyVersion });
    } else {
      this.props.setSearchBoxValue("");
      //#3804 Maksud
      //this.props.getCompanyListProp();
      this.props.rowActiveIdProps("");
    }
    //#3804 Maksud
    //this.props.getServicePlansList();
    this.props.showHideListProp(true);
    this.props.getSubscriptionListProp(companyId, this.props.calledFrom,this.state.myCompanyId);
    //#3804 Maksud
    //this.props.fetchAllServiceProviders();
    //console.log(this.props.filteringSubscriptionProps && this.props.filteringSubscriptionProps)
    //this.props.subscriptionList && console.log(this.props.subscriptionList, 'subsub')
  }

  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.errorOccured !== prevState.errorOccured ||
      nextProps.filteringSubscriptionProps !==
        prevState.subscriptionListState ||
      nextProps.sevicePlanProps !== prevState.sevicePlanList ||
      nextProps.searchBoxText !== prevState.searchBox ||
      nextProps.activeId !== prevState.rowActiveId||
      nextProps.myCompanyId !== prevState.myCompanyId
    ) {
      return {
        errorOccured: nextProps.errorOccured,
        subscriptionListState: nextProps.filteringSubscriptionProps,
        sevicePlanList: nextProps.sevicePlanProps,
        searchBox: nextProps.searchBoxText,
        rowActiveId: nextProps.activeId,
        myCompanyId: nextProps.myCompanyId
      };
    } else return null;
  }

  filterBySelectedStatus = (filterByStatus: any) => {
    let filteredList: any;
    if (filterByStatus === "disabled") {
      filteredList = this.props.subscriptionList.filter(
        (x: any) => x.subscription.Disabled === true
      );
      this.props.getSubscriptionListPropsFilter(filteredList);
    } else if (filterByStatus === "enabled") {
      filteredList = this.props.subscriptionList.filter(
        (x: any) => x.subscription.Disabled === false
      );
      this.props.getSubscriptionListPropsFilter(filteredList);
    } else if (filterByStatus === "status_all") {
      this.props.getSubscriptionListPropsFilter(this.props.subscriptionList);
    } else {
      this.props.getSubscriptionListPropsFilter(this.props.subscriptionList);
    }
  };
  filterBySelectedPlan = (plan: any) => {
    if (plan === "plan_all") {
      this.props.getSubscriptionListPropsFilter(this.props.subscriptionList);
    } else {
      var filteredList = this.props.subscriptionList.filter(
        (x: any) => x.subscription.ServicePlanId === parseInt(plan)
      );
      this.props.getSubscriptionListPropsFilter(filteredList);
    }
  };
  cancelButton() {
    this.props.showHideListProp(true);
    this.props.addSubscriptionCalledFromCompanyTab(false);
  }
  render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          {this.props.companyId !== undefined ? null : (
            <NotificationComponent />
          )}
          {this.props.showHideList === true ? (
            <div
              className={
                this.state.companyId === ""
                  ? "container-fluid"
                  : "container-fluid subscription_box"
              }
            >
              {this.props.pending === false ? (
                this.state.errorOccured !== undefined &&
                this.state.errorOccured !== "" &&
                this.state.errorOccured.split(":")[1] === "Subscription" ? (
                  <div className="container-fluid flex-grow-1 container-p-y">
                    {this.state.errorOccured.split(":")[0]}
                  </div>
                ) : (
                  <SubscriptionListComponent
                    filterationByTxt={(newList: any) => {
                      this.props.getSubscriptionListPropsFilter(newList);
                    }}
                    fromParentCompany={this.props.isParentCompany}
                    actualSubscriptionList={this.props.subscriptionList}
                    selectedSearchFilter={this.state.selectedSearch}
                    listCalledFrom={"Subscribe"}
                    ColumnName={
                      this.props.loggedinUserAccessLevel === -10
                        ? [
                            "Disabled",
                            "ID",
                            "Name",
                            "Company",
                            "Qty",
                            "User Assigned",
                            "Monthly Free Credit",
                            "Free Credit Remaining",
                            "Purchased Credit Remaining",
                          ]
                        : [
                            "Disabled",
                            "ID",
                            "Name",
                            "Company",
                            "Qty",
                            "Monthly Free Credit",
                            "Free Credit Remaining",
                            "Purchased Credit Remaining",
                          ]
                    }
                    CompanyIdProp={
                      this.props.companyId === undefined
                        ? ""
                        : this.props.companyId
                    }
                    companyVersion={this.props.companyVersion}
                    subscriptionList={this.state.subscriptionListState}
                    openAddUpdateWindow={(object: any) => {
                  
                      this.setState({
                        calledFrom: "edit",
                        editDetail: object.subscription,
                        tenantId:object.tenantId,
                      });
                      //#3804 Maksud
                      this.props.getServicePlansList();
                      this.props.getCompanyListProp();
                      this.props.fetchAllServiceProviders();

                      this.props.showHideListProp(false);
                    }}
                    showHideListSubscript={(value: any) => {
                      //#3804 Maksud
                      this.props.getServicePlansList();
                      this.props.getCompanyListProp();
                      this.props.fetchAllServiceProviders();

                      this.props.showHideListProp(value);
                      // this.props.getSubscriptionLength()
                      this.setState({
                        calledFrom: "add",
                      });
                    }}
                    addSubscriptionCalledFromCompanyTab={(value: any) => {
                      this.props.addSubscriptionCalledFromCompanyTab(value);
                    }}
                    searchFunction={(filterBy: any, type: any) => {
                      if (type === "status") {
                        this.filterBySelectedStatus(filterBy);
                      } else {
                        this.filterBySelectedPlan(filterBy);
                      }
                    }}
                    loggedInUserRole={this.props.loggedinUserAccessLevel}
                    searchBoxProp={this.state.searchBox}
                    SearchBoxText_value={(value: any) =>
                      this.props.setSearchBoxValue(value)
                    }
                    activeIdProp={this.state.rowActiveId}
                    rowActiveIdCompanyProps={(id: any) =>
                      this.props.rowActiveIdProps(id)
                    }

                  />
                )
              ) : (
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              )}
              {/* {console.log(this.props.subscriptionList, 'ahhhhhhhh')} */}
            </div>
          ) : (
            <SubscriptionAddUpdateComponent
              companyId={this.props.companyId}
              companyVersion={this.props.companyVersion}
              loggedInUserRole={this.props.loggedinUserAccessLevel}
              totalSubscriberListCount={parseInt(this.props.totalSubList)}
              calledFrom={this.state.calledFrom}
              editData={this.state.editDetail}
              tenantId={this.state.tenantId}
              selectedCompanyId={this.state.companyId}
              deleteSubscriptionProps={(Id: number) => {
                this.props.deleteSubscriptionById(Id, this.state.companyId);
                this.setState({ showEdit: false });
              }}
              addUpdateSubscriptionProp={(type: string, data: {}) => {
                this.props.addUpdateSubscriptionProps(
                  type,
                  data,
                  this.state.companyId
                );
              }}
              updateSubscriptionContactLookupProp={(subscriptionId: number, tenantId: string, enabled: boolean) => {
                this.props.updateSubscriptionContactLookupProps(
                  subscriptionId,
                  tenantId,
                  enabled
                );
              }}
              servicePlan={this.state.sevicePlanList}
              companyListProps={this.props.companyList }             
              changeState={() => this.cancelButton()}
              providersList={this.props.providersList}
              updateSubscriptionEmailNotifications={(obj: any) =>
                this.props.updateSubscriptionEmailNotifications(obj)
              }
              fetchModifyFreeCreditRenewalDate={(
                subscriptionid: number,
                date: Date
              ) =>
                this.props.fetchModifyFreeCreditRenewalDate(
                  subscriptionid,
                  date
                )
              }
            />
          )}
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    searchBoxText:
      props.characterReducer.searchTextBoxValue_SubscriptionReducer,
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    showHideList: props.characterReducer.showHideList,
    subscriptionList: props.characterReducer.subscriptionList,
    errorOccured: props.characterReducer.errorListMessage,
    savedSuccessfull: props.characterReducer.dataSaved,
    pending: props.characterReducer.pendingSubscriptionList,
    totalSubList: props.characterReducer.subscriptionListLengthRedux,
    filteringSubscriptionProps: props.characterReducer.filteringSubscription,
    sevicePlanProps: props.characterReducer.servicePlanListReducer,
    subscriptionAddCalledFromCompany:
      props.characterReducer.subscriptionAddCalledFromCompanyTabReducer,
    companyList: props.characterReducer.companyList.sort((a: any, b: any) =>
      a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0
    ),
    activeId: props.characterReducer.rowActiveId_SbscriptionReducer,
    providersList: props.characterReducer.allServiceProvidersList,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    setSearchBoxValue: (value: any) => {
      dispatch(searchBox_Value_In_Subscription(value));
    },
    rowActiveIdProps: (id: any) => {
      dispatch(rowActiveId_Subscription(id));
    },
    showHideListProp: (value: boolean) => {
      dispatch(showHideList(value));
    },
    getCompanyListProp: () => {
      dispatch(getCompanyList("", [], ""));
    },
    getSubscriptionListPropsFilter: (list: any) => {
      dispatch<any>(getSubscriptionListAction(list));
    },
    pendingSubLisProp: (value: any) => {
      dispatch(pendingSubscriptionList(value));
    },
    getSubscriptionLength: () => {
      dispatch<any>(getSubscriptListLength());
    },
    getSubscriptionListProp: (companyId: any, calledFrom: any,myCompanyId:any) => {
      dispatch(pendingSubscriptionList(false));
      if (companyId !== "") {
        dispatch(getSubscriptionList(companyId, "company"));
      } else if (calledFrom === "addCompany") {
        dispatch(getSubscriptionList(companyId, "addCompany"));
      } else {
       // companyId = 1874;
         //dispatch(getSubscriptionList(companyId, "subscription"));
         dispatch(getSubscriptionList(myCompanyId, "subscription"));
      }
    },
    addUpdateSubscriptionProps: (type: string, object: {}, companyId: any) => {
      dispatch(addUpdateSubscription(type, object, companyId));
    },
    // #3461  //Maksud    
    updateSubscriptionContactLookupProps: (subscriptionId: number, tenantId: string, enabled: boolean) => {
      debugger;
      dispatch(updateSubscriptionContactLookup(subscriptionId, tenantId,enabled));
    },

    deleteSubscriptionById: (id: number, companyId: any) => {
      dispatch(deleteSubscriptionBySubscriptionId(id, companyId));
    },
    getServicePlansList: () => {
      dispatch<any>(getServicePlans());
    },
    addSubscriptionCalledFromCompanyTab: (value: any) => {
      dispatch<any>(subscriptionAddCalledFromCompanyTab(value));
    },
    fetchAllServiceProviders: () => {
      dispatch(fetchAllServiceProviders());
    },
    updateSubscriptionEmailNotifications: (obj: any) => {
      dispatch<any>(updateSubscriptionEmailNotifications(obj));
    },
    fetchModifyFreeCreditRenewalDate: (subscriptionId: number, date: Date) => {
      dispatch<any>(fetchModifyFreeCreditRenewalDate(subscriptionId, date));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionParentComponent);
