import * as React from 'react';
import { DataTableComponent } from '../sharedComponents/dataTableComponent';

export class SubscriptionListComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props)
        this.state = {
            subscriptionData: [],
            searchBox:"",
            activeId:""
        }

    }
    componentDidMount(){
        // console.log(this.props.subscriptionList)
    }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.subscriptionList !== prevState.subscriptionData ||
            nextProps.searchBoxProp !== prevState.searchBox ||
            nextProps.activeIdProp !== prevState.activeId) {
            return { 
                     subscriptionData: nextProps.subscriptionList,
                     searchBox:nextProps.searchBoxProp,
                     activeId:nextProps.activeIdProp

                 };
        }
        else return null;
    }
    public render() {

        return (
            <React.Fragment>
                <div className="positionRelative paddTop46">
                    <div className="card-datatable table-responsive">
                        <DataTableComponent
                        loggedInUserRole={this.props.loggedInUserRole}

                        ParentCompany={this.props.fromParentCompany}
                        filteredByTxtBoxSearch={(newList:any)=>{this.props.filterationByTxt(newList)}}
                        actualData={this.props.actualSubscriptionList}   
                        hideFilter={this.props.CompanyIdProp !=="" ? false :true }
                        filterUserByCompanyId={(subsId: any) => { this.props.filterUserByCompanyId(subsId) }}
                            listCalled={this.props.listCalledFrom}
                            columnName={this.props.ColumnName}
                            rowsList={this.state.subscriptionData}
                            calledFrom={'Subscription'}
                            openEditComponent={(object: any) => {                               
                                this.props.openAddUpdateWindow(object)                                
                            }}
                            showHideListProp={(value:any)=>{
                                this.props.showHideListSubscript(value)
                                if(this.props.CompanyIdProp !== ""){
                                    this.props.addSubscriptionCalledFromCompanyTab(true)
                                  }
                                  else{
                                   this.props.addSubscriptionCalledFromCompanyTab(false)
                                 }
                              }}
                              searchFunctionality={(value:any,type:any)=>{
                                 this.props.searchFunction(value,type)
                              }}
                              searchBoxText = {this.state.searchBox}
                              setSearchBoxText={(value:any)=>this.props.SearchBoxText_value(value)}
                              rowActiveIdCompany_Props ={(id:any)=>{this.props.rowActiveIdCompanyProps(id)}}
                              activeCompanyId={this.state.activeId}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }
};

