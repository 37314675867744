import * as React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import * as commonMethods from "../../commonMethods";
import { GroupAddComponent } from "./groupAddComponent";

export class GroupNonMemberComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      activeTab: "groupMember",
      pendingGroupMeberListState: true,
      GroupMemberList: [],
      groupNotMember: [],
      pendingGroupNoMemeber: true,
      arrowDisplays: "both",
      filtereList: {},
      disable: false,
      disableGroupNotMemberLits: false,
      disableGroupMemberLits: false,
      showHideSelectALL: this.props.showHideSelectAllIconGroupMemeber,
      showHideSelectAllNotGpMember: this.props.showHideSelectAllNotGroupMember,
      GroupEmail: this.props.GroupEmail,
      subscriptionList: [],
      editDetails: {},
      actualGroupMember: [],
      actualNonGroupMember: [],
      showMinusIcon_GroupMemberList: false,
      showMinusIcon_GroupNonMemberList: false,
      assignMembersTogroup: false,
      selectedGroupNameEmail: this.props.selectedGroupEmailName,
      pendingNonGroupMembers: false,
      pendingGroupMembers: false,
      uniqueNumber: "",
      defaultCountryCode: "",
      storeText: true,
      disableGroup: false,
      creditLimit: "",
      uniqueNumberCost: "",
      usedCredits: "",
      registerationDate: "",
      email: "",
      name: "",
      roleId: "",
      subscriptionId: "",
      subscriptionName: "",
      setUniqueNumberList: [],
      searchGroupMember_text: "",
      searchGroupNonMember_text: "",
      emailEnabled: false,
    };
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.pendingGroupMemberList !==
        prevState.pendingGroupMeberListState ||
      nextProps.groupMemberList !== prevState.GroupMemberList ||
      nextProps.getGroupNotMemberListGroupMemeberComp !==
        prevState.groupNotMember ||
      nextProps.pendingGroupNotEmail !== prevState.pendingGroupNoMemeber ||
      nextProps.iconToBeDisplayedGroup !== prevState.arrowDisplays ||
      nextProps.showHideSelectAllIconGroupMemeber !==
        prevState.showHideSelectALL ||
      nextProps.showHideSelectAllNotGroupMember !==
        prevState.showHideSelectAllNotGpMember ||
      nextProps.subscriptionList !== prevState.subscriptionList ||
      nextProps.editData !== prevState.editDetails ||
      nextProps.actualGroupMemberData !== prevState.actualGroupMember ||
      nextProps.actualGroupNonMemberData !== prevState.actualNonGroupMember ||
      nextProps.pendingNonGroupList !== prevState.pendingNonGroupMembers ||
      nextProps.pendingGroupList !== prevState.pendingGroupMembers ||
      nextProps.ActiveTab !== prevState.activeTab ||
      nextProps.uniqueNumberGroupAdd !== prevState.uniqueNumber ||
      nextProps.DefaultCountryCode !== prevState.defaultCountryCode ||
      nextProps.StoreText !== prevState.storeText ||
      nextProps.DisableGroup !== prevState.disableGroup ||
      nextProps.CreditLimit !== prevState.creditLimit ||
      nextProps.UniqueNumberCost !== prevState.uniqueNumberCost ||
      nextProps.UsedCredits !== prevState.usedCredits ||
      nextProps.RegisterationDate !== prevState.registerationDate ||
      nextProps.Email !== prevState.email ||
      nextProps.Name !== prevState.name ||
      nextProps.RoleId !== prevState.roleId ||
      nextProps.SubscriptionId !== prevState.subscriptionId ||
      nextProps.SubscriptionName !== prevState.subscriptionName ||
      nextProps.unAssignPhoneNumberProps !== prevState.setUniqueNumberList ||
      nextProps.emailEnabledForGroup !== prevState.emailEnabled
    ) {
      return {
        activeTab: nextProps.ActiveTab,
        actualNonGroupMember: nextProps.actualGroupNonMemberData,
        actualGroupMember: nextProps.actualGroupMemberData,
        editDetails: nextProps.editData,
        showHideSelectAllNotGpMember: nextProps.showHideSelectAllNotGroupMember,
        showHideSelectALL: nextProps.showHideSelectAllIconGroupMemeber,
        arrowDisplays: nextProps.iconToBeDisplayedGroup,
        pendingGroupMeberListState: nextProps.pendingGroupMemberList,
        GroupMemberList: nextProps.groupMemberList,
        groupNotMember: nextProps.getGroupNotMemberListGroupMemeberComp,
        pendingGroupNoMemeber: nextProps.pendingGroupNotEmail,
        subscriptionList: nextProps.subscriptionList,
        pendingNonGroupMembers: nextProps.pendingNonGroupList,
        pendingGroupMembers: nextProps.pendingGroupList,
        roleId: nextProps.RoleId,
        email: nextProps.Email,
        name: nextProps.Name,
        subscriptionId: nextProps.SubscriptionId,
        subscriptionName: nextProps.SubscriptionName,
        disableGroup: nextProps.DisableGroup,
        uniqueNumber: nextProps.uniqueNumberGroupAdd,
        defaultCountryCode: nextProps.DefaultCountryCode,
        storeText: nextProps.StoreText,
        creditLimit: nextProps.CreditLimit,
        uniqueNumberCost: nextProps.UniqueNumberCost,
        usedCredits: nextProps.UsedCredits,
        registerationDate: nextProps.RegisterationDate,
        setUniqueNumberList: nextProps.unAssignPhoneNumberProps,
        emailEnabled: nextProps.emailEnabledForGroup,
      };
    } else return null;
  }

  selectCheckBox(e: any, groupMeber: any) {
    var data = e.target.checked;
    groupMeber.checked = data;
    var countOfFalse = this.state.GroupMemberList.filter(
      (x: any) => x.checked === true
    ).length;
    if (countOfFalse === 0) {
      this.setState({
        disableGroupNotMemberLits: false,
      });
      this.props.chnageIconName("both");
      this.setState({
        GroupMemberList: this.state.GroupMeberList,
        showMinusIcon_GroupMemberList: false,
      });
    } else {
      this.setState({
        GroupMemberList: this.state.GroupMeberList,
        showMinusIcon_GroupMemberList: true,
      });
      this.setState({
        disableGroupNotMemberLits: true,
      });
      this.props.chnageIconName("right");
    }
  }
  selectCheckBoxNotGoupMember(e: any, gorupMember: any) {
    var data = e.target.checked;
    gorupMember.checked = data;
    var countOfFalse = this.state.groupNotMember.filter(
      (x: any) => x.checked === true
    ).length;
    if (countOfFalse === 0) {
      this.setState({
        disableGroupMemberLits: false,
      });
      this.props.chnageIconName("both");

      this.setState({
        groupNotMember: this.state.groupNotMember,
        showMinusIcon_GroupNonMemberList: false,
      });
    } else {
      this.setState({
        groupNotMember: this.state.groupNotMember,
        showMinusIcon_GroupNonMemberList: true,
      });
      this.setState({
        disableGroupMemberLits: true,
        arrowDisplays: "left",
      });
      this.props.chnageIconName("left");
    }
  }
  renderGroupMemberData() {
    return this.state.GroupMemberList.map((groupMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div className="custom-control custom-checkbox circle_check">
            <input
              className="custom-control-input"
              type="checkbox"
              id={index}
              key={index}
              disabled={this.state.disableGroupMemberLits}
              checked={groupMeber.checked}
              value={groupMeber.checked}
              onChange={(e: any) => {
                this.selectCheckBox(e, groupMeber);
              }}
            />
            <label className="custom-control-label" htmlFor={index}></label>
          </div>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: groupMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                groupMeber.UserName !== ""
                  ? groupMeber.UserName
                  : groupMeber.Email
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{groupMeber.UserName}</h5>
            <p>{groupMeber.Email}</p>
          </div>
        </div>
      );
    });
  }
  saveUserInGroup() {
    //Assign memberto groups
    if (this.state.assignMembersTogroup === true) {
      var filterGroup = this.state.GroupMemberList.filter((x: any) => {
        return x.groupMember === false;
      });
      if (filterGroup.length !== 0) {
        this.props.addUserInGroup(
          filterGroup,
          this.state.selectedGroupNameEmail
        );
      }
    }
    //unASSIGN membes to groups
    else {
      var filterNonGroup = this.state.groupNotMember.filter((x: any) => {
        return x.groupMember === true;
      });
      if (filterNonGroup.length !== 0) {
        this.props.unAssignUserFromGroup(
          filterNonGroup,
          this.state.selectedGroupNameEmail
        );
      }
    }
  }
  searchGroupMembers = (e: any) => {
    this.setState({
      searchGroupMember_text: e.target.value,
    });
    var list = commonMethods.handleChange(e, this.state.actualGroupMember, "");
    this.props.groupMemberListFilter(list);
  };
  searchGroupNonMembers = (e: any) => {
    this.setState({
      searchGroupNonMember_text: e.target.value,
    });
    var list = commonMethods.handleChange(
      e,
      this.state.actualNonGroupMember,
      ""
    );
    this.props.groupNonMemberListFilter(list);
  };
  renderGroupNotMemberData() {
    return this.state.groupNotMember.map((groupMeber: any, index: any) => {
      return (
        <div className="media align-items-center membrName" key={index}>
          <div className="custom-control custom-checkbox circle_check">
            <input
              className="custom-control-input"
              id={"group" + index}
              type="checkbox"
              checked={groupMeber.checked}
              value={groupMeber.checked}
              onChange={(e: any) => {
                this.selectCheckBoxNotGoupMember(e, groupMeber);
              }}
              disabled={this.state.disableGroupNotMemberLits}
            />
            <label
              className="custom-control-label"
              htmlFor={"group" + index}
            ></label>
          </div>
          <div
            className="media_left d-flex justify-content-center"
            style={{ background: groupMeber.backGroundColor }}
          >
            <span className="align-self-center">
              {commonMethods.generateFirstLastNameIntials(
                groupMeber.UserName !== ""
                  ? groupMeber.UserName
                  : groupMeber.Email
              )}
            </span>
          </div>
          <div className="media-body">
            <h5>{groupMeber.UserName}</h5>
            <p>{groupMeber.Email}</p>
          </div>
        </div>
      );
    });
  }
  selectUnselectAllCheckboxes_GroupMEmberList = (
    calledFrom: any,
    list: any
  ) => {
    var newList = [];
    if (calledFrom === "selectAll") {
      if (this.state.GroupMemberList.length !== 0) {
        newList = this.state.GroupMemberList.map((x: any) => {
          x.checked = true;
          return x;
        });
        this.props.groupMemberListFilter(newList);
        this.props.chnageIconName("right");
        this.setState({
          showMinusIcon_GroupMemberList: true,
        });
      }
    } else {
      newList = this.state.GroupMemberList.map((x: any) => {
        x.checked = false;
        return x;
      });
      this.props.groupMemberListFilter(newList);
      this.props.chnageIconName("both");
      this.setState({
        showMinusIcon_GroupMemberList: false,
      });
    }
  };
  selectUnselectAllCheckboxes = (calledFrom: any, list: any) => {
    var newList = [];
    if (calledFrom === "selectAll") {
      if (this.state.groupNotMember.length !== 0) {
        newList = this.state.groupNotMember.map((x: any) => {
          x.checked = true;
          return x;
        });
        this.props.groupNonMemberListFilter(newList);
        this.props.chnageIconName("left");
        this.setState({
          showMinusIcon_GroupNonMemberList: true,
        });
      }
    } else {
      newList = this.state.groupNotMember.map((x: any) => {
        x.checked = false;
        return x;
      });
      this.props.groupNonMemberListFilter(newList);
      this.props.chnageIconName("both");
      this.setState({
        showMinusIcon_GroupNonMemberList: false,
      });
    }
  };
  arrowRight() {
    this.setState({
      disableGroupMemberLits: false,
      disableGroupNotMemberLits: false,
    });
    var filtereList = this.state.GroupMemberList.filter(
      (x: any) => x.checked === true
    );

    this.props.rightIconClick(
      filtereList,
      this.state.GroupMemberList,
      this.state.groupNotMember,
      this.state.actualGroupMember
    );
    this.setState({
      showMinusIcon_GroupMemberList: false,
      assignMembersTogroup: false,
      searchGroupMember_text: "",
    });
  }

  arrowLeft() {
    this.setState({
      disableGroupMemberLits: false,
      disableGroupNotMemberLits: false,
    });
    var filtereList = this.state.groupNotMember.filter(
      (x: any) => x.checked === true
    );
    this.props.leftIconClick(
      filtereList,
      this.state.GroupMemberList,
      this.state.groupNotMember,
      this.state.actualNonGroupMember
    );
    this.setState({
      showMinusIcon_GroupNonMemberList: false,
      assignMembersTogroup: true,
      searchGroupNonMember_text: "",
    });
  }

  public render() {
    return (
      <React.Fragment>
        <Tabs
          className="tabGroupMember"
          defaultActiveKey={this.state.activeTab}
          onSelect={(currentTabName: any) => {
            this.setState({
              activeTab: currentTabName,
              companyName: "",
            });
            this.props.setAtiveTabName(currentTabName);
          }}
          id="uncontrolled-tab-example"
        >
          <Tab
            eventKey="groupMember"
            title={
              <span>
                <span className="font-icon">&#112;</span> Group Members
              </span>
            }
          >
            {this.props.pendingGroupMemberList === true ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div>
                {this.state.GroupMemberList !== undefined ? (
                  <div className="member_sec">
                    <div className="groupGroupMemberDiv groupMember ">
                      {this.state.arrowDisplays === "right" ? (
                        <span
                          onClick={() => this.arrowRight()}
                          className="alignCenter right_arrow d-flex align-items-center justify-content-center"
                        >
                          <i
                            className="fas fa-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : this.state.arrowDisplays === "left" ? (
                        <span
                          onClick={() => this.arrowLeft()}
                          className="alignCenter d-flex align-items-center justify-content-center"
                        >
                          <i
                            className="fas fa-arrow-left"
                            aria-hidden="true"
                          ></i>
                        </span>
                      ) : (
                        <span className="alignCenter d-flex align-items-center justify-content-center">
                          <i
                            className="fa fa-exchange-alt"
                            aria-hidden="true"
                          ></i>
                        </span>
                      )}
                      <div className="teamBox bodrRight">
                        <div className="media align-items-center saleTeam">
                          <div className="media_left d-flex justify-content-center">
                            <span className="align-self-center">
                              {commonMethods.generateFirstLastNameIntials(
                                this.state.GroupEmail
                              )}
                            </span>
                          </div>
                          <div className="media-body">
                            <h5>{this.state.GroupEmail}</h5>
                            <p>{this.state.GroupMemberList.length} members</p>
                          </div>
                        </div>
                        <div className="media align-items-center groupSearch">
                          <div className="custom-control custom-checkbox circle_check">
                            {this.state.GroupMemberList.length !== 0 &&
                            this.state.showMinusIcon_GroupMemberList ===
                              true ? (
                              <i
                                className="fa fa-minus-circle"
                                onClick={() => {
                                  this.selectUnselectAllCheckboxes_GroupMEmberList(
                                    "deselectAll",
                                    this.state.GroupMemberList
                                  );
                                }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <div>
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="groupMember12"
                                  onClick={() => {
                                    this.selectUnselectAllCheckboxes_GroupMEmberList(
                                      "selectAll",
                                      this.state.GroupMemberList
                                    );
                                  }}
                                  disabled={this.state.disableGroupMemberLits}
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="groupMember12"
                                ></label>
                              </div>
                            )}
                          </div>
                          <div className="media-body">
                            <div className="search_btn">
                              <input
                                className="form-control searchUser"
                                value={this.state.searchGroupMember_text}
                                onChange={(e: any) => {
                                  this.searchGroupMembers(e);
                                }}
                                type="text"
                                placeholder="Search Users"
                              />
                              <button type="button">
                                <i className="fas fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {this.state.GroupMemberList.length === 0 ? (
                          <div className="media-body">
                            <div className="noUser_found">
                              No group Members found
                            </div>
                          </div>
                        ) : (
                          <div className="memberBox">
                            {this.state.pendingGroupMembers === true ? (
                              <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            ) : (
                              this.renderGroupMemberData()
                            )}
                          </div>
                        )}
                      </div>
                      {this.state.groupNotMember !== undefined ? (
                        <div className="teamBox">
                          <div className="media align-items-center saleTeam">
                            <div className="media_left d-flex justify-content-center balck_bg">
                              <span className="align-self-center">
                                <i className="font-icon">G&#8203;</i>
                              </span>
                            </div>
                            <div className="media-body">
                              <h5> Users</h5>
                              <p>{this.state.groupNotMember.length} members</p>
                            </div>
                            <div className="media_right"></div>
                          </div>
                          <div className="media align-items-center groupSearch">
                            <div className="custom-control custom-checkbox circle_check">
                              {this.state.groupNotMember.length !== 0 &&
                              this.state.showMinusIcon_GroupNonMemberList ===
                                true ? (
                                <i
                                  className="fa fa-minus-circle"
                                  onClick={() => {
                                    this.selectUnselectAllCheckboxes(
                                      "deselectAll",
                                      this.state.groupNotMember
                                    );
                                  }}
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <div>
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="nonGroupMembers"
                                    onClick={() => {
                                      this.selectUnselectAllCheckboxes(
                                        "selectAll",
                                        this.state.groupNotMember
                                      );
                                    }}
                                    disabled={
                                      this.state.disableGroupNotMemberLits
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="nonGroupMembers"
                                  ></label>
                                </div>
                              )}
                            </div>
                            <div className="media-body">
                              <div className="search_btn">
                                <input
                                  className="form-control searchUser"
                                  value={this.state.searchGroupNonMember_text}
                                  onChange={(e: any) => {
                                    this.searchGroupNonMembers(e);
                                  }}
                                  type="text"
                                  placeholder="Search Users"
                                />
                                <button type="button">
                                  <i className="fas fa-search"></i>
                                </button>
                              </div>
                            </div>
                          </div>

                          {this.state.groupNotMember.length === 0 ? (
                            <div className="media-body">
                              <div className="noUser_found">
                                No group Members found
                              </div>
                            </div>
                          ) : (
                            <div className="memberBox">
                              {this.state.pendingNonGroupMembers === true ? (
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              ) : (
                                this.renderGroupNotMemberData()
                              )}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                    <div className="text-left mt-3 btnDiv">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                          this.saveUserInGroup();
                        }}
                      >
                        Save changes
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="btn btn-default cancelBtn"
                        onClick={() => this.props.changeState()}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </Tab>
          <Tab
            eventKey="groupDetail"
            title={
              <span>
                <span className="font-icon">&#67;</span> Group Detail
              </span>
            }
          >
            <GroupAddComponent
              uniquePhoneNumber={this.state.uniqueNumber}
              SubscriptionId={this.state.subscriptionId}
              SubscriptionName={this.state.subscriptionName}
              defaultCountryCodeForAddEditUserPRops={
                this.state.defaultCountryCode
              }
              StoreText={this.state.storeText}
              DisableGroup={this.state.disableGroup}
              creditLimitForAddEditUserProp={this.state.creditLimit}
              UniqueNumberCost={this.state.uniqueNumberCost}
              UsedCredits={this.state.usedCredits}
              RegisterationDate={this.state.registerationDate}
              Email={this.state.email}
              Name={this.state.name}
              RoleId={this.state.roleId}
              emailEnabledProps={this.state.emailEnabled}
              EmailEnabledForAddEdit={(value: any) => {
                this.props.setEmailEnabled(value);
              }}
              storeTextForAddEdit={(value: any) => {
                this.props.setStoreText(value);
              }}
              disableForAddEdit={(value: any) => {
                this.props.setDisable(value);
              }}
              setCreditLimit={(value: any) => {
                this.props.setCreditLimit(value);
              }}
              setUniqueNumberCost={(value: any) => {
                this.props.setUniqueNumberCost(value);
              }}
              setDefaultCountryCode={(value: any) => {
                this.props.setDefaultCountryCode(value);
              }}
              setEmail={(value: any) => {
                this.props.setEmail(value);
              }}
              setName={(value: any) => {
                this.props.setName(value);
              }}
              setRoleId={(value: any) => {
                this.props.setRoleId(value);
              }}
              setSubscriptionId={(value: any) => {
                this.props.setSubscriptionId(value);
              }}
              setSubscriptionName={(value: any) => {
                this.props.setSubscriptionName(value);
              }}
              companyId={this.props.companyId}
              subscriptionList={this.state.subscriptionList}
              changeState={() => this.props.changeState()}
              calledFrom={"edit"}
              editData={this.state.editDetails}
              addUpdateUserProp={(type: any, data: any) =>
                this.props.addUpdateUserProp(type, data, null)
              }
              updateUserEmailNotifications={(obj: any) =>
                this.props.updateUserEmailNotifications(obj)
              }
             
              deleteGroup={(email: any, companyId: any,confirm:boolean) => {   
                debugger;           
                this.props.deleteGroupByEmail(email, companyId,confirm);
              }}
              releaseNumberFromApi={(value: any) => {
                this.props.releaseNumber(value);
              }}
              roleOfLoggedInUser={this.props.loggedInUserRole}
              unAssignPhoneNumberProps={this.state.setUniqueNumberList}
              setUniqueNumber={(value: any) => {
                this.props.setUniqueNumber(value);
              }}
              getUnAssignedNumbers_groups={(
                smsServieType: any,
                subscriptionId: any
              ) => {
                this.props.getUnAssignedNumbers(smsServieType, subscriptionId);
              }}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}
