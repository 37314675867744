
import * as React from 'react';
import * as commonMethod from '../../commonMethods';
import { getLoggedInUSerName } from '../../adalConfig';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import PhoneInput from 'react-phone-input-2';
//import Select from 'react-select';
import NotificationComponent from '../sharedComponents/displayNotifications';

import 'react-phone-input-2/lib/style.css';
import * as action from '../../redux/actions/actions';
class MyProfileComponent extends React.Component<any, any> {
    constructor(props: any, state: any) {
        super(props)
        this.state = {
            Email: '',
            Name: '',
            RegistrationDate: '',
            UsedCredit: '',
            UniqueNumber: "",
            Disabled:false,
            StoreText: true,
            Role: 20,
            CreditLimit: "",
            ErrorEmail: "",
            ErrorPhoneNumber: '',
            ErrorSubscription: "",
            InvalidEmail: "",
            ErrorCreditLimit: "",
            ErrorName: "",
            pendingResult: true,
            loggedInEmail: "",
            DefaultCountryCode: "",
            isGroup:false,
            DisableAllFields:true
            }
    }
    componentDidMount() {
        var email = getLoggedInUSerName().userName;
        this.setState({
            loggedInEmail: email
        })
        this.props.getloggedInUserDetail(email);
   }
    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.emailAddEditUserProps !== prevState.Email ||
            nextProps.nameAddEditUserProps !== prevState.Name ||
            nextProps.registerationDateForAddEditUserProps !== prevState.RegistrationDate ||
            nextProps.usedCreditForAddEditUserProps !== prevState.UsedCredit ||
            nextProps.uniqueNumberProp !== prevState.UniqueNumber ||
            nextProps.roleIdForAddEditUserProps !== prevState.Role ||
            nextProps.creditLimitForAddEditUserProps !== prevState.CreditLimit ||
            nextProps.pendingLoggedInResult !== prevState.pendingResult || 
            nextProps.DefaultCountryCode !== prevState.DefaultCountryCode
        ) { 
            return {
                pendingResult: nextProps.pendingLoggedInResult,
                Email: nextProps.emailAddEditUserProps,
                Name: nextProps.nameAddEditUserProps,
                RegistrationDate: nextProps.registerationDateForAddEditUserProps,
                UsedCredit: nextProps.usedCreditForAddEditUserProps,
                UniqueNumber: nextProps.uniqueNumberProp,
                Role: nextProps.roleIdForAddEditUserProps,
                CreditLimit: nextProps.creditLimitForAddEditUserProps,
                DefaultCountryCode:nextProps.DefaultCountryCode
            };
        }
        else return null;
    }

    IsValid() {
        var isValid = true;
        if (this.state.Email.length === 0) {
            this.setState({ ErrorEmail: "This Field is Required" })
            isValid = false
        }

        if (this.state.ErrorPhoneNumber !== "" || this.state.ErrorCreditLimit !== "") {
            isValid = false
        }
        if (this.state.Name.length === 0) {
            isValid = false

            this.setState({ ErrorName: "This Field is Required" })
        }

        return isValid;
    }



    addUpdateData = (e: any) => {
        if (this.state.Email.length !== 0 && commonMethod.validEmail(this.state.Email) === false) {
            return false
        }
        if (this.IsValid() === true) {
            var creditLimit = this.state.CreditLimit === 0 || this.state.CreditLimit === undefined ? null : this.state.CreditLimit;

            var dataBody = {
                "email": this.state.Email,
                "role": this.state.Role,
                "creditLimit": creditLimit,
                "usedCredit": this.state.UsedCredit,
                "registrationDate": this.state.RegistrationDate,
                "rooms": {},
                "uniqueNumber":   this.state.UniqueNumber,
                "defaultCountryCode": this.state.DefaultCountryCode === '' ? 1 : parseInt(this.state.DefaultCountryCode),
                "username": this.state.Name,
            };
              this.props.addUpdateUserProp('edit', dataBody)
        }
    }

    creditLimitValidation = (e: any) => {
        var validCredit = commonMethod.validCredit(e.target.value)
        if (validCredit === true || e.target.value === '') {
            this.setState({ CreditLimit: parseFloat(e.target.value), ErrorCreditLimit: "" })
        }
        else {
            this.setState({
                ErrorCreditLimit: "Credit Limit should be between 0-999",
                CreditLimit: parseFloat(e.target.value)
            })
        }
    }

    renderButtonHtml = () => {
        return (
            <div className="col-12">
                <div className="btnDiv d-flex justify-content-between align-items-center">
                    <div>
                        <button type="button" className="btn btn-primary" onClick={(e) => this.addUpdateData(e)}>Save changes</button>
                        <button type="button" className="btn btn-default cancelBtn" onClick={() => window.history.back()}>Cancel</button>
                    </div>
                </div>
            </div>
        )
    }
    renderHtml() {
        return (
            <div className="nav-tabs-top">
                <div className="tab-content border-0">
                    <div className="tab-pane fade show active" id="user-edit-account">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="formDiv">
                                        <div className="row text-left">
                                            <div className="col-md-6">
                                                <div className={(this.state.ErrorName !== "") ? "form-group validationClass" : "form-group"}>
                                                    <label className="form-label">Name<small className="text-muted">*</small></label>
                                                    <input type="text" className="form-control"
                                                        name="validation-email" placeholder="Name"
                                                        onChange={(e) => {
                                                            this.setState({ Name: e.target.value, ErrorName: "" })
                                                            this.props.setName(e.target.value)
                                                        }}
                                                        value ={this.state.Name}
                                                    />
                                                    <small className="form-text">{this.state.ErrorName} </small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={(this.state.ErrorPhoneNumber !== "") ? "form-group validationClass" : "form-group"}>
                                                    <label className="form-label">Number</label>
                                                    <PhoneInput
                                                        disabled={this.state.DisableAllFields}
                                                        disableDropdown={true}
                                                        disableCountryCode={false}
                                                        enableSearch={true}
                                                        placeholder={"x (xxx) xxx-xxx"}
                                                        value={this.state.UniqueNumber}
                                                   />
                                                    <small className="form-text ">{this.state.ErrorPhoneNumber} </small>
                                                </div>
                                            </div>
               
                                            <div className="col-md-6">
                                                <div className={(this.state.ErrorEmail !== "" || this.state.InvalidEmail !== "") ? "form-group validationClass" : "form-group"}>
                                                    <label className="form-label">Email<small className="text-muted">*</small></label>
                                                    <input type="text" className="form-control"
                                                        disabled={this.state.DisableAllFields}
                                                        name="validation-email" placeholder="Email"
                                                        
                                                        value={this.state.Email} />
                                                    <small className="form-text">{this.state.ErrorEmail} </small>
                                                    <small className="form-text">{this.state.InvalidEmail} </small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Role</label>
                                                    <select value={this.state.Role}
                                                        disabled={this.state.DisableAllFields}
                                                         className="custom-select">
                                                        <option value={20}>User</option>
                                                        <option value={5}> Partner admin</option>
                                                        <option value={10}> Subscription admin</option>
                                                        <option value={0}>Admin</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={this.state.ErrorCreditLimit !== "" ? "form-group validationClass" : "form-group"}>
                                                    <label className="form-label">Credit Limit</label>
                                                    <input type="number" className="form-control"
                                                        onChange={(e) => {
                                                            this.props.setCreditLimit(e.target.value)
                                                            this.creditLimitValidation(e)
                                                        }}
                                                        disabled={this.state.DisableAllFields}
                                                        value={this.state.CreditLimit !== undefined? this.state.CreditLimit : 0 }
                                                        name="validation-email" placeholder="CreditLimit" 
                                                    />
                                                    <small className="form-text ">{this.state.ErrorCreditLimit} </small>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={"form-group"}>
                                                    <label className="form-label">Created Date</label>
                                                    <input type="date" className="form-control"
                                                        disabled={this.state.DisableAllFields}
                                                        name="validation-email" placeholder="Created Date"
                                                         value={(this.state.RegistrationDate !== "" && this.state.RegistrationDate !== undefined) ? this.state.RegistrationDate.split("T")[0] : this.state.RegistrationDate} 
                                                         />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Used Credits</label>
                                                    <input type="number" className="form-control"
                                                      disabled={this.state.DisableAllFields}
                                                        value={this.state.UsedCredit}
                                                        name="validation-email" placeholder="UsedCredits" required />
                                                </div>
                                            </div>
                                             <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Country Code</label>
                                                    <input type="number" className="form-control"
                                                         disabled={this.state.DisableAllFields}
                                                         value={this.state.DefaultCountryCode}
                                                        name="validation-email" placeholder="DefaultCountryCode" required
                                                    />
                                                </div>
                                            </div>
                                            <div className="w-100"></div>
                                         </div>
                                    </div>
                                </div>
                                {this.renderButtonHtml()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <div className="layout-content">
                    <NotificationComponent />
                    <div className="container-fluid subscription_box">
                        {
                            this.state.pendingResult === true || this.state.pendingResult === undefined ? //.pendingResult === true ?
                                <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                :
                                <div className="">
                                    <h2 className="companyName  text-left">
                                        <span className="iconNavigation cursor_pointer">
                                            <i className="fa fa-chevron-left cursor_pointer" aria-hidden="true" onClick={() => { window.history.back(); }} ></i></span>
                                        {(this.state.Name === undefined || this.state.Name === "") ? <b>{this.state.loggedInEmail}</b> : <b>{this.state.Name}</b>}
                                    </h2>
                                    <Tabs className="tabGroupMember" defaultActiveKey={'User Details'}
                                        onSelect={(currentTabName: any) => {
                                        }}
                                        id="uncontrolled-tab-example">
                                        <Tab eventKey="User Details" title={<span><span className="font-icon">&#67;</span> User Details</span>}>
                                            {this.renderHtml()}
                                        </Tab>
                                    </Tabs>
                                </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
    return {
        errorOccured: props.characterReducer.errorListMessage,
        savedSuccessfull: props.characterReducer.dataSaved,
        subscriptionList: props.characterReducer.subscriptionList,
        editData: props.characterReducer.loggedInUserDetailReducer,
        pendingLoggedInResult: props.characterReducer.pendingResultReducer,
        emailAddEditUserProps: props.characterReducer.emailForAddEditUser,
        nameAddEditUserProps: props.characterReducer.nameForAddEditUser,
        registerationDateForAddEditUserProps: props.characterReducer.registerationDate,
        usedCreditForAddEditUserProps: props.characterReducer.usedCredit,
        uniqueNumberProp: props.characterReducer.showUniqueNumberReducer,
        storeTextForAddEditUserProps: props.characterReducer.storeTextForAddEditUser,
        roleIdForAddEditUserProps: props.characterReducer.roleIdForAddEditUser,
        creditLimitForAddEditUserProps: props.characterReducer.creditLimitForAddEditUser,
        loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
        DefaultCountryCode:props.characterReducer.defaultCountryCode,
        uniqueNumberCostProps:props.characterReducer.uniqueNumbetCostForAddEditUser,
        disableProps:props.characterReducer.disabledForAddEditUser,
        groupForAddEditProps:props.characterReducer.groupForAddEditReducer,
        enableEmailForAddEditUserProps:props.characterReducer.enableEmailForAddEditUserReducer,
        costCodeForAddEditUserProps:props.characterReducer.costCodeForAddEditUserReducer




    };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, null, any>, props: any) => {
    return {
        getloggedInUserDetail: (email: any) => {
            dispatch(action.loggedInUserEmail(email))
        },
        getSubscriptionBycompId: (compId: any) => {
            dispatch<any>(action.getSubscriptionList(compId, "Users"))
        },
        setEmail: (value: any) => {
            dispatch(action.emailForUser(value))
        },
        setName: (value: any) => {
            dispatch(action.nameForUser(value))
        },
        setCreditLimit: (value: any) => {
            dispatch(action.creditLimitForUser(value))
        },
        setRoleId: (value: any) => {
            dispatch(action.roleIdForUser(value))
        },
         setUsedCredits: (value: any) => {
            dispatch(action.usedCreditForUser(value))
        },
        uniqueNumberProps: (value: any) => {
            dispatch(action.showNumberInUniqueNumber(value))
        },
        setDefaultCountryCode: (value: any) => {
            dispatch(action.defaultCountryCodeForUser(value))
        },
        addUpdateUserProp: (type: string, data: {}, compId: any) => {
            dispatch(action.addUpdateUsers(type, data, compId, "myProfile"))
        },
        setRegisterationDate: (value: any) => {
            dispatch(action.registerationDateForUser(value))
        },
        uniqueNumberCostForAddEdit:(value:any)=>{
            dispatch(action.uniqueNumberCostForUser(value))
        },
        setCostCode:(value:any)=>{
            dispatch(action.setCostCode(value))
        }
    }
}
export default connect(mapStateToProps,
    mapDispatchToProps)(MyProfileComponent);