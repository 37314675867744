import * as React from "react";
//import * as commonMethod from "../../commonMethods";
import ConfirmationDeleteComponent from "../sharedComponents/confirmationDeleteComponent";

export class WebexIntegrationComponent extends React.Component<any, any> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      id: this.props?.editData?.Id || "",
      webExName:
        this.props?.companyWebEx?.webHookRequests !== null
          ? this.props?.companyWebEx?.webHookRequests.length > 0
            ? this.props?.companyWebEx?.webHookRequests[0]?.name
            : ""
          : "",
      targetURL:
        this.props?.companyWebEx?.webHookRequests !== null
          ? this.props?.companyWebEx?.webHookRequests.length > 0
            ? this.props?.companyWebEx?.webHookRequests[0]?.targetUrl
            : ""
          : "",
      webExAccessToken: this.props?.companyWebEx?.webexToken || "",
      isEnable:
        this.props?.companyWebEx?.webHookRequests !== null
          ? this.props?.companyWebEx?.webHookRequests.length > 0
            ? true
            : false
          : false,
      companyId: this.props?.companyId,
      myCompanyID: "",
      companyVersion:
        this.props.calledFrom === "edit"
          ? this.props?.editData?.Version
          : this.props.loggedInUserRole === 5
          ? this.props.myCompanyVersion
          : 1,
    };
  }
  IsValid() {
    var isValid = true;
    if (!this.state.webExName) {
      this.setState({ ErrorName: "This Field is Required" });
      isValid = false;
    }
    if (!this.state.webExAccessToken) {
      isValid = false;
      this.setState({
        ErrorCurrency: "This Field is Required",
      });
    }

    return isValid;
  }
  addUpdateCompanyWebEx = (e: any) => {
    if (this.IsValid() === true) {
      //if (this.props?.companyWebEx?.WebExName !== undefined ||this.props?.companyWebEx?.WebExName !== "" ) {

      this.props.addUpdateCompanyWebex(
        this.state.companyId,
        this.state.webExName,
        this.state.targetURL,
        this.state.webExAccessToken,
        "add"
      );
      // } else {
      //   this.props.addUpdateCompanyWebex(this.state.companyId,this.state.webExName,
      //     this.state.targetURL, this.state.webExAccessToken,"add");
      // }
    }
  };
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    if (
      nextProps.parentCompanyList !== prevState.companyListState ||
      nextProps.myCompanyId !== prevState.myCompanyID
    ) {
      return {
        companyListState: nextProps.parentCompanyList,
        myCompanyID: nextProps.myCompanyId,
      };
    } else return null;
  }
  componentDidMount() {
    // if (this.props.calledFrom === "edit") {
    //   var selectedPArentCompanyName =
    //     this.state.companyListState !== undefined
    //       ? this.state.companyListState.length !== 0
    //         ? this.props.editData.ParentId !== 0
    //           ? this.state.companyListState
    //               .filter((x: any) => x.Id === this.props.editData.ParentId)
    //               .map((x: any) => x.Name)[0]
    //           : ""
    //         : ""
    //       : "";
    //   this.setState({
    //     SelectParentCompanyName: selectedPArentCompanyName,
    //   });
    // }
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <div className="nav-tabs-top">
            <div className="tab-content border-0">
              <div className="tab-pane fade show active" id="user-edit-account">
                <div className="card-body pb-2">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="formDiv">
                        <div className="row">
                          <div className="col-md-4">
                            <div
                              className={
                                this.state.ErrorName !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Web Hook Name
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"Web Hook Name"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    webExName: e.target.value,
                                    ErrorName: "",
                                  });
                                }}
                                defaultValue={this.state.webExName}
                              />
                              <small className="form-text ">
                                {this.state.ErrorName}{" "}
                              </small>
                            </div>
                            <div
                              className={
                                this.state.ErrorName !== ""
                                  ? "form-group validationClass"
                                  : "form-group"
                              }
                            >
                              <label className="form-label">
                                Token
                                <small className="text-muted">*</small>
                              </label>
                              <input
                                type="text"
                                placeholder={"WebEx Access Token"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    webExAccessToken: e.target.value,
                                    ErrorName: "",
                                  });
                                }}
                                defaultValue={this.state.webExAccessToken}
                              />
                              <small className="form-text ">
                                {this.state.ErrorName}{" "}
                              </small>
                            </div>
                            <div className="form-group">
                              <label className="form-label">Target URL</label>
                              <input
                                type="text"
                                placeholder={"Target URL"}
                                className="form-control"
                                onChange={(e) => {
                                  this.setState({
                                    targetURL: e.target.value,
                                  });
                                }}
                                value={this.state.targetURL}
                              />
                            </div>
                            <div className="form-group">
                              <label className="custom-control custom-checkbox m-0 checkbox_right">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={(e) => {
                                    this.setState({
                                      isEnable: e.target.checked,
                                    });
                                  }}
                                  checked={this.state.isEnable}
                                />
                                <span className="custom-control-label">
                                  Is Enable
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="btnDiv d-flex justify-content-between align-items-center">
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary saveBtn"
                            onClick={(e) => this.addUpdateCompanyWebEx(e)}
                          >
                            Save
                          </button>
                          <button
                            type="button"
                            className="btn btn-default cancelBtn"
                            onClick={this.props.changeState}
                          >
                            Cancel
                          </button>
                        </div>
                        <div>
                          {this.props.calledFrom === "edit" ? (
                            <button
                              type="button"
                              className="btn btn-primary delete_btn"
                              data-toggle="modal"
                              data-target="#exampleModal"
                            >
                              <span className="font-icon">&#107;</span>Delete
                            </button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConfirmationDeleteComponent
            deleteSucces={() => {
              // debugger;
              this.props.deleteCompanyWebEx(this.state.companyId);
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
