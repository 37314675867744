import * as React from "react";
import * as commonMethod from "../../commonMethods";
import { connect } from "react-redux";
import Select, { components } from "react-select";
//import PhoneInput from 'react-phone-input-2';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { getLoggedInUSerName } from "../../adalConfig";
import { ThunkDispatch } from "redux-thunk";
import * as action from "../../redux/actions/actions";
import "react-phone-input-2/lib/style.css";
import NotificationComponent from "../sharedComponents/displayNotifications";
const CaretDownIcon = () => {
  return <div className="number_arrow"></div>;
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
class MyCompanyComponent extends React.Component<any, any> {
  constructor(props: any, state: any) {
    super(props);
    this.state = {
      pendingResult: true,
      companyName: "",
      primaryContactName: "",
      primaryContactEmail: "",
      primaryContactPhone: "",
      billingAddresscountry: "",
      billingAddresscurrency: "",
      billingAddresscity: "",
      billingAddresszipCode: "",
      companyId: "",
      billingAddressline3: "",
      InvalidEmail: "",
      ErrorName: "",
      ErrorCountry: "",
      ErrorCity: "",
      ErrorZipCode: "",
      ErrorLine1: "",
      isParent: false,
      companyListState: [],
      parentID: "",
      subscriptionCost: "",
      registrationDate: "",
      SelectParentCompanyName: "",

      //Disable
      disableCompanyName: false,
      disableCompanyEmail: true,
      disableRegisterationDate: false,
      diablePrimaryContactName: false,
      disablePRimaryContactPhone: false,
      disaleIsPArent: false,
      disablePArentId: false,
      disableSubscriptionCost: false,
      disableCurrency: false,
    };
  }
  componentDidMount() {
    var email = getLoggedInUSerName().userName;
    this.props.getMyCompanyDetail(email);
    this.props.getParentCompaniesListAction();
    this.rolePermission();
  }
  rolePermission() {
    switch (this.props.loggedinUserAccessLevel) {
      case -10: {
        this.setState({
          disableCompanyName: false,
          disableRegisterationDate: false,
          diablePrimaryContactName: false,
          disablePRimaryContactPhone: false,
          disaleIsPArent: false,
          disablePArentId: false,
          disableSubscriptionCost: false,
          disableCurrency: false,
        });
        break;
      }
      //Partner admin
      case 5: {
        this.setState({
          disableCompanyName: false,
          disableRegisterationDate: true,
          diablePrimaryContactName: false,
          disablePRimaryContactPhone: false,
          disaleIsPArent: false,
          disablePArentId: false,
          disableSubscriptionCost: false,
          disableCurrency: true,
        });
        break;
      }
      //Company aDmin
      case 0: {
        this.setState({
          disableCompanyName: false,
          disableRegisterationDate: true,
          diablePrimaryContactName: false,
          disablePRimaryContactPhone: false,
          disaleIsPArent: false,
          disablePArentId: false,
          disableSubscriptionCost: false,
          disableCurrency: true,
        });
        break;
      }
      default: {
        this.setState({
          disableCompanyName: true,
          disableRegisterationDate: true,
          diablePrimaryContactName: true,
          disablePRimaryContactPhone: true,
          disaleIsPArent: true,
          disablePArentId: true,
          disableSubscriptionCost: true,
          disableCurrency: true,
        });
        break;
      }
    }
  }
  static getDerivedStateFromProps(nextProps: any, prevState: any) {
    var selectedPArentCompanyName = "";
    if (
      nextProps.pendingLoggedInCompanyDetail !== prevState.pendingResult ||
      nextProps.MyCompanyName !== prevState.companyName ||
      nextProps.MyCompanyEmail !== prevState.primaryContactEmail ||
      nextProps.MyPrimaryContactName !== prevState.primaryContactName ||
      nextProps.MyPrimaryContactNumber !== prevState.primaryContactPhone ||
      nextProps.MyCompanySubscriptionCost !== prevState.subscriptionCost ||
      nextProps.MyCompanyId !== prevState.companyId ||
      nextProps.MyCompanyLine3 !== prevState.billingAddressline3 ||
      nextProps.MyCompanyCity !== prevState.billingAddresscity ||
      nextProps.MyCompanyCountry !== prevState.billingAddresscountry ||
      nextProps.MyCompanyZipCode !== prevState.billingAddresszipCode ||
      nextProps.MyCompanyCurrency !== prevState.billingAddresscurrency ||
      nextProps.parentCompanyList !== prevState.companyListState ||
      nextProps.isParentMyCompany !== prevState.isParent ||
      nextProps.parentIdMyCompany !== prevState.parentID ||
      nextProps.myCompanyRegisterationDate !== prevState.registrationDate
    ) {
      if (
        nextProps.parentCompanyList !== undefined &&
        nextProps.parentIdMyCompany !== undefined
      ) {
        selectedPArentCompanyName = nextProps.parentCompanyList
          .filter((x: any) => x.Id === nextProps.parentIdMyCompany)
          .map((x: any) => x.Name)[0];
      }

      return {
        companyName: nextProps.MyCompanyName,
        primaryContactEmail: nextProps.MyCompanyEmail,
        primaryContactName: nextProps.MyPrimaryContactName,
        pendingResult: nextProps.pendingLoggedInCompanyDetail,
        primaryContactPhone: nextProps.MyPrimaryContactNumber,
        subscriptionCost: nextProps.MyCompanySubscriptionCost,
        companyId: nextProps.MyCompanyId,
        billingAddressline3: nextProps.MyCompanyLine3,
        billingAddresscountry: nextProps.MyCompanyCountry,
        billingAddresscity: nextProps.MyCompanyCity,
        billingAddresszipCode: nextProps.MyCompanyZipCode,
        billingAddresscurrency: nextProps.MyCompanyCurrency,
        companyListState: nextProps.parentCompanyList,
        isParent: nextProps.isParentMyCompany,
        parentID: nextProps.parentIdMyCompany,
        registrationDate: nextProps.myCompanyRegisterationDate,
        SelectParentCompanyName: selectedPArentCompanyName,
      };
    } else return null;
  }

  IsValid() {
    var isValid = true;
    if (this.state.companyName.length === 0) {
      isValid = false;
      this.setState({ ErrorName: "This Field is Required" });
    }
    return isValid;
  }

  addUpdateData = (e: any) => {
    if (
      this.state.primaryContactEmail.length !== 0 &&
      commonMethod.validEmail(this.state.primaryContactEmail) === false
    ) {
      return false;
    }
    if (this.IsValid() === true) {
      var dataBody = {
        id: this.state.companyId,
        name: this.state.companyName,
        registrationDate: this.state.registrationDate,
        subscriptions: {},
        subscriptionCost: this.state.subscriptionCost,
        currency: this.state.billingAddresscurrency,
        primaryContactPhone: this.state.primaryContactPhone,
        primaryContactEmail: this.state.primaryContactEmail,
        primaryContactName: this.state.primaryContactName,
        isParent: this.state.isParent,
        parentId: this.state.parentID,
      };
      this.props.addUpdatedCompanyProps("edit", dataBody);
    }
  };

  renderButtonHtml = () => {
    return (
      <div className="col-12">
        <div className="btnDiv d-flex justify-content-between align-items-center">
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => this.addUpdateData(e)}
            >
              Save changes
            </button>
            <button
              type="button"
              className="btn btn-default cancelBtn"
              onClick={() => window.history.back()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  };
  renderHtml() {
    return (
      <div className="nav-tabs-top">
        <div className="tab-content border-0">
          <div className="tab-pane fade show active" id="user-edit-account">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="formDiv">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.ErrorName !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Company Name<small className="text-muted">*</small>
                          </label>
                          <input
                            type="text"
                            placeholder={"Company Name"}
                            className="form-control"
                            onChange={(e) => {
                              this.props.setCompanyName(e.target.value);
                              this.setState({ ErrorName: "" });
                            }}
                            value={this.state.companyName}
                            disabled={this.state.disableCompanyName}
                          />
                          <small className="form-text ">
                            {this.state.ErrorName}{" "}
                          </small>
                        </div>
                        <div className="form-group">
                          <label className="form-label">
                            Primary Contact Name
                          </label>
                          <input
                            type="text"
                            placeholder={"Primary Contact"}
                            className="form-control"
                            onChange={(e) => {
                              this.props.setPrimaryContactName(e.target.value);
                            }}
                            disabled={this.state.diablePrimaryContactName}
                            value={this.state.primaryContactName}
                          />
                        </div>
                        <div
                          className={
                            this.state.InvalidEmail !== ""
                              ? "form-group validationClass"
                              : "form-group"
                          }
                        >
                          <label className="form-label">
                            Primary Contact Email
                          </label>
                          <input
                            placeholder={"Email"}
                            type="text"
                            className="form-control"
                            onChange={(e) => {
                              var valid = commonMethod.validEmail(
                                e.target.value
                              );
                              if (!valid) {
                                this.setState({
                                  InvalidEmail:
                                    "Please Enter Valid Email Address",
                                });
                              } else {
                                this.setState({ InvalidEmail: "" });
                              }
                              this.props.setPrimaryContactEmail(e.target.value);
                            }}
                            value={this.state.primaryContactEmail}
                            disabled={this.state.disableCompanyEmail}
                          />
                          <small className="form-text">
                            {this.state.InvalidEmail}{" "}
                          </small>
                        </div>

                        <div className="form-group">
                          <label className="form-label">
                            Primary Contact Number
                          </label>
                          <input
                            type="text"
                            placeholder={"Number"}
                            className="form-control"
                            onChange={(e) => {
                              this.props.setPrimaryContactNumber(
                                e.target.value
                              );
                            }}
                            value={this.state.primaryContactPhone}
                            disabled={this.state.disableCompanyName}
                          />
                        </div>
                        <div className={"form-group"}>
                          <label className="form-label">Created Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="validation-email"
                            placeholder="Created Date"
                            onChange={(e: any) => {
                              var dateObj = new Date(e.target.value);
                              this.props.setRegisterationDate(
                                dateObj.toISOString()
                              );
                            }}
                            disabled={this.state.disableRegisterationDate}
                            value={
                              this.state.registrationDate !== "" &&
                              this.state.registrationDate !== undefined
                                ? this.state.registrationDate.split("T")[0]
                                : ""
                            }
                          />
                        </div>
                        {/* Display if logged in user id superadmin */}
                        {this.props.loggedinUserAccessLevel === -10 ? (
                          <div>
                            <div className="form-group">
                              <label className="custom-control custom-checkbox m-0 checkbox_right">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  onChange={() => {
                                    this.props.setIsParent(
                                      !this.state.isParent
                                    );
                                  }}
                                  checked={
                                    this.state.isParent !== undefined
                                      ? this.state.isParent
                                      : false
                                  }
                                  disabled={this.state.disaleIsPArent}
                                />
                                <span className="custom-control-label">
                                  Is Parent
                                </span>
                              </label>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-md-6">
                        <div className="form-group marginBtm15">
                          <label className="form-label">Currency</label>
                          <input
                            type="text"
                            placeholder={"State"}
                            className="form-control"
                            onChange={(e) => {
                              this.props.setCurrencyValue(e.target.value);
                            }}
                            value={this.state.billingAddresscurrency}
                            disabled={this.state.disableCurrency}
                          />
                        </div>
                        {this.props.loggedinUserAccessLevel === -10 ? (
                          this.state.isParent === false ? (
                            <div className={"form-group"}>
                              <label className="form-label">
                                Parent Company{" "}
                              </label>
                              <Select
                                isDisabled={this.state.disablePArentId}
                                placeholder="Select"
                                components={{ DropdownIndicator }}
                                value={[
                                  {
                                    label: this.state.SelectParentCompanyName,
                                    value: this.state.SelectParentCompanyId,
                                  },
                                ]}
                                onChange={(opt: any) => {
                                  this.props.setParentId(opt.value);
                                  this.setState({
                                    SelectParentCompanyName: opt.label,
                                  });
                                }}
                                options={
                                  this.state.companyListState !== undefined
                                    ? this.state.companyListState.map(
                                        (opt: any) => ({
                                          label: opt.Name,
                                          value: opt.Id,
                                        })
                                      )
                                    : []
                                }
                              />
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {this.renderButtonHtml()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="layout-content">
          <NotificationComponent />

          <div className="container-fluid subscription_box">
            {this.state.pendingResult === true ? (
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="">
                <h2 className="companyName  text-left">
                  <span className="iconNavigation cursor_pointer">
                    <i
                      className="fa fa-chevron-left cursor_pointer"
                      aria-hidden="true"
                      onClick={() => window.history.back()}
                    ></i>
                  </span>
                  {this.state.companyName === undefined ||
                  this.state.companyName === "" ? (
                    <b>{this.state.companyName}</b>
                  ) : (
                    <b>{this.state.companyName}</b>
                  )}
                </h2>
                <Tabs
                  className="tabGroupMember"
                  defaultActiveKey={"Company Detail"}
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    eventKey="Company Detail"
                    title={
                      <span>
                        <span className="font-icon">&#67;</span> Company Details
                      </span>
                    }
                  >
                    {this.renderHtml()}
                  </Tab>
                </Tabs>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// Grab the characters from the store and make them available on props
const mapStateToProps = (props: any) => {
  return {
    errorOccured: props.characterReducer.errorListMessage,
    savedSuccessfull: props.characterReducer.dataSaved,
    pendingLoggedInCompanyDetail:
      props.characterReducer.pendingLoggedInCompanyDetailReducer,
    MyCompanyName: props.characterReducer.myCompanyNameReducer,
    MyCompanyEmail: props.characterReducer.myCompanyEmailReducer,
    MyPrimaryContactName:
      props.characterReducer.myCompanyPrimaryContactNameReducer,
    MyPrimaryContactNumber:
      props.characterReducer.myCompanyPrimaryContactNumberReducer,
    MyCompanySubscriptionCost:
      props.characterReducer.myCompanySubscriptionCostReducer,
    MyCompanyId: props.characterReducer.myCompanyIdReducer,
    MyCompanyLine3: props.characterReducer.myCompanyLine3Reducer,
    MyCompanyCity: props.characterReducer.myCompanyCityReducer,
    MyCompanyCountry: props.characterReducer.myCompanyCountryReducer,
    MyCompanyZipCode: props.characterReducer.myCompanyZipCodeReducer,
    MyCompanyCurrency: props.characterReducer.myCompanyCurrencyReducer,
    parentCompanyList: props.characterReducer.parentCompanyListReducer,
    loggedinUserAccessLevel: props.characterReducer.loggedinUserAccessLevel,
    isParentMyCompany: props.characterReducer.myCompanyIsParentReducer,
    parentIdMyCompany: props.characterReducer.myCompanyParentIDReducer,
    myCompanyRegisterationDate:
      props.characterReducer.myCompanyRegisterationDateReducer,
  };
};
const mapDispatchToProps = (
  dispatch: ThunkDispatch<{}, null, any>,
  props: any
) => {
  return {
    getMyCompanyDetail: (email: any) => {
      dispatch(action.loggedInCompanyDetail(email));
    },
    setCompanyName: (value: any) => {
      dispatch(action.myCompanyName(value));
    },
    setPrimaryContactEmail: (value: any) => {
      dispatch(action.myCompanyEmail(value));
    },
    setPrimaryContactName: (value: any) => {
      dispatch(action.myCompanyPrimaryContactName(value));
    },
    setPrimaryContactNumber: (value: any) => {
      dispatch(action.myCompanyPrimaryContactNumber(value));
    },
    setSubscriptionCost: (value: any) => {
      dispatch(action.myCompanySubscriptionCost(value));
    },

    setLine3: (value: any) => {
      dispatch(action.myCompanyLine3(value));
    },
    setCompanyCity: (value: any) => {
      dispatch(action.myCompanyCity(value));
    },
    setMyCountry: (value: any) => {
      dispatch(action.myCompanyCoutnry(value));
    },
    setZipCode: (value: any) => {
      dispatch(action.myCompanyZipCode(value));
    },
    setCurrencyValue: (value: any) => {
      dispatch(action.myCompanyCurrency(value));
    },
    getParentCompaniesListAction: () => {
      dispatch(action.getParentCompaniesList());
    },
    setIsParent: (value: any) => {
      dispatch(action.myCompanyIsParent(value));
    },
    setParentId: (value: any) => {
      dispatch(action.myCompanyParentId(value));
    },
    setRegisterationDate: (value: any) => {
      dispatch(action.setRegisterationDateCompany(value));
    },
    addUpdatedCompanyProps: (type: string, object: {}) => {
      dispatch(action.addUpdateCompany(type, object, "myCompanyProfile"));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCompanyComponent);
